import React from "react";
import { Header } from "../../components/Header";
import { Container } from "./style";
import { FaCheckCircle } from "react-icons/fa";
import { useHistory } from "react-router";
import { useGlobal } from "../../hooks/global";

export const Finish: React.FC = () => {
  const { push } = useHistory();
  const { setAtualPageGlobal, languagePage } = useGlobal();

  if (languagePage === "PT") {
    return (
      <Container>
        <Header />
        <div
          style={{
            height: "100%",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            fontFamily: "Gotham-Bold",
            color: "#FFF",
            fontSize: 26,
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 40, color:"#021F3A" }}
          >
            <FaCheckCircle
              style={{
                marginRight: 10,
                boxShadow: "4px 4px 20px -2px rgba(0, 0, 0, 0.7)",
                borderRadius: 15,
                height: 30,
                width: 30,
              }}
              size={30}
              color="#021F3A"
            />
            Perfeito, você concluiu seu cadastro!
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
              fontSize: 20,
            }}
          >
            <div
              onClick={() => push("/company/planos")}
              style={{
                background: "#134480",
                cursor: "pointer",
                padding: 10,
                borderRadius: 10,
                marginRight: 10,
                boxShadow: "4px 4px 20px -2px rgba(0, 0, 0, 0.7)",
              }}
            >
              Consultar planos
            </div>
            <div
              onClick={() => push("/login")}
              style={{
                background: "#134480",
                alignItems: "center",
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                padding: 10,
                borderRadius: 10,
                boxShadow: "4px 4px 20px -2px rgba(0, 0, 0, 0.7)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: 20,
                  width: 20,
                  marginRight: 20,
                }}
              >
                <img
                  src={
                    "https://res.cloudinary.com/hkkzuevm3/image/upload/v1642441717/Assets-FlashPlayHr/areaCliente_rakd41_xl8lsw.png"
                  }
                />
              </div>
              Área do Cliente
            </div>
          </div>
        </div>
      </Container>
    );
  } else {
    return (
      <Container>
        <Header />
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontFamily: "Gotham-Bold",
            color: "#FFF",
            fontSize: 26,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 40 }}
          >
            <FaCheckCircle
              style={{
                marginRight: 10,
                boxShadow: " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                borderRadius: 15,
              }}
              size={30}
              color="#009A49"
            />
            Perfect, you have completed your registration!
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
              fontSize: 20,
            }}
          >
            <div
              onClick={() => push("/company/planos")}
              style={{
                background: "#134480",
                cursor: "pointer",
                padding: 10,
                borderRadius: 10,
                marginRight: 10,
                boxShadow: " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
              }}
            >
              Check our plans
            </div>
            <div
              onClick={() => push("/login")}
              style={{
                background: "#134480",
                alignItems: "center",
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                padding: 10,
                borderRadius: 10,
                boxShadow: " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
              }}
            >
              <div style={{ display: "flex", height: 20, marginRight: 10 }}>
                <img
                  src={
                    "https://res.cloudinary.com/hkkzuevm3/image/upload/v1635024167/Assets-FlashPlayHr/areaCliente_rakd41.png"
                  }
                />
              </div>
              Customer's area
            </div>
          </div>
        </div>
      </Container>
    );
  }
};
