import React, { useState } from 'react';

const ToggleButton = ({ tag, tagMarc, setTagMarc }) => {
    const isActive = tagMarc.id === tag.id;

    const handleClick = () => {
        setTagMarc(isActive ? {} : tag);
    };

    return (
        <button
            onClick={handleClick}
            style={{
                backgroundColor: isActive ? "#66bb6a" : "#cccccc",
                color: "white",
                padding: "10px 20px",
                cursor: "pointer",
                borderRadius: "20px",
                outline: "none",
                boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
            }}
        >
            {isActive ? (
                <div>Listando</div>
            ) : (
                <div style={{ color: "#05264e" }}>Listar "quiz"</div>
            )}
        </button>
    );
};

export default ToggleButton;
