import styled from "styled-components";

const SocialBarContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr;
`;

const ExternalLink = styled.a`
	color: #d6d6d6;
	text-decoration: none;
	a,
	:hover,
	:focus,
	:active {
		text-decoration: none;
		color: inherit;
	}
`;

export { SocialBarContainer, ExternalLink };
