import styled from "styled-components";

export const Conteiner = styled.div`
  margin: auto;
  color: #fff;
  font-family: "Gotham-Medium";
  max-width: 800px;
  min-width: 200px;
  width: 100%;

  @media (max-width: 768px) {
    width: 90%;
  }

  .cards {
    margin: 10px;

    background: #134480;
    max-width: 300px;
    min-width: 200px;
    width: 100%;
    // display:block;
    // border-radius: 10px;
  }
  .scrollEstilo {
    scrollbar-width: thin;
    scrollbar-color: #00213d #061522;
    padding: 10px;
    height: 60vh;
    margin-left: 10px;

    ::-webkit-scrollbar {
      width: 10px;
      border-radius: 20px;
    }

    ::-webkit-scrollbar-track {
      background: #061522;
      border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #00213d;
      border-radius: 20px;
      border: 3px solid #061522;
    }
  }

  // @media (max-width:576px){
  //     .cards{

  //         margin:auto;
  //         margin-bottom:10px;

  //     }
  // }

  .buttons {
    color: #fff;
    background: #1c993f;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }

  .card-img {
    padding: 10px;
  }
`;

export const Titulo = styled.h4`
  text-align: center;
  font-family: "Gotham-Bold", sans-serif;
  color: #134480;
`;

interface ButtonProps {
  background: string;
  backgroundHover: string;
}
export const Button = styled.button<ButtonProps>`
  cursor: pointer;

  padding: 10px 16px;
  border-radius: 5px;
  border: none;
  margin-right: 8px;

  font-family: "Gotham-Medium", sans-serif;
  font-size: 14px;
  background: ${(props) => props.background};

  color: #fff;
  transition: 0.5s;

  span {
    visibility: hidden;
  }

  :hover {
    background: ${(props) => props.backgroundHover};
    span {
      visibility: visible;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

export const Span = styled.span`
  display: flex;
  position: absolute;
  left: -25%;
  color: #fff;
  flex: 1;
  white-space: nowrap;
  transform: translatex(25%);
  bottom: calc(15% + 8px);
  background: #2f3a8f;
  padding: 0.625rem;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  ::before {
    border-style: solid;
    border-color: #2f3a8f transparent;
    border-width: 0.375rem 0.375rem 0 0.375rem;
    bottom: -0.3125rem;
    content: "";
    left: 50%;
    transform: translatex(-50%);
    position: absolute;
  }
  box-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 0.5);
  z-index: 5;
`;
export const Conteudo = styled.div`
  margin: 10px;
`;

export const BoxButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface StatusProps {
  state: boolean | null;
}
export const Status = styled.div<StatusProps>`
  padding: 4px 8px;
  border-radius: 2.5px;

  background: ${(props) =>
    props.state === null
      ? "#b5b826"
      : props.state === true
      ? "#58bb4d"
      : "#DB2016"};
  p {
    font-family: "Gotham-Bold", sans-serif;
    color: #fff;
    font-size: 12px;
    margin: auto;
  }
`;

export const FooterText = styled.h6`
  font-family: "Gotham-Bold", sans-serif;
  font-size: 16px;
  color: #4e4e4e;
`;
