import styled, { css } from "styled-components";
import Colors from "../../styles";

interface ContainerType {
  type: string;
}

interface BolinhaData {
  isActive: boolean;
}

export const Container = styled.div<ContainerType>`

  display: flex;
  height: 100vh;
  position: relative;
  flex-direction: column;
  background-position: bottom;
  transition: all 1s;
  border: solid;
  border-color: #021f3a;
  
  ${(props) =>
    props.type === "empresa" &&
    css`
      background-image: url("https://res.cloudinary.com/hkkzuevm3/image/upload/v1648759101/Assets-FlashPlayHr-webp/fundoTelaDeEmpresa_h64p94_sotoow.webp");
      background-size: cover;
      
    `}
  ${(props) =>
    (props.type === "candidato" || props.type === "consultor" || props.type === "both" || props.type === "selections" || props.type === "consultancy") &&
    css`
      background-size: cover;
      
    `}
    ${(props) =>
    props.type === "" &&
    css`
      background-size: cover;
      background-repeat: no-repeat;
      @media(max-width:375px){
        height: 150vh;

      }
     
     
      
    `}

    .form-logon{
      display: flex;
      height: 70%;
      position: absolute;
      width: 100%;
      justify-content: center
    }

    .form-logon-dados{
      height: 50%;
      position: absolute;
      width: 100%;
      justify-content: center
      color:#23238E !important;
    }

    .opcoes{
      padding: 10px; margin-top: 100px; width: 100px;
      color:#fff;
      font-size:22px;
      font-family: "Gotham-Medium", sans-serif;
    }
`;
export const Button = styled.button`
border-radius: 20px;
box-shadow: 4px 4px 18px -2px rgba(0,0,0,0.75);
-webkit-box-shadow: 4px 4px 18px -2px rgba(0,0,0,0.75);

cursor: pointer;
flex-direction: row;
align-items: center;
background: #0b243b;

margin: auto;
width: 100%;
min-width: 150px;
max-width:250px;

margin-bottom:20px;
color:#fff;
font-size:32px;
font-family: "Gotham-Medium", sans-serif;
   

    :hover{
      
    }

`
export const Bolinha = styled.div<BolinhaData>`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: ${Colors.backgroundWhite};
  transition: all 0.3s;
  ${(props) =>
    props.isActive &&
    css`
      background: ${"#134480"};
    `}
`;
