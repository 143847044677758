import React, { useCallback, useEffect, useRef, useState } from "react";
import InputPerfil, { InputRefProps } from "../../../components/InputPerfil";
import ModalAlteraSenha from "../../../components/Modals/AlteraSenha";
import { useAuth } from "../../../hooks/auth";

import api from "../../../services/api";
import { useGlobal } from "../../../hooks/global";
import { fileProps } from "../../../interfaces/fileUploadInterface";
import { Modal } from "@material-ui/core";
import { LoadingSale } from "../../../components/Animations/LoadingSale";
import { CardContainer, ContainerLoading, Descricao } from "./style";
import { storage } from "../../../firebase";
import CancelContaPlano from "../../../components/Modals/CancelContaPlano";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useToast } from "../../../hooks/toast";
import { useHistory } from "react-router-dom";
import Toastify from "toastify-js";

const PainelDoConsultor: React.FC = () => {
  const { user, setUser, signOut } = useAuth();

  const { push } = useHistory();

  const { addToast } = useToast();
  const [modal, setModal] = useState<boolean>(false);
  const [valorHora, setValorHora] = useState<string>(user.valueHour);
  const [anoExperiencia, setAnoExperiencia] = useState<string>(
    String(user.yearExperience === null ? "" : user.yearExperience)
  );
  const [openModalAlteraSenha, setOpenModalAlteraSenha] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<fileProps>(
    user.avatar ? ({ preview: user.avatar } as fileProps) : ({} as fileProps)
  );
  const [pdf, setPdf] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const inputNameRef = useRef<InputRefProps>(null);
  const inputValorHora = useRef<InputRefProps>(null);
  const inputAnoExperiencia = useRef<InputRefProps>(null);
  const { setAtualPageGlobal, languagePage } = useGlobal();

  const [expecialidade, setExpecialidade] = useState<string>(user.specs);

  useEffect(() => {
    setAtualPageGlobal("/candidate/painelDoConsultor");
  }, []);

  const deleteAccount = async () => {
    const res = await api.post("/common/delete-account");

    const { deleted, error } = res.data;

    if (deleted) {
      addToast({
        type: "success",
        icon: "info",
        title:
          languagePage === "PT"
            ? "Sua conta foi excluida!"
            : "Your account has been deleted!",
        description:
          languagePage === "PT"
            ? "Foi um prazer ter você na nossa plataforma."
            : "Your account has been deleted!",
      });
      signOut();
      push("/#");
    } else {
      addToast({
        type: "error",
        icon: "alert",
        title: languagePage === "PT" ? "Aviso" : "Notice",
        description: error,
      });
    }
  };
  const OnChangeInputValorHora = useCallback((event) => {
    setValorHora(event.target.value);
  }, []);

  const OnChangeInputAnoExperiencia = useCallback((event) => {
    setAnoExperiencia(event.target.value);
  }, []);

  const onClickSave = useCallback(async () => {
    if (Number(valorHora) <= 0 || Number(anoExperiencia) <= 0) {
      Toastify({
        text:
          languagePage === "PT"
            ? "Coloque um valor valido!!!"
            : "Enter a valid value!!!",

        duration: 3000,
        style: {
          background: "linear-gradient(to right, red, #ccc)",
        },
      }).showToast();
    } else {
      Toastify({
        text:
          languagePage === "PT"
            ? "Mudança de valor feita com sucesso feita com sucesso!"
            : "Value change done successfully done successfully!",

        duration: 3000,
        style: {
          background: "linear-gradient(to right, #00b09b, #96c93d)",
        },
      }).showToast();
      const date = Date.now().toString();
      setLoading(true);

      setTimeout(async () => {
        if (uploadedFiles.file) {
          const data = new FormData();
          data.append("avatar", uploadedFiles.file, uploadedFiles.name);
          await api.patch("/avatar", data);
        }
        if (!!pdf && pdf.path !== null) {
          const uploadTask = storage
            .ref(`curriculum/${date}-${pdf.name}`)
            .put(pdf);

          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              console.log(error);
            },
            () => {
              storage
                .ref("curriculum")
                .child(`${date}-${pdf.name}`)
                .getDownloadURL()
                .then(async (url) => {
                  await api.post("/common", {
                    ...user,
                    value: valorHora,
                    specs: expecialidade,
                    yearExperience: anoExperiencia,
                  });

                  setUser({ ...user, valueHour: valorHora });
                  setUser({ ...user, specs: expecialidade });
                  setUser({ ...user, yearExperience: Number(anoExperiencia) });
                  localStorage.setItem(
                    "@flashPlayHR:user",
                    JSON.stringify({
                      ...user,
                      valueHour: valorHora.replace(",", "."),
                    })
                  );
                  setLoading(false);
                });
            }
          );
        } else {
          await api.post("/common/valor-hora", {
            ...user,
            value: valorHora.replace(",", "."),
            specs: expecialidade,
            yearExperience: anoExperiencia,
          });
          setUser({ ...user, valueHour: valorHora });
          setUser({ ...user, specs: expecialidade });
          setUser({ ...user, yearExperience: Number(anoExperiencia) });
          localStorage.setItem(
            "@flashPlayHR:user",
            JSON.stringify({ ...user, valueHour: valorHora })
          );
          setLoading(false);
        }
      }, 200);
    }
  }, [
    valorHora,
    anoExperiencia,
    languagePage,
    uploadedFiles.file,
    uploadedFiles.name,
    pdf,
    user,
    expecialidade,
    setUser,
  ]);

  const SenhaAlterada = useCallback((data) => {
    setOpenModalAlteraSenha(false);
    setLoading(true);
    setTimeout(async () => {
      try {
        await api.post("/editPassword", data);
      } catch (e) {}
      setLoading(false);
    }, 200);
  }, []);
  return (
    <div>
      <Descricao>
        {languagePage === "PT"
          ? "Descrição: Mindset Camaleão ++, Líder/consultores-Economia Circular e ESG"
          : "Description: Chameleon ++ Mindset, Leader/Consultants - Circular Economy and ESG"}
      </Descricao>
      <CardContainer>
        <CancelContaPlano
          open={modal}
          model="account"
          onClose={() => setModal(false)}
          onExclude={deleteAccount}
        />
        <ModalAlteraSenha
          open={openModalAlteraSenha}
          onClose={() => setOpenModalAlteraSenha(false)}
          onSave={SenhaAlterada}
        />
          {loading && (
            <Modal open={loading}>
              <ContainerLoading>
                <LoadingSale />
              </ContainerLoading>
            </Modal>
          )}

        <div className="box">
          <div
            className="button-remove"
            onClick={() => {
              setModal(true);
            }}
          >
            <AiOutlineCloseCircle />
          </div>

          <div className="box-in">
            <div>
              <div
                className="box-upload"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FFF",
                  boxShadow: " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                  minWidth: 166,
                  minHeight: 166,
                  maxWidth: 166,
                  maxHeight: 166,
                  margin: 5,
                  marginBottom: 20,
                }}
              >
                <img
                  style={{ width: 150, height: 150 }}
                  src={
                    user.avatar !== null
                      ? user.avatar
                      : "https://res.cloudinary.com/hkkzuevm3/image/upload/v1645643455/Assets-FlashPlayHr/perfil_f6utrt.png"
                  }
                  alt="Imagem"
                />
              </div>

              <div>
                <div
                  onClick={onClickSave}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    borderRadius: 5,
                    background: "#1C993F",
                    boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                    color: "#fff",
                    fontFamily: "Gotham-Bold",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 50,
                    width: 170,
                    marginRight: 10,
                    padding: 10,
                    margin: 5,
                  }}
                >
                  {languagePage === "PT" ? "Salvar" : "Save"}
                </div>

                <div
                  onClick={() => push("/candidate/propostas")}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    borderRadius: 5,
                    background: "#1C993F",
                    boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                    color: "#fff",
                    fontFamily: "Gotham-Bold",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 50,
                    width: 170,
                    marginRight: 10,
                    padding: 10,
                    margin: 5,
                  }}
                >
                  {languagePage === "PT" ? "Ver propostas" : "See proposals"}
                </div>
              </div>
            </div>

            <div className="box-in-inputs">
              <div>
                <InputPerfil
                  styleInput={{
                    fontFamily: "Gotham-Medium",
                    color: "#000",
                    background: "#FFF",
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  styleContainer={{
                    background: "#fff",
                    margin: "auto",
                    width: "100%",
                    maxWidth: "1024px",
                    minWidth: "150px",
                    marginBottom: 20,
                    cursor: "pointer",
                  }}
                  placeholder={
                    languagePage === "PT"
                      ? "Nome do candidato"
                      : "Candidate's name"
                  }
                  value={user.name}
                  label="nome"
                />
              </div>

              <div
                className="input-responsive"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 20,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    minWidth: "150px",
                    margin: "5px",
                  }}
                >
                  <InputPerfil
                    styleInput={{
                      fontFamily: "Gotham-Medium",
                      color: "#000",
                      background: "#FFF",
                      fontSize: 16,
                    }}
                    styleContainer={{
                      background: "#FFF",

                      width: "100%",
                      maxWidth: "200px",
                      minWidth: "150px",
                    }}
                    placeholder="Gênero"
                    label="gênero"
                    value={user.sex}
                    disabled
                  />
                </div>

                <div
                  style={{
                    margin: "5px",
                    width: "100%",
                    maxWidth: "500px",
                    minWidth: "150px",
                  }}
                >
                  <InputPerfil
                    styleInput={{
                      fontFamily: "Gotham-Medium",
                      color: "#000",
                      background: "#FFF",
                      fontSize: 16,
                      maxWidth: 50,
                    }}
                    styleContainer={{
                      background: "#FFF",
                      fontSize: 16,
                      width: "100%",
                      maxWidth: "500px",
                      minWidth: "150px",
                    }}
                    placeholder="Valor/horas"
                    label="valor_horas"
                    defaultValue={valorHora}
                    ref={inputValorHora}
                    onChange={OnChangeInputValorHora}
                  />
                </div>
                <div
                  style={{
                    margin: "5px",
                    width: "100%",
                    maxWidth: "500px",
                    minWidth: "150px",
                  }}
                >
                  <InputPerfil
                    styleInput={{
                      fontFamily: "Gotham-Medium",
                      color: "#000",
                      background: "#FFF",
                      fontSize: 16,
                    }}
                    styleContainer={{
                      background: "#FFF",

                      width: "100%",
                      maxWidth: "500px",
                      minWidth: "150px",
                    }}
                    placeholder="Quantos anos de experiência na área?"
                    label="Nível de experiência:"
                    defaultValue={anoExperiencia}
                    ref={inputAnoExperiencia}
                    onChange={OnChangeInputAnoExperiencia}
                  />
                </div>
              </div>
              <div
                className="input-responsive"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 10,
                  marginTop: -10,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    maxWidth: "1000px",
                    minWidth: "150px",
                  }}
                >
                  <textarea
                    placeholder={
                      languagePage === "PT"
                        ? "Coloque aqui as suas expecialidades!"
                        : "Put your specialties here!"
                    }
                    style={{
                      background: "#ccc",
                      width: "100%",
                      maxWidth: 1000,
                      minWidth: 200,
                      height: 100,
                      margin: "auto",
                      borderRadius: 10,
                    }}
                    value={expecialidade}
                    onChange={(e) => setExpecialidade(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default PainelDoConsultor;
