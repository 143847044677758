import styled from "styled-components";

const ContainerFlex = styled.section`
	display: flex;
`

const ContactContainer = styled.section`
	grid-area: contact;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	width: 100%;
	background-color: #fa9d19;
	padding: 1em;
	@media screen and (min-width: 769px) {
		padding: 3em;
	}
	@media screen and (min-width: 321px) and (max-width: 375px) {
		width: 101%;
	}
`;

const InnerContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 0.3fr 1fr;
	background-color: #000124;
	border-radius: 24px;
	width: 100%;
	padding: 1em;
	place-items: center;
	@media screen and (min-width: 769px) {
		padding: 2em;
		gap: 2em;
		grid-template-columns: 1fr 1.5fr;
		grid-template-rows: 1fr;
	}
`;

const SubmitButton = styled.button`
	width: 100%;
	font-family: Inter-Bold;
	font-size: 1em;
	margin: 20px 0px;
	color: white;
	background: rgb(25, 60, 128);
	background: linear-gradient(
		269deg,
		rgba(25, 60, 128, 1) 0%,
		rgba(100, 153, 255, 1) 100%
	);
	:hover {
		background: rgb(25, 60, 128);
		background: linear-gradient(
			90deg,
			rgba(25, 60, 128, 1) 0%,
			rgba(100, 153, 255, 1) 100%
		);
	}
	border: none;
	border-radius: 16px;
	padding: 15px;
	@media screen and (min-width: 769px) {
		margin: 20px 10px 10px 10px;
	}
`;

const Form = styled.form`
	color: #121212;
`;

const Input = styled.input`
	display: block;
	border-radius: 16px;
	border: none;
	margin: 10px 0px;
	width: 100%;
	background-color: #d6d6d6;
	color: #121212;
	padding: 15px 25px;
	font-family: Inter-Medium;
	font-size: 1em;
	:focus,
	:active {
		outline: none;
	}
	@media screen and (min-width: 769px) {
		margin: 10px;
	}
`;

const TextArea = styled.textarea`
	display: block;
	border-radius: 16px;
	border: none;
	margin: 10px 0px;
	width: 100%;
	height: 150px;
	max-height: 300px;
	padding: 15px 25px;
	font-size: 1em;
	background-color: #d6d6d6;
	font-family: Inter-Medium;
	color: #121212;
	:focus,
	:active {
		outline: none;
	}
	@media screen and (min-width: 769px) {
		margin: 10px;
	}
`;

const SectionTitleContainer = styled.div`
	margin-bottom: 1em;
`;

const SectionTitle = styled.h1`
	font-family: Inter-Bold;
	color: #ffff;
`;

const SectionTitleMarca = styled.h1`
	font-family: Inter-Bold;
	font-size: 2rem;
	color: #ffff;
`;

const SectionTitleMarcaEntrada = styled.h1`
	font-family: Inter-Bold;
	font-size: 1.5rem;
	color: #ffff;
	align-items: center;
	text-align: center;
`;

const TextContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
`;

const TextContainerColumn = styled.div`
display: flex;

flex-direction: column;
`;

const Text = styled.p`
	font-family: Inter-Medium;
	margin-bottom: 0.5em;
`;

const TextEntrada = styled.p`
	font-family: Inter-Medium;
	margin-bottom: 0.5em;
	align-items: start;
	text-align: start;
`;

const ImageContainer = styled.div``;

const Image = styled.img``;

const ImageEntrada = styled.img`
	align-items: center;
	text-align: center;
	margin: 30px;
`;

const ImageEntrada3 = styled.img`
	align-items: center;
	text-align: center;
	padding-left: 35%;
	@media screen and (max-width: 769px) {
		display: none;
	}
`;

const FormContainer = styled.div`
	width: 70%;
`;

const InfoContainer = styled.div`
	color: #d6d6d6;
`;

export {
	ContactContainer,
	InnerContainer,
	SubmitButton,
	Form,
	FormContainer,
	Input,
	TextArea,
	SectionTitleContainer,
	SectionTitle,
	SectionTitleMarca,
	InfoContainer,
	TextContainer,
	Text,
	TextEntrada,
	ImageContainer,
	ImageEntrada,
	Image,
	SectionTitleMarcaEntrada,
	ImageEntrada3,
	ContainerFlex,
	TextContainerColumn
};
