import React, { useCallback, useRef } from "react";
import { GoChevronLeft } from "react-icons/go";
import { HiChevronRight } from "react-icons/hi";
import { useForm } from "../../../hooks/form";
import Input, { InputRefProps } from "../../Input";
import { useGlobal } from "../../../hooks/global";
import { BotoesForm, Container, Titulo } from "../style";

interface NameCompanyData {
  onNext: Function;
  onBack: Function;
}

const NameCompany: React.FC<NameCompanyData> = ({ onNext, onBack }) => {
  const nameRef = useRef<InputRefProps>(null);

  const { nome, setNome, setType } = useForm();

  const onClickNext = useCallback(() => {
    if (nome != "") onNext();
  }, [nome]);

  const onClickBack = useCallback(() => {
    onBack();
  }, []);

  const onChange = useCallback(() => {
    const name = nameRef.current?.getInputValue()
      ? nameRef.current.getInputValue()
      : "";
    setNome(name);
  }, [nameRef]);

  const { setAtualPageGlobal, languagePage } = useGlobal();


    return (
      <Container
      style={{
        textAlign:"center"
      }}
      >
        <div>
          <Titulo>
            {languagePage === "PT" ? "Ótimo, só precisamos de alguns dados da sua empresa" : "Great, we just need some data from your Company"}
          </Titulo>
          <Input
            ref={nameRef}
            label="Nome da Empresa"
            defaultValue={nome}
            onChange={onChange}
            styleInput={{
              fontFamily: "Gotham-Bold",
              color: "#FFF",
              background: "#0b243b",
              fontSize: 16,
            }}
            styleContainer={{ background: "#0b243b" }}
            placeholder="Nome da Empresa"
          />
          <div style={{ display: "flex", marginTop: 20 }}>
            <BotoesForm onClick={onClickBack}>
              <GoChevronLeft />
              {languagePage === "PT" ? "Anterior" : "Back"}
            </BotoesForm>
            <BotoesForm
              onClick={onClickNext}
              style={{
              
                cursor: nome ? "pointer" : "not-allowed",
              }}
            >
              {languagePage === "PT" ? " Próximo" : "Next"}
              <HiChevronRight style={{ marginLeft: 10 }} />
            </BotoesForm>
          </div>
        </div>
      </Container>
    );
 
};

export default NameCompany;
