import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardGroup,
  CardImg,
  CardText,
  CardTitle,
} from "reactstrap";
import { useGlobal } from "../../../hooks/global";
import api from "../../../services/api";
import { Button, Conteiner, Titulo } from "./style";
import { useHistory, useParams } from "react-router-dom";
import { useToast } from "../../../hooks/toast";

export const ListaConsultoresRoot: React.FC = () => {
  const { languagePage, setAtualPageGlobal } = useGlobal();
  const { addToast } = useToast();
  const [dados, setDados] = useState<any[]>([]);
  const { proposalId } = useParams<any>();
  const [hoursEmployed, setHoursEmployed] = useState<number>(0);
  const { push } = useHistory<any>();

  useEffect(() => {
    api.get(`/company/proposal/${proposalId}`).then((response) => {
      const { hoursEmployed } = response.data;
      setHoursEmployed(hoursEmployed);
    });
    api
      .get("/company/list-consultants")
      .then((response) => {
        const { consultants } = response.data;
        console.log(consultants);
        setDados(consultants);
      })
      .catch((err) => console.log(err));
  }, [proposalId]);
  useEffect(() => {
    setAtualPageGlobal(`/root/consultores/${proposalId}`);
  }, [proposalId, setAtualPageGlobal]);

  const handleSelectConsultant = useCallback(
    async (consultantId, exactAmount) => {
      try {
        const res = await api.post(`/company/select-consultant/${proposalId}`, {
          consultantId,
          exactAmount,
        });
        const { updated } = res.data;

        if (updated) {
          const result = await api.post(
            `/company/create-finance-demand-proposal/${proposalId}`
          );
          const { created } = result.data;

          if (created) {
            addToast({
              title: "Sucesso!",
              description: "Consultor selecionado com sucesso",
              icon: "info",
              type: "success",
            });
            push("/root/propostas");
            return;
          }
        }
      } catch (err) {
        addToast({
          title: "Erro!",
          description: "Não foi possível selecionar o consultor.",
          icon: "alert",
          type: "info",
        });
        return;
      }
    },
    [addToast, proposalId, push]
  );

  const decidindoPerfil = (soft: number, hard: number) => {
    let perfil = "";
    if (0 <= soft && soft <= 11) {
      perfil += "Lobo Guará";
    } else if (soft <= 19) {
      perfil += "Camaleão";
    } else if (soft > 19) {
      perfil += "Borboleta";
    }
    if (0 <= hard && hard <= 7) {
      perfil += " --";
    } else if (hard <= 15) {
      perfil += " -";
    } else if (hard <= 22) {
      perfil += " +";
    } else if (hard > 22) {
      perfil += " ++";
    }

    return perfil;
  };

  return (
    <Conteiner>
      <Titulo>{languagePage === "PT" ? "Consultores" : "Consultants"}</Titulo>
      <hr style={{ color: "#000" }} />
      <CardGroup style={{ margin: "auto", justifyContent: "center" }}>
        {dados.map((consultant) => {
          return (
            <Card className="cards">
              <CardImg
                className="card-img"
                style={{
                  backgroundColor: "#FFFFFF",
                  width: "100%",
                  height: 300,
                }}
                src={
                  consultant.avatar !== null
                    ? consultant.avatar
                    : "https://res.cloudinary.com/hkkzuevm3/image/upload/v1645643455/Assets-FlashPlayHr/perfil_f6utrt.png"
                }
              />
              <CardBody>
                <CardText
                  style={{
                    fontFamily: "Gotham-Bold",
                    fontSize: 15,
                  }}
                >
                  {consultant.category}
                </CardText>
                <CardText
                  style={{
                    fontFamily: "Gotham-Medium",
                    fontSize: 14,
                  }}
                >
                  {decidindoPerfil(consultant.soft, consultant.hard)}
                </CardText>

                <CardText style={{ fontFamily: "Gotham-Medium" }}>
                  {languagePage === "PT" ? "Valor/Hora:" : "Value/Hour: BRL"}{" "}
                  {Intl.NumberFormat("pt-BR", {
                    currency: "brl",
                    style: "currency",
                  }).format(consultant.valueHour)}
                </CardText>

                <CardText
                  style={{
                    fontFamily: "Gotham-Light",
                    fontSize: 15,
                    margin: "0 0 5px",
                  }}
                >
                  {consultant.description}
                </CardText>
                <CardText
                  style={{
                    fontFamily: "Gotham-Light",
                    fontSize: 15,
                  }}
                >
                  {consultant.specs}
                </CardText>
                <CardText
                  style={{
                    fontFamily: "Gotham-Medium",
                    fontSize: 15,
                  }}
                >
                  Nível: {consultant.nivel}
                </CardText>
                <CardTitle tag="h5" style={{ fontFamily: "Gotham-Bold" }}>
                  {languagePage === "PT" ? "Total:" : "Total: BRL"}{" "}
                  {Intl.NumberFormat("pt-BR", {
                    currency: "brl",
                    style: "currency",
                  }).format(consultant.valueHour * hoursEmployed)}
                </CardTitle>
                <Button
                  className="buttons"
                  onClick={() =>
                    handleSelectConsultant(
                      consultant.id,
                      consultant.valueHour * hoursEmployed
                    )
                  }
                >
                  {languagePage === "PT"
                    ? "Selecionar consultor"
                    : "Select consultant"}
                </Button>
              </CardBody>
            </Card>
          );
        })}
      </CardGroup>
    </Conteiner>
  );
};
