import React, { useCallback, useRef, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { MdQuestionAnswer } from "react-icons/md";
import { ErrorProps, Verify } from "../../../services/schemaServices";
import {
  Container,
  Header,
  IconDelete,
  ButtonEnd,
  ContainerHeader,
  ContainerTitle,
  Formulario,
  ContainerItemForm,
  ContainerBottom,
  Select,
} from "./styles";
import InputLabel, { InputRefProps } from "../../InputLabel";
import { useGlobal } from "../../../hooks/global";

interface AlterarPreferenciaProps {
  open: boolean;
  preference: string;
  setPreference: any;
  onClose: Function;
  onFunction: Function;
}

export const ModalAlterarPreferencia: React.FC<AlterarPreferenciaProps> = ({
  open,
  onClose,
  onFunction,
  setPreference,
  preference,
}) => {
  const [errors, setErrors] = useState<ErrorProps[]>([] as ErrorProps[]);
  const { languagePage } = useGlobal();
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <ContainerHeader>
          <Header>
            <ContainerTitle>
              <MdQuestionAnswer
                style={{ marginRight: "0.625rem" }}
                color="#101A36"
              />
              {languagePage === "PT"
                ? "Alteração de perfil do usuário"
                : "User profile change"}
            </ContainerTitle>
            <IconDelete onClick={() => onClose()} color="#C4C4C4" />
          </Header>
        </ContainerHeader>

        <Formulario>
          <ContainerItemForm>
            <Select
              value={preference}
              onChange={(e) => setPreference(e.target.value)}
              name="interview"
            >
              <option value="candidate">Candidato</option>
              <option value="consultant">Consultor</option>
            </Select>
          </ContainerItemForm>
          <ContainerItemForm style={{ fontStyle: "italic", color: "#333333" }}>
            {languagePage === "PT"
              ? "Observação: Caso mude para outro perfil as seleções que o usuário faz parte serão perdidas assim como as avaliações de HardSkill e Softskill."
              : "Note: If you change to another profile, the selections that the user is a part of will be lost as well as the HardSkill and Softskill ratings."}
          </ContainerItemForm>
        </Formulario>

        <ContainerBottom>
          <ButtonEnd onClick={() => onClose()} style={{ color: "#252323" }}>
            {languagePage === "PT" ? "Cancelar" : "Cancel"}
          </ButtonEnd>
          <ButtonEnd
            onClick={() => onFunction()}
            style={{ color: "#101A36", cursor: "pointer" }}
          >
            {languagePage === "PT" ? "Alterar" : "Change"}
          </ButtonEnd>
        </ContainerBottom>
      </Container>
    </Modal>
  );
};

export default ModalAlterarPreferencia;
