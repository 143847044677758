import React, { useCallback, useRef } from "react";
import { GoChevronLeft } from "react-icons/go";
import { HiChevronRight } from "react-icons/hi";
import { useForm } from "../../../hooks/form";
import { useGlobal } from "../../../hooks/global";
import Input, { InputRefProps } from "../../Input";
import { BotoesForm, Container, ContainerButton, Titulo } from "../style";

interface NameCandidateData {
  onNext: Function;
  onBack: Function;
}

const NameCandidate: React.FC<NameCandidateData> = ({ onNext, onBack }) => {
  const nameRef = useRef<InputRefProps>(null);

  const { nome, setNome, setType } = useForm();

  const onClickNext = useCallback(() => {
    if (nome !== "") onNext();
  }, [nome]);

  const onClickBack = useCallback(() => {
    setType("");
    onBack();
  }, [nameRef]);

  const onChange = useCallback(() => {
    const name = nameRef.current?.getInputValue()
      ? nameRef.current.getInputValue()
      : "";
    setNome(name);
  }, [nameRef]);
  const { setAtualPageGlobal, languagePage } = useGlobal();


  return (
    <Container>
      <div>
        <Titulo>
          {languagePage === "PT" ? "Ótimo, só precisamos de alguns dados pessoais" : " Great, we just need some personal data:"}
        </Titulo>
        <Input
          styleInput={{
            fontFamily: "Gotham-Bold",
            color: "#FFF",
            background: "#0b243b",
            fontSize: 16,
          }}
          styleContainer={{ background: "#0b243b" }}
          placeholder="Seu nome completo"
          label="Seu nome completo"
          onChange={onChange}
          ref={nameRef}
          defaultValue={nome}
        />
        <ContainerButton>
          <BotoesForm onClick={onClickBack}>
            <GoChevronLeft />
            {languagePage === "PT" ? "Anterior" : "Back"}
          </BotoesForm>
          <BotoesForm
            onClick={onClickNext}
            style={{

              cursor: nome !== "" ? "pointer" : "not-allowed",
            }}
          >
            {languagePage === "PT" ? "Próximo" : "Next"}
            <HiChevronRight style={{ marginLeft: 10 }} />
          </BotoesForm>
        </ContainerButton>
      </div>
    </Container>
  );

};

export default NameCandidate;
