import styled from "styled-components";

export const Container = styled.div`
	//position alterada de absolute para fixed para que o toast apareça sempre na tela independente da posição do scroll
	position: fixed;
	right: 0;
	top: 0;
	padding: 1.875rem;
	padding-left: 0.625rem;
	padding-bottom: 0.625rem;
	overflow: hidden;
	z-index: 100;
`;
