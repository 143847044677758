import styled from "styled-components";

export const ConEmailTel = styled.div`
  .media-277 {
    margin: "auto";
  }
  @media (max-width: 300px) {
    .media-277 {
      margin-left: -20px;
    }
  }
  @media (max-width: 642px) {
    .flexWOne {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }
  }
  @media (max-width: 706px) {
    .flexW {
      flex-wrap: wrap;

      justify-content: center;
      text-align: center;
    }
  }
`;
