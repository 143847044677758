import { InputBaseProps } from "@material-ui/core";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  CSSProperties,
  useState,
} from "react";
import { useRef } from "react";
import {
  FaAddressCard,
  FaLinkedin,
  FaTransgenderAlt,
  FaUserAlt,
  FaFilePdf,
} from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { ImPhone } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import {
  AlertError,
  Container,
  ContainerError,
  ContainerInput,
  InputBase,
  Label,
  TooltipError,
} from "./style";
import { IoLocationSharp } from "react-icons/io5";

export interface InputRefProps {
  getInputValue: () => string;
  setInputValue: (value: string) => void;
}

interface InputProps extends InputBaseProps {
  styleInput: CSSProperties;
  styleContainer: CSSProperties;
  errors?: string | null;
  label: string;
}

const InputPerfil: React.ForwardRefRenderFunction<InputRefProps, InputProps> = (
  { styleContainer, errors, styleInput, label, ...props },
  ref
) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const [hoverError, setHoverError] = useState<boolean>(false);

  // useEffect(() => {
  //     document.getElementsByClassName(label)[0].getElementsByTagName("input")[0].setAttribute("style", "line-height:120px")
  // }, [])

  const getInputValue = useCallback(() => {
    return inputRef.current?.getElementsByTagName("input")[0].value
      ? inputRef.current?.getElementsByTagName("input")[0].value
      : "";
  }, [inputRef]);

  const setInputValue = useCallback((newValue) => {
    document
      .getElementsByClassName(label)[0]
      .getElementsByTagName("input")[0].value = newValue;
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getInputValue,
      setInputValue,
    };
  });

  const focusInput = useCallback(() => {
    inputRef.current?.getElementsByTagName("input")[0].focus();
  }, []);

  return (
    <Container ref={(ref: any) => (ref = ref)}>
      <ContainerInput style={{ ...styleContainer }} error={!!errors}>
        {label === "email" && <MdEmail />}
        {label === "nome" && <FaUserAlt />}
        {label === "responsible" && <FaUserAlt />}
        {label === "telefone" && <ImPhone />}
        {(label === "cnpj" || label === "cpf") && <FaAddressCard />}
        {(label === "cidade" || label === "estado" || label === "pais") && (
          <IoLocationSharp />
        )}
        {label === "gênero" && <FaTransgenderAlt />}
        {label === "linkedIn" && <FaLinkedin />}
        {label === "curriculum" && <FaFilePdf />}
        {label === "valor_horas" && (
          <div style={{ width: 160 }}>Valor hora: R$</div>
        )}

        <InputBase
          className={label}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          {...props}
          style={{ ...styleInput }}
          ref={inputRef}
        />

        {!!errors && (
          <ContainerError onClick={focusInput}>
            <AlertError
              onMouseEnter={() => setHoverError(true)}
              onMouseLeave={() => setHoverError(false)}
              style={{
                opacity: errors !== null ? 1 : 0,
                cursor: errors !== null ? "default" : "text",
              }}
            >
              <FiAlertCircle
                color={"#AF0708"}
                size={"1.375rem"}
                style={{ padding: "0.3125rem" }}
              />
            </AlertError>
            {hoverError && <TooltipError>{errors}</TooltipError>}
          </ContainerError>
        )}
      </ContainerInput>
    </Container>
  );
};

export default forwardRef(InputPerfil);
