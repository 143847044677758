import styled from "styled-components";

export const Container = styled.div`

    background: #010648;
    /* 010648 */
    

    
    section{
        background: url("https://res.cloudinary.com/hkkzuevm3/image/upload/v1648675867/Assets-FlashPlayHr-webp/imagem03_b5p4q8-_1__hbvogh.webp") no-repeat;
      
        background-position:center ;
        background-size: 60%;
        height: 500px;
        

    }


    .efeito-text{
        /* text-shadow: 
               -1px -1px 0px #FFF, 
               -1px 1px 0px #FFF,                    
                1px -1px 0px #FFF,                  
                1px 0px 0px #FFF; */
    }
    .bg_card{
        background-color: linear-gradient(45deg, rgb(198, 213, 246) 0%,rgb(255, 255, 255) 100%);
        background: linear-gradient(45deg, rgb(198, 213, 246) 0%,rgb(255, 255, 255) 100%);
        border-radius: 20px;
        text-decoration: none;
        
        

    }
    .jLink{
        font-family: "Gotham-Bold";
        font-size: 30px;
        color: rgb(19, 68, 128);
        
    }
    .jDescricao{
        color: black;
        font-size: 1.2em;
    }
    @media(max-width: 1400px){
      .jDescricao{
        color: black;
        font-size: 1em;
    }

    }
    @media(max-width: 992px){
      .jDescricao{
        color: black;
        font-size: 0.8em;
    }

    }
    @media(max-width: 576px){
      .jDescricao{
        color: black;
        font-size: 1.2em;
    }

    }
    @media(max-width: 392px){
      .jDescricao{
        color: black;
        font-size: 1.1em;
    }

    }

 .bodyQ{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  
}

.quadrado {
  background: url("https://res.cloudinary.com/hkkzuevm3/image/upload/v1648757272/Assets-FlashPlayHr-webp/camale%C3%A3o_wh3qdt_blirgb.webp");
  background-size:cover;
  width: 300px;
  height: 200px;
  animation: go-back 3s infinite alternate;
}

@keyframes go-back {
  from {
    transform: translateX(-200px);
  }
  to {
    transform: translateX(200);
  }
}

`