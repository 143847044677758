import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardGroup,
  CardText,
  CardFooter,
  CardTitle,
} from "reactstrap";
import api from "../../../services/api";
import {
  BoxButton,
  Button,
  Conteiner,
  FooterText,
  Span,
  Status,
  Titulo,
} from "./style";
import { useHistory } from "react-router-dom";
import { useGlobal } from "../../../hooks/global";
import { ModalPayment } from "../../../components/Modals/Payment";
import { ModalCancelarProposta } from "../../../components/Modals/CancelarProposta";

export const MinhasPropostas: React.FC = () => {
  const [dados, setDados] = useState<any[]>([]);
  const { push } = useHistory<any>();
  const [value, setValue] = useState<number>(0);
  const [proposalId, setProposalId] = useState<string>();
  const [modal, setModal] = useState(false);
  const [modalCancelar, setModalCancelar] = useState(false);
  const { languagePage, setAtualPageGlobal } = useGlobal();

  useEffect(() => {
    setAtualPageGlobal("/company/consultoria/minhas-propostas");
  }, []);

  const formator = new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
    minimumFractionDigits: 2,
  });

  const handleStartConversation = useCallback(
    async (id, amount, initialPaid) => {
      if (initialPaid === true) {
        return push(`/company/consultoria/conversacao/${id}`);
      }
      return push({
        pathname: "/company/checkout",
        state: { amount, id },
      });
    },
    [push]
  );
  const handleExcludeProposal = useCallback((id) => {
    setProposalId(id);
    setModalCancelar(true);
  }, []);
  const handleConcludeProposal = useCallback(async (id) => {
    setProposalId(id);
    const { data: payment } = await api.get(
      `/company/verify-payment-success/${id}`
    );
    if (payment.finance.finalProposalInvoicePaid === true) {
      return;
    }
    setValue(payment.finance.finalProposalInvoice);

    setModal(true);
  }, []);
  useEffect(() => {
    api
      .get("/company/list-proposals")
      .then((response) => {
        const { proposals } = response.data;
        var aceitos = [];
        var aguardo = [];
        var cancelado = [];
        var recusado = [];
        var ordenado: any[] = [];

        for (var i = 0; i < proposals.length; i++) {
          if (proposals[i].accept === true && proposals[i].canceled === null) {
            aceitos.push(proposals[i]);
          } else if (
            proposals[i].accept === null &&
            proposals[i].canceled === null
          ) {
            aguardo.push(proposals[i]);
          } else if (
            proposals[i].accept === null &&
            proposals[i].canceled === true
          ) {
            cancelado.push(proposals[i]);
          } else if (
            proposals[i].accept === false &&
            proposals[i].canceled === null
          ) {
            recusado.push(proposals[i]);
          }
        }
        ordenado = ordenado.concat(aceitos, aguardo, cancelado, recusado);
        setDados(ordenado);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Conteiner>
      <ModalPayment
        value={value}
        open={modal}
        onClose={() => setModal(false)}
        onFunction={() => {}}
        proposalId={proposalId as string}
      />
      <ModalCancelarProposta
        open={modalCancelar}
        onClose={() => setModalCancelar(false)}
        proposalId={proposalId as string}
      />
      <Titulo>
        {languagePage === "PT" ? "Minhas Propostas" : "Proposals I made"}
      </Titulo>
      <hr style={{ color: "#000" }} />
      <CardGroup
        style={{
          margin: "auto",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {dados.length !== 0 &&
          dados.map((item) => (
            <Card style={{ marginBottom: 32, borderRadius: 5 }}>
              <CardTitle
                style={{
                  padding: 16,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ color: "#4e4e4e", fontFamily: "Gotham-Bold" }}>
                  {item.category}
                </h5>
                <Status state={item.accept}>
                  <p>
                    {item.accept === null && item.consultant === null
                      ? "Nenhum consultor selecionado"
                      : item.accept === null && !!item.consultant
                      ? "Em aguardo"
                      : item.accept === true
                      ? "Aceito"
                      : "Recusado"}
                  </p>
                </Status>
              </CardTitle>
              <CardBody
                style={{
                  padding: 16,
                  display: "flex",
                  flexDirection: window.innerWidth <= 768 ? "column" : "row",
                  alignItems:
                    window.innerWidth <= 768 ? "flex-start" : "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <label
                    style={{
                      color: "#4e4e4e",
                      fontFamily: "Gotham-Medium",
                      fontSize: 16,
                    }}
                  >
                    Descrição
                  </label>
                  <p
                    style={{
                      color: "#4e4e4e",
                      fontFamily: "Gotham-Light",
                      fontSize: 15,
                      maxWidth: 400,
                    }}
                  >
                    {item.description}
                  </p>
                  <label
                    style={{
                      color: "#4e4e4e",
                      fontFamily: "Gotham-Medium",
                      fontSize: 16,
                    }}
                  >
                    Quantidade de horas
                  </label>
                  <p
                    style={{
                      color: "#4e4e4e",
                      fontFamily: "Gotham-Light",
                      fontSize: 15,
                    }}
                  >
                    {item.hoursEmployed} horas
                  </p>
                  <label
                    style={{
                      color: "#4e4e4e",
                      fontFamily: "Gotham-Medium",
                      fontSize: 16,
                    }}
                  >
                    Valor da proposta
                  </label>
                  <p
                    style={{
                      color: "#4e4e4e",
                      fontFamily: "Gotham-Light",
                      fontSize: 15,
                    }}
                  >
                    {item.exactAmount === 0 || item.exactAmount === null
                      ? formator.format(item.amountEstimate)
                      : formator.format(item.exactAmount)}
                  </p>
                </div>
                {!!item.consultant && (
                  <div>
                    <div>
                      {!!item.consultant && (
                        <>
                          <img
                            src={
                              !!item.consultant.avatar
                                ? item.consultant.avatar
                                : "https://res.cloudinary.com/hkkzuevm3/image/upload/v1645643455/Assets-FlashPlayHr/perfil_f6utrt.png"
                            }
                            style={{
                              display: "flex",
                              width: "100%",
                              height: "100%",
                              maxWidth: 80,
                              maxHeight: 80,
                              borderRadius: 8,
                              marginBottom: 8,
                            }}
                          />
                          <label
                            style={{
                              color: "#4e4e4e",
                              fontFamily: "Gotham-Medium",
                              fontSize: 16,
                            }}
                          >
                            Nível
                          </label>
                          {item.consultant.yearExperience >= 8 ? (
                            <p
                              style={{
                                color: "#4e4e4e",
                                fontFamily: "Gotham-Light",
                                fontSize: 15,
                              }}
                            >
                              Sênior
                            </p>
                          ) : item.consultant.yearExperience < 8 &&
                            item.consultant.yearExperience >= 6 ? (
                            <p
                              style={{
                                color: "#4e4e4e",
                                fontFamily: "Gotham-Light",
                                fontSize: 15,
                              }}
                            >
                              Pleno
                            </p>
                          ) : (
                            <p
                              style={{
                                color: "#4e4e4e",
                                fontFamily: "Gotham-Light",
                                fontSize: 15,
                              }}
                            >
                              Júnior
                            </p>
                          )}
                          <label
                            style={{
                              color: "#4e4e4e",
                              fontFamily: "Gotham-Medium",
                              fontSize: 16,
                            }}
                          >
                            Experiência
                          </label>
                          <p
                            style={{
                              color: "#4e4e4e",
                              fontFamily: "Gotham-Light",
                              fontSize: 15,
                              maxWidth: 400,
                            }}
                          >
                            {item.consultant.specs}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </CardBody>
              <CardFooter style={{ padding: 16 }}>
                <BoxButton>
                  {!item.canceled &&
                    !item.conclused &&
                    !item.consultant &&
                    item.amountEstimate < 10000 && (
                      <Button
                        background="#134480"
                        backgroundHover="#003668"
                        onClick={() =>
                          push(`/company/consultoria/consultores/${item.id}`)
                        }
                      >
                        Escolher Consultor
                      </Button>
                    )}
                  {!item.canceled &&
                    !item.conclused &&
                    !!item.consultant &&
                    !!item.accept &&
                    item.accept === true && (
                      <Button
                        onClick={() =>
                          handleStartConversation(
                            item.id,
                            item.financeProposals.proposalInvoiceInitial,
                            item.financeProposals.proposalInvoiceInitialPaid
                          )
                        }
                        background="#3e8f8b"
                        backgroundHover="#327774"
                      >
                        {!!item.financeProposals &&
                          item.financeProposals.proposalInvoiceInitialPaid ===
                            null && (
                            <Span>
                              Para conversar com consultor, é preciso fazer o
                              primeiro pagamento
                            </Span>
                          )}
                        Conversar com consultor
                      </Button>
                    )}
                  {!item.canceled && !item.conclused && item.accept === null && (
                    <>
                      <Button
                        onClick={() =>
                          push({
                            pathname: "/company/consultoria/editar-proposta",
                            state: { proposal: item },
                          })
                        }
                        background="#c9cc2c"
                        backgroundHover="#b5b826"
                      >
                        Editar Proposta
                      </Button>
                      <Button
                        onClick={() => handleExcludeProposal(item.id)}
                        background="#DB2016"
                        backgroundHover="#96140e"
                      >
                        Cancelar Proposta
                      </Button>
                    </>
                  )}
                  {!item.canceled &&
                    !item.conclused &&
                    !!item.consultant &&
                    !!item.accept &&
                    item.accept === true &&
                    !!item.financeProposals &&
                    item.financeProposals.proposalInvoiceInitialPaid ===
                      true && (
                      <Button
                        onClick={() => handleConcludeProposal(item.id)}
                        background="#58bb4d"
                        backgroundHover="#4f9947"
                      >
                        Concluir Proposta
                      </Button>
                    )}
                  {!item.canceled &&
                    !!item.conclused &&
                    item.conclused === true && (
                      <FooterText>Esta proposta já foi concluída</FooterText>
                    )}
                  {!!item.canceled && !!item.canceledDescription && (
                    <FooterText>{item.canceledDescription}</FooterText>
                  )}
                  {item.accept === false && (
                    <FooterText>{item.recusedDescription}</FooterText>
                  )}
                </BoxButton>
              </CardFooter>
            </Card>
          ))}
      </CardGroup>
    </Conteiner>
  );
};
