import React from 'react'
import { FormProvider } from './form';
import { AuthProvider } from './auth';
import { GlobalProvider } from './global';
import { ToastProvider } from './toast';

const AppProvider: React.FC = ({ children }) => {
    return (
        <GlobalProvider>
            <ToastProvider>
                <FormProvider>
                    <AuthProvider>
                        {children}
                    </AuthProvider>
                </FormProvider>
            </ToastProvider>
        </GlobalProvider>
    )
}

export default AppProvider;