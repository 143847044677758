import styled from "styled-components";

export const ContainerModal = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Template = styled.div`
  margin: auto;
  margin-top: 30px;
  width: 100%;
  /* height: 608px; */
  max-width: 1314px;

  min-width: 200px;
  justify-content: center;
  align-items: center;

  @media (max-width: 769px) {
    height: 100vh;
  }
`;

export const CloseButton = styled.div`
  margin: auto;
  margin-top: -10px;
  margin-bottom: 20px;
  width: 95%;
  display: flex;

  justify-content: flex-end;
  cursor: pointer;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 500px;

  height: 100%;
  padding: 16px;
  //max-height: 570px;
  //min-height: 288px;
  /* overflow-y: scroll; */
  /* left: 298px; */
  /* top: -80px; */

  background: #ffffff;
  box-shadow: 0px 16px 80px rgba(0, 0, 0, 0.32);
  border-radius: 5px;
  margin: auto;
  //margin-bottom: 10px;
  //margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    font-family: "Gotham-Medium", sans-serif;
    font-size: 18px;
  }

  select {
    margin: 16px 0;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    padding: 8px;
  }

  @media (max-width: 769px) {
    justify-content: center;
    margin-top: -20px;
  }
`;
