import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { questionInterface } from "../../interfaces/questionsInterface";
import { ContainerConteudo, Seta, SetasTwo } from './style';

export interface questionRefProps {
  getResp: () => number;
}

interface questaoData {
  numberOfQuestion: number;
  question: questionInterface;
  onBack: Function;
  onGo: Function;
  respostaInicial: number;
}

const Questao: React.ForwardRefRenderFunction<questionRefProps, questaoData> = (
  { numberOfQuestion, question, onBack, onGo, respostaInicial },
  ref
) => {
  const [marc, setMarc] = useState(respostaInicial);

  const getResp = useCallback(() => {
    return marc;
  }, [marc]);

  useImperativeHandle(ref, () => {
    return {
      getResp,
    };
  });

  return (
    <div>
      <ContainerConteudo ref={(ref: any) => (ref = ref)}>
        <div>
          <Seta onClick={() => onBack()}
            style={{
              boxShadow: " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
              background: "#134480",
              cursor: "pointer",
            }}>
            <GoChevronLeft />
          </Seta>
        </div>
        <div className="conteudo">
          <div className="number-questio">
            {`${numberOfQuestion}. ${question.title}`}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="painel-questao">
              <div
                onClick={() => setMarc(1)}
                className="alternativa"
                style={{background: marc === 1 ? "#134480" : "#133770"}}>
                a
              </div>
              {question.alternativeOne}
            </div>
            <div className="painel-questao">
              <div
                onClick={() => setMarc(2)}
                className="alternativa"
                style={{background: marc === 2 ? "#134480" : "#133770"}}>
                b
              </div>
              {question.alternativeTwo}
            </div>
            <div className="painel-questao">
              <div  className="alternativa"
                onClick={() => setMarc(3)}
                style={{background: marc === 3 ? "#134480" : "#133770"}}>
                c
              </div>
              {question.alternativeThree}
            </div>
            <div className="painel-questao">
              <div
                onClick={() => setMarc(4)}
                className="alternativa"
                style={{
                  background: marc === 4 ? "#134480" : "#133770"}}
              >
                d
              </div>
              {question.alternativeFour}
            </div>
          </div>
        </div>
        <div>
          <Seta
            onClick={() => onGo()}
            style={{
              boxShadow: " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
              background: marc === -1 ? "#C4C4C4" : "#134480",
              cursor: marc === -1 ? "not-allowed" : "pointer",
            }}>
            <GoChevronRight />
          </Seta>
        </div>

      </ContainerConteudo>
      <div style={{display:"flex", justifyContent:"center"}}>
        <SetasTwo
          onClick={() => onBack()}
          style={{ 
            boxShadow: " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
            background: "#134480",
            borderRadius: "50%",          
          }}>
          <GoChevronLeft />
        </SetasTwo>

        <SetasTwo
          onClick={() => onGo()}
          style={{
            boxShadow: " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
            background: marc === -1 ? "#C4C4C4" : "#134480",
            cursor: marc === -1 ? "not-allowed" : "pointer",
          }}>
          <GoChevronRight />
        </SetasTwo>
      </div>
    </div>
  );
};

export default forwardRef(Questao);
