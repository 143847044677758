import { CircularProgress } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { FaUser, FaUserAlt } from "react-icons/fa";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useHistory, useParams } from "react-router";
import { useGlobal } from "../../../hooks/global";
import { requestInterface } from "../../../interfaces/requestInterface";
import { selectionInterface } from "../../../interfaces/selectionInterface";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { BoxBackTitle, Setas, SetasTwo } from "./style";

export const RootSolicitacoes: React.FC = () => {
  const { push } = useHistory();
  const [atualPage, setAtualPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const { selectionId }: any = useParams();
  const [requests, setRequests] = useState<requestInterface[]>(
    [] as requestInterface[]
  );
  const [selection, setSelection] = useState<selectionInterface>(
    {} as selectionInterface
  );
  const [loading, setLoading] = useState(true);
  const { addToast } = useToast();

  const { setAtualPageGlobal, languagePage } = useGlobal();

  const searchRequests = useCallback(async () => {
    setLoading(true);
    try {
      setNumberOfPages(0);
      const resu = await api.get(
        `/root/resquest/all/${selectionId}?limit=3&page=${atualPage}`
      );
      setSelection(resu.data.selection);
      setRequests(resu.data.rows);
      setNumberOfPages(Math.ceil(resu.data.count / 3));
    } catch (err: any) {}
    setLoading(false);
  }, [atualPage]);

  useEffect(() => {
    setAtualPageGlobal("/root/solicitacoes/:selectionId");
    const timer = setTimeout(searchRequests, 200);
    return () => clearTimeout(timer);
  }, [atualPage]);

  // useEffect(()=>{
  //     setInterval(searchRequests, 30000);
  // },[])

  const changePage = useCallback(
    (type) => {
      if (type === "proxima") {
        if (atualPage < numberOfPages) setAtualPage(atualPage + 1);
      } else if (atualPage !== 1) setAtualPage(atualPage - 1);
    },
    [atualPage, numberOfPages]
  );

  return (
    <div
      style={{
        display: "flex",

        flexDirection: "column",
        height: "calc(100% - 40px)",
        margin: 20,
        fontFamily: "Gotham-Medium",
        color: "#64666b",
        fontSize: 26,
      }}
    >
      <BoxBackTitle>
        <div
          onClick={() => push("/root/consultores")}
          style={{
            fontSize: 16,
            left: 0,
            flexDirection: "row",
            color: "#64666b",
            cursor: "pointer",
            width: "20%",
            maxWidth: "250px",
            minWidth: "150px",
            margin: 5,

            textAlign: "center",
          }}
        >
          {languagePage === "PT" ? "Voltar" : "Back"}
        </div>
        <div
          style={{
            width: "70%",

            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            margin: 5,
          }}
        >
          {languagePage === "PT"
            ? " Solicitações da Seleção"
            : "Selection Requests"}{" "}
          "{selection.name}"
        </div>
      </BoxBackTitle>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          justifyContent: "space-between",
          fontSize: 16,
          alignItems: "center",

          marginTop: 20,
        }}
      >
        <Setas>
          <div
            onClick={() => changePage("anterior")}
            style={{
              padding: 5,
              background: atualPage === 1 ? "#C4C4C4" : "#134480",
              marginRight: 10,
              borderRadius: "50%",
              color: "#FFF",
              justifyContent: "center",
              alignItems: "center",
              cursor: atualPage === 1 ? "default" : "pointer",
            }}
          >
            <FiChevronLeft size={28} />
          </div>
        </Setas>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "95%",
            minHeight: 310,
            justifyContent: "center",
          }}
        >
          {!loading && requests.length > 0 && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                maxWidth: "1200px",
                minWidth: "200px",
                justifyContent: "center",
              }}
            >
              {requests.map((request) => (
                <div
                  style={{
                    display: "flex",

                    flexDirection: "column",
                    width: "100%",
                    minWidth: "200px",
                    maxWidth: "350px",
                    margin: "1vw",
                    background: "#134480",
                    padding: 20,
                    boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                    borderRadius: 5,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      color: "#FFF",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        background: "#FFF",
                        padding: 10,
                        borderRadius: 5,
                        marginRight: 10,
                      }}
                    >
                      {request.candidate.avatar && (
                        <img
                          style={{ height: 30 }}
                          src={request.candidate.avatar}
                        />
                      )}
                      {!request.candidate.avatar && (
                        <FaUser color="#134480" size={30} />
                      )}
                    </div>
                    {request.candidate.name}
                  </div>
                  <div
                    onClick={() =>
                      push(
                        `/root/solicitacoes/perfilConsultor/${selectionId}/${request.id}`
                      )
                    }
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      marginTop: 10,
                      marginBottom: 10,
                      justifyContent: "center",
                      color: "#FFF",
                      padding: 10,
                      flexDirection: "row",
                      borderRadius: 5,
                      background: "#0f2230",
                    }}
                  >
                    <FaUserAlt style={{ marginRight: 10 }} />
                    {languagePage === "PT" ? "Ver Perfil" : "View profile"}
                  </div>
                </div>
              ))}
            </div>
          )}
          {!loading && requests.length === 0 && (
            <div
              style={{
                display: "flex",
                //height: "100%",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "Gotham-Medium-Italic",
                textAlign: "center",
                opacity: 0.8,
                fontSize: 16,
                overflow: "hidden",
              }}
            >
              {languagePage === "PT"
                ? "Esta Seleção não possui nenhuma solicitação"
                : "This Selection has no request"}
            </div>
          )}
          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          )}
        </div>
        <Setas>
          <div
            onClick={() => changePage("proxima")}
            style={{
              padding: 5,
              marginLeft: 10,
              background:
                atualPage === numberOfPages || numberOfPages === 0
                  ? "#C4C4C4"
                  : "#134480",
              borderRadius: "50%",
              color: "#FFF",
              justifyContent: "center",
              alignItems: "center",
              cursor:
                atualPage === numberOfPages || numberOfPages === 0
                  ? "default"
                  : "pointer",
            }}
          >
            <FiChevronRight size={28} />
          </div>
        </Setas>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          marginTop: 20,
        }}
      >
        <SetasTwo
          onClick={() => changePage("anterior")}
          style={{
            padding: 5,
            background: atualPage === 1 ? "#C4C4C4" : "#134480",
            marginRight: 10,
            borderRadius: "50%",
            color: "#FFF",
            justifyContent: "center",
            alignItems: "center",
            cursor: atualPage === 1 ? "default" : "pointer",
          }}
        >
          <FiChevronLeft size={28} />
        </SetasTwo>

        <SetasTwo
          onClick={() => changePage("proxima")}
          style={{
            padding: 5,
            marginLeft: 10,
            background:
              atualPage === numberOfPages || numberOfPages === 0
                ? "#C4C4C4"
                : "#134480",
            borderRadius: "50%",
            color: "#FFF",
            justifyContent: "center",
            alignItems: "center",
            cursor:
              atualPage === numberOfPages || numberOfPages === 0
                ? "default"
                : "pointer",
          }}
        >
          <FiChevronRight size={28} />
        </SetasTwo>
      </div>
    </div>
  );
};
