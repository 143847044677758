import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    height: 100vh;
    position: relative;
    flex-direction: column;
    background-position:bottom;
    transition: all 1s;
    background-size: cover;
`