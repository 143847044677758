import styled from "styled-components";

export const Container  = styled.div`
height:100vh;

.btn-contratar{
    display: block;
    padding:5px; 
    margin:20px; 
    width:200px;
    background: #28CB38;
border: 1px solid #000000;
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
}

hr{ 
    border-bottom: 1px solid #000000;
}


@media (max-width:561px){
    .wrap_card_perfil{
        flex-wrap: "wrap",
    }
}
`

export const Titulo = styled.div`
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 21px;

    color: #134480;

    
`
export const Button = styled.button`

    cursor: pointer;
    margin:10px;
    background: transparent;
    border:none;

    font-family: "Gotham-Medium";

    color: rgb(100, 102, 107);

    :hover{
        transition: 1s;
        color:#fff;

    }

`
export const Descricao = styled.div`
margin:20px;
text-align: center;

font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 32px;


color: #134480;
`

export const CardPefil = styled.div`

   
    width: 100%;
    max-width:1000px;
    min-width:200px;
   
   

    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin:auto;

    display: flex;

    @media (max-width:632px){
        display: block;
       text-align: center;
       justify-content: center;
       align-items: center;
    }
  
    

`
export const Valore = styled.div`
display: flex;

@media (max-width:911px){
    display: block;
}
`
export const Avatar = styled.img`
    width: 200px;
    height: 200px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 20px;
    margin:20px;
    padding:10px;
`
export const NameText = styled.div`

    width: 100%;
    min-width:200px;
    max-width:700px;

    height: 40px;


    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    margin:10px;
    padding:10px;
    cursor: pointer;
`

export const InputTEXTO= styled.input`

    width: 100%;
    // min-width:200px;
    // max-width:320px;




    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    margin:10px;
    
`

export const TextArea = styled.div`

 width: 100%;
 max-width:1000px;
 min-width:200px;
 margin:auto;
 margin-top: 10px;
 height:400px;
 padding-bottom:20px;

 textarea{  height:350px;}


`