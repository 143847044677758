import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";

import { useGlobal } from "../../../hooks/global";
import { useHistory } from "react-router";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/auth";
import { useToast } from "../../../hooks/toast";
import { IoLogoWhatsapp } from "react-icons/io5";
import CancelContaPlano from "../../../components/Modals/CancelContaPlano";
import { CircularProgress } from "@material-ui/core";
import { BsFlower1, BsFlower2 } from "react-icons/bs";
import { IoFlowerOutline } from "react-icons/io5";
import { GiFlowers } from "react-icons/gi";
import { FaRegTrashAlt } from "react-icons/fa";

export const Planos: React.FC = () => {
  const { setAtualPageGlobal, languagePage } = useGlobal();
  const [plans, setPlans] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { push } = useHistory();
  const { user, setUser, signOut } = useAuth();
  const { addToast } = useToast();
  const [mode, setMode] = useState("");
  const [modal, setModal] = useState<boolean>(false);

  useEffect(() => {
    setAtualPageGlobal("/company/planos");
    api.get("/company/plans").then((response) => {
      const { data } = response.data;
      setPlans(data);
      setLoading(false);
    });
  }, []);

  const handleSubscription = async (
    priceId: string,
    namePlan: string,
    value: number
  ) => {
    if (!!user.subscriptionId) {
      const res = await api.post("/company/update-subscription", {
        priceId: priceId,
        planName: namePlan,
      });
      const { planName, subscription, status, candidateLimit, vacancyLimit } =
        res.data;

      setUser({
        ...user,
        planName: planName,
        candidateLimit: candidateLimit,
        vacancyLimit: vacancyLimit,
        subscriptionId: subscription,
      });

      if (status === "past_due") {
        addToast({
          title:
            languagePage === "PT" ? "Efetue o pagamento" : "Make the payment",
          description:
            languagePage === "PT"
              ? "Para não perder o acesso ao plano."
              : "In order not to lose access to the plan.",
          icon: "alert",
          type: "error",
        });
      } else if (status === "active") {
        addToast({
          title: languagePage === "PT" ? "Sucesso" : "Success",
          description:
            languagePage === "PT"
              ? `Seu plano foi trocado para o ${planName}`
              : `Your plan has been switched to ${planName}`,
          icon: "products",
          type: "success",
        });
      }
      localStorage.setItem(
        "@flashPlayHR:user",
        JSON.stringify({
          ...user,
          planName: planName,
          candidateLimit: candidateLimit,
          vacancyLimit: vacancyLimit,
          subscriptionId: subscription,
        })
      );
      return push({
        pathname: "/company/selecoes",
      });
    } else {
      const res = await api.post("/company/create-subscription", {
        customerId: user.customerId,
        priceId: priceId,
        planName: namePlan,
      });
      const {
        clientSecret,
        subscription,
        planName,
        candidateLimit,
        vacancyLimit,
      } = res.data;

      setUser({
        ...user,
        planName: planName,
        candidateLimit: candidateLimit,
        vacancyLimit: vacancyLimit,
        subscriptionId: subscription,
      });

      localStorage.setItem(
        "@flashPlayHR:user",
        JSON.stringify({
          ...user,
          planName: planName,
          candidateLimit: candidateLimit,
          vacancyLimit: vacancyLimit,
          subscriptionId: subscription,
        })
      );

      localStorage.setItem(
        "@flashplayHR:plans",
        JSON.stringify({ clientSecret: clientSecret, planName: planName })
      );

      return push({
        pathname: "/company/pagamento",
        state: {
          clientSecret: clientSecret,
          planName: namePlan,
          value: value,
        },
      });
    }
  };
  const cancelAccountOrPlan = useCallback(async () => {
    if (mode === "account") {
      const res = await api.post("/company/delete-account", { id: user.id });

      const { deleted, error } = res.data;

      if (deleted) {
        addToast({
          type: "success",
          icon: "info",
          title:
            languagePage === "PT"
              ? "Sua conta foi excluida"
              : "Your account has been deleted",
          description:
            languagePage === "PT"
              ? "Foi um prazer ter você na nossa plataforma."
              : "It was a pleasure having you on our platform.",
        });
        signOut();
        push("/#");
      } else {
        addToast({
          type: "error",
          icon: "alert",
          title: languagePage === "PT" ? "Aviso" : "Notice",
          description: error,
        });
      }
    } else {
      const res = await api.post("/company/cancel-subscription");
      const { status } = res.data;

      if (status === "canceled") {
        addToast({
          type: "info",
          icon: "info",
          title:
            languagePage === "PT"
              ? "Seu plano foi cancelado!"
              : "Your plan has been cancelled!",
          description:
            languagePage === "PT"
              ? "Para ter acesso completo a plataforma precisa assinar plano."
              : "To have full access to the platform, you need to subscribe to a plan.",
        });
      } else {
        addToast({
          type: "info",
          icon: "info",
          title:
            languagePage === "PT"
              ? "Seu plano será cancelado"
              : "Your plan will be canceled",
          description:
            languagePage === "PT"
              ? "Para reativar é só escolher um plano."
              : "To reactivate, just choose a plan.",
        });
      }

      setModal(false);
    }
  }, []);
  if (languagePage === "PT") {
    return (
      <Container>
        <CancelContaPlano
          open={modal}
          model={mode}
          onClose={() => setModal(false)}
          onExclude={cancelAccountOrPlan}
        />
        {loading && (
          <div
            style={{
              display: "flex",

              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}

        {!loading && (
          <>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                fontFamily: "Gotham-Bold",
                color: "#FFF",
                fontSize: 26,
                alignItems: "center",
                justifyContent: "center",

                marginTop: -15,
                padding: 10,
                marginBottom: 50,
              }}
            >
              {plans.map((plan) => {
                if (plan.currency === "brl") {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        padding: 20,
                        margin: "20px 10px",
                        maxWidth: 300,
                        minHeight: 584.5,
                        //marginRight: 10,
                        //marginLeft: 10,
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFF",
                        borderRadius: 20,
                        boxShadow: "2px 2px 20px -6px rgba(255, 145, 11, 1)",
                        WebkitBoxShadow: "2px 2px 20px -6px rgbargba(255, 145, 11, 1)",
                      }}
                    >
                      <div
                        style={{
                          color: "#081118",
                          fontSize: 30,
                          marginBottom: 20,
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        {plan.name === "Básico" ? (
                          <BsFlower2 />
                        ) : plan.name === "Intermediário" ? (
                          <IoFlowerOutline />
                        ) : (
                          <BsFlower1 />
                        )}
                        &#32;{plan.name}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          color: "#081118",
                          fontSize: 16,
                          marginBottom: 5,
                          textAlign: "left",
                          maxWidth: 280,
                          minHeight: 216,
                        }}
                      >
                        <ul>
                          {plan.description.split(". ").map((line: any) => {
                            return <li style={{ marginBottom: 5 }}>{line}</li>;
                          })}
                        </ul>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          color: "#134480",
                          marginTop: 30,
                        }}
                        /*
                        {Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 2,
                        }).format(plan.amount / 100)}
                        / {languagePage === "PT" ? "mês" : plan.interval}
                        */
                      >
                      </div>
                      {plan.name !== "Básico" && (
                        <div style={{ color: "#081118", fontSize: 15 }}>
                          (Até 3 meses)
                        </div>
                      )}
                      <div
                        onClick={() =>
                          // user.id
                          //   ? handleSubscription(
                          //       plan.id,
                          //       plan.name,
                          //       plan.amount / 100
                          //     )
                          //   : push("/logon")

                          /*
                            ? (window.location.href = `https://wa.me/5571986956150?text=nome%20do%20assinante:%20${user.name
                              },%20plano:%20${plan.name
                              },%20valor:%20${Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                                minimumFractionDigits: 2,
                              }).format(plan.amount / 100)}}`)
                            : push("/logon")
                          */
                          user.id
                            ? (window.location.href = `https://wa.me/5571986956150?text=nome%20do%20assinante:%20${user.name
                              },%20plano:%20${plan.name
                              }}`)
                            : (window.location.href = `https://wa.me/5555719974806?text=Ol%C3%A1%2C+gostaria+de+conhecer+os+planos+b%C3%A1sicos`)
                        }
                        style={{
                          background: "#134480",
                          cursor: "pointer",
                          fontSize: 16,
                          padding: 10,
                          borderRadius: 10,
                          marginTop: 20,
                          boxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                          WebkitBoxShadow:
                            "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                        }}
                      >
                        {user.planName === "NoPlan" && plan.name === "Básico"
                          ? "Adquirir plano"
                          : "Adquirir plano"}
                      </div>
                    </div>
                  );
                }
              })}
              {/* plano personalizado */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  padding: 20,
                  margin: "20px 10px",
                  maxWidth: 300,
                  height: 350,
                  //marginRight: 10,
                  //marginLeft: 10,
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: "#FFF",
                  borderRadius: 20,
                  boxShadow: "2px 2px 20px -6px rgba(255, 145, 11, 1)",
                  WebkitBoxShadow: "2px 2px 20px -6px rgba(255, 145, 11, 1)",
                }}
              >
                <div
                  style={{
                    color: "#081118",
                    fontSize: 30,
                    alignItems: "center",
                  }}
                >
                  <GiFlowers />
                  &#32;Personalizado
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    color: "#081118",
                    fontSize: 16,
                    marginBottom: 5,
                    textAlign: "center",
                    maxWidth: 280,
                  }}
                >
                  Entre em contato para discutirmos seu plano personalizado e
                  formas de pagamentos
                </div>

                <div
                  style={{
                    background: "#134480",
                    cursor: "pointer",
                    fontSize: 16,
                    padding: 10,
                    borderRadius: 10,
                    marginTop: 20,
                    boxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 1)",
                    WebkitBoxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 1)",
                  }}
                >
                  <a
                    href="https://api.whatsapp.com/send?phone=5571986956150"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Fale com a gente <IoLogoWhatsapp color="white" size={20} />
                  </a>
                </div>
                <div
                  style={{
                    color: "#081118",
                    fontSize: "small",
                    maxWidth: 260,
                    textAlign: "center",
                  }}
                >
                  <p>
                    *Obs.: O plano personalizado consiste em diagnóstico e
                    capacitação
                  </p>
                </div>
              </div>
            </div>
            <div style={{ minWidth: 150, margin: "auto", padding: 5 }}>
              <div
                onClick={() => {
                  setMode("plan");
                  setModal(true);
                }}
                style={{
                  display: "flex",
                  color: "#FFF",
                  cursor: "pointer",
                  textDecoration: "none",
                  flexDirection: "row",
                  background: " rgb(19, 68, 128)",
                  padding: 20,
                  borderRadius: 20,
                  marginBottom: 20,
                  boxShadow: "0.125rem 0.125rem 0.5rem rgba(0, 0,0, 1)",
                  textAlign: "center",
                  margin: "auto",
                  width: "100%",

                  height: 80,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  <div
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      fontFamily: "Gotham-Bold",
                      padding: '5px',
                    }}
                  >
                    Cancelar plano atual   &#32;
                    <FaRegTrashAlt
                      style={{
                        cursor: "pointer",
                        fontSize: 20,
                        margin: "auto",
                      }}
                    ></FaRegTrashAlt>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
    );
  } else {
    return (
      <Container>
        <CancelContaPlano
          open={modal}
          model={mode}
          onClose={() => setModal(false)}
          onExclude={cancelAccountOrPlan}
        />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            fontFamily: "Gotham-Bold",
            color: "#FFF",
            fontSize: 26,
            alignItems: "center",
            justifyContent: "center",

            marginTop: -15,
            padding: 10,
            marginBottom: 50,
          }}
        >
          {plans.map((plan) => {
            if (plan.currency === "usd" && plan.name !== "myproduct") {
              return (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    padding: 20,
                    margin: "20px 10px",
                    maxWidth: 300,
                    minHeight: 584.5,
                    //marginRight: 10,
                    //marginLeft: 10,
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#FFF",
                    borderRadius: 20,
                    boxShadow: "2px 2px 20px -6px rgba(0, 0, 0, 0.5)",
                    WebkitBoxShadow: "2px 2px 20px -6px rgba(0, 0, 0, 0.5)",
                  }}
                >
                  <div
                    style={{ color: "#081118", fontSize: 30, marginBottom: 20 }}
                  >
                    {plan.name === "Basic" ? (
                      <BsFlower2 />
                    ) : plan.name === "Intermediary" ? (
                      <IoFlowerOutline />
                    ) : (
                      <BsFlower1 />
                    )}
                    &#32;{plan.name}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      color: "#081118",
                      fontSize: 16,
                      marginBottom: 5,
                      textAlign: "left",
                      maxWidth: 280,
                      minHeight: 216,
                    }}
                  >
                    <ul>
                      {plan.description?.split("; ").map((line: any) => {
                        return <li style={{ marginBottom: 5 }}>{line}</li>;
                      })}
                    </ul>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      color: "#134480",
                      marginTop: 30,
                    }}
                    /*
                    {Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                    }).format(plan.amount / 100)}
                    / {languagePage === "PT" ? "mês" : plan.interval}
                    */
                  >
                  </div>
                  {plan.name !== "Basic" && (
                    <div style={{ color: "#081118", fontSize: 15 }}>
                      (up to 3 months)
                    </div>
                  )}
                  <div
                    onClick={() =>
                      //user.id
                      //? handleSubscription(
                      //  plan.id,
                      //plan.name,
                      //plan.amount / 100
                      //)
                      //: push("/logon")

                      /*
                        ? (window.location.href = `https://wa.me/5571986956150?text=nome%20do%20assinante:%20${user.name
                          },%20plano:%20${plan.name
                          },%20valor:%20${Intl.NumberFormat("en", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                          }).format(plan.amount / 100)}}`)
                        : push("/logon")
                      */
                      user.id
                        ? (window.location.href = `https://wa.me/5571986956150?text=nome%20do%20assinante:%20${user.name
                          },%20plano:%20${plan.name
                          }}`)
                        : (window.location.href = `https://wa.me/5555719974806?text=Ol%C3%A1%2C+gostaria+de+conhecer+os+planos+intermedi%C3%A1rios`)
                    }
                    style={{
                      background: "#134480",
                      cursor: "pointer",
                      fontSize: 16,
                      padding: 10,
                      borderRadius: 10,
                      marginTop: 20,
                      boxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                      WebkitBoxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                    }}
                  >
                    {user.planName === "NoPlan" && plan.name === "Basic"
                      ? "Free Trial"
                      : "Buy plan"}
                  </div>
                </div>
              );
            }
          })}
          {/* plano personalizado */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              padding: 20,
              margin: "20px 10px",
              maxWidth: 300,
              height: 350,
              //marginRight: 10,
              //marginLeft: 10,
              justifyContent: "space-between",
              alignItems: "center",
              background: "#FFF",
              borderRadius: 20,
              boxShadow: "2px 2px 20px -6px rgba(0, 0, 0, 0.5)",
              WebkitBoxShadow: "2px 2px 20px -6px rgba(0, 0, 0, 0.5)",
            }}
          >
            <div style={{ color: "#081118", fontSize: 30 }}>
              <GiFlowers />
              &#32;Custom
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                color: "#081118",
                fontSize: 16,
                marginBottom: 5,
                textAlign: "center",
                maxWidth: 280,
              }}
            >
              Contact us to discuss your custom plan
            </div>

            <div
              style={{
                background: "#134480",
                cursor: "pointer",
                fontSize: 16,
                padding: 10,
                borderRadius: 10,
                marginTop: 20,
                boxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                WebkitBoxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
              }}
            >
              <a
                href="https://api.whatsapp.com/send?phone=5571986956150"
                style={{ textDecoration: "none", color: "white" }}
              >
                Contact us <IoLogoWhatsapp color="white" size={20} />
              </a>
            </div>
            <div
              style={{
                color: "#081118",
                fontSize: "small",
                maxWidth: 260,
                textAlign: "center",
              }}
            >
              <p>
                *Note: The custom plan consists of diagnosis and qualification
              </p>
            </div>
          </div>
        </div>
        <div style={{ minWidth: 150, margin: "auto", padding: 5 }}>
          <div
            onClick={() => {
              setMode("plan");
              setModal(true);
            }}
            style={{
              display: "flex",
              color: "#FFF",
              cursor: "pointer",
              textDecoration: "none",
              flexDirection: "row",
              background: " rgb(19, 68, 128)",
              padding: 20,
              borderRadius: 20,
              marginBottom: 20,
              boxShadow: " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
              textAlign: "center",
              margin: "auto",
              width: "100%",

              height: 80,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                margin: "auto",
              }}
            >
              <div
                style={{
                  margin: "auto",
                  textAlign: "center",
                  fontFamily: "Gotham-Bold",
                }}
              >
                Cancel current plan&#32;
                <FaRegTrashAlt
                  style={{ cursor: "pointer", fontSize: 20, margin: "auto" }}
                ></FaRegTrashAlt>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
};
