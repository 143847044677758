import   React, { CSSProperties }                from 'react';
import { InputBaseProps }     from '@material-ui/core';
import { ErrorProps }         from '../../services/schemaServices';
import { forwardRef,
         useCallback,
         useRef,
         useState, 
         useImperativeHandle 
       } from 'react';
import { Container, 
         ContainerError, 
         IconError, 
         InputBase, 
         Label, 
         TooltipError 
       } from './style';


export interface InputRefProps {
    getInputValue:() => string;
}

interface InputProps extends InputBaseProps{
    id:string;
    errors:ErrorProps[];
    attr:string;
    label:string;
    lostFocus?:Function;
    getFocus?:Function;
    labelStyle?:CSSProperties;
    containerStyle?:CSSProperties;
}

const InputLabel:React.ForwardRefRenderFunction<InputRefProps,InputProps> = ({id,containerStyle = {},errors,labelStyle = {},label,disabled,attr,value,defaultValue,getFocus = () => {},lostFocus = () => {},...props},ref) => {
    const [ inputFocus   , setInputFocus   ] = useState<boolean>(false)
    const [ inputChecked , setInputChecked ] = useState<boolean>(!!value || !!defaultValue)
    const [ hoverError   , setHoverError   ] = useState<boolean>(false)
    const   inputRef = useRef<HTMLDivElement>(null)

    const getInputValue = useCallback(()=>{
        return inputRef.current?.getElementsByTagName('input')[0].value ? inputRef.current?.getElementsByTagName('input')[0].value : ""
    },[inputRef])

    useImperativeHandle(ref,() => {
        return {
            getInputValue
        };
    });

    const FocusInput = useCallback(()=>{
        inputRef.current?.getElementsByTagName('input')[0].focus()
    },[inputRef])

    const OnFocus = useCallback(()=>{
        const value = getInputValue()
        setInputFocus(true)
        setInputChecked(true)
        getFocus(value)
    },[])

    const OnBlur = useCallback(()=>{
        const value = getInputValue()
        const flag = lostFocus(value)
        const valor = flag === undefined ? value : false
        if(!valor)setInputChecked(false)
        setInputFocus(false)
    },[])

    const SearchMessage = useCallback(()=>{
        for(let i = 0 ; i < errors.length; i++)if(errors[i].attr === attr)return errors[i].message
        return false
    },[errors])

    return(
        <Container style={{...containerStyle}} ref={(ref:any) => ref = ref} haveErrors={!!SearchMessage()} isFocused={inputFocus}>
                <InputBase 
                    {...props}
                    id={id} 
                    value={value}
                    disabled={disabled}
                    defaultValue={defaultValue}
                    onFocus={OnFocus} 
                    onBlur={OnBlur} 
                    ref={inputRef}
                    autoComplete="off" 
                    autoCorrect="off" 
                    spellCheck="false"
                />
                {(!!SearchMessage() && !inputFocus ) && 
                    <ContainerError>
                        <IconError onMouseEnter={()=>setHoverError(true)} onMouseLeave={()=>setHoverError(false)} size={"1.375rem"} color="#AF0708"/>
                        {hoverError && 
                            <TooltipError>{SearchMessage()}</TooltipError>
                        }
                    </ContainerError>
                }
                <Label style={{cursor:disabled ? 'default' : 'text',...labelStyle}} onClick={FocusInput} haveError={!!SearchMessage()} isFocused={inputFocus} isChecked={inputChecked}>{label}</Label>
        </Container>
    )
}

export default forwardRef(InputLabel);