import styled from "styled-components";


export const Container = styled.div`
        /* display: flex; */
        flex-wrap: wrap;
        cursor: default;
        height: 100%;
        max-width: 800px;
        min-width: 275px;
        width: 100%;
        flex-direction: column;
        font-family: Gotham-Bold;
        justify-content: center;
        align-items: center;
        color: #FFF;
        font-size: 26px;
        margin-top: 100px;
`

export const ContainerTitulo = styled.div`
        /* display: flex; */
        flex-wrap: wrap;
        cursor: default;
        height: 100%;
        max-width: 800px;
        min-width: 275px;
        width: 100%;
        flex-direction: column;
        font-family: Gotham-Bold;
        justify-content: center;
        align-items: center;
        color: #021f3a;
        font-size: 26px;
        margin-top: 100px;
`

export const DecisionMenu = styled.div`
  display: flex; 
  flex-wrap:wrap;  
  margin-top: 40px; 
  padding:10px;
                    
  width: 100%;
  max-width:700px;
  min-width:100px;

  .decisao_menu{
      border-radius: 20px;
    box-shadow: 4px 4px 18px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 4px 4px 18px -2px rgba(0,0,0,0.75);
    
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    background: #0b243b;

    margin: auto;
    width: 100%;
    max-width:330px;
    min-width: 150px;
    padding: 20px;
    margin-bottom:20px;



    
  }

  .mobil-icons, .mobil-icons_company{
    display:none;
  }
  .mobil-font-aw{
    text-align: center; 
    margin: 10px;
  }
  @media(max-width:375px){
    .web-icons{
      display:none;
    }
    .mobil-icons{
      // display:block;
      // text-align: center;
      // align-items: center;
      // justify-content: center;
      // background:red;
      // height:200px;

      height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
    }
    .decisao_menu{
     
      width: 200px;
      height:250px;
      
     
    }
    .decisao_menu_company{
      width: 200px;
      height:180px;
    }
    .mobil-icons_company{
   

      height: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
    }
  }

  
                  
`