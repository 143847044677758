import styled from "styled-components";

interface SetaProps{
  atualPage: number;
}


export const Setas = styled.div<SetaProps>`
  display: flex;
  padding: 5px;
  border-radius: 50%;
  color: #FFF;
  justify-content: center;
  align-items: center;
  background: ${(props: any) =>
    props.atualPage===1 ? "#C4C4C4" : "#f27823"};
  cursor:${(props: any) =>
    props.atualPage===1 ? "default" : "pointer"};
  

  @media (max-width: 433px) {
    display: none;
  }
`;

export const SetasTwo = styled.div`
  display: none;
  width: 50px;
  height: 50px;
  padding: 20px;
  margin: 10px;

  @media (max-width: 433px) {
    display: flex;
  }
`;
export const DisplayFlex = styled.div`

  display: flex;


`
export const TextAlignCenter = styled.div`
  text-align: center;
`
export const Container = styled.div`
  min-height: calc(100% - 20px);
  flex-direction: column;
  margin: 20px;
  margin-top: 0px;
  color: "#64666b";
  font-family: "Gotham-Bold";
`
export const DescriptionQuiz = styled(DisplayFlex)`

  margin: auto;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;

`
export const CardDescriptionQuiz = styled.div`
  display: inline-block;
  max-width: 300px;
  /* height: 150px; */
  margin: 10px;
  border: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 10px;
  justify-content: center;

  p{
    margin: 10px;
    text-align:left;
  }
`

export const Selections = styled(DisplayFlex)`

  flex-direction: column;
  align-items: center;
`
export const SelectionsTitle = styled(TextAlignCenter)`
  color: #64666b;
  font-family: "Gotham-Medium";
  font-size: 26px;

`
export const CardSelecoes = styled(DisplayFlex)`

  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 0px;
`

export const CardContainer = styled(DisplayFlex)`
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  min-height: 114px;
  width: 100%;
  max-width: 800px;
`
