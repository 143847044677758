import React, { useCallback, useRef, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { MdAssignment } from 'react-icons/md';
import { ErrorProps, Verify } from '../../../services/schemaServices';
import {
    Container,
    Header,
    IconDelete,
    ButtonEnd,
    ContainerHeader,
    ContainerTitle,
    Formulario,
    ContainerItemForm,
    ContainerBottom
} from './style';
import InputLabel, { InputRefProps } from '../../InputLabel';
import {useGlobal} from '../../../hooks/global'

interface ModalNovoQuestionarioProps {
    open: boolean;
    onClose: Function;
    onSave: Function;
}

export const ModalNovoQuestionario: React.FC<ModalNovoQuestionarioProps> = ({ open, onClose, onSave }) => {
    const [errors, setErrors] = useState<ErrorProps[]>([] as ErrorProps[])
    const inputNameRef = useRef<InputRefProps>(null)

    const Save = useCallback(() => {
        const name = inputNameRef.current?.getInputValue()
        const errors = Verify([{
            name: 'name',
            value: name,
            required: { message: 'Campo obrigatório!', value: true }
        }
        ])
        setErrors(errors)
        if (errors.length === 0) onSave({ name })
    }, [inputNameRef])
    const {languagePage} = useGlobal()

    return (
        <Modal open={open} onClose={() => onClose()} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Container>

                <ContainerHeader>
                    <Header>
                        <ContainerTitle>
                            <MdAssignment style={{ marginRight: "0.625rem" }} color="#101A36" />
                            {languagePage === "PT" ? "Novo Questionário Específico" : "New Specific Questionnaire"}
                        </ContainerTitle>
                        <IconDelete onClick={() => onClose()} color="#C4C4C4" />
                    </Header>
                </ContainerHeader>

                <Formulario>
                    <ContainerItemForm>
                        <InputLabel
                            errors={errors}
                            label= {languagePage === "PT" ? "Nome do Questionário" : "Quiz Name"}
                            attr="name"
                            id='InputNameCategory'
                            ref={inputNameRef}
                            inputProps={{ style: { fontFamily: "math",fontWeight:"bold" } }}
                        />
                    </ContainerItemForm>
                </Formulario>

                <ContainerBottom>
                    <ButtonEnd onClick={() => onClose()} style={{ color: "#252323" }}>{languagePage === "PT" ? "Cancelar" : "Cancel"}  </ButtonEnd>
                    <ButtonEnd onClick={Save} style={{ color: "#101A36" }}>  {languagePage === "PT" ? "Salvar" : "To save"}   </ButtonEnd>
                </ContainerBottom>

            </Container>
        </Modal>
    )
}

export default ModalNovoQuestionario;