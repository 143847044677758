import React, { useCallback, useState, useEffect } from "react";

import Modal from "@material-ui/core/Modal";
import { Container, Description, Footer, Title } from "./style";
import { useHistory } from "react-router-dom";

interface ModalVisualizarPropostaProps {
  open: boolean;
  onClose: () => void;
  proposal: any;
}

export const ModalVisualizarProposta: React.FC<
  ModalVisualizarPropostaProps
> = ({ onClose, open, proposal }) => {
  const { push } = useHistory();
  const [description, setDescription] = useState<string>("");
  const [proposalId, setProposalId] = useState<string>("");

  useEffect(() => {
    if (!proposal) {
      return onClose();
    }
    setDescription(proposal.description);
    setProposalId(proposal.id);
  }, [onClose, proposal]);

  const handleClick = useCallback(() => {
    push(`/root/consultoria/${proposalId}`);
  }, [proposalId, push]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <Title>
          <h4>Descrição</h4>
        </Title>
        <Description>
          <p>{description}</p>
        </Description>
        <Footer>
          {!!proposal && !proposal.consultant && (
            <button onClick={() => handleClick()}>Selecionar Consultor</button>
          )}
        </Footer>
      </Container>
    </Modal>
  );
};
