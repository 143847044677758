import styled from "styled-components";

export const Container = styled.div`
    display: flex;
   
    position: relative;
    flex-direction: column;
    background-position:bottom;
    transition: all 1s;
    background: url("https://res.cloudinary.com/hkkzuevm3/image/upload/v1648759101/Assets-FlashPlayHr-webp/fundoTelaDeEmpresa_h64p94_sotoow.webp") fixed;
    background-size: cover;
   
`