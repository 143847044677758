import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 25px;
    font-family: "Gotham-Medium", sans-serif;
    color: #134480;
  }

  .box-button {
    display: flex;
    width: 100%;
    margin: 16px auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .box-card {
    background: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 16px auto;
    padding: 16px;
    border-radius: 8px;

    h4 {
      font-family: "Gotham-Medium", sans-serif;
      font-size: 18px;
      color: #133c65;
      margin: 0px 0px 16px;
    }

    button {
      padding: 16px 32px;
      background: #134480;
      border: none;
      border-radius: 5px;
      color: #fff;
      font-family: "Gotham-Regular", sans-serif;
      font-size: 14px;
      margin-top: 32px;
    }
    .voltar {
      padding: 0px;
      background: none;
      color: #134480;
      margin-top: 16px;
    }
  }
  .card {
    display: flex;
    width: 100%;
    padding: 16px;
  }
`;

export const Button = styled.button`
  background: #134480;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  border-radius: 8px;
  border: none;
  color: #fff;
  transition: background 0.2s;
  cursor: pointer;

  &:hover {
    background-color: #133c65;
  }

  p {
    font-size: 18px;
    font-family: "Gotham-Regular", sans-serif;
    margin-top: 16px;
  }
`;

export const Input = styled.input`
  display: flex;
  padding: 4px 8px;
  width: 100%;
`;
