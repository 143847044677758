import React from "react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { ContainerPortCompany, TypeCompany } from "./style";
import { useForm } from "../../../hooks/form";
import { useGlobal } from "../../../hooks/global";
import { BotoesForm, ButtonNextBack, Container, Titulo } from "../style";

interface PortCompanyData {
  onNext: Function;
  onBack: Function;
}

const PortCompany: React.FC<PortCompanyData> = ({ onNext, onBack }) => {
  const { posPorte, setPosPorte } = useForm();
  const { setAtualPageGlobal, languagePage } = useGlobal();

  return (
    <Container
      style={{    
        width: "100%",
        textAlign: "center",
        marginTop:110
      }}
    >
      <div style={{ width: "100%" }}>
        <Titulo>
          {languagePage === "PT"
            ? "Identifique o porte atual da sua empresa."
            : "Identify your company's current size."}
        </Titulo>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            textAlign: "center",
            width: "100%",
          }}
        >
          <ContainerPortCompany
            isSelected={posPorte === 0}
            onClick={() => setPosPorte(0)}
            style={{ margin: "auto", marginBottom: 10, width: 250 }}
          >
            <div className="flex-h40-w40">
              <img
                src={
                  "https://res.cloudinary.com/hkkzuevm3/image/upload/v1635024016/Assets-FlashPlayHr/pequenoPorte_wd4yyh.png"
                }
                alt="Imagens"
              />
            </div>
            <TypeCompany>
              <div>
                {languagePage === "PT" ? "Pequeno porte" : "Small Business"}
              </div>
            </TypeCompany>
          </ContainerPortCompany>
          <ContainerPortCompany
            isSelected={posPorte === 1}
            onClick={() => setPosPorte(1)}
            style={{ margin: "auto", marginBottom: 10, width: 250 }}
          >
            <div className="flex-h40-w40">
              <img
                src={
                  "https://res.cloudinary.com/hkkzuevm3/image/upload/v1635023914/Assets-FlashPlayHr/m%C3%A9dioPorte_w2wbl4.png"
                }
                alt="Imagem"
              />
            </div>
            <TypeCompany>
              <div>
                {languagePage === "PT" ? "Médio porte" : "Medium Business"}
              </div>
            </TypeCompany>
          </ContainerPortCompany>
          <ContainerPortCompany
            isSelected={posPorte === 2}
            onClick={() => setPosPorte(2)}
            style={{ margin: "auto", marginBottom: 10, width: 250 }}
          >
            <div className="flex-h40-w40">
              <img
                src={
                  "https://res.cloudinary.com/hkkzuevm3/image/upload/v1635023988/Assets-FlashPlayHr/grandePorte_qstoa0.png"
                }
                alt="Imagem"
              />
            </div>
            <TypeCompany>
              <div>
                {languagePage === "PT" ? "Grande porte" : "Big Enterprise"}
              </div>
            </TypeCompany>
          </ContainerPortCompany>
          <ContainerPortCompany
            isSelected={posPorte === 3}
            onClick={() => setPosPorte(3)}
            style={{ margin: "auto", marginBottom: 10, width: 250 }}
          >
            <div className="flex-h40-w40">
              <img
                src={
                  "https://res.cloudinary.com/hkkzuevm3/image/upload/v1635024056/Assets-FlashPlayHr/startup_eqwdr4.png"
                }
                alt="Imagem"
              />
            </div>
            <TypeCompany>
              Startup
            </TypeCompany>
          </ContainerPortCompany>
        </div>

        <ButtonNextBack>
          <BotoesForm onClick={() => onBack()}>
            <GoChevronLeft />
            {languagePage === "PT" ? "Anterior" : "Back"}
          </BotoesForm>
          <BotoesForm onClick={() => onNext()}>
            {languagePage === "PT" ? "Próximo" : "Next"}
            <GoChevronRight style={{ marginLeft: 5 }} />
          </BotoesForm>
        </ButtonNextBack>
      </div>
    </Container>
  );
};

export default PortCompany;
