import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Header } from "../../components/Header";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import * as Yup from "yup";
import { InputRefProps } from "../../components/Input";
import { useGlobal } from "../../hooks/global";
import SetorTypo from "../../components/Modals/SetorTypo";
import { ContainerGeral, Title } from "./style";
import { FaUserAlt } from "react-icons/fa";
import { selectionInterface } from "../../interfaces/selectionInterface";
import { useToast } from "../../hooks/toast";
const PublicSelecoesExpecifica: React.FC = () => {
    const { user, setUser } = useAuth();
    const { push } = useHistory();
    const [selectionId, setSelectionId] = useState<any>();
    const [selection, setSelection] = useState<any>();
    const [errorCodeAccess, setErrorCodeAccess] = useState<string | null>(null);
    const inputCodeAccessRef = useRef<InputRefProps>(null);
    const { languagePage } = useGlobal()
    const [dataPublic, setDataPublic] = useState<selectionInterface>()
    const [dataConsultant, setDataConsultant] = useState<any>()
    const [openModalCodigo, setOpenModalCodigo] = useState<boolean>(false);
    const SenhaAlterada = useCallback((data) => {
        setOpenModalCodigo(false);
    }, []);
    const { addToast } = useToast();

    const id = useParams<any>()
    const buscarSelecoes = async () => {
        try {
            const resuPublic = await api.get(`/selections/` + id.id);
            setDataPublic(resuPublic.data)
            console.log(resuPublic.data)
        } catch (err) {

        }

    }
    useEffect(() => {
        buscarSelecoes()
    }, [])

    const VerifyCodeAccess = useCallback(async () => {
        let resu;
        setErrorCodeAccess(null);

        const title = inputCodeAccessRef.current?.getInputValue();
        const schemaTitle = Yup.object().shape({
            title: Yup.string().required(
                languagePage === "PT" ? "Campo obrigatório" : "Required field"
            ),
        });
        try {
            await schemaTitle.validate({ title });
        } catch (err: any) {
            setErrorCodeAccess(err.errors ? err.errors[0] : null);
            resu = err.errors ? err.errors[0] : null;
        }
        return resu;
    }, [inputCodeAccessRef]);

    const solicitarInscricao = useCallback(
        async (selectionId: any, selectionPublic: boolean) => {
            if(user.preference === dataPublic?.preference){
               try{
                const resposta =  await api.post(`/common/subscribeInSelection/${selectionId}`);
                addToast({
                    type: "info",
                    icon: "alert",
                    title: "Solicitação feita com sucesso.",
                     description: "Vaga para "+user.preference,
                  });
                push("/")
                return;
               }catch(err){
                addToast({
                    type: "info",
                    icon: "alert",
                    title: "Você já se canditador a está vaga.",
                    description: "Vaga para "+user.preference,
                  });
                  push("/")
                  return;
               }
               
             
            }else{
                addToast({
                    type: "info",
                    icon: "alert",
                    title: "Seu perfil não é compativel para esta vaga.",
                    description: "Vaga para "+user.preference,
                  });
                  push("/")
            }
            // if (user.preference === "consultant") {
            //     await api.post(`/common/subscribeInSelection/${selectionId}`);
            //     return;
            // }
            if (selectionPublic === true) {

                setTimeout(async () => {
                    await api.post(`/common/subscribeInSelection/${selectionId}`);


                }, 200);
            } else {
                const errorCodeAccess = await VerifyCodeAccess();

                if (!!!errorCodeAccess) {
                    const codeAccessVerified =
                        inputCodeAccessRef.current?.getInputValue();

                    setTimeout(async () => {
                        await api.post(`/common/subscribeInSelection/${selectionId}`, {
                            codeAccess: codeAccessVerified,
                        });


                    }, 200);
                }
            }
        },
        [VerifyCodeAccess, user.preference,dataPublic]
    );
    const solicitando = (id: any, publi: any) => {
        if (publi === false) {
            setSelectionId(id);
            setOpenModalCodigo(true);
        } else {
            solicitarInscricao(id, publi);
        }
    };
    return (
        <>
            <Header />
            <ContainerGeral>
                <Title>Seleções Especifica</Title>
                <SetorTypo
                    open={openModalCodigo}
                    selectionId={selectionId}
                    onClose={() => setOpenModalCodigo(false)}
                    onSave={SenhaAlterada}
                ></SetorTypo>
                <Container style={{ margin: "auto", marginTop: 40, }}>
                    <Row style={{ justifyContent: "center", alignItems: "center" }}>
                        <Col xs={4} className="card-geral">
                            <div style={{ display: "flex", color: "#fff" }}>
                                <div style={{ width: "50px", height: 50, margin: "10px" }}>
                                    <FaUserAlt size={40}></FaUserAlt>
                                </div>
                                <div style={{ margin: "10px" }}>
                                    {dataPublic?.name}

                                </div>
                            </div>

                            <div style={{ color: "#fff" }}>Descrição</div>
                            <div className="texto-descr" style={{ color: "#000", width: "100%", background: "#fff", borderRadius: 10, padding: 10 }}>{dataPublic?.description}</div>

                            <div style={{ marginTop: "10px" }}>
                                {user.id ?
                                    <button type="button" onClick={() => solicitando(dataPublic?.id, dataPublic?.public)} >Solicitar Inscricão</button> :
                                    <button type="button" onClick={() => push("/login")}>Faça sua conta e inscreva-se</button>}

                            </div>
                        </Col>
                      


                    </Row>
                </Container>

            </ContainerGeral>
        </>
    )
}

export default PublicSelecoesExpecifica