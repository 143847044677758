import React, { useCallback, useEffect, useState } from "react";
import { FaLinkedin, FaUser, FaRegFilePdf } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import InputPerfil from "../../../components/InputPerfil";
import { UserProps } from "../../../interfaces/userInterface";
import api from "../../../services/api";
import { MaskCNPJ, MaskCPF, MaskPhone } from "../../../services/maskServices";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { selectionInterface } from "../../../interfaces/selectionInterface";
import { useGlobal } from "../../../hooks/global";
import { ConEmailTel } from "./style";

export const RootPerfilCandidatos: React.FC = () => {
  const { requestId, selectionId }: any = useParams();
  const [candidate, setCandidate] = useState<UserProps>({} as UserProps);
  const { push } = useHistory();
  const [selection, setSelection] = useState<selectionInterface>(
    {} as selectionInterface
  );

  const { setAtualPageGlobal, languagePage } = useGlobal();

  const searchInfos = useCallback(async () => {
    const resu = await api.get(`/root/resquest/${requestId}`);
    setCandidate(resu.data.candidate);
    setSelection(resu.data.selection);
  }, []);

  useEffect(() => {
    setAtualPageGlobal(
      "/root/solicitacoes/perfilConsultor/:selectionId/:requestId"
    );
    searchInfos();
  }, []);

  const accept = useCallback(async (requestId) => {
    await api.post(`/root/request/accept/${requestId}`);
    push(`/root/solicitacoes/${selectionId}`);
  }, []);

  const decline = useCallback(async (requestId) => {
    await api.post(`/root/request/decline/${requestId}`);
    push(`/root/solicitacoes/${selectionId}`);
  }, []);

  return (
    <ConEmailTel
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 40px)",
        margin: 20,
        fontFamily: "Gotham-Medium",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          color: "#64666b",
          fontSize: 26,
          textAlign: "center",
        }}
      >
        <div
          onClick={() => push(`/root/solicitacoes/${selectionId}`)}
          style={{
            display: "flex",
            fontSize: 16,
            left: 0,
            flexDirection: "row",
            color: "#64666b",
            cursor: "pointer",
            width: "20%",
            maxWidth: "300px",
            minWidth: "200px",
            margin: "5px",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          {/* <HiOutlineArrowNarrowLeft style={{ marginRight: 10 }} /> */}
          {languagePage === "PT" ? "Voltar" : "Back"}
        </div>
        <div
          style={{
            width: "70%",

            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            margin: 5,
          }}
        >
          {languagePage === "PT"
            ? "Perfil do Candidato para a seleção"
            : "Candidate Profile for selection"}{" "}
          "{selection.name}"
        </div>
      </div>
      <div
        style={{
          display: "flex",
          margin: "auto",
          width: "100%",
          maxWidth: "1300px",
          minWidth: "200px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#fff",
            borderRadius: 10,
            padding: 20,

            margin: 20,
            width: "100%",
            maxWidth: "1300px",
            minWidth: "200px",
          }}
        >
          <div
            className="flexWOne"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              style={{
                display: "flex",
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                background: "#FFF",
                boxShadow: " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                minWidth: 166,
                minHeight: 166,
                maxWidth: 166,
                maxHeight: 166,
                margin: 10,
              }}
            >
              {candidate.avatar && (
                <img style={{ height: 140 }} src={candidate.avatar} />
              )}
              {!candidate.avatar && <FaUser color="#000" size={140} />}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: 1100,
                minWidth: "200px",
              }}
            >
              <InputPerfil
                styleInput={{
                  fontFamily: "Gotham-Medium",
                  color: "#000",
                  background: "#FFF",
                  fontSize: 16,
                }}
                styleContainer={{
                  background: "#FFF",
                  margin: 20,
                  marginTop: 0,
                }}
                placeholder="Nome do Candidato"
                value={candidate.name}
                disabled
                label="nome"
              />

              <div
                className="flexW"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <InputPerfil
                  styleInput={{
                    fontFamily: "Gotham-Medium",
                    color: "#000",
                    background: "#FFF",
                    fontSize: 16,
                  }}
                  styleContainer={{
                    background: "#FFF",
                    margin: "auto",
                    marginBottom: 20,

                    width: "90%",
                    maxWidth: "500px",
                    minWidth: "150px",
                  }}
                  placeholder="Email"
                  label="email"
                  value={candidate.email}
                  disabled
                />
                <InputPerfil
                  styleInput={{
                    fontFamily: "Gotham-Medium",
                    color: "#000",
                    background: "#FFF",
                    fontSize: 16,
                  }}
                  styleContainer={{
                    background: "#FFF",
                    margin: "auto",
                    marginBottom: 20,

                    width: "90%",
                    maxWidth: "500px",
                    minWidth: "150px",
                  }}
                  placeholder="Telefone"
                  label="telefone"
                  value={MaskPhone(
                    candidate.phone === undefined ? "" : candidate.phone
                  )}
                  disabled
                />
              </div>
              <div
                className="flexW"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <InputPerfil
                  styleInput={{
                    fontFamily: "Gotham-Medium",
                    color: "#000",
                    background: "#FFF",
                    fontSize: 16,
                  }}
                  styleContainer={{
                    background: "#FFF",

                    margin: "auto",
                    marginBottom: 20,

                    width: "90%",
                    maxWidth: "500px",
                    minWidth: "150px",
                  }}
                  placeholder="CPF / CNPJ"
                  label="cnpj"
                  value={
                    candidate.cnpj
                      ? MaskCNPJ(candidate.cnpj)
                      : candidate.cpf
                      ? MaskCPF(candidate.cpf)
                      : ""
                  }
                  disabled
                />
                <InputPerfil
                  styleInput={{
                    fontFamily: "Gotham-Medium",
                    color: "#000",
                    background: "#FFF",
                    fontSize: 16,
                  }}
                  styleContainer={{
                    background: "#FFF",
                    margin: "auto",
                    marginBottom: 20,

                    width: "90%",
                    maxWidth: "500px",
                    minWidth: "150px",
                  }}
                  placeholder="Gênero"
                  label="gênero"
                  value={candidate.sex}
                  disabled
                />
              </div>
            </div>
          </div>
          <div
            className="media-277"
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent:
                !!candidate.linkedin || !!candidate.curriculum
                  ? "space-between"
                  : "flex-end",
              fontFamily: "Gotham-Medium",
              color: "#FFF",

              width: "100%",
              maxWidth: 1250,
              minWidth: "200px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {!!candidate.linkedin && (
                <a
                  target="_blank"
                  href={candidate.linkedin}
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "#FFF",
                    cursor: "pointer",
                    minWidth: 146,
                    justifyContent: "center",
                    alignItems: "center",

                    borderRadius: 5,
                    background: "#0A66C2",
                    boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                    height: 50,
                    width: 160,
                    marginRight: 10,
                    padding: 10,
                    margin: 10,
                  }}
                  rel="noreferrer"
                >
                  <FaLinkedin style={{ marginRight: 5 }} />
                  LinkedIn
                </a>
              )}
              {!!candidate.curriculum && (
                <a
                  target="_blank"
                  href={candidate.curriculum}
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "#FFF",
                    cursor: "pointer",
                    minWidth: 146,
                    justifyContent: "center",
                    alignItems: "center",

                    borderRadius: 5,
                    background: "#ff2c2c",
                    boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                    height: 50,
                    width: 160,
                    marginRight: 10,
                    padding: 10,
                    margin: 10,
                  }}
                  rel="noreferrer"
                >
                  <FaRegFilePdf style={{ marginRight: 5 }} />
                  Currículo
                </a>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <div
                onClick={() => decline(requestId)}
                style={{
                  cursor: "pointer",
                  height: 50,
                  width: 160,
                  marginRight: 10,
                  padding: 10,
                  margin: 10,

                  borderRadius: 5,
                  background: "#8D171A",
                  boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                }}
              >
                {languagePage === "PT" ? "Recusar" : "Refuse"} Recusar
              </div>
              <div
                onClick={() => accept(requestId)}
                style={{
                  cursor: "pointer",
                  padding: 10,
                  height: 50,
                  width: 160,
                  margin: 10,
                  borderRadius: 5,
                  background: "#009848",
                  boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                }}
              >
                {languagePage === "PT" ? "Aceitar" : "Accept"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConEmailTel>
  );
};
