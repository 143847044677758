import styled from "styled-components";

export const ContainerLoading = styled.div`
    display: flex;
    outline: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;



    
`

export const ConEmailTel = styled.div`


    .margemTop{
        margin-top: -10px;
    }

    /* .media-277{
        margin: "auto"
    } */
    @media(max-width: 377px){
        .media-277{
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
        }
    }
    @media(max-width: 642px){
        .flexWOne{
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;

        }
        .margemLeft{

            margin-left: 10;
        }
    }
    @media (max-width: 706px) {
        .margemTop{
        margin-top: 10px;

    }
      .flexW {
        flex-wrap: wrap;

        justify-content: center;
        text-align: center;
      }
  }

   
`


