import React, { useCallback, useEffect, useState } from "react";
import {
  ButtonField,
  CardPefil,
  Container,
  InputField,
  TitleField,
} from "./style";
import { useHistory } from "react-router";
import api from "../../../services/api";
import Toastify from "toastify-js";
import { useGlobal } from "../../../hooks/global";

export const EditarProposta: React.FC = () => {
  const { push, location } = useHistory<any>();
  const { languagePage, setAtualPageGlobal } = useGlobal();

  const { proposal } = location.state || [];
  const [proposalId, setProposalId] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [exactAmount, setExactAmount] = useState<number>(0);
  const [amountEstimate, setAmountEstimate] = useState<number>(0);
  const [hoursEmployed, setHoursEmployed] = useState<string | number>(0);
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    if (!location || !location.state) {
      push("/company/consultoria/minhas-propostas");
    }
  }, []);

  useEffect(() => {
    setCategory(proposal.category);
    setAmountEstimate(Number(proposal.amountEstimate));
    setExactAmount(proposal.exactAmount);
    setHoursEmployed(proposal.hoursEmployed);
    setDescription(proposal.description);
    setProposalId(proposal.id);
  }, []);

  const formatter = new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
  });

  useEffect(() => {
    setAtualPageGlobal("/company/consultoria/editar-proposta");
  }, []);

  const handleUpdateProposal = useCallback(async () => {
    await api
      .put(`/company/update-proposal/${proposalId}`, {
        description: description,
        hoursEmployed: hoursEmployed,
        amountEstimate: amountEstimate,
        category: category,
      })
      .then((res) => {
        Toastify({
          text:
            languagePage === "PT"
              ? "Sua proposta foi alterada com sucesso"
              : "Your proposal has been changed successfully",
          duration: 3000,
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
        }).showToast();
        push("/company/consultoria/minhas-propostas");
      })
      .catch((err) => {
        Toastify({
          text:
            languagePage === "PT"
              ? "Tivemos um problema ao enviar a proposta, por favor tente novamente!"
              : "We had a problem submitting the proposal, please try again!",

          duration: 3000,
          style: {
            background: "linear-gradient(to right, red, #ccc)",
          },
        }).showToast();
      });
  }, [
    proposalId,
    description,
    hoursEmployed,
    amountEstimate,
    category,
    languagePage,
  ]);

  useEffect(() => {
    if (!location || !location.state) {
      push("/company/consultoria/minhas-propostas");
    }
  }, [location, push]);

  return (
    <Container>
      <TitleField>
        <h4>{languagePage === "PT" ? "Editar Proposta" : "Edit Proposal"}</h4>
      </TitleField>

      <CardPefil>
        <InputField>
          <label>Qual área voltada:</label>
          <input
            type="text"
            value={category}
            disabled={exactAmount !== 0 ? true : false}
            onChange={(e) => setCategory(e.target.value)}
          />
        </InputField>
        <InputField>
          <label>Diga o máximo que quer gastar:</label>
          <input
            type="number"
            step={100}
            disabled={exactAmount !== 0 ? true : false}
            value={amountEstimate.toFixed(2)}
            onChange={(e) => {
              if (Number(e.target.value) < 0) setAmountEstimate(0);
              else setAmountEstimate(Number(e.target.value));
            }}
          />
        </InputField>
        <InputField>
          <label>Quantidade de horas quer que seja empregada:</label>
          <input
            type="number"
            disabled={exactAmount !== 0 ? true : false}
            value={hoursEmployed}
            onChange={(e) => setHoursEmployed(e.target.value)}
          />
        </InputField>
        <InputField>
          <label>Descrição:</label>
          <textarea
            value={description}
            placeholder="Descreva seu projeto"
            onChange={(e) => setDescription(e.target.value)}
          />
        </InputField>
        <ButtonField>
          <button onClick={() => handleUpdateProposal()}>
            Salvar alterações
          </button>
        </ButtonField>
      </CardPefil>
    </Container>
  );
};
