import React, { useState, useCallback } from "react";
import Modal from "@material-ui/core/Modal";
import { TiDelete } from "react-icons/ti";
import { CloseButton, Container, ContainerModal, Template } from "./style";
import api from "../../../services/api";

interface ModalAprovarEntrevistaProps {
  open: boolean;
  onClose: Function;
  selectionId: string;
  consultantId: string;
}

export const ModalAprovarEntrevista: React.FC<ModalAprovarEntrevistaProps> = ({
  open,
  onClose,
  selectionId,
  consultantId,
}) => {
  const [approved, setApproved] = useState<string>("");

  const handleInterviewApproved = useCallback(
    async (e: any) => {
      e.preventDefault();

      await api.post(
        `root/approved-selection/interview/${selectionId}/${consultantId}`,
        {
          interview: approved === "true" ? true : false,
        }
      );

      onClose();
    },
    [approved, selectionId, consultantId, onClose]
  );

  return (
    <ContainerModal>
      <Modal style={{ margin: "0" }} open={open} onClose={() => onClose()}>
        <Template>
          <CloseButton>
            <div>
              <TiDelete color="#fff" size={40} onClick={() => onClose()} />
            </div>
          </CloseButton>
          <Container>
            <label>Aprovar candidato na entrevista?</label>
            <select
              value={approved}
              onChange={(e) => setApproved(e.target.value)}
              name="interview"
            >
              <option value="--">--</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleInterviewApproved}
            >
              Finalizar
            </button>
          </Container>
        </Template>
      </Modal>
    </ContainerModal>
  );
};

export default ModalAprovarEntrevista;
