import styled, { css } from "styled-components";

interface AbaAtual {
  isAtual: boolean;
}

export const ContainerAbas = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  // Lembrar
  justify-content: space-around;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #134480;
  margin: 20px;
  //box-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 1);
`;

export const Aba = styled.div<AbaAtual>`
  color: #134480;
  font-family: Gotham-Bold;

  font-size: 20px;
  cursor: pointer;
  ${(props) =>
    props.isAtual &&
    css`
      color: #64666b;
    `}
  .icone_res {
    display: none;
  }
  @media (max-width: 675px) {
    .icone_res {
      display: block;
    }
    .icone_nores {
      display: none;
    }
  }

  .nav {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 150px;
      z-index: 2;
      font-family: "Gotham-Medium", sans-serif;
      font-size: 20px;
      -webkit-box-shadow: 5px 5px 50px 15px rgba(165, 165, 165, 0.5);
      box-shadow: 5px 5px 50px 15px rgba(165, 165, 165, 0.5);
    }
    ul li {
      position: relative;
    }
    li ul {
      position: absolute;

      top: 150px;
      display: none;
    }
    ul li a {
      display: block;
      text-decoration: none;
      color: #6086b5;
      background: #fff;
      padding: 5px;
      //border: 1px solid #ccc;
    }

    li:hover ul {
      display: block;
    }
    ul li a:hover {
      background: #fff;
      color: #134480;
    }
    .mostra:hover {
      a .nav-lateral {
        display: block;
        margin-left: -155px;
        margin-top: -120px;
      }
      a .nav-lateral li a:hover {
        background: #fff;
        color: #134480;
      }
    }

    li a .nav-lateral {
      display: none;
    }
  }
`;
