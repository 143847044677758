import   React                    from 'react';
import   Modal                    from '@material-ui/core/Modal';
import { MdAssignment } from 'react-icons/md';
import { ErrorProps, Verify }     from '../../../services/schemaServices';
import { useCallback, 
         useRef,
         useState 
       } from 'react';
import { Container, 
         Header, 
         IconDelete, 
         ButtonEnd, 
         ContainerHeader, 
         ContainerTitle, 
         Formulario, 
         ContainerItemForm, 
         ContainerBottom 
       } from './style';
import { tagInterface } from '../../../interfaces/tagInterface';
import InputLabel, { InputRefProps } from '../../InputLabel';

interface ModalEditarQuestionarioProps{
    open:boolean;
    onClose:Function;
    onSave:Function;
    data:tagInterface;
}

export const ModalEditarQuestionario:React.FC<ModalEditarQuestionarioProps> = ({open,onClose,onSave,data}) => {
    const   inputNameRef        = useRef<InputRefProps>(null)
    const [ errors, setErrors ] = useState<ErrorProps[]>([] as ErrorProps[])

    const Save = useCallback(() => {
        const name = inputNameRef.current?.getInputValue()
        const id   = data.id
        const errors = Verify([{
            name: 'name',
            value: name,
            required: { message: 'Campo obrigatório!', value: true }
        }
        ]) 
        setErrors(errors)
        if(errors.length === 0)onSave({ id,name })
    }, [inputNameRef])

    return(
            <Modal open={open} onClose={()=>onClose()} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Container>

                    <ContainerHeader>
                        <Header>
                            <ContainerTitle>
                                <MdAssignment style={{marginRight:"0.625rem"}} color="#101A36"/>
                                Editar Questionário Específico
                            </ContainerTitle>
                            <IconDelete onClick={()=>onClose()} color="#C4C4C4"/>
                        </Header>
                    </ContainerHeader>

                    <Formulario>
                        <ContainerItemForm>
                            <InputLabel
                                errors={errors}
                                label="ID"
                                attr="id"
                                disabled
                                defaultValue={data.id}
                                id='InputIdCategory'
                                inputProps={{style:{fontFamily:"math",fontWeight:"bold"}}}
                            />
                        </ContainerItemForm>
                        <ContainerItemForm>
                            <InputLabel
                                errors={errors}
                                label="Nome do Questionário"
                                attr="name"
                                defaultValue={data.name}
                                id='InputNameCategory'
                                ref={inputNameRef}
                                inputProps={{style:{fontFamily:"math",fontWeight:"bold"}}}

                            />
                        </ContainerItemForm>
                    </Formulario>
                    
                    <ContainerBottom>
                        <ButtonEnd onClick={()=>onClose()} style={{color:"#252323"}}> Cancelar </ButtonEnd>
                        <ButtonEnd onClick={Save}          style={{color:"#101A36"}}> Salvar   </ButtonEnd>
                    </ContainerBottom>

                </Container>
            </Modal>
    )
}

export default ModalEditarQuestionario;