const findPosByKey = (key, values) => {
    for (let i = 0; i < values.length; i++) {
        if (values[i] === key) return i;
    }
    return -1;
}

const GenerateDate = (date) => {
    const arrayDate = date.split('/')
    let newDate = ''
    for (let i = (arrayDate.length - 1); i >= 0; i--) {
        if (i !== 0) newDate += arrayDate[i] + '-'
        else newDate += arrayDate[i]
    }
    return `${newDate} 00:00:00 -03:00`
}

module.exports = { findPosByKey, GenerateDate }