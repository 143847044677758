import React, { useCallback, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { ErrorProps } from "../../../services/schemaServices";
import InputLabel from "../../InputLabel";
import {
  Container,
  Header,
  IconDelete,
  ButtonEnd,
  ContainerHeader,
  Formulario,
  ContainerItemForm,
  ContainerBottom,
} from "./style";
import { UserProps } from "../../../interfaces/userInterface";
import { useTransition } from "@react-spring/core";
import { MaskCNPJ, MaskPhone } from "./../../../services/maskServices";
import SelectFly from "./../../SelectFly";
import DataPicker from "../../DataPicker";
import {
  IsNumber,
  DeleteContentBackward,
} from "./../../../services/inputServices";
interface ModalEmpresaProps {
  open: boolean;
  data: UserProps;
  onClose: Function;
  onSave: Function;
}

const optionsPlan = ["Nenhum", "Personalizado"];

export const ModalEmpresa: React.FC<ModalEmpresaProps> = ({
  open,
  onClose,
  onSave,
  data,
}) => {
  const numberOfPlan = useCallback((planNameValue) => {
    for (let i = 0; i < optionsPlan.length; i++)
      if (optionsPlan[i] === planNameValue) return i;
    return 0;
  }, []);

  const [errors, setErrors] = useState<ErrorProps[]>([] as ErrorProps[]);
  const [abaValue, setAbaValue] = useState(0);
  const [posNumOptionPlan, setPosNumOptionPlan] = useState(
    numberOfPlan(data.planName)
  );
  const [datePaidPicker, setDatePaidPicker] = useState<Date>(() => {
    if (!!data.expiresIn) {
      let dataCorreta = new Date(data.expiresIn);
      dataCorreta.setDate(dataCorreta.getDate() - 10);
      return dataCorreta;
    } else return new Date();
  });
  const [numOfVanacy, setNumOfVanacy] = useState(
    !!data.vacancyLimit ? `${data.vacancyLimit}` : "0"
  );
  const [numOfCandidates, setNumOfCandidates] = useState(
    !!data.candidateLimit ? `${data.candidateLimit}` : "0"
  );

  const transitions = useTransition(abaValue, {
    keys: null,
    from: { opacity: 0 },
    enter: { opacity: 1, config: { duration: 400 } },
    leave: { opacity: 0, config: { duration: 0 } },
  });

  const OnChangeInputNumOfVanacy = useCallback(
    (event) => {
      const char = event.nativeEvent.data;
      const eventType = event.nativeEvent.inputType;
      if (eventType === "insertText" && IsNumber(char)) {
        const newNumOfCandidates = parseInt(`${numOfVanacy + char}`);
        setNumOfVanacy(`${newNumOfCandidates}`);
      } else if (eventType === "deleteContentBackward" && numOfVanacy) {
        const newNumOfVanacy =
          DeleteContentBackward(numOfVanacy) === ""
            ? "0"
            : DeleteContentBackward(numOfVanacy);
        setNumOfVanacy(newNumOfVanacy);
      }
    },
    [numOfVanacy]
  );

  const OnChangeInputNumOfCandidates = useCallback(
    (event) => {
      const char = event.nativeEvent.data;
      const eventType = event.nativeEvent.inputType;
      if (eventType === "insertText" && IsNumber(char)) {
        const newNumOfCandidates = parseInt(`${numOfCandidates + char}`);
        setNumOfCandidates(`${newNumOfCandidates}`);
      } else if (eventType === "deleteContentBackward" && numOfCandidates) {
        const newNumOfCandidates =
          DeleteContentBackward(numOfCandidates) === ""
            ? "0"
            : DeleteContentBackward(numOfCandidates);
        setNumOfCandidates(newNumOfCandidates);
      }
    },
    [numOfCandidates]
  );

  const Save = useCallback(() => {
    let dataCorreta = datePaidPicker;
    dataCorreta.setDate(dataCorreta.getDate() + 10);

    data.candidateLimit =
      posNumOptionPlan !== 0 ? parseInt(numOfCandidates) : 0;
    data.vacancyLimit = posNumOptionPlan !== 0 ? parseInt(numOfVanacy) : 0;
    data.expiresIn = `${dataCorreta}`;
    data.planName = optionsPlan[posNumOptionPlan];
    onSave(data);
  }, [posNumOptionPlan, numOfCandidates, numOfVanacy, datePaidPicker]);

  const changePlan = useCallback((newPosNumOptionPlan) => {
    setPosNumOptionPlan(newPosNumOptionPlan);
    if (optionsPlan[newPosNumOptionPlan] === "Básico") {
      setNumOfVanacy("1");
      setNumOfCandidates("15");
    } else if (optionsPlan[newPosNumOptionPlan] === "Intermediário") {
      setNumOfVanacy("2");
      setNumOfCandidates("50");
    } else if (optionsPlan[newPosNumOptionPlan] === "Avançado") {
      setNumOfVanacy("3");
      setNumOfCandidates("85");
    } else {
      setNumOfVanacy("0");
      setNumOfCandidates("0");
    }

    if (optionsPlan[newPosNumOptionPlan] === "Básico") {
      setNumOfVanacy("1");
      setNumOfCandidates("15");
    } else if (optionsPlan[newPosNumOptionPlan] === "Intermediário") {
      setNumOfVanacy("2");
      setNumOfCandidates("50");
    } else if (optionsPlan[newPosNumOptionPlan] === "Avançado") {
      setNumOfVanacy("3");
      setNumOfCandidates("85");
    } else {
      setNumOfVanacy("0");
      setNumOfCandidates("0");
    }

    const dataAtual = new Date();
    dataAtual.setMonth(dataAtual.getMonth() + 1);
    setDatePaidPicker(dataAtual);
  }, []);

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <ContainerHeader>
          <Header>
            Empresa
            <IconDelete onClick={() => onClose()} color="#C4C4C4" />
          </Header>
        </ContainerHeader>

        <Formulario>
          <ContainerItemForm>
            <InputLabel
              errors={errors}
              label="Nome"
              attr="name"
              value={data.name}
              disabled={true}
              id="InputNameCompany"
              containerStyle={{ width: "100%" }}
              inputProps={{
                style: {
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: 18,
                },
              }}
            />
          </ContainerItemForm>
          <ContainerItemForm>
            <InputLabel
              errors={errors}
              label="Email"
              attr="emeil"
              value={data.email}
              disabled={true}
              id="InputEmailCompany"
              containerStyle={{ width: "100%" }}
              inputProps={{
                style: {
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: 18,
                },
              }}
            />
          </ContainerItemForm>
          <ContainerItemForm>
            <InputLabel
              errors={errors}
              label="CNPJ"
              attr="cnpj"
              value={MaskCNPJ(data.cnpj)}
              disabled={true}
              id="InputCNPJCompany"
              containerStyle={{ width: "100%", marginRight: 5 }}
              inputProps={{
                style: {
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: 18,
                  color: "#000 !important",
                },
              }}
            />
            <InputLabel
              errors={errors}
              label="Telefone"
              attr="phone"
              value={MaskPhone(data.phone)}
              disabled={true}
              id="InputPhoneCompany"
              containerStyle={{ width: "100%", marginLeft: 5 }}
              inputProps={{
                style: {
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: 18,
                  color: "#000 !important",
                },
              }}
            />
          </ContainerItemForm>
          <ContainerItemForm>
            <InputLabel
              errors={errors}
              label="Nº de Colaboradores"
              attr="employeesRange"
              value={data.employeesRange}
              disabled={true}
              id="InputEmployeesRangeCompany"
              containerStyle={{ width: "100%", marginRight: 5 }}
              inputProps={{
                style: {
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: 18,
                  color: "#000 !important",
                },
              }}
            />
            <InputLabel
              errors={errors}
              label="Porte"
              attr="companySize"
              value={data.companySize}
              disabled={true}
              id="InputCompanySizeCompany"
              containerStyle={{ width: "100%", marginLeft: 5 }}
              inputProps={{
                style: {
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: 18,
                  color: "#000 !important",
                },
              }}
            />
          </ContainerItemForm>
          <ContainerItemForm>
            <InputLabel
              errors={errors}
              label="Cidade"
              attr="city"
              value={data.city}
              disabled={true}
              id="InputCityCompany"
              containerStyle={{ width: "100%", marginRight: 5 }}
              inputProps={{
                style: {
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: 18,
                  color: "#000 !important",
                },
              }}
            />
            <InputLabel
              errors={errors}
              label="Estado"
              attr="state"
              value={data.state}
              disabled={true}
              id="InputStateCompany"
              containerStyle={{ width: "100%", marginLeft: 5, marginRight: 5 }}
              inputProps={{
                style: {
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: 18,
                  color: "#000 !important",
                },
              }}
            />
            <InputLabel
              errors={errors}
              label="País"
              attr="country"
              value={data.country}
              disabled={true}
              id="InputCountryCompany"
              containerStyle={{ width: "100%", marginLeft: 5 }}
              inputProps={{
                style: {
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: 18,
                  color: "#000 !important",
                },
              }}
            />
          </ContainerItemForm>
          <ContainerItemForm>
            <SelectFly
              label="plans"
              styleContainer={{
                minHeight: "100%",
                width: "100%",
                boxShadow: "none",
                fontFamily: "Poppins",
                color: "#000",
              }}
              styleSelect={{
                background: "#FFF",
                minHeight: "100%",
                boxShadow: "none",
                border: "2px solid #C4C4C4",
                height: "calc(3.4375rem - 8px)",
              }}
              onChangeOption={changePlan}
              posOption={posNumOptionPlan}
              labelIndicator="Plano"
              options={optionsPlan}
              defaultOptions={data.planName}
              className="Select-Fly-plans"
            />
          </ContainerItemForm>
          {posNumOptionPlan !== 0 && (
            <ContainerItemForm style={{ marginTop: 4 }}>
              <InputLabel
                errors={errors}
                label="Limite de Vagas"
                attr="vanacyLimit"
                onChange={OnChangeInputNumOfVanacy}
                value={numOfVanacy}
                id="InputVanacyLimitCompany"
                containerStyle={{ width: "100%", marginRight: 5 }}
                inputProps={{
                  style: {
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "#000 !important",
                  },
                }}
              />
              <InputLabel
                errors={errors}
                label="Limite de Candidatos"
                attr="candidatesLimit"
                onChange={OnChangeInputNumOfCandidates}
                value={numOfCandidates}
                id="InputCandidatesLimitCompany"
                containerStyle={{
                  width: "100%",
                  marginLeft: 5,
                  marginRight: 5,
                }}
                inputProps={{
                  style: {
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "#000 !important",
                  },
                }}
              />
              <DataPicker
                initialDate={datePaidPicker}
                value={datePaidPicker}
                onChange={(date: Date) => setDatePaidPicker(date)}
                inputStyle={{
                  color: "#000",
                  fontFamily: "math",
                  fontWeight: "bold",
                }}
                format={"dd 'de' MMMM 'de' yyyy"}
                inputLabelStyle={{
                  background: "#FFF",
                  color: "#757575",
                  fontFamily: "math",
                  fontSize: "0.75rem",
                }}
                views={["date"]}
                KeyboardButtonProps={{ color: "#262626" }}
                id={"DataPickerModalClient"}
                label={"Expira em"}
                containerProps={{
                  minHeight: "3.4375rem",
                  maxHeight: "3.4375rem",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 5,
                }}
              />
            </ContainerItemForm>
          )}
        </Formulario>

        <ContainerBottom>
          <ButtonEnd onClick={() => onClose()} style={{ color: "#252323" }}>
            {" "}
            Cancelar{" "}
          </ButtonEnd>
          <ButtonEnd
            onClick={() => Save()}
            style={{ color: "#101A36", cursor: "pointer" }}
          >
            {" "}
            Salvar
          </ButtonEnd>
        </ContainerBottom>
      </Container>
    </Modal>
  );
};

export default ModalEmpresa;
