import styled, { keyframes } from "styled-components";

const ResultsContainer = styled.section`
	grid-area: results;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(6, 1fr);
	gap: 1em;
	width: 100%;
	height: auto;
	padding: 2em;
	background-color: #000124;
	text-align: center;
	@media screen and (min-width: 426px) and (max-width: 768px) {
		padding: 2em 8em;
	}
	@media screen and (min-width: 769px) {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: 1fr 1fr;
		gap: 3em;
	}
	@media screen and (min-width: 321px) and (max-width: 375px) {
		width: 101%;
	}
`;
const TextTitle = styled.h1`
	font-family: Inter-ExtraBold;
	font-size: 2em;
	@media screen and (min-width: 769px) {
		font-size: 2.5em;
	}
`;

const NumberTitle = styled.h1`
	font-size: 2em;
	font-family: Inter-ExtraBold;
	@media screen and (min-width: 769px) {
		font-size: 2.5em;
	}
`;

const TextContainer = styled.div`
	font-family: Inter-Bold;
`;

const Text = styled.p`
	font-size: 0.9em;
	@media screen and (min-width: 769px) {
		font-size: 1.8em;
	}
`;

const TitleContainer = styled.div`
	margin-bottom: 1em;
`;

const BlueCardContainer = styled.div`
	border-radius: 16px;
	padding: 1em;
	width: 100%;
	background-color: #00008B;
	${TitleContainer} {
		color: #fff;
	}
	${Text} {
		color: #fff;
	}
	align-items: center;
	justify-content: center;
	@media screen and (min-width: 769px) {
		&:hover {
			transform: translateY(-10px);
		}
	}
`;

const WhiteCardContainer = styled.div`
	border-radius: 16px;
	padding: 1em;
	width: 100%;
	background-color: #ffffff;
	${TitleContainer} {
		color: #000;
	}
	${Text} {
		color: #000;
	}
	align-items: center;
	justify-content: center;
	@media screen and (min-width: 769px) {
		&:hover {
			transform: translateY(-10px);
		}
	}
`;

const LogOnButton = styled.button`
	width: 200px;
	height: 50px;
	font-family: Inter-Medium;
	color: #fff;
	border: none;
	background-color: #e58417;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

	&:hover {
    	opacity: 0.9;
  	}

	&:active {
		opacity: 0.8;
	}

`;

const Link = styled.a`
	text-decoration: none;
	color: white;
	&:hover {
    	color: white;
  	}
`
const ButtonClick: React.CSSProperties = {
	backgroundColor: "#ff8c00",
	width: "150px"
}

export {
	ResultsContainer,
	Text,
	TitleContainer,
	TextContainer,
	TextTitle,
	NumberTitle,
	BlueCardContainer,
	WhiteCardContainer,
	LogOnButton,
	Link,
	ButtonClick
};
