import styled from "styled-components";

export const DisplayFlex = styled.div`
  display: flex;
`
export const AbatarContainer = styled(DisplayFlex)`

  flex-direction: row;
  color: #FFF;
  align-items: center;
  div{
    display: flex;
    background: #FFF;
    padding: 10px;
    border-radius: 5px;
    margin-right: 10px;
  }
`
export const Container = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: column;
margin: 20;
font-family: Gotham-Medium;
color: #64666b;
font-Size: 26;

.pointer-flex-fff-10-center-row-5{
  cursor: pointer;
  display: flex;
  color: #FFF;
  padding: 10px;
  justify-content: center;
  flex-direction: row;
  border-radius: 5px; 
}


`
export const Setas = styled.div`
  display: flex;
  padding: 5px;
  border-radius: 50%;
  color: #FFF;
  margin:10px;
  justify-content: center;
  align-items: center;
  @media (max-width: 433px) {
    display: none;
  }
`;

export const BoxBackTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: calc(10vh - 30px);
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #6b6b6b;
  font-size: 3.5vh;

  .voltar{
    font-size: 2.15vh;
  color: #6b6b6b;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  min-width: 70px;
  border-radius: 50px;
  border: 1px solid #6b6b6b;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 8px;
  position: absolute;
  left: 15px;
      top: 20px;
  transform: translateY(-50%);

  @media (max-width: 768px) {
    font-size: 1.6vh;
    max-width: 120px;
    padding: 6px;
  }

  @media (max-width: 480px) {
    font-size: 1.3vh;
    max-width: 100px;
    padding: 4px;
  }
  }
  .nome{
    
    width: 70%; 
    white-space: nowrap;
    overflow: hidden;
    text-verflow: ellipsis;
    margin: 5px 
  }

  @media (max-width: 640px) {
    margin-top: 10px;
    font-size: 1.6vh;

    div {
      top: -30px;
    }
  }
`;

export const SubContainer = styled.div`
    display: flex;

    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    font-size: 16px;
    align-items: center;

    margin-top: 20px;

    .conteudo{
      display: flex,
      margin-bottom: 20px,
      justify-content: space-between,
      align-items: center,
    }
    .avatar-nome{

      display: flex;
      flex-wrap: wrap;
      width: 100%;
      min-width: 200px;
      max-width: 1100px;
      justify-content: center;
  
    }
    .candidados{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      width: 100%;
      min-width: 200px;
      max-width: 300px;
      margin: 10px;
      background: #134480;
      padding: 20px;
      box-shadow: 0.125rem 0.125rem 0.5rem rgba(0,0,0,1);
      border-radius: 5px;
    }


`
export const SetasTwo = styled.div`
  display: none;
  width: 50px;
  height: 50px;
  padding: 20px;
  margin: 10px;
  border-radius:50%;
  color: #134480;
  padding: 5px;
  justify-content: center;
  align-items: center;
 
  @media (max-width: 433px) {
    display: flex;
  }
`;

export const Tabela = styled.div`
  scrollbar-width: thin;
  scrollbar-color: #00213d #061522;
  padding: 10px;
  width: 60vw;

  ::-webkit-scrollbar {
    width: 12px;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track {
    background: #061522;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #00213d;
    border-radius: 20px;
    border: 3px solid #061522;
  }

  justify-content: center;

  table {
    margin: auto;
  }
  .th{
    padding: 16px;
    text-align: center;
    align-items: center;
    justify-items: center;
    font-family: Gotham-Medium;
    font-size: 22px;
  }

  .th-dinamico{
    padding: 16px;
    text-align: center;
    font-family: Gotham-Light;
    font-size: 18px;
  }
`;
