import styled, { css } from "styled-components";
import { TiDelete } from 'react-icons/ti';
interface tagProps {
    isRespondida: boolean;
}
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFF;
    width: 50rem;
    position: relative;
    border-radius: 0.3125rem;
    outline: 0;
    *{
        font-family:'math';
        font-weight: bold;
    }
`

export const ContainerHeader = styled.div`
    display:flex;
    width:100%;
    margin-bottom:0.625rem;
`

export const Header = styled.div`
    display:flex;
    justify-content: center;
    position:relative;
    align-items: center;
    padding:0.625rem;
    width: 100%;
    font-size: 1.5rem;
    border-bottom: 0.0625rem solid #DDDDDD;
    margin-bottom:0.625rem;
    color:#101A36;
`

export const ContainerTitle = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
`

export const IconDelete = styled(TiDelete)`
    cursor: pointer;
    position:absolute;
    right: 15px;
`

export const ButtonEnd = styled.div`
    margin:0.625rem;
    margin-top:0rem;
    color:#252323;
    cursor: pointer;
`

export const Formulario = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    position:relative;
`

export const ContainerItemForm = styled.div`
    padding-left:1.25rem;
    padding-right:1.25rem;
    margin-bottom:0.625rem;
    display: flex;
    flex-direction: row;
`

export const ContainerBottom = styled.div`
    display:flex;
    width:100%;
    height:100%;
    flex-direction:row;
    justify-content:flex-end;
    align-items:flex-end;
`

export const ContainerTag = styled.div<tagProps>`
    display: flex;
    min-width: 200px;
    width: 50%;
    margin: 0px;
    flex-direction: column;
    background: #081118;
    padding: 20px;
    border-radius: 5px;
    ${props => !props.isRespondida && css`
        background: #424242;
        color:#FFF;
    `}
`

export const TitleTag = styled.div<tagProps>`
    display: flex;
    flex-direction: row;
    color: #f15115;
    align-items: center;
    ${props => !props.isRespondida && css`
        color:#FFF;
    `}
`

export const ContainerIconTag = styled.div<tagProps>`
    display: flex;
    background: #FFF;
    padding: 10px;
    border-radius: 5px;
    margin-right: 10px;
    ${props => !props.isRespondida && css`
        color:#000;
    `}
`

export const Button = styled.div<tagProps>`
    display: flex;
    cursor: pointer;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    padding: 10px;
    flex-direction: row;
    border-radius: 5px;
    background: #f15115;
    ${props => !props.isRespondida && css`
        background:#000;
        cursor: not-allowed;
    `}
`