import React from "react";
import NavBar from "../../../components/HomeNavBar"
import { useGlobal } from "../../../hooks/global";
import {
    GeneralContainer,
    ContainerSection,
    ContainerArticle,
    BookImage,
    ButtonStyle,
    Title,
    Subtitle,
    Paragraph,
    LogoContainer,
    FlashPlayLogo,
    HeaderBar,
    ContainerBody,
    ContainerBodyProduct,
    Container,
    Message,
    ContainerImages,
    ContainerImage,
    Image,
    ContainerCard,
    Category,
    ContainerTitle
} from "./styles"
import { useHistory } from "react-router-dom";
import { Typography, Button } from '@mui/material';

const BookCombo: React.FC = () => {
    const { setLanguagePage, languagePage } = useGlobal();
    const { push } = useHistory();

    return (
        <>
            <GeneralContainer>
                <HeaderBar id="headerbar">
                    {window.matchMedia("(min-width:769px)").matches &&
                        <LogoContainer>
                            <FlashPlayLogo
                                src='https://res.cloudinary.com/dkqcb920e/image/upload/v1655915573/logo3_e6ucbq_pwaatc.webp'
                                alt='EcoRecitec|FlashPlayHR'
                                onClick={() => push("/")}
                            />
                        </LogoContainer>
                    }
                    <NavBar />
                </HeaderBar>

                <ContainerCard>
                    <ContainerBody style={{ marginTop: "7%" }}>
                        <ContainerBodyProduct style={ContainerSection}>
                            <img src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1702313848/Assets-FlashPlayHr/jm0zhdkgf60ncfcrrvtj.png" alt="lixo-vivo-ebook" style={BookImage} />

                            <Container style={ContainerArticle}>
                                <Typography style={Title}>
                                    {languagePage === "PT"
                                        ? "A Revolução do Lixo Vivo"
                                        : "The Living Waste Revolution"}
                                </Typography>
                                <Typography style={Subtitle}>{languagePage === "PT"
                                    ? "Conceitos de Economia Circular para crianças"
                                    : "Circular Economy Concepts for Children"}</Typography>

                                <Typography style={Paragraph}>
                                    {languagePage === "PT"
                                        ? "E-book infantil A Revolução do Lixo Vivo, conceitos de Economia Circular para crianças em formato ePub. Uma obra da Startup EcoRecitec que tem o propósito de dar suporte à transição de linear para circular, neste sentido também, com consultores da área pedagógica e de cultura construímos um projeto pedagógico para criar a mentalidade direcionada à Economia Circular."
                                        : "Children's e-book The Living Waste Revolution, Circular Economy concepts for children in ePub format. A work by Startup EcoRecitec that aims to support the transition from linear to circular, in this sense too, with consultants from the pedagogical and cultural areas we built a pedagogical project to create a mindset aimed at the Circular Economy."}
                                </Typography>

                                <Typography style={Message}>
                                    {languagePage === "PT"
                                        ? "Aproveite a Oferta e Boas festas!"
                                        : "Take advantage of the Offer and Happy Holidays!"}
                                </Typography>
                            </Container>
                        </ContainerBodyProduct>


                        <Container>
                            <Typography style={Subtitle}>
                                R$ 27,00
                            </Typography>

                            <Button style={ButtonStyle} target="_blank" href="https://pay.hotmart.com/E88523041P">{languagePage === "PT"
                                ? "Comprar Produto"
                                : "Buy Product"}</Button>
                        </Container>
                    </ContainerBody >

                    <ContainerTitle>
                        <Typography style={Title}>
                        {languagePage === "PT"
                                ? "Outros Produtos"
                                : "Other products"}
                        </Typography>
                    </ContainerTitle>

                    <ContainerImages>
                        <ContainerImage >
                            <img src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1702313849/Assets-FlashPlayHr/lc4pgdnmzftgs0ynasjn.png" alt="mindset-ebook" style={Image} />
                            <Container style={ContainerArticle}>
                                <Typography style={Subtitle}>{languagePage === "PT"
                                    ? "Mindset"
                                    : "Mindset"}</Typography>
                                <Typography style={Category}>
                                    E-Book
                                </Typography>
                                <Button style={ButtonStyle} onClick={() => push("/mindset")}>{languagePage === "PT"
                                ? "Detalhes do Produto"
                                : "Product details"}</Button>
                            </Container>
                        </ContainerImage>

                        <ContainerImage>
                            <img src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1702313848/Assets-FlashPlayHr/rd9mt7srxz7zbdnjcigd.png" alt="saberes-circulares-ebook" style={Image} />
                            <Container style={ContainerArticle}>
                                <Typography style={Subtitle}>{languagePage === "PT"
                                    ? "Saberes Circulares"
                                    : "Circular Knowledge"}</Typography>
                                <Typography style={Category}>
                                    E-Book
                                </Typography>
                                <Button style={ButtonStyle} onClick={() => push("/saberesCirculares")}>{languagePage === "PT"
                                ? "Detalhes do Produto"
                                : "Product details"}</Button>
                            </Container>
                        </ContainerImage>

                        {/* <ContainerImage>
                            <img src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1702313848/Assets-FlashPlayHr/ymnfrhlkenmgipirgt6v.png" alt="combo-de-natal" style={Image} />
                            <Container style={ContainerArticle}>
                                <Typography style={Subtitle}>{languagePage === "PT"
                                    ? "Combo de Natal"
                                    : "Christmas combo"}</Typography>
                                <Typography style={Category}>
                                    E-Book
                                </Typography>
                                <Button style={ButtonStyle} onClick={() => push("/bookCombo")}>{languagePage === "PT"
                                ? "Detalhes do Produto"
                                : "Product details"}</Button>
                            </Container>
                        </ContainerImage> */}
                    </ContainerImages>
                </ContainerCard>
            </GeneralContainer>
        </>
    );
}

export default BookCombo;