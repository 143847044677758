import styled from "styled-components";

export const Span  = styled.span`
    display:flex;
    position:absolute;
    left:50%;
    color:#FFF;
    transform:translatex(-50%);
    bottom:calc(100% + 8px);
    background:#32416B;
    padding:0.625rem;
    font-size: 0.75rem;
    border-radius:0.3125rem;
    ::before{
        border-style:solid;
        border-color: #32416B transparent;
        border-width: 0.375rem 0.375rem 0 0.375rem;
        bottom:-0.3125rem;
        content: "";
        left:50%;
        transform:translatex(-50%);
        position: absolute;
    }
    box-shadow:0.125rem 0.125rem 0.5rem rgba(0,0,0,0.7);
    z-index: 999;
`

export const ContainerOptions = styled.div`
    display:flex;
    overflow:visible;
    position:relative;
    min-width:100%;
    min-height:100%;
    justify-content:center;
    align-items:center; 
`