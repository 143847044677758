import { CircularProgress } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { FaUser } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import PaginationCustom, {
  PaginationRefProps,
} from "../../../components/PaginationCustom";
import { useGlobal } from "../../../hooks/global";
import { UserProps } from "../../../interfaces/userInterface";
import api from "../../../services/api";

import { Setas, SetasTwo } from "./style";

import Modal from "react-modal";
import { Form, FormGroup, Input, Label, Button } from "reactstrap";
import axios from "axios";
import DecisionModal from "../../../components/ConfirmModal/decisionModal";

Modal.setAppElement("#root");

export const Consultores: React.FC = () => {
  const [values, setValues] = useState({ name: "", description: "", id: "" });
  const { push } = useHistory();
  const [loadingConsultant, setLoadingConsultant] = useState<boolean>(true);
  const [atualPage, setAtualPage] = useState(1);
  const { setAtualPageGlobal, languagePage } = useGlobal();
  const paginationRef = useRef<PaginationRefProps>(null);
  const [openModal, setOpenModal] = useState(false);
  const AnchorsRef = useRef<HTMLDivElement[]>([] as HTMLDivElement[]);
  const [consultant, setConsultant] = useState<UserProps>({} as UserProps);
  const [selections, setSelections] = useState<UserProps[]>([] as UserProps[]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [hover, setHover] = useState<number>(-1);
  const [modalOpen, setModalOpen] = useState(false);
  const [itemId, setItemId] = useState(null);
  
  const changePage = useCallback(
    (type) => {
      if (type === "proxima") {
        if (atualPage < numberOfPages) setAtualPage(atualPage + 1);
      } else if (atualPage !== 1) setAtualPage(atualPage - 1);
    },
    [atualPage, numberOfPages]
  );

  const Click = useCallback((data) => {
    setConsultant(data);
    setOpenModal(true);
  }, []);

  const SearchSelections = useCallback(async () => {
    setLoadingConsultant(true);
    setTimeout(async () => {
      const resu = await api.get(
        `/root/consultants?limit=3&&page=${atualPage}`
      );
      setSelections(resu.data.consultants);
      setNumberOfPages(Math.ceil(resu.data.count / 3));
      setLoadingConsultant(false);
    }, 200);
  }, [atualPage]);

  useEffect(() => {
    setAtualPageGlobal("/root/consultores");
  }, []);

  useEffect(() => {
    SearchSelections();
  }, [atualPage]);

  const toggleModal = () => setModalOpen(!modalOpen);
  
  const removeSelectionConsultants = useCallback(async (selectionId) => {
    setLoadingConsultant(true);
    await api.delete(`/root/consultants/${selectionId}`);
    setTimeout(async () => {
      await SearchSelections();
    }, 200);
  }, []);

  const handleAction = (id) => {
    setItemId(id);
    toggleModal();
  };

  const confirmDelete = (itemId) => {
    removeSelectionConsultants(itemId);
    toggleModal();
  };
  const changeValues = (value: any) => {
    setValues((prevValue) => ({
      ...prevValue,
      [value.target.name]: value.target.value,
    }));
  };

  const editSelection = async () => {
    await api
      .put(`/root/selection/${values.id}`, {
        name: values.name,
        description: values.description,
      })
      .then((response) => {
        setOpenModal(false);
      })
      .finally(() => window.location.reload());
    //${selections.map(selection.id)}
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  function handleOpenModal(name: string, description: string, id: string) {
    setValues({ name, description, id });
    setIsOpen(true);
  }

  function handleCloseModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      width: "80%",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        margin: "auto",
        flexDirection: "column",
        marginBottom: 50,
        alignItems: "center",
        fontFamily: "Gotham-Bold",
        fontSize: 22,
      }}
    >
      {(selections.length > 0 || loadingConsultant) && (
        <div>
          {" "}
          <div
            style={{
              display: "flex",
              // background:"red",
              marginBottom: 20,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Setas
              onClick={() => changePage("anterior")}
              style={{
                padding: 5,
                background: atualPage === 1 ? "#C4C4C4" : "#f27823",
                borderRadius: "50%",
                color: "#134480",
                justifyContent: "center",
                alignItems: "center",
                cursor: atualPage === 1 ? "default" : "pointer",
              }}
            >
              <FiChevronLeft />
            </Setas>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "95%",
                minHeight: 310,
                justifyContent: "center",
              }}
            >
              {!loadingConsultant &&
                selections.map((selection) => {
                  return (
                    <div style={{ margin: 10 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                          flexDirection: "column",
                          margin: "auto",
                          background: "#fff",
                          padding: 20,
                          borderRadius: 10,
                          width: "80%",
                          minWidth: "150px",
                          maxWidth: "400px",
                          height: 350,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            hyphens: "auto",
                            color: "#000000",
                            alignItems: "center",
                            marginBottom: 20,
                            // background: "red",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              maxHeight: 50,
                              maxWidth: 50,
                              minHeight: 50,
                              minWidth: 50,
                              marginRight: 10,
                              padding: 12,
                              background: "#727272",

                              borderRadius: 5,
                              overflow: "hidden",
                            }}
                          >
                            <FaUser size={50} />
                          </div>
                          <div
                            style={{
                              whiteSpace: "normal",
                              wordWrap: "normal",
                              textOverflow: "ellipsis",
                              fontSize: 13,
                              width: 250,
                            }}
                          >
                            {selection.name}
                          </div>

                          {/* <IconDelete onClick={() => onClose()} color="#C4C4C4" style={{ float: "right", fontSize: 30, }} /> */}
                        </div>

                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            onClick={() =>
                              push(`/root/solicitacoes/${selection.id}`)
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: 13,
                              background: "#2F80ED",
                              padding: 10,
                              borderRadius: 10,
                              marginBottom: 10,
                              color: "#fff",
                              cursor: "pointer",
                              textAlign: "center",
                            }}
                          >
                            {languagePage === "PT"
                              ? "Consultores a serem aprovados"
                              : "Consultants to be approved"}
                          </div>
                          <div
                            onClick={() =>
                              push(`/root/consultores/${selection.id}`)
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: 13,
                              background: "#2360B2",
                              padding: 10,
                              borderRadius: 10,
                              marginBottom: 10,
                              color: "#fff",
                              cursor: "pointer",
                              textAlign: "center",
                            }}
                          >
                            {languagePage === "PT"
                              ? "Consultores Aprovados"
                              : "Approved Consultants"}
                          </div>
                          <div
                            onClick={() =>
                              handleOpenModal(
                                selection.name,
                                selection.description,
                                selection.id
                              )
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: 13,
                              background: "#083f86",
                              padding: 10,
                              borderRadius: 10,
                              color: "#fff",
                              cursor: "pointer",
                            }}
                          >
                            {languagePage === "PT" ? "Editar" : "Edit"}
                          </div>
                          <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={handleCloseModal}
                            style={customStyles}
                          >
                            <Form inline>
                              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                                <Label className="me-sm-2" for="exampleName">
                                  {languagePage === "PT"
                                    ? "Título da Seleção"
                                    : "Selection Title"}
                                </Label>
                                <Input
                                  id="name"
                                  name="name"
                                  placeholder=""
                                  value={values.name}
                                  onChange={changeValues}
                                />
                              </FormGroup>
                              <FormGroup
                                className="mb-2 me-sm-2 mb-sm-0"
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                }}
                              >
                                <Label
                                  className="me-sm-2"
                                  for="exampleDescription"
                                >
                                  {languagePage === "PT"
                                    ? "Descrição"
                                    : "Description"}
                                </Label>
                                <textarea
                                  id="exampleDescription"
                                  name="description"
                                  placeholder=""
                                  value={values.description}
                                  onChange={changeValues}
                                  style={{
                                    padding: "4px 8px",
                                    borderRadius: 5,
                                    border: "1px solid #c6c6c6",
                                  }}
                                />
                              </FormGroup>
                              <Button
                                className="mt-2"
                                onClick={() => editSelection()}
                              >
                                {languagePage === "PT" ? "Salvar" : "To save"}
                              </Button>
                            </Form>

                            <Button className="mt-2" onClick={handleCloseModal}>
                              {languagePage === "PT" ? "Fechar" : "Close"}
                            </Button>
                          </Modal>
                          <div
                            onClick={() => handleAction(selection.id)}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: 16,
                              background: "#f0954b",
                              padding: 10,
                              borderRadius: 10,
                              color: "#fff",
                              cursor: "pointer",
                              marginTop: 10,
                            }}
                          >
                            {languagePage === "PT" ? "Excluir" : "Delete"}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {loadingConsultant && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="secondary" />
                </div>
              )}
            </div>
            {/* Jesus */}
            <Setas
              onClick={() => changePage("proxima")}
              style={{
                padding: 5,
                background: atualPage >= numberOfPages ? "#C4C4C4" : "#f27823",
                borderRadius: "50%",
                color: "#134480",
                justifyContent: "center",
                alignItems: "center",
                cursor: atualPage >= numberOfPages ? "default" : "pointer",
              }}
            >
              <FiChevronRight />
            </Setas>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SetasTwo
              onClick={() => changePage("anterior")}
              style={{
                padding: 5,
                background: atualPage === 1 ? "#C4C4C4" : "#f27823",
                borderRadius: "50%",
                color: "#134480",
                justifyContent: "center",
                alignItems: "center",
                cursor: atualPage === 1 ? "default" : "pointer",
              }}
            >
              <FiChevronLeft />
            </SetasTwo>

            <SetasTwo
              onClick={() => changePage("proxima")}
              style={{
                padding: 5,
                background: atualPage >= numberOfPages ? "#C4C4C4" : "#f27823",
                borderRadius: "50%",
                color: "#134480",
                justifyContent: "center",
                alignItems: "center",
                cursor: atualPage >= numberOfPages ? "default" : "pointer",
              }}
            >
              <FiChevronRight />
            </SetasTwo>
          </div>
        </div>
      )}
      <DecisionModal
            isOpen={modalOpen}
            toggle={toggleModal}
            onConfirm={() => confirmDelete(itemId)}
            languagePage={languagePage}
            message={languagePage === "PT" ? "Tem certeza que deseja excluir esta seleção?" : "Are you sure you want to delete this selection?"}
          />
    </div>
  );
};
