import styled from "styled-components";

export const Container = styled.div`
  margin-top: 60px;
  display: flex;

  width: 100%;
  height: 100%;
  flex-direction: column;
  font-family: Gotham-Bold;
  justify-content: center;
  align-items: center;
  text-align: center;

  .box-button {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    justify-content: space-around;
  }
`;

export const Title = styled.h3`

`;

export const ContainerDropInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 32px auto;
  max-width: 900px;
`;

export const Dropdown = styled.select`
  border: none;
  height: 50px;
  padding: 0 12px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
  background-color: #0b243b;
`;

export const Input = styled.input`
  height: 50px;
  border: none;
  width: 100%;
  max-width: 400px;
  padding-left: 8px;
  font-size: 18px;
  color: #fff;
  background-color: #0b243b;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const Button = styled.button`
  display: flex;
  margin: auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 10px;
  color: #fff;
  box-shadow: 4px 4px 18px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 4px 4px 18px 0px rgba(0, 0, 0, 0.5);
  background: #047a3f;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;
