import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  width: 100%;
  max-width: 1200px;
  margin: 0px auto 16px;
  border-radius: 8px;
  padding: 16px;
`;

export const TitleField = styled.div`
  width: 100%;
  border-bottom: 1px solid #c9c9c9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  h4 {
    font-family: "Gotham-Bold", sans-serif;
    font-size: 20px;
    color: #4e4e4e;
  }
`;

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  label {
    font-family: "Gotham-Medium", sans-serif;
    font-size: 16px;
    margin-bottom: 2px;
    color: #4e4e4e;
  }

  input {
    border-radius: 8px;
    border: 1px solid #c2c2c2;
    padding: 4px 6px;
    font-size: 14px;
    font-family: "Gotham-Regular", sans-serif;
    color: #4e4e4e;
  }

  textarea {
    border-radius: 8px;
    border: 1px solid #c2c2c2;
    padding: 4px 6px;
    font-size: 14px;
    font-family: "Gotham-Regular", sans-serif;
    color: #4e4e4e;
  }
`;

export const ButtonField = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 32px;

  button {
    background: #134480;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    font-family: "Gotham-Medium", sans-serif;
    font-size: 15px;
    color: #fff;
    transition: all 0.2s;
  }

  button:hover {
    background: #003668;
  }
`;
