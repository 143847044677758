import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  CardPefil,
  Container,
  Descricao,
  InputTEXTO,
  NameText,
  TextArea,
  Titulo,
} from "./style";
import { useHistory } from "react-router";
import api from "../../../services/api";
import Toastify from "toastify-js";
import { useGlobal } from "../../../hooks/global";

export const PerfilConsultor: React.FC = () => {
  const { push, location } = useHistory<any>();
  const [dados, setDados] = useState();
  const [calculoFinal, setCalculoFinal] = useState(0);
  const [horas, setHoras] = useState(0);
  const { languagePage } = useGlobal();

  const { user, perfil, tipo } = location.state || [];
  const [proposalDescription, setProposalDescription] = useState("");
  const formatter = new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
  });

  const handleCreateProposal = useCallback(async () => {
    await api
      .post("/company/create-proposal", {
        description: proposalDescription,
        finalValue: calculoFinal,
        consultantId: user.id,
        amountHours: horas,
        category: tipo,
      })
      .then((res) => {})
      .catch((err) => {
        Toastify({
          text:
            languagePage === "PT"
              ? "Tivemos um problema ao enviar a proposta, por favor tente novamente!"
              : "We had a problem submitting the proposal, please try again!",

          duration: 3000,
          style: {
            background: "linear-gradient(to right, red, #ccc)",
          },
        }).showToast();
      });
  }, [proposalDescription, calculoFinal, horas]);

  useEffect(() => {
    if (!location || !location.state) {
      push("/company/perfil");
    }
    api
      .get("/company/list-consultants")
      .then((response) => {
        setDados(response.data);
      })
      .catch((error) => {});
  }, []);

  const calculado = (n: number) => {
    if (n > 0) {
      setHoras(n);
      const valor = Number(user.valueHour);
      setCalculoFinal(n * valor);
    } else {
      setCalculoFinal(0);
    }
  };
  const imagemAva = () => {
    try {
      if (user.avatar !== null) {
        return user.avatar;
      } else {
        return "https://res.cloudinary.com/hkkzuevm3/image/upload/v1645643455/Assets-FlashPlayHr/perfil_f6utrt.png";
      }
    } catch (error) {}
  };
  const nomeCon = () => {
    try {
      if (user.name !== null) {
        return user.name;
      }
    } catch (error) {}
  };
  const sexeCon = () => {
    try {
      if (user.sex !== null) {
        return user.sex;
      }
    } catch (error) {}
  };
  const valorHoraCon = () => {
    try {
      if (user.valueHour !== null) {
        return user.valueHour;
      }
    } catch (error) {}
  };
  const descriptionCon = () => {
    try {
      if (user.description !== null) {
        return user.description;
      }
    } catch (error) {}
  };
  const specs = () => {
    try {
      if (user.specs !== null) {
        return user.specs;
      }
    } catch (error) {}
  };

  const decidindoPerfil = (soft: number, hard: number) => {
    let perfil = "";
    if (0 <= soft && soft <= 11) {
      perfil += "Lobo Guará";
    } else if (soft <= 19) {
      perfil += "Camaleão";
    } else if (soft > 19) {
      perfil += "Borboleta";
    }

    if (0 <= hard && hard <= 7) {
      perfil += " --";
    } else if (hard <= 15) {
      perfil += " -";
    } else if (hard <= 22) {
      perfil += " +";
    } else if (hard > 22) {
      perfil += " ++";
    }

    return perfil;
  };
  const perfilCon = () => {
    try {
      if (user.soft !== null && user.hard !== null) {
        return decidindoPerfil(user.soft, user.hard);
      }
    } catch (error) {}
  };

  const contratar = async () => {
    if (calculoFinal <= 0 || proposalDescription === "") {
      if (user.valueHour === 0) {
        Toastify({
          text:
            languagePage === "PT"
              ? "Este consultor não pode receber propostas!!!"
              : "This consultant cannot receive proposals!!!",

          duration: 3000,
          style: {
            background: "linear-gradient(to right, red, #ccc)",
          },
        }).showToast();
      } else {
        Toastify({
          text:
            languagePage === "PT"
              ? "Coloque um valor valido e uma decrição!!!"
              : "Put a valid value and a description!!!",

          duration: 3000,
          style: {
            background: "linear-gradient(to right, red, #ccc)",
          },
        }).showToast();
      }
    } else {
      await handleCreateProposal();

      Toastify({
        text:
          languagePage === "PT"
            ? "Solicitação de servições feita com sucesso!"
            : "Service request successful!",

        duration: 3000,
        style: {
          background: "linear-gradient(to right, #00b09b, #96c93d)",
        },
      }).showToast();
      push("/company/perfil");
    }
  };

  return (
    <Container>
      <Titulo>
        {languagePage === "PT" ? "Consultores" : "Consultants"} {tipo}
      </Titulo>
      <hr style={{ color: "#000" }} />

      <Descricao>
        {descriptionCon()} {perfilCon()}
      </Descricao>

      <div style={{ margin: 20 }}>
        <CardPefil>
          <div
            style={{ display: "block", padding: 5, width: 250, margin: "auto" }}
          >
            <Avatar style={{ display: "block" }} src={imagemAva()}></Avatar>

            <Button
              className="btn-contratar"
              onClick={() => {
                contratar();
              }}
            >
              {languagePage === "PT" ? "Enviar Proposta" : "Send Proposal"}
            </Button>
          </div>

          <div
            style={{ width: "100%", maxWidth: 800, minWidth: 200, margin: 20 }}
          >
            <div style={{ maxWidth: 700, margin: "auto" }} className="row">
              <div className="col" style={{}}>
                <NameText
                  className="form-control"
                  placeholder="Sexo"
                  aria-label="Sexo"
                >
                  {sexeCon()}
                </NameText>
              </div>
              <div className="col" style={{}}>
                <NameText
                  className="form-control"
                  placeholder="Valor/horas"
                  aria-label="Valor/horas"
                >
                  {formatter.format(valorHoraCon())}
                </NameText>
              </div>
            </div>

            <div style={{ maxWidth: 700, margin: "auto" }} className="row">
              <div className="col" style={{}}>
                <InputTEXTO
                  type="number"
                  className="form-control"
                  placeholder={
                    languagePage === "PT"
                      ? "Coloque quantas horas você deseja"
                      : "Put how many hours you want"
                  }
                  aria-label="Coloque quantas horas
                                    você deseja"
                  onChange={(e) => calculado(Number(e.target.value))}
                />
              </div>
              <div className="col">
                <InputTEXTO
                  type="text"
                  className="form-control"
                  placeholder={
                    languagePage === "PT" ? "Valor final" : "Final value"
                  }
                  aria-label="Valor final"
                  value={formatter.format(calculoFinal)}
                />
              </div>
            </div>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                margin: 10,
              }}
            >
              <div
                style={{
                  width: "100%",
                  maxWidth: "1000px",
                  minWidth: "150px",
                }}
              >
                <textarea
                  placeholder={
                    languagePage === "PT"
                      ? "Coloque aqui as suas expecialidades!"
                      : "Put your specialties here!"
                  }
                  style={{
                    background: "#fff",
                    width: "100%",
                    maxWidth: 1000,
                    minWidth: 200,
                    height: 100,
                    margin: "auto",
                    borderRadius: 10,
                  }}
                  disabled
                  value={specs()}
                ></textarea>
              </div>
            </div>
          </div>
        </CardPefil>
        <TextArea className="mb-3">
          <label className="form-label">
            {languagePage === "PT"
              ? "Coloque aqui a descrição do seu projeto."
              : "Enter your project description here."}
          </label>
          <textarea
            className="form-control"
            id="Descricao"
            onChange={(e) => setProposalDescription(e.target.value)}
          ></textarea>
        </TextArea>
        <br></br>
        <br></br>
      </div>
    </Container>
  );
};
