import React from "react";
import { useState } from "react";
import {
    CloseOutline,
    MenuOutline,
} from "@styled-icons/evaicons-outline";

import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useGlobal } from "../../hooks/global";
import { useForm } from "../../hooks/form";


import {
    NavBarContainer,
    Link,
    NavLinkList,
    LinkListItem,
    ContryFlag,
    CountryFlagContainer,
    LogInOnContainer,
    LogInButton,
    LogOnButton,
    ToggleNavBar
} from "./styles";


const NavBar: React.FC = () => {
    const { setLanguagePage, languagePage } = useGlobal();
    const { push } = useHistory();
    const { setType } = useForm();

    const HandleLogon = useCallback(() => {
        push("logon");
        setType("");
    }, []);

    const HandleLogin = useCallback(() => {
        push("/login");
    }, []);

    let [showNavBar, setShowNavBar] = useState(false)
    function HandleToggle() {
        setShowNavBar(!showNavBar)
        if (showNavBar) {
            let toggle = document.getElementById("toggleMenu")
            toggle?.classList.remove("toggledMobileNavBarButton")
        } else {
            let toggle = document.getElementById("toggleMenu")
            toggle?.classList.add("toggledMobileNavBarButton")
        }
    }

    return (
        <>
            <ToggleNavBar onClick={HandleToggle} id="toggleMenu">
                {
                    showNavBar ? <CloseOutline /> : <MenuOutline />
                }
            </ToggleNavBar>

            {(window.matchMedia("(min-width:769px)").matches || showNavBar) &&
                <NavBarContainer>
                    {window.matchMedia("(max-width:768px)").matches &&
                        <NavLinkList>
                             
                            <Link onClick={() => push("/")}>
                                <LinkListItem>
                                    Home
                                </LinkListItem>
                            </Link>
                            <Link onClick={() => push("/educacao")}>
                                <LinkListItem>
                                    {languagePage === "PT" ? "EAD" : "Education"}
                                </LinkListItem>
                            </Link>
                            <Link href="#results">
                                <LinkListItem>
                                    {languagePage === "PT" ? "Saiba Mais" : "Know More About Us"}
                                </LinkListItem>
                            </Link>

                            <LinkListItem>
                                <CountryFlagContainer>
                                    <ContryFlag
                                        onClick={() => setLanguagePage("PT")}
                                        src="https://res.cloudinary.com/dkqcb920e/image/upload/v1655922654/emojione-v1_flag-for-brazil_jhywbg.png" />
                                    <ContryFlag
                                        onClick={() => setLanguagePage("EN")}
                                        src="https://res.cloudinary.com/dkqcb920e/image/upload/v1655922654/emojione-v1_flag-for-united-states_odebfq.png" />
                                </CountryFlagContainer>
                            </LinkListItem>
                            <LinkListItem>
                                <LogInOnContainer>
                                    <LogInButton onClick={HandleLogin}>{languagePage === "PT" ? "Entrar" : "Login"}</LogInButton>
                                    <LogOnButton onClick={HandleLogon}>{languagePage === "PT" ? "Cadastre-se" : "Sign up"}</LogOnButton>
                                </LogInOnContainer>
                            </LinkListItem>
                        </NavLinkList>

                    }
                    {window.matchMedia("(min-width:769px)").matches &&
                        <>
                            <NavLinkList>
                                <LinkListItem>
                                    <Link onClick={() => push("/saberesCirculares")}>
                                    {languagePage === "PT" ? "Produtos" : "Products"}
                                    </Link>
                                </LinkListItem>
                                <LinkListItem>
                                    <Link onClick={() => push("/")}>
                                        Home
                                    </Link>
                                </LinkListItem>
                                <LinkListItem>
                                    <Link onClick={() => push("/educacao")}>
                                        {languagePage === "PT" ? "EAD" : "Education"}
                                    </Link>
                                </LinkListItem>
                                <LinkListItem>
                                    <Link href="#results">
                                        {languagePage === "PT" ? "Saiba Mais" : "Know More About Us"}
                                    </Link>
                                </LinkListItem>
                            </NavLinkList>
                            <CountryFlagContainer>
                                <ContryFlag
                                    onClick={() => setLanguagePage("PT")}
                                    src="https://res.cloudinary.com/dkqcb920e/image/upload/v1655922654/emojione-v1_flag-for-brazil_jhywbg.png" />
                                <ContryFlag
                                    onClick={() => setLanguagePage("EN")}
                                    src="https://res.cloudinary.com/dkqcb920e/image/upload/v1655922654/emojione-v1_flag-for-united-states_odebfq.png" />
                            </CountryFlagContainer>
                            <LogInOnContainer>
                                <LogInButton onClick={HandleLogin}>{languagePage === "PT" ? "Entrar" : "Login"}</LogInButton>
                                <LogOnButton onClick={HandleLogon}>{languagePage === "PT" ? "Cadastre-se" : "Sign up"}</LogOnButton>
                            </LogInOnContainer>
                        </>
                    }
                </NavBarContainer>
            }
        </>

    )
}

export default NavBar