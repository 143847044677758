import styled, { css } from "styled-components";
import { InputBase as InputMaterial } from "@material-ui/core";

interface ContainerProps {
  error: boolean;
}

interface TooltipProps {
  diferTooltip: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerInput = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-hadow: 2px 2px 20px -2px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 2px 2px 20px -2px rgba(0, 0, 0, 0.7);
  background-color: #fff;
  ${(props) =>
    props.error &&
    css`
      border: 2px solid #af0708 !important;
    `}
`;

export const InputBase = styled(InputMaterial)`
  display: flex;
  width: 100%;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  color: #fff;
`;

export const ContainerError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const TooltipError = styled.div<TooltipProps>`
  display: flex;
  font-family: math;
  font-weight: bold;
  font-size: 1rem;
  position: absolute;
  color: #fff;
  ${(props) =>
    props.diferTooltip &&
    css`
      right: -5px;
    `}
  ${(props) =>
    !props.diferTooltip &&
    css`
      left: 50%;
      transform: translatex(-50%);
    `}
    bottom:calc(100% + 0.375rem);
  background: #32416b;
  padding: 0.625rem;
  border-radius: 0.3125rem;
  white-space: nowrap;
  ::before {
    border-style: solid;
    border-color: #32416b transparent;
    border-width: 0.375rem 0.375rem 0 0.375rem;
    bottom: -0.3125rem;
    content: "";
    position: absolute;
    ${(props) =>
      props.diferTooltip &&
      css`
        right: 10px;
      `}
    ${(props) =>
      !props.diferTooltip &&
      css`
        left: 50%;
        transform: translatex(-50%);
      `}
  }
  box-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

export const AlertError = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
`;
