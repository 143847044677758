import React from "react";
import { Facebook, Twitter, Instagram, Linkedin, Whatsapp } from '@styled-icons/boxicons-logos'

import { ExternalLink, SocialBarContainer } from "./styles";


const SocialBar: React.FC = () => {

    return (
        <SocialBarContainer>
            <ExternalLink href="https://pt-br.facebook.com/donaverde.green" target="_blank">
                <Facebook size={25} />
            </ExternalLink>
            <ExternalLink href="http://www.instagram.com.br/eco.recitec" target="_blank">
                <Instagram size={25} />
            </ExternalLink>
            <ExternalLink href="http://www.twitter.com.br" target="_blank">
                <Twitter size={25} />
            </ExternalLink>
            <ExternalLink href="https://www.linkedin.com/company/ecorecitec-economia-circular-sustentabilidade-e-tecnologia/" target="_blank">
                <Linkedin size={25} />
            </ExternalLink>
            <ExternalLink href="https://api.whatsapp.com/send?phone=557199748068" target="_blank">
                <Whatsapp size={25} />
            </ExternalLink>

        </SocialBarContainer>
    )
}

export default SocialBar