import { useElements, useStripe, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";

import { CircularProgress } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import { Container, Input } from "./styles";
import axios from "axios";
import jsonp from "jsonp";
import { useGlobal } from "../../../hooks/global";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`);

interface StepProps {
  amount: number | undefined;
  proposalId: string | undefined;
}

const BoletoPaymentElement = ({ amount, proposalId }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const { push } = useHistory();
  const { languagePage } = useGlobal();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [dados, setDados] = useState<string>("");
  const [erro, setError] = useState<boolean>(false);
  const [cep, setCep] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [uf, setUf] = useState<string>("");


  const handleCEP = (e: any) => {
    setCep(mask(e.target.value));
  };

  const handleCPFOrCNPJ = (e: any) => {
    setDados(mask(e.target.value));
  };

  const mask = (v: string) => {
    v = v.replace(/\D/g, "");

    if (v.length <= 8) {
      v = v.replace(/(\d{5})(\d{3})$/, "$1-$2");
    } else if (v.length > 8 && v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v;
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (erro) {
      setLoading(false);
      return;
    }
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const formatDados = dados.replace(/\D/g, "");
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "boleto",
      boleto: { tax_id: formatDados },
      billing_details: {
        name: name,
        email: email,
        address: {
          line1: address,
          city: city,
          state: uf,
          postal_code: cep,
          country: "BR",
        },
      },
    });

    if (error) {
      addToast({
        type: "error",
        description: error.message ? error.message : "",
        title: "",
        icon: "info",
      });
      setLoading(false);
      return;
    }
    const response = await api.post("/company/check-details", {
      proposalValue: amount,
      proposalId: proposalId,
      paymentMethodId: paymentMethod?.id,
    });

    const { paymentIntent } = response.data;

    const { error: errorT } = await stripe.confirmBoletoPayment(paymentIntent, {
      payment_method: {
        boleto: { tax_id: formatDados },
        billing_details: {
          name: name,
          email: email,
          address: {
            line1: address,
            city: city,
            state: uf,
            postal_code: cep,
            country: "BR",
          },
        },
      },
    });

    if (errorT) {
      addToast({
        type: "error",
        description: errorT.message ? errorT.message : "",
        title: "",
        icon: "info",
      });
      return;
    }
    setLoading(false);
    addToast({
      type: "success",
      title: languagePage === "PT" ? "Etapa concluída" : "Completed step",
      icon: "info",
      description:
        languagePage === "PT"
          ? "aguardando o pagamento"
          : "waiting for payment",
    });

    push("/company/consultoria/minhas-propostas");
  };

  const validateCNPJ = async (e: any) => {
    if (dados.length > 14) {
      const formated = dados.replace(/\D/g, "");
      jsonp(
        `https://www.receitaws.com.br/v1/cnpj/${formated}`,
        {},
        (err, res) => {
          if (err) {
            addToast({
              title: "Erro",
              description:
                languagePage === "PT"
                  ? "Ocorreu um erro, por favor tente novamente"
                  : "An error occurred. Please try again",
              icon: "alert",
              type: "error",
            });
            setError(true);
          } else {
            const { situacao } = res;

            if (situacao !== "ATIVA") {
              addToast({
                title: "Erro",
                description:
                  languagePage === "PT"
                    ? "CNPJ inválido ou não existe"
                    : "Invalid CNPJ or does not exist",
                icon: "alert",
                type: "error",
              });
              setError(true);
            } else {
              setError(false);
            }
          }
        }
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div style={{ background: "#fff", padding: 16, borderRadius: 8 }}>
        <div style={{ margin: "10px 0" }}>
          <label>Nome</label>
          <Input
            id="name"
            value={name}
            name="name"
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div style={{ margin: "10px 0" }}>
          <label>CPF/CNPJ</label>
          <Input
            id="tax_id"
            name="tax_id"
            required
            maxLength={18}
            value={dados}
            onChange={handleCPFOrCNPJ}
            onBlur={validateCNPJ}
          />
        </div>

        <div style={{ margin: "10px 0" }}>
          <label>E-mail</label>
          <Input
            type="email"
            value={email}
            id="email"
            name="email"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ margin: "10px 0", width: 160, marginRight: 16 }}>
            <label>CEP</label>
            <Input
              id="postal_code"
              name="postal_code"
              type="tel"
              required
              maxLength={9}
              value={cep}
              onChange={handleCEP}
              onBlur={async () => {
                const formatCep = cep.replace(/\D/g, "");
                if (formatCep !== "" && formatCep.length >= 8) {
                  const res = await axios.get(
                    `https://viacep.com.br/ws/${formatCep}/json/`
                  );

                  const { uf, logradouro, localidade } = res.data;

                  setUf(uf);
                  setCity(localidade);
                  setAddress(logradouro);
                }
              }}
            />
          </div>
          <div style={{ margin: "10px 0", flex: 1 }}>
            <label>{languagePage === "PT" ? "Endereço" : "Address"}</label>
            <Input
              value={address}
              id="address"
              name="address"
              required
              onChange={(e) => setAddress(e.target.value)}
              disabled={address.length > 0 || city.length > 0 ? false : true}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ margin: "10px 0", flex: 1 }}>
            <label>{languagePage === "PT" ? "Cidade" : "City"}</label>
            <Input
              value={city}
              id="city"
              name="city"
              required
              disabled={city.length > 0 ? false : true}
            />
          </div>

          <div style={{ margin: "10px 0", width: 100, marginLeft: 16 }}>
            <label>{languagePage === "PT" ? "Estado" : "State"}</label>
            <Input
              value={uf}
              id="state"
              name="state"
              required
              disabled={uf.length > 0 ? false : true}
            />
          </div>
        </div>

        <div id="error-message" role="alert"></div>
      </div>
      <button onClick={handleSubmit}>
        {loading === false ? (
          languagePage === "PT" ? (
            "Finalizar pagamento"
          ) : (
            "Finalize payment"
          )
        ) : (
          <CircularProgress size={18} color="inherit" />
        )}
      </button>
    </div>
  );
};

export const StepBoleto: React.FC<StepProps> = ({
  amount,
  proposalId,
}: StepProps) => {
  const { languagePage } = useGlobal();
  const { push } = useHistory();
  return (
    <Container>
      <div className="box-card">
        <h4>
          {languagePage === "PT" ? "Valor do pagamento" : "Payment amount"}{" "}
          {Intl.NumberFormat("pt-BR", {
            currency: "BRL",
            style: "currency",
          }).format(amount as number)}
        </h4>
        <Elements stripe={stripePromise}>
          <BoletoPaymentElement amount={amount} proposalId={proposalId} />
        </Elements>
        <button
          className="voltar"
          onClick={() => push("/company/consultoria/minhas-propostas")}
        >
          {languagePage === "PT" ? "Voltar" : "Return"}
        </button>
      </div>
    </Container>
  );
};
