import styled, { css, keyframes } from "styled-components";

const RotateAnimation = keyframes`  
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;


interface BolinhaData{
    isActive:boolean;
}

export const DisplayFlex = styled.div`
    display: flex;

`
export const Container = styled(DisplayFlex)`
    flex-direction: column;
    -webkit-flex-direction: column;
    align-items: center;
    margin: 20px;
    margin-top: 0px;
    font-family: "Gotham-Medium";

`
export const SubContainer = styled(DisplayFlex)`
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    min-width: 150px;
    color: #134481;
    font-size: 3.5vh;
`
export const Voltar = styled(DisplayFlex)`
  font-size: 2.15vh;
  color: #6b6b6b;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  min-width: 70px;
  border-radius: 50px;
  border: 1px solid #6b6b6b;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 8px;
  position: absolute;
  left: 0;
  transform: translateY(-50%);

  @media (max-width: 768px) {
    font-size: 1.6vh;
    max-width: 120px;
    padding: 6px;
  }

  @media (max-width: 480px) {
    font-size: 1.3vh;
    max-width: 100px;
    padding: 4px;
  }
`
export const Titulo = styled.div`
    width: 100%;
    max-width: 600px;
    min-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
`
export const QuestContainer = styled.div`
    margin: 10px;
    width: 100%;
    max-width: 1000px;
    min-width: 200px; 
`
export const ContainerGenerico = styled(DisplayFlex)`
    background:#FFF;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius:50px;
    width: 100%;
    max-width: 1000px;
    min-width: 200px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding:20px;
    padding-top:0px ;
    box-shadow: 0.125rem 0.125rem 0.5rem rgba(0,0,0,1);

    .sumir-texto{
        display: flex;
    }
    @media(max-width:380px){
        .sumir-texto{
        display: none;
    }
    }
`
export const ContainerGenericoHeader = styled(DisplayFlex)`
   
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    padding: 30px;
    width: 100%;

`
export const IntroducaoQuestionario = styled(DisplayFlex)`
    
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;

`
export const  ConteudoIntroducao = styled(DisplayFlex)`
   
    position: relative;
    width: 100%;
    max-width: 1000;
    min-width: 200;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #FFF;
    height: 100%;
`
export const IntroducaoOrientaco = styled(DisplayFlex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const OrientacoImagem = styled(DisplayFlex)`
    width: 100%;
    max-width: 600px;
    min-width: 200px;
    height: 50vh;
    justify-content: center;

    img{
        width: 100%;
        max-width: 600px;
        min-width: 250px;
    }
`
export const Img = styled(DisplayFlex)`
 height: 70px;
 width: 70px;
`
export const OrientacaoTexto = styled.div`
    color: #133770;
    font-family: "Gotham-Bold";
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    min-width: 100px;
    text-align: center;
    padding: 10px;
`
export const NextQuestoes = styled(DisplayFlex)`
    position: absolute;
    right: 0px;
`
export const Alternativa = styled(DisplayFlex)`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #134480;
    border-radius: 50%;
    font-size: 2.15vh;
    cursor: pointer;
`
export const LocalizacaoPagina = styled(DisplayFlex)`
 
    margin: 20px;
    flex-direction: row;
    width: 100%;
    justify-content: center;


`
export const ContainerCirculo = styled(DisplayFlex)`
    background:#FFF;
    height:calc(90vh - 30px);
    margin:80px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius:50px;
    flex-direction: column;
    padding:20px;
    padding-top:0px ;
    box-shadow: 0.125rem 0.125rem 0.5rem rgba(0,0,0,1);
`
export const Imagem = styled(DisplayFlex)`
   
    flex-direction: column;
    -webkit-flexe-direction: column;
    justify-content: space-between;
    color: #134481;
    align-items: center;
    width: 200px;
  
`

export const IconExame = styled(DisplayFlex)`
    
    flex-direction: row;
    -webkit-flex-direction: row;
    align-items: center;
`
export const TextIcon = styled.div`
    flex-direction: column;
    -webkit-flex-direction: column;
    font-size: 2.2vh;
    color: #136EAC;

`
export const ContainerRotate = styled(DisplayFlex)`
    background: #f15115;
    height: 30vh;
    width: 30vh;
    justify-content: center;
    padding: 20px;
    border-radius: 20px;
    animation: ${RotateAnimation} ease-in infinite  0.1s;
`

export const Bolinha = styled.div<BolinhaData>`
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: #5F5F5F;
    transition: all 0.3s;
    ${props => props.isActive && css`
        background: #f15115;
    `}
`