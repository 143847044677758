import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  @media (max-width: 768px) {
    width: 90%;
    div {
      flex-direction: column;
      justify-content: center;
    }
    p {
      text-align: center;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 200px;
  height: 200px;
  border-radius: 8px;
  border: none;
  background: #134480;
  color: #fff;
  transition: all 0.2s;
  font-family: "Gotham-Regular", sans-serif;
  font-size: 14px;

  &:hover {
    opacity: 0.95;
  }

  @media (max-width: 768px) {
    &:first-child {
      margin-bottom: 8px;
    }
  }
`;

export const Input = styled.input`
  display: flex;
  padding: 4px 8px;
  width: 100%;
  border-radius: 5px;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  margin-left: 5px;
`;

export const ButtonPayment = styled.button`
  padding: 8px;
  border: none;
  border-radius: 5px;
  background: #134480;
  color: #fff;
  font-family: "Gotham-Medium", sans-serif;
  font-size: 14px;
  margin-top: 8px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const Div = styled.div`
  margin: 12px 0;

  .card {
    padding: 8px;
  }
`;
