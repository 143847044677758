// Importaçãoes
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { animated, useTransition } from "@react-spring/web";
import { useGlobal } from "../../../hooks/global";
import { selectionInterface } from "../../../interfaces/selectionInterface";
import api from "../../../services/api";
import {
  Alternativas,
  AlternativasParteUm,
  AlternativasRespondidas,
  Animated,
  AnimatedContainer,
  AnimatedContainerDois,
  AnimatedContainerDoisTexto,
  AnimatedContainerUm,
  BotaoIniciarSoft,
  BotaoVoltar,
  BoxBackTitle,
  Container,
  ContainerGenerico,
  ContainerHeader,
  ContainerMain,
  ContainerrLogo,
  ContainerRotate,
  CursosPointer,
  Enuciado,
  ImganQuestao,
  IntroducaoEQuestao,
  IntroducaoLogo,
  IntroducaoUm,
  IntroducaoUmTextos,
  JustifyContentFlex,
  JustifyContentFWRSB,
  LayoutEnuciado,
  LayoutInstrucoes,
  LayoutInstrucoesImagem,
  LayoutInstrucoesTexto,
  LayoutQuestao,
  Logo,
  Pergunta,
  QuestaoSoftSkill,
  Setas,
  SetasTwo,
  TextoAzul,
  TextoBranco,
  TextoPergunta,
  TituloBox,
} from "./style";
import { Radio } from "@material-ui/core";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { FaFeatherAlt } from "react-icons/fa";
import { useAuth } from "../../../hooks/auth";
import Cronometro from "../../../components/Cronometro";
// Interface (Tipando os tados)
interface alternativa {
  alternativa: string;
}
interface SoftSkillProps {
  title: string;
  img: string;
  a: alternativa;
  b: alternativa;
  c: alternativa;
  d: alternativa;
  e?: alternativa;
  f?: alternativa;
}

interface ResSoftSkillProps {
  title: string;
  marked: string;
}
// Inicio da componente
export const SoftSkillNovo: React.FC = () => {
  // Constantes
  const { user } = useAuth();
  const [atualPage, setAtualPage] = useState(0);
  const { languagePage, tempoEncerrado } = useGlobal();
  const { push } = useHistory();
  const { selectionId }: any = useParams();
  const [selection, setSelection] = useState<selectionInterface>(
    {} as selectionInterface
  );
  const [respostas, setRespostas] = useState<ResSoftSkillProps[]>([]);
  const [posicao, setPosicao] = useState(0);
  // Quesões
  const [questions, setQuestions] = useState<SoftSkillProps[]>([]);
  const [question, setQuestion] = useState<SoftSkillProps>(
    {} as SoftSkillProps
  );
  const [questionsEn, setQuestionsEn] = useState<SoftSkillProps[]>([]);
  const [questionEn, setQuestionEn] = useState<SoftSkillProps>(
    {} as SoftSkillProps
  );
  const [checked, setChecked] = useState(0);
  const [escolido, setEscolhido] = useState("");

  const [sexoAPI, setSexoAPI] = useState("");

  // Funções
  const searchSelection = useCallback(async () => {
    const resu = await api.get(`/common/selectionData/${selectionId}`);
    setSelection(resu.data);
  }, [selectionId]);
  useEffect(() => {
    searchSelection();
  }, [searchSelection]);
  const transitions = useTransition(atualPage, {
    keys: null,
    from: { opacity: 0 },
    enter: { opacity: 1, config: { duration: 400 } },
    leave: { opacity: 0, config: { duration: 0 } },
  });

  const questionsSoft = async () => {
    try {
      var sexo = "";
      if (user.sex === "Homem" || user.sex === "Man") {
        sexo = "man";
      } else if (user.sex === "Mulher" || user.sex === "Woman") {
        sexo = "woman";
      } else {
        sexo = "others";
      }
      setSexoAPI(sexo);
      const resu = await api.get(`/common/selection/${selectionId}`);
      const soft = resu.data.soft;

      if (!soft) {
        const dados = await api.get("/common/sel/questions/soft/PT/" + sexo);
        const dadosEn = await api.get("/common/sel/questions/soft/EN/" + sexo);
        setQuestions(dados.data.questions);
        setQuestion(dados.data.questions[0]);
        setQuestionsEn(dadosEn.data.questions);
        setQuestionEn(dadosEn.data.questions[0]);
      } else {
        push("/candidate/minhasSelecoes");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setPosicao(0);
    questionsSoft();
  }, []);

  const send = async () => {
    try {
      const res = await api.post(
        `/common/correction/question/soft/${sexoAPI}`,
        { questions: respostas }
      );
      const resu = await api.post(`/common/answer/soft/${selectionId}`, {
        value: res.data.value,
      });
    } catch (e) {
      console.log(e);
    }
  };
  const next = async (title: string, marked: string) => {
    if (tempoEncerrado === "true") {
      const res = await api.post(
        `/common/correction/question/soft/${sexoAPI}`,
        { questions: respostas }
      );
      await api.post(`/common/answer/soft/${selectionId}`, {
        value: res.data.value,
      });
      localStorage.removeItem("timeFinal");
      localStorage.removeItem("tempoAtual");
      localStorage.removeItem("tempoTotal");

      push("/candidate/minhasSelecoes");
    }
    if (escolido !== "") {
      setChecked(0);

      var fleg = false;
      for (let i = 0; i < respostas.length; i++) {
        if (respostas[i]?.title === title) {
          fleg = true;
          if (respostas[i]?.marked !== marked) {
            respostas.splice(i, 1);
            respostas.push({
              title: title,
              marked: marked,
            });
          }
        }
      }

      if (fleg === false) {
        respostas.push({
          title: title,
          marked: marked,
        });
      }

      if (posicao < 0) {
        setAtualPage(2);
        setQuestion(questions[0]);
        setQuestionEn(questionsEn[0]);
      } else {
        const valorZero = posicao + 1;
        setPosicao(valorZero);
        setAtualPage(2);
        setQuestion(questions[valorZero]);
        setQuestionEn(questionsEn[valorZero]);

        if (posicao >= 4) {
          setPosicao(0);
          send();
          push("/candidate/minhasSelecoes");
        }
      }
      setPosicao(posicao + 1);
      setEscolhido("");
    }
  };

  const respondendo = (questio: any) => {
    return questio;
  };

  // Retono da componete
  return (
    <ContainerMain>
      <ContainerHeader>
        <BoxBackTitle>
          <BotaoVoltar onClick={() => push("/candidate/minhasSelecoes")}>
            {languagePage === "PT" ? "Voltar" : " Come back"}
          </BotaoVoltar>
          <TituloBox>
            <abbr title={selection.name} style={{ textDecoration: "none" }}>
              {languagePage === "PT"
                ? "Exame Soft Skill da Seleção"
                : "Selection Soft Skill Exam "}{" "}
              "{selection.name ? selection.name : ""}"
            </abbr>
          </TituloBox>
        </BoxBackTitle>
      </ContainerHeader>
      <IntroducaoEQuestao>
        {transitions((style, i) => {
          if (i === 0) {
            return (
              <Animated style={{ ...style }}>
                <Container>
                  <IntroducaoLogo>
                    <ContainerrLogo>
                      <div>
                        <Logo>
                          <img
                            src={
                              "https://res.cloudinary.com/hkkzuevm3/image/upload/v1648745972/Assets-FlashPlayHr-webp/logo_k4qnwy-_2__nckvbo.webp"
                            }
                            alt="FlashPlayHR"
                          />
                        </Logo>
                      </div>
                    </ContainerrLogo>
                  </IntroducaoLogo>

                  <IntroducaoUm>
                    {languagePage === "PT" ? (
                      <IntroducaoUmTextos>
                        <TextoBranco>O propósito do Circular SoftSkills</TextoBranco>
                        <TextoAzul>
                          é auxiliar em uma
                          <br />
                          avaliação preliminar do perfil para
                          <br />
                          Inovação.
                          <br /> <br />
                          Para um resultado próximo do real,
                        </TextoAzul>
                        <TextoBranco>
                          o "conhecimento" precisa ser um <br />
                          pouco esquecido neste momento.
                        </TextoBranco>
                        <TextoAzul>
                          A palavra de ordem é "vivenciar".
                          <br />
                          <br />
                        </TextoAzul>
                        <TextoBranco>
                          O foco deve estar na pergunta:
                          <br />
                          Como me sinto? E o nosso slogan é
                          <br />
                          "Vivenciar para Ser".
                        </TextoBranco>
                        <br />
                        <br />
                        <TextoAzul>Boa sorte!</TextoAzul>
                      </IntroducaoUmTextos>
                    ) : (
                      <IntroducaoUmTextos>
                        <TextoBranco>The purpose of Circular SoftSkills </TextoBranco>
                        <TextoAzul>
                          is to assist in <br />
                          a preliminary assessment of the profile for
                          <br />
                          Innovation.
                          <br />
                          <br />
                          For a result to be close to real,{" "}
                        </TextoAzul>
                        <TextoBranco>
                          the “knowledge” needs to be <br />
                          somewhat forgotten at this point.
                        </TextoBranco>
                        <TextoAzul>The watchword is “to experience”.</TextoAzul>
                        <br />
                        <br />
                        <TextoBranco>
                          The focus should be on the question:
                          <br />
                          How do I feel? And our slogan is <br />
                          “Living to Be”.
                        </TextoBranco>
                        <br />
                        <br />
                        <TextoAzul>Good Luck!</TextoAzul>
                      </IntroducaoUmTextos>
                    )}
                    <div>
                      <BotaoIniciarSoft onClick={() => setAtualPage(1)}>
                        <GoChevronRight color="#FFF" />
                      </BotaoIniciarSoft>
                    </div>
                  </IntroducaoUm>
                </Container>
              </Animated>
            );
          }
          if (i === 1) {
            return (
              <Animated style={{ ...style }}>
                <ContainerGenerico>
                  <AnimatedContainer>
                    <AnimatedContainerUm>
                      <div>

                        <Logo>
                          <img
                            src={
                              "https://res.cloudinary.com/hkkzuevm3/image/upload/v1648750223/Assets-FlashPlayHr-webp/logo_k4qnwy_3_xspara.webp"
                            }
                            alt="FlashPlayHR"
                          />
                        </Logo>
                      </div>
                    </AnimatedContainerUm>
                    <div>
                      {languagePage === "PT"
                        ? "Atenção! Você terá uma única chance para fazer o exame cronometrado!"
                        : "Attention! You will have only one chance to take the timed exam!"}
                    </div>
                    <AnimatedContainerDois>
                      <FaFeatherAlt size={70} color="#D1EAF8" />
                      <AnimatedContainerDoisTexto>
                        <div>Exame quiz</div>
                        <div>Soft Skill</div>
                      </AnimatedContainerDoisTexto>
                    </AnimatedContainerDois>
                  </AnimatedContainer>
                  <LayoutQuestao>
                    <div>
                      <Setas onClick={() => setAtualPage(0)}>
                        <GoChevronLeft />
                      </Setas>
                    </div>
                    <LayoutInstrucoes>
                      <div>
                        <LayoutInstrucoesImagem>
                          <img
                            src={
                              "https://res.cloudinary.com/hkkzuevm3/image/upload/v1648750630/Assets-FlashPlayHr-webp/01_i9ujdn_amc0tr.webp"
                            }
                            alt="Imagem"
                          />
                        </LayoutInstrucoesImagem>
                      </div>

                      {languagePage === "PT" ? (
                        <LayoutInstrucoesTexto>
                          <ul>
                            <li>Para a realização desta avaliação precisamos da sua
                            imaginação e concentração.</li>
                            <li>
                              {" "}
                              Para a realização deste teste recomendamos
                              respirar profundamente pelo menos 03 vezes.
                            </li>
                            <li>
                              Será necessário, para a sua realização, estar em
                              um ambiente tranquilo e silencioso.
                            </li>
                            <li>
                              Avalie se a sua mente está tranquila e siga em
                              frente.
                            </li>
                          </ul>
                          Boa sorte!!
                        </LayoutInstrucoesTexto>
                      ) : (
                        <LayoutInstrucoesTexto>
                          <ul>
                            <li>
                              To carry out this assessment we need your
                            imagination and concentration.
                            </li>
                            <li>
                              {" "}
                              To perform this test, we recommend taking a deep
                              breath at least 03 times.
                            </li>
                            <li>
                              It will be necessary, for its accomplishment, to
                              be in a quiet and silent environment.
                            </li>
                            <li>
                              Assess whether your mind is clear and move on.
                            </li>
                          </ul>
                          Good luck!!
                        </LayoutInstrucoesTexto>
                      )}
                    </LayoutInstrucoes>
                    <div>
                      <Setas onClick={() => setAtualPage(2)}>
                        <GoChevronRight />
                      </Setas>
                    </div>
                  </LayoutQuestao>
                  <JustifyContentFlex>
                    <SetasTwo onClick={() => setAtualPage(0)}>
                      <GoChevronLeft />
                    </SetasTwo>
                    <SetasTwo onClick={() => setAtualPage(2)}>
                      <GoChevronRight />
                    </SetasTwo>
                  </JustifyContentFlex>
                </ContainerGenerico>
              </Animated>
            );
          }
          if (atualPage === 2) {
            return (
              <Animated>
                <ContainerGenerico>
                  {tempoEncerrado !== "true" && (
                    <Cronometro
                      background="#133770"
                      width="80px"
                      height="50px"
                      fontSize="22px"
                      tempoFinal={3}
                    />
                  )}
                  <AnimatedContainer style={{ paddingBottom: 0 }}>
                    <AnimatedContainerUm>
                      <div>
                        <Logo>
                          <img
                            src={
                              "https://res.cloudinary.com/hkkzuevm3/image/upload/v1648750223/Assets-FlashPlayHr-webp/logo_k4qnwy_3_xspara.webp"
                            }
                            alt="FlashPlayHR"
                          />
                        </Logo>
                      </div>
                    </AnimatedContainerUm>
                    <AnimatedContainerDois>
                      <FaFeatherAlt size={70} color="#D1EAF8" />
                      <AnimatedContainerDoisTexto>
                        <div>Exame quiz</div>
                        <div>Soft Skill</div>
                      </AnimatedContainerDoisTexto>
                    </AnimatedContainerDois>
                  </AnimatedContainer>
                  <LayoutQuestao>
                    <QuestaoSoftSkill>
                      <LayoutEnuciado>
                        {languagePage === "PT" ? (
                          <Enuciado>
                            <TextoAzul>
                              {question?.title.split(".")[0]}.
                            </TextoAzul>
                          </Enuciado>
                        ) : (
                          <Enuciado>
                            <TextoAzul>
                              {questionEn?.title.split(".")[0]}.
                            </TextoAzul>
                          </Enuciado>
                        )}
                        {/* AVISO: NÂO TIRE O ESPAÇÃO DA FRENTE DA PALAVRA "NESTE". */}
                        {question?.title.split(".")[1] ===
                          " Neste momento você se sente de que forma?" ? (
                          <ImganQuestao
                            item="girar"
                            style={{
                              position: "relative",
                              marginRight: 60,
                            }}
                          >
                            <ContainerRotate />
                            <div
                              style={{
                                display: "flex",
                                height: "24vh",
                                width: "24vh",
                                padding: "5px",
                                position: "absolute",
                                top: "50%",
                                right: "50%",
                                transform: "translateY(-50%) translateX(50%)",
                              }}
                            >
                              <img src={question?.img} alt="imagens" />
                            </div>
                          </ImganQuestao>
                        ) : question?.title.split(".")[0] ===
                          "Imagine-se dentro deste circulo" ? (
                          <ImganQuestao
                            style={{
                              borderRadius: 100,
                              height: "30vh",
                              width: "30vh",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                height: "24vh",
                                width: "24vh",
                                padding: "5px",
                                WebkitJustifyContent: "center",
                              }}
                            >
                              <img src={question?.img} alt="imagens" />
                            </div>
                          </ImganQuestao>
                        ) : question?.title.split(".")[0] ===
                          "Imagine-se agora no contexto do desenho apresentado" ? (
                          <div
                            style={{
                              display: "flex",
                              width: "35vh",
                              height: "35vh",
                              backgroundColor: "#134480",
                              borderRadius: 150,
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              border: "2px solid black",
                            }}
                          >
                            <ImganQuestao
                              style={{
                                border: "2px solid black",
                                margin: "auto",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  height: "24vh",
                                  width: "24vh",
                                  padding: "5px",
                                  WebkitJustifyContent: "center",
                                }}
                              >
                                <img src={question?.img} alt="imagens" />
                              </div>
                            </ImganQuestao>
                          </div>
                        ) : (
                          <ImganQuestao>
                            <div
                              style={{
                                display: "flex",
                                height: "24vh",
                                width: "24vh",
                                padding: "5px",
                                WebkitJustifyContent: "center",
                              }}
                            >
                              <img src={question?.img} alt="imagens" />
                            </div>
                          </ImganQuestao>
                        )}
                      </LayoutEnuciado>
                      <JustifyContentFWRSB>
                        <Pergunta>
                          <TextoPergunta
                            style={{ marginLeft: -15, width: 300 }}
                          >
                            {languagePage === "PT"
                              ? question?.title.split(".")[1]
                              : questionEn?.title.split(".")[1]}
                          </TextoPergunta>
                        </Pergunta>
                        <Alternativas>
                          <div
                            className="flex-wrap-mobile"
                            style={{ width: "100%" }}
                          >
                            <AlternativasParteUm>
                              {languagePage === "PT" ? (
                                <>
                                  <AlternativasRespondidas
                                    onClick={() =>
                                      setEscolhido(respondendo(question?.a))
                                    }
                                  >
                                    <Radio
                                      onClick={() => setChecked(1)}
                                      checked={checked === 1 ? true : false}
                                      color="default"
                                    />
                                    <CursosPointer>{question?.a}</CursosPointer>
                                  </AlternativasRespondidas>
                                  <AlternativasRespondidas
                                    onClick={() =>
                                      setEscolhido(respondendo(question?.b))
                                    }
                                  >
                                    <Radio
                                      onClick={() => setChecked(2)}
                                      checked={checked === 2 ? true : false}
                                      color="default"
                                    />
                                    <CursosPointer>{question?.b}</CursosPointer>
                                  </AlternativasRespondidas>
                                  <AlternativasRespondidas
                                    onClick={() =>
                                      setEscolhido(respondendo(question?.c))
                                    }
                                  >
                                    <Radio
                                      onClick={() => setChecked(3)}
                                      checked={checked === 3 ? true : false}
                                      color="default"
                                    />
                                    <CursosPointer>{question?.c}</CursosPointer>
                                  </AlternativasRespondidas>
                                  <AlternativasRespondidas
                                    onClick={() =>
                                      setEscolhido(respondendo(question?.d))
                                    }
                                  >
                                    <Radio
                                      onClick={() => setChecked(4)}
                                      checked={checked === 4 ? true : false}
                                      color="default"
                                    />
                                    <CursosPointer>{question?.d}</CursosPointer>
                                  </AlternativasRespondidas>
                                  {question?.e && (
                                    <AlternativasRespondidas
                                      onClick={() =>
                                        setEscolhido(respondendo(question?.e))
                                      }
                                    >
                                      <Radio
                                        onClick={() => setChecked(5)}
                                        checked={checked === 5 ? true : false}
                                        color="default"
                                      />
                                      <CursosPointer>
                                        {question?.e}
                                      </CursosPointer>
                                    </AlternativasRespondidas>
                                  )}

                                  {question?.f && (
                                    <AlternativasRespondidas
                                      onClick={() =>
                                        setEscolhido(respondendo(question?.f))
                                      }
                                    >
                                      <Radio
                                        onClick={() => setChecked(6)}
                                        checked={checked === 6 ? true : false}
                                        color="default"
                                      />
                                      <CursosPointer>
                                        {question?.f}
                                      </CursosPointer>
                                    </AlternativasRespondidas>
                                  )}
                                </>
                              ) : (
                                <>
                                  <AlternativasRespondidas
                                    onClick={() =>
                                      setEscolhido(respondendo(questionEn?.a))
                                    }
                                  >
                                    <Radio
                                      onClick={() => setChecked(1)}
                                      checked={checked === 1 ? true : false}
                                      color="default"
                                    />
                                    <CursosPointer>
                                      {questionEn?.a}
                                    </CursosPointer>
                                  </AlternativasRespondidas>
                                  <AlternativasRespondidas
                                    onClick={() =>
                                      setEscolhido(respondendo(questionEn?.b))
                                    }
                                  >
                                    <Radio
                                      onClick={() => setChecked(2)}
                                      checked={checked === 2 ? true : false}
                                      color="default"
                                    />
                                    <CursosPointer>
                                      {questionEn?.b}
                                    </CursosPointer>
                                  </AlternativasRespondidas>
                                  <AlternativasRespondidas
                                    onClick={() =>
                                      setEscolhido(respondendo(questionEn?.c))
                                    }
                                  >
                                    <Radio
                                      onClick={() => setChecked(3)}
                                      checked={checked === 3 ? true : false}
                                      color="default"
                                    />
                                    <CursosPointer>
                                      {questionEn?.c}
                                    </CursosPointer>
                                  </AlternativasRespondidas>
                                  <AlternativasRespondidas
                                    onClick={() =>
                                      setEscolhido(respondendo(questionEn?.d))
                                    }
                                  >
                                    <Radio
                                      onClick={() => setChecked(4)}
                                      checked={checked === 4 ? true : false}
                                      color="default"
                                    />
                                    <CursosPointer>
                                      {questionEn?.d}
                                    </CursosPointer>
                                  </AlternativasRespondidas>
                                  {questionEn?.e && (
                                    <AlternativasRespondidas
                                      onClick={() =>
                                        setEscolhido(respondendo(questionEn?.e))
                                      }
                                    >
                                      <Radio
                                        onClick={() => setChecked(5)}
                                        checked={checked === 5 ? true : false}
                                        color="default"
                                      />
                                      <CursosPointer>
                                        {questionEn?.e}
                                      </CursosPointer>
                                    </AlternativasRespondidas>
                                  )}

                                  {questionEn?.f && (
                                    <AlternativasRespondidas
                                      onClick={() =>
                                        setEscolhido(respondendo(questionEn?.f))
                                      }
                                    >
                                      <Radio
                                        onClick={() => setChecked(6)}
                                        checked={checked === 6 ? true : false}
                                        color="default"
                                      />
                                      <CursosPointer>
                                        {questionEn?.f}
                                      </CursosPointer>
                                    </AlternativasRespondidas>
                                  )}
                                </>
                              )}
                            </AlternativasParteUm>
                          </div>
                        </Alternativas>
                      </JustifyContentFWRSB>
                    </QuestaoSoftSkill>
                    <div>
                      <Setas
                        onClick={() => next(question?.title, escolido)}
                        style={{
                          marginRight: 10,
                          background: escolido === "" ? "#C4C4C4" : "#134480",
                          cursor: escolido === "" ? "not-allowed" : "pointer",
                        }}
                      >
                        <GoChevronRight />
                      </Setas>
                    </div>
                  </LayoutQuestao>

                  <JustifyContentFlex>
                    <SetasTwo
                      onClick={() => next(question?.title, escolido)}
                      style={{
                        background: escolido === "" ? "#C4C4C4" : "#134480",
                        cursor: escolido === "" ? "not-allowed" : "pointer",
                      }}
                    >
                      <GoChevronRight />
                    </SetasTwo>
                  </JustifyContentFlex>
                </ContainerGenerico>
              </Animated>
            );
          }
        })}
      </IntroducaoEQuestao>
    </ContainerMain>
  );
};
