import styled from "styled-components";

const FooterContainer = styled.footer`
	grid-area: footer;
	display: grid;
	grid-template-columns: 5fr 1fr;
	grid-template-rows: 1fr;
	/* place-items: center normal; */
	background-color: #173976;
	width: 100%;
	padding: 3em;
	@media screen and (min-width: 321px) and (max-width: 375px) {
		width: 101%;
	}
`;

const TextContainer = styled.div`
	align-items: center;
	width: 100%;
`;

const Text = styled.p`
	font-family: Inter-Medium;
	color: #d6d6d6;
	font-size: small;
`;

export { FooterContainer, TextContainer, Text };
