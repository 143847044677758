import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppProvider from './hooks';
import 'bootstrap/dist/css/bootstrap.min.css';

import { createGlobalStyle } from 'styled-components';
import InterRegular from './assets/fonts/Inter/Inter-Regular.ttf'
import InterBold from './assets/fonts/Inter/Inter-Bold.ttf'
import InterMedium from './assets/fonts/Inter/Inter-Medium.ttf'
import InterExtraBold from './assets/fonts/Inter/Inter-ExtraBold.ttf'

const GlobalStyle = createGlobalStyle`
  html{
    scroll-behavior: smooth;
  }

  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

 
  @font-face{
      font-family: 'Inter';
      src: local('Inter'), 
      url(${InterRegular}) format('truetype');
  }
  @font-face{
      font-family: 'Inter-Bold';
      src: local('Inter-Bold'), 
      url(${InterBold}) format('truetype');
  }
  @font-face{
      font-family: 'Inter-Medium';
      src: local('Inter-Medium'), 
      url(${InterMedium}) format('truetype');
  }
  @font-face{
      font-family: 'Inter-ExtraBold';
      src: local('Inter-ExtraBold'), 
      url(${InterExtraBold}) format('truetype');
  }

  
`

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
