import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { useForm } from "../../../hooks/form";
import { Box, ContainerNumFunc, IconFaUserAlt } from "./style";
import { useGlobal } from "../../../hooks/global";
import { BotoesForm, ButtonNextBack } from "../style";

interface NumFuncCompanyData {
  onNext: Function;
  onBack: Function;
}

const NumFuncCompany: React.FC<NumFuncCompanyData> = ({ onNext, onBack }) => {
  const { posNumFunc, setPosNumFunc } = useForm();
  const { setAtualPageGlobal, languagePage } = useGlobal();

  return (
    <Box>
      <div style={{marginTop:130}}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginBottom: 10,
            textAlign: "center",
            marginTop:150

          }}
        >
          <div style={{ margin: "auto" }}>
            {" "}
            {languagePage === "PT"
              ? "Seu número de funcionários nos diz oque você precisa."
              : "Your number of employees will tell us what you need."}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ContainerNumFunc
            isSelected={posNumFunc === 0}
            onClick={() => setPosNumFunc(0)}
          >
            <div className="font-gotham-bold-size-30">0-10</div>
            <div className="font-gotham-medium-size-20">
              {languagePage === "PT" ? "Funcionários" : "Employees"}
            </div>
            <IconFaUserAlt>
              <FaUserAlt color="#B66527" size={16} />
            </IconFaUserAlt>
          </ContainerNumFunc>
          <ContainerNumFunc
            isSelected={posNumFunc === 1}
            onClick={() => setPosNumFunc(1)}
          >
            <div className="font-gotham-bold-size-30">11-21</div>
            <div className="font-gotham-medium-size-20">
              {languagePage === "PT" ? "Funcionários" : "Employees"}
            </div>
            <IconFaUserAlt>
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt color="#B66527" size={16} />
            </IconFaUserAlt>
          </ContainerNumFunc>
          <ContainerNumFunc
            isSelected={posNumFunc === 2}
            onClick={() => setPosNumFunc(2)}
          >
            <div className="font-gotham-bold-size-30">22-50</div>
            <div className="font-gotham-medium-size-20">
              {languagePage === "PT" ? "Funcionários" : "Employees"}
            </div>
            <IconFaUserAlt>
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt color="#B66527" size={16} />
            </IconFaUserAlt>
          </ContainerNumFunc>
          <ContainerNumFunc
            isSelected={posNumFunc === 3}
            onClick={() => setPosNumFunc(3)}
          >
            <div className="font-gotham-bold-size-30">
              51-100
            </div>
            <div className="font-gotham-medium-size-20">
              {languagePage === "PT" ? "Funcionários" : "Employees"}
            </div>
            <IconFaUserAlt>
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt color="#B66527" size={16} />
            </IconFaUserAlt>
          </ContainerNumFunc>
          <ContainerNumFunc
            isSelected={posNumFunc === 4}
            onClick={() => setPosNumFunc(4)}
          >
            <div className="font-gotham-bold-size-30">100+</div>
            <div className="font-gotham-medium-size-20">
              {languagePage === "PT" ? "Funcionários" : "Employees"}
            </div>
            <IconFaUserAlt>
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt style={{ marginRight: 1 }} color="#B66527" size={16} />
              <FaUserAlt color="#B66527" size={16} />
            </IconFaUserAlt>
          </ContainerNumFunc>
        </div>
        <ButtonNextBack>
          <BotoesForm onClick={() => onBack()} >
            <GoChevronLeft />
            {languagePage === "PT" ? "Anterior" : "Back"}
          </BotoesForm>
          <BotoesForm onClick={() => onNext()} >
            {languagePage === "PT" ? "Próximo" : "Next"}
            <GoChevronRight style={{ marginLeft: 5 }} />
          </BotoesForm>
        </ButtonNextBack>
      </div>
    </Box>
  );
};

export default NumFuncCompany;
