import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useGlobal } from "../../../hooks/global";

import { loadStripe } from "@stripe/stripe-js";
import { BsCreditCard2Back } from "react-icons/bs";
import { BiMailSend } from "react-icons/bi";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import { Button, Container, SubContainer } from "./style";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`);

const DefineMethodPaymentCard = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { languagePage } = useGlobal();

  const { addToast } = useToast();
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    localStorage.removeItem("@flashplayHR:plans");

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_URL}/company`,
      },
    });

    if (result.error) {
      addToast({
        type: "error",
        title: "Error",
        icon: "alert",
        description: result.error.message as string,
      });
      return;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement options={{ paymentMethodOrder: ["card"] }} />
      <button
        type="submit"
        disabled={!stripe || !elements}
        style={{
          border: "none",
          padding: "8px 16px",
          color: "#fff",
          background: "#134480",
          borderRadius: 8,
          marginTop: 16,
        }}
      >
        {languagePage === "PT" ? "Assinar" : "To sign"}
      </button>
    </form>
  );
};

const PaymentCard = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { languagePage } = useGlobal();
  const { addToast } = useToast();
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    localStorage.removeItem("@flashplayHR:plans");

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_URL}/company`,
      },
    });

    if (result.error) {
      addToast({
        type: "error",
        title: "Error",
        icon: "alert",
        description: result.error.message as string,
      });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement options={{ paymentMethodOrder: ["card"] }} />
      <button
        type="submit"
        disabled={!stripe || !elements}
        style={{
          border: "none",
          padding: "8px 16px",
          color: "#fff",
          background: "#134480",
          borderRadius: 8,
          marginTop: 16,
        }}
      >
        {languagePage === "PT" ? "Assinar" : "To sign"}
      </button>
    </form>
  );
};
export const Pagamento: React.FC = () => {
  const { push } = useHistory<any>();
  const { setAtualPageGlobal, languagePage } = useGlobal();
  const [plan, setPlan] = useState<any>();
  const { addToast } = useToast();
  const [state, setState] = useState<string>("");

  const handleFaturaToEmail = async (e: any) => {
    e.preventDefault();
    const res = await api.post("/company/enviar-fatura", {
      planName: plan.planName,
    });

    const { enviado } = res.data;

    if (enviado) {
      localStorage.removeItem("@flashplayHR:plans");
      addToast({
        type: "info",
        icon: "info",
        title: languagePage === "PT" ? "Fatura enviada" : "Invoice sent",
        description:
          languagePage === "PT"
            ? "Por favor, verifique o seu e-mail."
            : "Please check your email.",
      });
      push("/company");
    }
  };

  const options = {
    clientSecret: !!plan ? plan.clientSecret : "",
  };

  useEffect(() => {
    setAtualPageGlobal("/company/pagamentos");
    const plans: any = localStorage.getItem("@flashplayHR:plans");
    setPlan(JSON.parse(plans));
  }, []);

  return (
    <Container>
      <SubContainer>
        {state === "" && (
          <div>
            <Button onClick={() => setState("card")} type="button">
              <BsCreditCard2Back size={56} />
            </Button>
            <Button
              onClick={handleFaturaToEmail}
              type="button"
              style={{
                marginTop: 18,
                fontSize: 12,
              }}
            >
              <BiMailSend size={46} />
              {languagePage === "PT"
                ? "Enviar fatura para o e-mail"
                : "Send invoice to email"}
            </Button>
          </div>
        )}
        {state === "card" && !!plan && (
          <Elements stripe={stripePromise} options={options}>
            <PaymentCard />
          </Elements>
        )}
      </SubContainer>
    </Container>
  );
};
