import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  h5 {
    font-family: "Gotham-Medium", sans-serif;
    font-size: 16px;
    text-align: justify;

    strong {
      font-family: "Gotham-Bold", sans-serif;
    }
  }

  input {
    font-family: "Gotham-Regular", sans-serif;
    font-size: 15px;
  }
  .input-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    margin: 4px 0 16px;

    p {
      font-family: "Gotham-Medium", sans-serif;
      font-size: 12px;
      color: red;
      margin: 2.5px 0 0;
    }
  }

  button {
    border: none;
    padding: 8px;
    border-radius: 5px;
    background: #134480;
    color: #fff;
    font-family: "Gotham-Medium", sans-serif;
    font-size: 12px;
    transition: opacity 0.2s;
  }

  button:hover {
    opacity: 0.9;
  }

  button:first-child {
    margin-right: 16px;
  }

  @media (max-width: 768px) {
    width: 90%;
    div {
      flex-direction: column;
      justify-content: center;
    }
    p {
      text-align: center;
    }
  }
`;

interface InputProps {
  error: boolean;
}
export const Input = styled.input<InputProps>`
  display: flex;
  padding: 4px 8px;
  width: 100%;
  border-radius: 5px;
  border: none;
  ${(props) =>
    props.error
      ? "border-bottom: 1px solid red;"
      : "border-bottom: 1px solid #c4c4c4;"}

  margin-left: 5px;
`;
