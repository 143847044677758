import styled from "styled-components";

export const Setas = styled.div`
  display: flex;

  @media (max-width: 433px) {
    display: none;
  }
`;
export const BoxBackTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: calc(10vh - 30px);
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #6b6b6b;
  font-size: 3.5vh;

  @media (max-width: 640px) {
    margin-top: 10px;
    font-size: 1.6vh;

    div {
      top: -30px;
    }
  }
`;

export const SetasTwo = styled.div`
  display: none;
  width: 50px;
  height: 50px;
  padding: 20px;
  margin: 10px;

  @media (max-width: 433px) {
    display: flex;
  }
`;
