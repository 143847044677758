import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Card = styled.div`
  padding: 16px;
  border-radius: 20px;
  background-color: #134480;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .box-container {
    flex: 1;
  }

  .card-column {
    flex-direction: column;
  }

  .card-box-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .card-box-image {
    width: 250px;
    height: 250px;
    border-radius: 8px;
    padding: 8px;
    background: white;
    margin-right: 16px;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 8px;
    }

    @media (max-width: 768px) {
      width: 140px;
      height: 140px;
      margin-bottom: 32px;
    }
  }
  .card-box {
    flex-direction: column;
    flex: 1;
    h5 {
      font-family: "Gotham-Medium", sans-serif;
      font-size: 16px;
      color: #f2f2f2;
    }
    p {
      font-family: "Gotham-Light", sans-serif;
      color: #fff;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .box-button {
    h4 {
      font-family: "Gotham-Medium", sans-serif;
      color: #fff;
    }
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Button = styled.button`
  border: none;
  padding: 5px 16px;
  border-radius: 5px;
  margin: 0px 8px;
  font-family: "Gotham-Regular", sans-serif;
  font-size: 14px;

  &:first-child {
    margin-left: 0px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 5px 0px;
    padding: 8px 16px;
  }
`;
