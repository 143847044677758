import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect, useRef, useCallback } from "react";
import PaginationCustom, {
  PaginationRefProps,
} from "../../../components/PaginationCustom";
import { useGlobal } from "../../../hooks/global";
import api from "../../../services/api";

export const FinanceiroPropostas: React.FC = () => {
  const { setAtualPageGlobal, languagePage } = useGlobal();
  const [loadingProposals, setLoadingProposals] = useState<boolean>(true);
  const [finances, setFinances] = useState<any[]>();
  const paginationRef = useRef<PaginationRefProps>(null);
  const [atualPage, setAtualPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);

  useEffect(() => {
    api
      .get(`root/proposals?limit=11&&page=${atualPage}`)
      .then((response) => {
        const { proposals, count } = response.data;
        setFinances(proposals);
        setNumberOfPages(Math.ceil(count / 11));
        setLoadingProposals(false);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    setAtualPageGlobal("/root/financeiro");
  }, []);

  const currency = Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
  const changePage = useCallback(
    (newPage) => {
      if (atualPage !== newPage) setAtualPage(newPage);
    },
    [atualPage]
  );
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "space-between",
        margin: 20,
        marginTop: 0,
        flexDirection: "column",
        marginBottom: 20,
      }}
    >
      {!loadingProposals && (
        <table
          style={{
            width: "100%",
            borderRadius: 5,
            borderCollapse: "collapse",
            textAlign: "center",
          }}
        >
          <thead>
            {languagePage === "PT" ? (
              <tr style={{ color: "#FFF" }}>
                <th
                  style={{
                    background: "#302c2c",
                    width: "12%",
                    borderTopLeftRadius: 10,
                    padding: 16,
                  }}
                >
                  Empresa
                </th>
                <th
                  style={{
                    background: "#302c2c",
                    width: "12%",
                  }}
                >
                  Consultor
                </th>
                <th
                  style={{
                    background: "#302c2c",
                    width: "5%",
                  }}
                >
                  Horas total
                </th>
                <th
                  style={{
                    background: "#302c2c",
                    width: "5%",
                  }}
                >
                  Aceito
                </th>
                <th
                  style={{
                    background: "#302c2c",
                    width: "5%",
                  }}
                >
                  Cancelado
                </th>
                <th
                  style={{
                    background: "#302c2c",
                    width: "10%",
                  }}
                >
                  Valor da proposta
                </th>
                <th
                  style={{
                    background: "#302c2c",
                    width: "10%",
                  }}
                >
                  Valor do 1º pagamento
                </th>
                <th
                  style={{
                    background: "#302c2c",
                    width: "5%",
                  }}
                >
                  1º etapa pago
                </th>
                <th
                  style={{
                    background: "#302c2c",
                    width: "10%",
                  }}
                >
                  Valor do 2º pagamento
                </th>
                <th
                  style={{
                    background: "#302c2c",
                    width: "6%",
                  }}
                >
                  2º etapa pago
                </th>
                <th
                  style={{
                    background: "#302c2c",
                    width: "10%",
                  }}
                >
                  Repasse
                </th>
                <th
                  style={{
                    background: "#302c2c",
                    width: "10%",
                    borderTopRightRadius: 10,
                  }}
                >
                  Plataforma
                </th>
              </tr>
            ) : (
              <tr style={{ color: "#FFF" }}>
                <th
                  style={{
                    background: "#302c2c",
                    width: "23.33%",
                    borderTopLeftRadius: 10,
                    padding: 10,
                  }}
                >
                  proposal ID
                </th>
                <th
                  style={{
                    background: "#302c2c",
                    width: "10%",
                    borderTopRightRadius: 10,
                  }}
                >
                  Platform will receive
                </th>
              </tr>
            )}
          </thead>
          <tbody style={{ background: "#FFF" }}>
            {!!finances &&
              finances.map((finance, index) =>
                finance.proposals.map((proposal: any, index: number) => (
                  <tr key={finance.id}>
                    <td
                      style={{ borderBottom: "1px solid #C4C4C4", padding: 10 }}
                    >
                      {proposal.company.name}
                    </td>
                    <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                      {proposal.consultant.name}
                    </td>
                    <td
                      style={{ borderBottom: "1px solid #C4C4C4", padding: 10 }}
                    >
                      {proposal.hoursEmployed}
                    </td>
                    <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                      {!!proposal.accept
                        ? proposal.accept === true
                          ? "Sim"
                          : "Não"
                        : "---"}
                    </td>
                    <td
                      style={{ borderBottom: "1px solid #C4C4C4", padding: 10 }}
                    >
                      {!!proposal.canceled ? "Sim" : "Não"}
                    </td>

                    <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                      {currency.format(proposal.exactAmount)}
                    </td>
                    <td
                      style={{ borderBottom: "1px solid #C4C4C4", padding: 10 }}
                    >
                      {currency.format(finance.proposalInvoiceInitial)}
                    </td>
                    <td
                      style={{ borderBottom: "1px solid #C4C4C4", padding: 10 }}
                    >
                      {finance.proposalInvoiceInitialPaid === true
                        ? "Sim"
                        : finance.proposalInvoiceInitialPaid === false
                        ? "Reembolsado"
                        : "Não"}
                    </td>
                    <td
                      style={{ borderBottom: "1px solid #C4C4C4", padding: 10 }}
                    >
                      {currency.format(finance.finalProposalInvoice)}
                    </td>
                    <td
                      style={{ borderBottom: "1px solid #C4C4C4", padding: 10 }}
                    >
                      {finance.finalProposalInvoicePaid ? "Sim" : "Não"}
                    </td>
                    <td
                      style={{ borderBottom: "1px solid #C4C4C4", padding: 10 }}
                    >
                      {currency.format(proposal.consultantAmountEstimate)}
                    </td>
                    <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                      {currency.format(finance.finalValuePlatform)}
                    </td>
                  </tr>
                ))
              )}
          </tbody>
        </table>
      )}
      {loadingProposals && (
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          padding: 10,
        }}
      >
        <PaginationCustom
          ref={paginationRef}
          pages={numberOfPages}
          disable={loadingProposals}
          onChange={changePage}
        />
      </div>
    </div>
  );
};
