import React, { useCallback, useRef, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { MdQuestionAnswer } from 'react-icons/md';
import { ErrorProps, Verify } from '../../../services/schemaServices';
import {
    Container,
    Header,
    IconDelete,
    ButtonEnd,
    ContainerHeader,
    ContainerTitle,
    Formulario,
    ContainerItemForm,
    ContainerBottom,
    Check
} from './style';
import InputLabel, { InputRefProps } from '../../InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { questionInterface } from '../../../interfaces/questionsInterface';
import {useGlobal} from '../../../hooks/global'

interface ModalEditarPerguntaProps {
    open: boolean;
    onClose: Function;
    onSave: Function;
    data: questionInterface;
}

export const ModalEditarPergunta: React.FC<ModalEditarPerguntaProps> = ({ open, onClose, onSave, data }) => {
    const [errors, setErrors] = useState<ErrorProps[]>([] as ErrorProps[])
    const inputNameRef = useRef<InputRefProps>(null)
    const inputOneRef = useRef<InputRefProps>(null)
    const inputTwoRef = useRef<InputRefProps>(null)
    const inputthreeRef = useRef<InputRefProps>(null)
    const inputFourRef = useRef<InputRefProps>(null)
    const [rightAnswer, setRightAnswer] = useState<String>(data.rightAnswer)
    const {languagePage} = useGlobal()

    const Save = useCallback(() => {
        const name = inputNameRef.current?.getInputValue()
        const id = data.id
        const alternativeOne = inputOneRef.current?.getInputValue()
        const alternativeTwo = inputTwoRef.current?.getInputValue()
        const alternativeThree = inputthreeRef.current?.getInputValue()
        const alternativeFour = inputFourRef.current?.getInputValue()
        const errors = Verify(
            [
                {
                    name: 'name',
                    value: name,
                    required: { message: languagePage === "PT" ?'Campo obrigatório!' : "Required field!", value: true }
                },
                {
                    name: 'Input1',
                    value: alternativeOne,
                    required: { message: languagePage === "PT" ?'Campo obrigatório!' : "Required field!", value: true }
                },
                {
                    name: 'Input2',
                    value: alternativeTwo,
                    required: { message: languagePage === "PT" ?'Campo obrigatório!' : "Required field!", value: true }
                },
                {
                    name: 'Input3',
                    value: alternativeThree,
                    required: { message: languagePage === "PT" ?'Campo obrigatório!' : "Required field!", value: true }
                },
                {
                    name: 'Input4',
                    value: alternativeFour,
                    required: { message: languagePage === "PT" ?'Campo obrigatório!' : "Required field!", value: true }
                }

            ])
        setErrors(errors)
        if (errors.length === 0) onSave({ id, title: name, alternativeOne, alternativeTwo, alternativeThree, alternativeFour, rightAnswer })
    }, [inputNameRef, inputOneRef, inputTwoRef, inputthreeRef, inputFourRef, rightAnswer])

    return (
        <Modal open={open} onClose={() => onClose()} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Container>

                <ContainerHeader>
                    <Header>
                        <ContainerTitle>
                            <MdQuestionAnswer style={{ marginRight: "0.625rem" }} color="#101A36" />
                            {languagePage === "PT" ? "Editar Pergunta" : "Edit Question"}
                        </ContainerTitle>
                        <IconDelete onClick={() => onClose()} color="#C4C4C4" />
                    </Header>
                </ContainerHeader>

                <Formulario>
                    <ContainerItemForm>
                        <InputLabel
                            errors={errors}
                            label="ID"
                            attr="id"
                            disabled
                            containerStyle={{ width: "100%" }}
                            value={data.id}
                            id='InputIdQuestion'
                            inputProps={{ style: { fontFamily: "math", fontWeight: "bold" } }}
                        />
                    </ContainerItemForm>
                    <ContainerItemForm>
                        <InputLabel
                            errors={errors}
                            label= {languagePage === "PT" ? "Título da Pergunta" : "Question Title"} 
                            attr="name"
                            id='InputNameQuestion'
                            ref={inputNameRef}
                            containerStyle={{ width: "100%" }}
                            defaultValue={data.title}
                            inputProps={{ style: { fontFamily: "math", fontWeight: "bold" } }}
                        />
                    </ContainerItemForm>
                    <ContainerItemForm>
                        <Check>
                            <Checkbox checked={rightAnswer === "1"} onClick={() => setRightAnswer("1")} />
                        </Check>
                        <InputLabel
                            errors={errors}
                            label={languagePage === "PT" ? "1º Alternativa" : "1st Alternative"}
                            attr="Input1"
                            id='Input1'
                            ref={inputOneRef}
                            containerStyle={{ width: "100%" }}
                            defaultValue={data.alternativeOne}
                            inputProps={{ style: { fontFamily: "math", fontWeight: "bold" } }}
                        />
                    </ContainerItemForm>
                    <ContainerItemForm>
                        <Check>
                            <Checkbox checked={rightAnswer === "2"} onClick={() => setRightAnswer("2")} />
                        </Check>
                        <InputLabel
                            errors={errors}
                            label={languagePage === "PT" ? "2º Alternativa" : "2st Alternative"}
                            attr="Input2"
                            id='Input2'
                            ref={inputTwoRef}
                            containerStyle={{ width: "100%" }}
                            defaultValue={data.alternativeTwo}
                            inputProps={{ style: { fontFamily: "math", fontWeight: "bold" } }}
                        />
                    </ContainerItemForm>
                    <ContainerItemForm>
                        <Check>
                            <Checkbox checked={rightAnswer === "3"} onClick={() => setRightAnswer("3")} />
                        </Check>
                        <InputLabel
                            errors={errors}
                            label={languagePage === "PT" ? "3º Alternativa" : "3st Alternative"}
                            attr="Input3"
                            id='Input3'
                            ref={inputthreeRef}
                            containerStyle={{ width: "100%" }}
                            defaultValue={data.alternativeThree}
                            inputProps={{ style: { fontFamily: "math", fontWeight: "bold" } }}
                        />
                    </ContainerItemForm>
                    <ContainerItemForm>
                        <Check>
                            <Checkbox checked={rightAnswer === "4"} onClick={() => setRightAnswer("4")} />
                        </Check>
                        <InputLabel
                            errors={errors}
                            label={languagePage === "PT" ? "4º Alternativa" : "4st Alternative"}
                            attr="Input4"
                            id='Input4'
                            ref={inputFourRef}
                            containerStyle={{ width: "100%" }}
                            defaultValue={data.alternativeFour}
                            inputProps={{ style: { fontFamily: "math", fontWeight: "bold" } }}
                        />
                    </ContainerItemForm>
                    <ContainerItemForm style={{ fontStyle: "italic", color: "#333333" }}>
                        {languagePage === "PT" ? "Observação: Marque a alternativa correta!" : "Note: Check the correct alternative!"}
                    </ContainerItemForm>
                </Formulario>

                <ContainerBottom>
                    <ButtonEnd onClick={() => onClose()} style={{ color: "#252323" }}>{languagePage === "PT" ? "Cancelar" : "Cancel"}  </ButtonEnd>
                    <ButtonEnd onClick={() => rightAnswer === "" ? {} : Save()} style={{ color: "#101A36", cursor: rightAnswer === "" ? "not-allowed" : "pointer" }}> {languagePage === "PT" ? "Salvar" : "To save"}</ButtonEnd>
                </ContainerBottom>

            </Container>
        </Modal>
    )
}

export default ModalEditarPergunta;