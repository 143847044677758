import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaRegFilePdf, FaLinkedin, FaUser } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import InputPerfil from "../../../components/InputPerfil";
import { UserProps } from "../../../interfaces/userInterface";
import api from "../../../services/api";
import { MaskCPF, MaskPhone } from "../../../services/maskServices";
import { selectionInterface } from "../../../interfaces/selectionInterface";
import { FaFeatherAlt, FaFire, FaPlay } from "react-icons/fa";
import { MdLibraryBooks } from "react-icons/md";
import { GoGear } from "react-icons/go";
import { tagInterface } from "../../../interfaces/tagInterface";
import {
  ContainerTag,
  TitleTag,
  ContainerIconTag,
  Button,
  ConEmailTel,
} from "./style";
import { useGlobal } from "../../../hooks/global";

export const PerfilCandidato: React.FC = () => {
  const { userId, selectionId }: any = useParams();
  const [candidate, setCandidate] = useState<UserProps>({} as UserProps);
  const { push } = useHistory();
  const [selection, setSelection] = useState<selectionInterface>(
    {} as selectionInterface
  );
  const [tags, setTags] = useState<tagInterface[]>({} as tagInterface[]);
  const [soft, setSoft] = useState(null);
  const [hard, setHard] = useState(null);
  const refLink = useRef<HTMLAnchorElement>(null);
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);

  const { setAtualPageGlobal, languagePage } = useGlobal();

  const searchInfos = useCallback(async () => {
    const resu = await api.get(
      `/company/candidateInSelection/${selectionId}/${userId}`
    );

    setCandidate(resu.data.candidate);
    setSelection(resu.data.selection);
    setTags(resu.data.tags);
    setSoft(resu.data.soft);
    setHard(resu.data.hard);
  }, []);

  useEffect(() => {

    setAtualPageGlobal("/company/selecoes");
    searchInfos();

  }, []);

  const gerarPDF = useCallback((type, tagId = null) => {
    // refLink.current?.setAttribute(
    //   "href",`${process.env.REACT_APP_API}/company/report/${type}/${candidate.id}/${selection.id}${tagId ? `/${tagId}/${languagePage}` : `/${languagePage}`}`
    // );
    // refLink.current?.click();
    const win = window.open(`${process.env.REACT_APP_API}/company/report/${type}/${candidate.id}/${selection.id}${tagId ? `/${tagId}/${languagePage}` : `/${languagePage}`}`, '_blank');
    win?.focus();
  }, [refLink, candidate, selection, languagePage]);
  // const gerarPDF = useCallback(
  //   (type, tagId = null) => {
  //     refLink.current?.setAttribute(
  //       "href",
  //       `${process.env.REACT_APP_API}/company/report/${type}/${candidate.id}/${selection.id}${tagId ? `/${tagId}/${languagePage}` : `/${languagePage}`}`
  //     );
  //     refLink.current?.click();
  //   },
  //   [refLink, candidate, selection, languagePage]
  // );

  return (
    <ConEmailTel
      style={{
        display: "flex",
        flexDirection: "column",
        margin: 20,

        fontFamily: "Gotham-Medium",
      }}
    >

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          color: "#64666b",
          fontSize: 26,
          textAlign: "center",
        }}
      >
        <div
          onClick={() => push(`/company/candidatos/${selectionId}`)}
          style={{
            fontSize: '2.15vh',
            color: '#6b6b6b',
            cursor: 'pointer',
            width: '100%',
            maxWidth: '200px',
            minWidth: '70px',
            borderRadius: '50px',
            border: '1px solid #6b6b6b',
            flexDirection: 'row',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '8px',
            position: 'absolute',
            left: '15px',
            top: '20px',
            transform: 'translateY(-50%)',
          }}
        >

          {languagePage === "PT" ? "Voltar" : "Back"}
        </div>
        <div
          style={{
            width: "70%",

            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            margin: 5,
          }}
        >
          {languagePage === "PT"
            ? "Perfil do Candidato para a seleção"
            : "Candidate Profile for selection"}{" "}
          "{selection.name}"
        </div>
      </div>
      <div
        style={{
          display: "flex",
          margin: "auto",
          width: "100%",
          maxWidth: "1300px",
          minWidth: "200px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#fff",
            borderRadius: 10,
            padding: 20,

            margin: 20,
            width: "100%",
            maxWidth: "1300px",
            minWidth: "200px",
          }}
        >
          <div
            className="flexWOne"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              style={{
                display: "flex",
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                background: "#FFF",
                boxShadow: " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                minWidth: 166,
                minHeight: 166,
                maxWidth: 166,
                maxHeight: 166,
                margin: 10,
              }}
            >
              {candidate.avatar && (
                <img style={{ height: 140 }} src={candidate.avatar} alt="Imagem de perfil" />
              )}
              {!candidate.avatar && <FaUser color="#000" size={140} />}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: 1100,
                minWidth: "200px",
              }}
            >
              <InputPerfil
                styleInput={{
                  fontFamily: "Gotham-Medium",
                  color: "#000",
                  background: "#FFF",
                  fontSize: 16,
                }}
                styleContainer={{
                  background: "#FFF",
                  margin: 20,
                  marginTop: 0,
                }}
                placeholder={languagePage === "PT" ? "Nome da Empresa" : "Company Name"}
                value={candidate.name}
                disabled
                label="nome"
              />
              <div
                className="flexW"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <InputPerfil
                  styleInput={{
                    fontFamily: "Gotham-Medium",
                    color: "#000",
                    background: "#FFF",
                    fontSize: 16,
                  }}
                  styleContainer={{
                    background: "#FFF",
                    margin: "auto",
                    marginBottom: 20,

                    width: "90%",
                    maxWidth: "500px",
                    minWidth: "150px",
                  }}
                  placeholder="Email"
                  label="email"
                  value={candidate.email}
                  disabled
                />
                <InputPerfil
                  styleInput={{
                    fontFamily: "Gotham-Medium",
                    color: "#000",
                    background: "#FFF",
                    fontSize: 16,
                  }}
                  styleContainer={{
                    background: "#FFF",
                    margin: "auto",
                    marginBottom: 20,

                    width: "90%",
                    maxWidth: "500px",
                    minWidth: "150px",
                  }}
                  placeholder={languagePage === "PT" ? "Telefone" : "Phone"}
                  label="telefone"
                  value={MaskPhone(
                    candidate.phone === undefined ? "" : candidate.phone
                  )}
                  disabled
                />
              </div>
              <div
                className="flexW"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <InputPerfil
                  styleInput={{
                    fontFamily: "Gotham-Medium",
                    color: "#000",
                    background: "#FFF",
                    fontSize: 16,
                  }}
                  styleContainer={{
                    background: "#FFF",

                    margin: "auto",
                    marginBottom: 20,

                    width: "90%",
                    maxWidth: "500px",
                    minWidth: "150px",
                  }}
                  placeholder="CPF"
                  label="cpf"
                  value={MaskCPF(
                    candidate.cpf === undefined ? "" : candidate.cpf
                  )}
                  disabled
                />
                <InputPerfil
                  styleInput={{
                    fontFamily: "Gotham-Medium",
                    color: "#000",
                    background: "#FFF",
                    fontSize: 16,
                  }}
                  styleContainer={{
                    background: "#FFF",
                    margin: "auto",
                    marginBottom: 20,

                    width: "90%",
                    maxWidth: "500px",
                    minWidth: "150px",
                  }}
                  placeholder="Gênero"
                  label="gênero"
                  value={candidate.sex}
                  disabled
                />
              </div>
            </div>
          </div>
          <div
            className="media-277"
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent:
                !!candidate.linkedin || !!candidate.curriculum
                  ? "space-between"
                  : "flex-end",
              fontFamily: "Gotham-Medium",
              color: "#FFF",

              width: "100%",
              maxWidth: 1250,
              minWidth: "200px",
            }}
          >
            <div
              style={{ display: "flex", flexWrap: "wrap", textAlign: "center" }}
            >
              {!!candidate.linkedin && (
                <a
                  target="_blank"
                  href={candidate.linkedin}
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "#FFF",
                    cursor: "pointer",
                    minWidth: 146,
                    justifyContent: "center",
                    alignItems: "center",

                    borderRadius: 5,
                    background: "#0A66C2",
                    boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                    height: 50,
                    width: 160,
                    marginRight: 10,
                    padding: 10,
                    margin: 10,
                  }}
                  rel="noreferrer"
                >
                  <FaLinkedin style={{ marginRight: 5 }} />
                  LinkedIn
                </a>
              )}
              {!!candidate.curriculum && (
                <a
                  target="_blank"
                  href={candidate.curriculum}
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "#FFF",
                    cursor: "pointer",
                    minWidth: 146,
                    justifyContent: "center",
                    alignItems: "center",

                    borderRadius: 5,
                    background: "#ff2c2c",
                    boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",

                    height: 50,
                    width: 160,
                    marginRight: 10,
                    padding: 10,
                    margin: 10,
                  }}
                  rel="noreferrer"
                >
                  <FaRegFilePdf style={{ marginRight: 5 }} />
                  {languagePage === "PT" ? "Currículo" : "Professional resume"}
                </a>
              )}
            </div>
            <div
              style={{
                cursor: "pointer",
                borderRadius: 5,
                background: "#8D171A",
                boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                height: 50,
                width: 160,
                marginRight: 10,
                padding: 10,
                margin: 10,
                textAlign: "center"
              }}
            >
              {languagePage === "PT" ? "Remover" : "Remove"}
            </div>
          </div>


        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          overflowX: "auto",
          marginTop: 10,
          paddingTop: 10,
          fontFamily: "Gotham-Bold",
          justifyContent: 'center'
        }}
      >
        <ContainerTag isRespondida={!!soft}>
          <TitleTag isRespondida={!!soft}>
            <ContainerIconTag isRespondida={!!soft}>
              <FaFeatherAlt size={30} />
            </ContainerIconTag>
            {languagePage === "PT" ? "Exame Soft Skill" : "Soft Skill Exam"}
          </TitleTag>
          <Button
            onClick={() => (!!soft ? gerarPDF("soft") : {})}
            isRespondida={!!soft}
          >
            <MdLibraryBooks style={{ marginRight: 10 }} />
            {languagePage === "PT" ? "Ver relatório" : "View report"}
          </Button>
        </ContainerTag>
        <ContainerTag isRespondida={!!soft && !!hard}>
          <TitleTag isRespondida={!!soft && !!hard}>
            <ContainerIconTag isRespondida={!!soft && !!hard}>
              <FaFeatherAlt size={30} />
            </ContainerIconTag>
            {languagePage === "PT"
              ? "Integração Soft + Hard"
              : "Soft + Hard Integration"}
          </TitleTag>
          <Button
            onClick={() => (!!soft && !!hard ? gerarPDF("integrado") : {})}
            isRespondida={!!soft && !!hard}
          >
            <MdLibraryBooks style={{ marginRight: 10 }} />
            {languagePage === "PT" ? "Ver relatório" : "View report"}
          </Button>
        </ContainerTag>
        {tags.length > 0 &&
          tags.map((tag) => {
            if (tag.questions.length > 0)
              return (
                <ContainerTag isRespondida={tag.answers.length > 0}>
                  <TitleTag isRespondida={tag.answers.length > 0}>
                    <ContainerIconTag isRespondida={tag.answers.length > 0}>
                      <GoGear size={30} />
                    </ContainerIconTag>
                    {tag.name}
                  </TitleTag>
                  <Button
                    onClick={() =>
                      tag.answers.length > 0
                        ? gerarPDF("especifico", tag.id)
                        : {}
                    }
                    isRespondida={tag.answers.length > 0}
                  >
                    <MdLibraryBooks style={{ marginRight: 10 }} />
                    {languagePage === "PT" ? "Ver relatório" : "View report"}
                  </Button>
                </ContainerTag>
              );
          })}
      </div>
    </ConEmailTel>
  );
};
