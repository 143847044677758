import React from "react";
import { useGlobal } from "../../hooks/global";

import {
  TryContainer,
  Image,
  ImageContainer,
  DescriptionOne,
  DescriptionTwo,
  TextContainer,
  SectionTitle,
  SectionTitleContainer,
} from "./styles";

const Try: React.FC = () => {
  const { setLanguagePage, languagePage } = useGlobal();
  return (
    <TryContainer>
      <TextContainer>
        <SectionTitleContainer>
          <a
              href="https://api.whatsapp.com/send?phone=557199748068"
              style={{ textDecoration: "none", color: "white" }}
            >
          <SectionTitle>
            {languagePage === "PT" ? "Experimente" : "Test it"}
          </SectionTitle>
          </a>
        </SectionTitleContainer>
        <DescriptionOne>
          {languagePage === "PT"
            ? "Estamos em parceria com consultores das diversas áreas em Pensamento Sistêmico, ESG, Economia Circular, Modelos Circulares. E profissionais que associam a sua área específica à Inovação Circular. Doutoranda em Psicologia no contexto da Economia Circular, Celene Brito definiu 03 tipos de Mentalidade de 'Linear ao Circular/Sistêmico'. O método viabiliza a um diagnóstico sistêmico fundamental para as organizações que querem atuar em sistemas disruptivos, sistêmicos e complexos. Na próxima página apresentamos os nossos planos para a sua assinatura. Ou entre em contado para solicitar um teste."
            : "We are in partnership with consultants from different areas in Systems Thinking, ESG, Circular Economy, Circular Models. And professionals who associate their specific area with Circular Innovation. Doctoral student in Psychology in the context of Circular Economy, Celene Brito defined 03 types of Mentality from 'Linear to Circular/Systemic'. The method enables a fundamental systemic diagnosis for organizations that want to work in disruptive, systemic and complex systems. On the next page we present our plans for your subscription. Or contact us to request a test."}
        </DescriptionOne>
        {/* <DescriptionOne>
          {languagePage === "PT"
            ? "A sua empresa vai ganhar em tempo e recursos ao realizar uma avaliação sistêmica dos seus líderes e colaboradores, como também dos parceiros e stakeholders. A partir do diagnóstico da MindSet Sistêmica pode-se traçar o melhor design para os projetos de ESG, Economia Circular e Modelos Circulares e agilizar a sua inovação."
            : "Your company will save time and resources by carrying out a systemic assessment of your leaders and employees, as well as partners and stakeholders. From the Systemic MindSet diagnosis, you can outline the best design for ESG, Circular Economy projects and Circular Models. "}
        </DescriptionOne>
        <DescriptionTwo>
          {languagePage === "PT"
            ? "Estamos em parceria com consultores das diversas áreas em Pensamento Sistêmico, ESG, Economia Circular, Modelos Circulares. E profissionais que associam a sua área específica à Inovação Circular. Doutoranda em Psicologia no contexto da Economia Circular, Celene Brito definiu 03 tipos de Mentalidade de 'Linear ao Circular/Sistêmico'. O método viabiliza a um diagnóstico sistêmico fundamental para as organizações que querem atuar em sistemas disruptivos, sistêmicos e complexos. Na próxima página apresentamos os nossos planos para a sua assinatura. Ou entre em contado para solicitar um teste."
            : "We are in partnership with consultants from different areas in Systems Thinking, ESG, Circular Economy, Circular Models. And professionals who associate their specific area with Circular Innovation. Doctoral student in Psychology in the context of Circular Economy, Celene Brito defined 03 types of Mentality from 'Linear to Circular/Systemic'. The method enables a fundamental systemic diagnosis for organizations that want to work in disruptive, systemic and complex systems. On the next page we present our plans for your subscription. Or contact us to request a test."}
        </DescriptionTwo> */}
      </TextContainer>
      {window.matchMedia("(min-width:769px)").matches && (
        <ImageContainer>
          <Image
            src="https://res.cloudinary.com/dkqcb920e/image/upload/v1657559352/3_-try-section-ggxfgc.webp"
            alt="Experimente"
          />
        </ImageContainer>
      )}
    </TryContainer>
  );
};

export default Try;
