import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputRefProps } from "../../../components/Input";
import InputPerfil from "../../../components/InputPerfil";
import SelectFly from "../../../components/SelectFly";
import { Upload } from "../../../components/Upload";
import { useAuth } from "../../../hooks/auth";
import { useGlobal } from "../../../hooks/global";
import { fileProps } from "../../../interfaces/fileUploadInterface";
import ModalAlteraSenha from "../../../components/Modals/AlteraSenha";
import api from "../../../services/api";
import { findPosByKey } from "../../../services/generalServices";
import {
  DeleteContentBackward,
  IsNumber,
} from "../../../services/inputServices";
import { MaskCNPJ, MaskPhone } from "../../../services/maskServices";
import { uniqueId } from "lodash";
import filesize from "filesize";
import { Modal } from "@material-ui/core";
import { LoadingSale } from "../../../components/Animations/LoadingSale";
import { ConEmailTel, ContainerLoading } from "./style";
import { useHistory } from "react-router-dom";
import { useToast } from "../../../hooks/toast";
import CancelContaPlano from "../../../components/Modals/CancelContaPlano";
import { AiOutlineCloseCircle } from "react-icons/ai";

const numFuncOptions = ["0 - 10", "11 - 21", "22 - 50", "51 - 100", "100+"];
const portOptions = ["Pequeno Porte", "Médio Porte", "Grande Porte", "Startup"];
const portOptionsTra = ["Small Size", "Medium Size", "Large", "Startup"];

export const Perfil: React.FC = () => {
  const { user, setUser, signOut } = useAuth();
  const { push } = useHistory();
  const { addToast } = useToast();
  const inputNameRef = useRef<InputRefProps>(null);
  const [modal, setModal] = useState<boolean>(false);
  const inputResponsalvel = useRef<InputRefProps>(null); //Mudei aqui
  const [openModalAlteraSenha, setOpenModalAlteraSenha] = useState(false);
  const inputEmailRef = useRef<InputRefProps>(null);
  const inputCityRef = useRef<InputRefProps>(null);
  const inputStateRef = useRef<InputRefProps>(null);
  const inputCountryRef = useRef<InputRefProps>(null);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<string>(user.phone);
  const [phoneString, setPhoneString] = useState<string>(MaskPhone(user.phone));
  const [cnpjNumber, setCnpjNumber] = useState<string>(user.cnpj);
  const [cnpjString, setCnpjString] = useState<string>(MaskCNPJ(user.cnpj));
  const { setAtualPageGlobal, languagePage } = useGlobal();
  const [posNumFunc, setPosNumFunc] = useState(
    findPosByKey(user.employeesRange, numFuncOptions) !== -1
      ? findPosByKey(user.employeesRange, numFuncOptions)
      : 0
  );
  const [posPorte, setPosPorte] = useState(
    findPosByKey(
      user.companySize,
      languagePage === "PT" ? portOptions : portOptionsTra
    ) !== -1
      ? findPosByKey(
        user.companySize,
        languagePage === "PT" ? portOptions : portOptionsTra
      )
      : 0
  );
  // const [portOptionsTra, setPortOptionsTra] = useState(findPosByKey(user.companySize, portOptionsTra) !== -1 ? findPosByKey(user.companySize, portOptionsTra) : 0)

  const [uploadedFiles, setUploadedFiles] = useState<fileProps>(
    user.avatar ? ({ preview: user.avatar } as fileProps) : ({} as fileProps)
  );

  useEffect(() => {
    setAtualPageGlobal("/company/perfil");
  }, []);

  const OnChangeInputPhone = useCallback(
    (event) => {
      const char = event.nativeEvent.data;
      const eventType = event.nativeEvent.inputType;
      if (
        eventType === "insertText" &&
        phoneNumber.length < 11 &&
        IsNumber(char)
      ) {
        setPhoneNumber(phoneNumber + char);
        setPhoneString(MaskPhone(phoneNumber + char));
      } else if (eventType === "deleteContentBackward" && phoneNumber) {
        setPhoneNumber(DeleteContentBackward(phoneNumber));
        setPhoneString(MaskPhone(DeleteContentBackward(phoneNumber)));
      }
    },
    [phoneNumber, setPhoneNumber, setPhoneString]
  );

  const OnChangeInputCNPJ = useCallback(
    (event) => {
      const char = event.nativeEvent.data;
      const eventType = event.nativeEvent.inputType;
      if (
        eventType === "insertText" &&
        cnpjNumber.length < 14 &&
        IsNumber(char)
      ) {
        setCnpjNumber(cnpjNumber + char);
        setCnpjString(MaskCNPJ(cnpjNumber + char));
      } else if (eventType === "deleteContentBackward" && cnpjNumber) {
        setCnpjNumber(DeleteContentBackward(cnpjNumber));
        setCnpjString(MaskCNPJ(DeleteContentBackward(cnpjNumber)));
      }
    },
    [cnpjNumber, setCnpjNumber, setCnpjString]
  );

  const clickSave = useCallback(async () => {
    setLoading(true);
    const name = inputNameRef.current?.getInputValue();
    const responsible = inputResponsalvel.current?.getInputValue(); //Mudei aqui
    const email = inputEmailRef.current?.getInputValue();
    const phone = phoneNumber;
    const companySize = portOptions[posPorte];
    const employeesRange = numFuncOptions[posNumFunc];
    const cnpj = cnpjNumber;
    const city = inputCityRef.current?.getInputValue();
    const state = inputStateRef.current?.getInputValue();
    const country = inputCountryRef.current?.getInputValue();
    setTimeout(async () => {
      if (uploadedFiles.file) {
        const data = new FormData();
        data.append("avatar", uploadedFiles.file, uploadedFiles.name);
        await api.patch("/avatar", data);
      }
      const newUser = await api.put(`/company/${user.id}`, {
        name,
        responsible,
        email,
        phone,
        companySize,
        employeesRange,
        cnpj,
        city,
        state,
        country,
      });

      setUser(newUser.data);
      localStorage.setItem("@flashPlayHR:user", JSON.stringify(newUser.data));
      setLoading(false);
    }, 200);
  }, [
    inputNameRef,
    inputResponsalvel,
    uploadedFiles,
    inputEmailRef,
    user,
    posPorte,
    posNumFunc,
    inputCityRef,
    inputStateRef,
    inputCountryRef,
    phoneNumber,
    cnpjNumber,
  ]);

  const cancelAccountOrPlan = useCallback(async () => {
    if (mode === "account") {
      const res = await api.post("/company/delete-account", { id: user.id });

      const { deleted, error } = res.data;

      if (deleted) {
        addToast({
          type: "success",
          icon: "info",
          title: languagePage === "PT" ?"Sua conta foi excluida":"Your account has been deleted",
          description: languagePage === "PT" ?"Foi um prazer ter você na nossa plataforma.":"It was a pleasure having you on our platform.",
        });
        signOut();
        push("/#");
      } else {
        addToast({
          type: "error",
          icon: "alert",
          title: languagePage === "PT" ?"Aviso":"Notice",
          description: error,
        });
      }
    } else {
      const res = await api.post("/company/cancel-subscription");
      const { status } = res.data;

      if (status === "canceled") {
        addToast({
          type: "info",
          icon: "info",
          title: languagePage === "PT" ?"Seu plano foi cancelado!":"Your plan has been cancelled!",
          description:
          languagePage === "PT" ?"Para ter acesso completo a plataforma precisa assinar um plano.":"To have full access to the platform you need to subscribe to a plan.",
        });
      }
    }
  }, [mode]);
  const handleUpload = useCallback(
    (files: any) => {
      const newUploadedFiles = {
        file: files[0],
        id: uniqueId(),
        name: files[0].name,
        readableSize: filesize(files[0].size),
        preview: URL.createObjectURL(files[0]),
        progress: 0,
        uploaded: false,
        error: false,
        url: null,
      };
      setUploadedFiles(newUploadedFiles);
    },
    [uploadedFiles]
  );

  const SenhaAlterada = useCallback((data) => {
    setOpenModalAlteraSenha(false);
    setLoading(true);
    setTimeout(async () => {
      try {
        await api.post("/editPassword", data);
      } catch (e) { }
      setLoading(false);
    }, 200);
  }, []);

  return (
    <ConEmailTel style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <CancelContaPlano

          open={modal}
          model={mode}
          onClose={() => setModal(false)}
          onExclude={cancelAccountOrPlan}

        />
      </div>

      <div style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // background: "#134480",
            background: "#fff",
            borderRadius: 10,
            padding: 20,

            margin: "auto",

            width: "100%", maxWidth: 1200,
            minWidth: "150px",
            justifyContent: "center",
            marginBottom: 10,



          }}
        >
          <div style={{ padding: 5 }}>
            <AiOutlineCloseCircle
              onClick={() => {
                setMode("account");
                setModal(true);
              }}
              style={{
                float: "right",
                cursor: "pointer",
                marginRight: "20px",
                marginBottom: 10,
                marginTop: "-10px",
                color: "#fff",

              }}
            ></AiOutlineCloseCircle>
          </div>
          <div className="flexW" style={{ display: "flex", flexDirection: "row", }}>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                background: "#FFF",
                boxShadow: " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",

                minWidth: 166,
                minHeight: 156,

                maxWidth: 166,
                maxHeight: 166,
              }}
            >
              <Upload file={uploadedFiles} onUpload={handleUpload} />
            </div>
            <div
              style={{
                display: "flex", flexWrap: "wrap", flexDirection: "column", width: "100%", maxWidth: 1100,
                minWidth: "200px", justifyContent: "center", alignItems: "center", textAlign: "center"
              }}
            >
              <div className="margemTop flexWOne" style={{ display: "flex", flexDirection: "row", width: "100%", maxWidth: 1000, minWidth: 200, }}>
                <div style={{
                  width: "95%",
                  maxWidth: "500px",
                  minWidth: "150px", margin: 10
                }}>
                  <InputPerfil
                    styleInput={{
                      fontFamily: "Gotham-Medium",
                      color: "#000",
                      background: "#FFF",
                      fontSize: 16,
                    }}
                    styleContainer={{
                      background: "#FFF",


                      marginTop: 0,


                    }}
                    placeholder={languagePage=== "PT" ? "Nome da Empresa":"Company Name"}
                    label="nome"
                    ref={inputNameRef}
                    defaultValue={user.name}
                  />
                </div>
                <div className="margemTop" style={{
                  width: "95%",
                  maxWidth: "500px",
                  minWidth: "150px", margin: 10
                }}>
                  <InputPerfil
                    styleInput={{
                      fontFamily: "Gotham-Medium",
                      color: "#000",
                      background: "#FFF",
                      fontSize: 16,
                    }}
                    styleContainer={{
                      background: "#FFF",


                      marginTop: 0,

                    }}
                    placeholder={languagePage === "PT" ?"Nome do Responsável":"Responsible Name"}
                    label="responsible"
                    ref={inputResponsalvel}
                    defaultValue={user.responsible}
                  />
                </div>
              </div>
              <div className="flexWOne" style={{ display: "flex", flexDirection: "row", width: "100%", maxWidth: 1000, minWidth: 200, }}>
                <div style={{
                  width: "95%",
                  maxWidth: "500px",
                  minWidth: "150px", margin: 10
                }}>
                  <InputPerfil
                    styleInput={{
                      fontFamily: "Gotham-Medium",
                      color: "#000",
                      background: "#FFF",
                      fontSize: 16,
                    }}
                    styleContainer={{
                      background: "#FFF",



                    }}
                    placeholder="Email"
                    label="email"
                    ref={inputEmailRef}
                    defaultValue={user.email}
                  />
                </div>
                <div style={{
                  width: "95%",
                  maxWidth: "500px",
                  minWidth: "150px", margin: 10
                }}>
                  <InputPerfil
                    styleInput={{
                      fontFamily: "Gotham-Medium",
                      color: "#000",
                      background: "#FFF",
                      fontSize: 16,
                    }}
                    styleContainer={{
                      background: "#FFF",



                    }}
                    placeholder="Telefone"
                    onChange={OnChangeInputPhone}
                    label="telefone"
                    value={phoneString}
                  />
                </div>
              </div>
              <div className="media-277"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",

                  maxWidth: 1000,
                  minWidth: 200,
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",

                }}>
                <div style={{
                  margin: 10,
                  width: "100%",
                  minWidth: 50,
                  maxWidth: 310,
                }}>
                  <InputPerfil
                    styleInput={{
                      fontFamily: "Gotham-Medium",
                      color: "#000",
                      background: "#FFF",
                      fontSize: 16,
                    }}
                    styleContainer={{
                      background: "#FFF",

                    }}
                    placeholder="Cidade"
                    label="cidade"
                    ref={inputCityRef}
                    defaultValue={user.city}
                  />
                </div>
                <div style={{
                  margin: 10,
                  width: "100%",
                  minWidth: 50,
                  maxWidth: 310,
                }}>
                  <InputPerfil
                    styleInput={{
                      fontFamily: "Gotham-Medium",
                      color: "#000",
                      background: "#FFF",
                      fontSize: 16,
                    }}
                    styleContainer={{
                      background: "#FFF",

                    }}
                    placeholder="Estado"
                    label="estado"
                    ref={inputStateRef}
                    defaultValue={user.state}
                  />
                </div>

                <div style={{
                  margin: 10,
                  width: "100%",
                  minWidth: 50,
                  maxWidth: 310,
                }}>
                  <InputPerfil
                    styleInput={{
                      fontFamily: "Gotham-Medium",
                      color: "#000",
                      background: "#FFF",
                      fontSize: 16,
                    }}
                    styleContainer={{
                      background: "#FFF",

                    }}
                    placeholder="País"
                    label="pais"
                    ref={inputCountryRef}
                    defaultValue={user.country}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* gggggggggggg */}
          <div className="media-277" style={{ display: "flex", flexDirection: "row", width: "100%", maxWidth: 1200, minWidth: 200, }}>
            <div style={{
              margin: 10,
              width: "100%",
              minWidth: 50,
              maxWidth: 370,
            }}>
              <InputPerfil
                styleInput={{
                  fontFamily: "Gotham-Medium",
                  color: "#000",
                  background: "#FFF",
                  fontSize: 16,
                }}
                styleContainer={{
                  background: "#FFF",
                }}
                placeholder="CNPJ"
                label="cnpj"
                value={cnpjString}
                onChange={OnChangeInputCNPJ}
              />
            </div>
            <div style={{
              margin: 10,
              width: "100%",
              minWidth: 50,
              maxWidth: 370,
            }}>
              <SelectFly
                label="rangeFunc"
                styleContainer={{

                  fontFamily: "Gotham-Medium",
                  color: "#000",
                  height: 44,
                  width: "100%",
                  minWidth: 50,
                  maxWidth: 370,

                }}
                styleSelect={{ background: "#FFF", minHeight: "100%" }}
                onChangeOption={(num: number) => setPosNumFunc(num)}
                posOption={posNumFunc}
                options={numFuncOptions}
                className="Select-Fly-NumFunc"
              />
            </div>
            <div style={{
              margin: 10,
              width: "100%",
              minWidth: 50,
              maxWidth: 370,
            }}>
              <SelectFly
                label="companySize"
                styleContainer={{
                  width: "100%",
                  minWidth: 50,
                  maxWidth: 370,
                  fontFamily: "Gotham-Medium",
                  color: "#000",
                  height: 44
                }}
                styleSelect={{ background: "#FFF", minHeight: "100%" }}
                onChangeOption={(num: number) => setPosPorte(num)}
                posOption={posPorte}
                options={languagePage === "PT" ? portOptions : portOptionsTra}
                className="Select-Fly-Port"
              />
            </div>
          </div>
          <div

            className="media-277"
            style={{

              flexDirection: "row",
              fontFamily: "Gotham-Medium",
              color: "#FFF",
              marginRight: 20,
              textAlign: "center", width: "100%", maxWidth: 1200, minWidth: 200, display: "flex", justifyContent: "flex-end"
            }}>

            <div
              onClick={() => setOpenModalAlteraSenha(true)}
              style={{
                cursor: "pointer",
                padding: 10,
                borderRadius: 5,
                background: "#f27823",
                boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",

                width: "200px",
                margin: 10,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {languagePage === "PT" ? "Alterar Senha" : "Change Password"}
            </div>
            <div
              onClick={clickSave}
              style={{
                cursor: "pointer",
                padding: 10,
                borderRadius: 5,
                background: "#f27823",
                boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                width: "200px",

                margin: 10,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {languagePage === "PT" ? "Salvar Alterações" : "Save editions"}
            </div>
          </div>
         
        </div>
      </div>
      {loading && (
        <Modal open={loading}>
          <ContainerLoading>
            <LoadingSale />
          </ContainerLoading>
        </Modal>
      )}
      {openModalAlteraSenha && (
        <ModalAlteraSenha
          open={openModalAlteraSenha}
          onClose={() => setOpenModalAlteraSenha(false)}
          onSave={SenhaAlterada}
        ></ModalAlteraSenha>
      )}
    </ConEmailTel>
  );
};
