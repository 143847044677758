import React, { useCallback, useRef, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { ErrorProps, Verify } from "../../../services/schemaServices";
import InputLabel, { InputRefProps } from "../../InputLabel";
import {
  Container,
  Header,
  IconDelete,
  ButtonEnd,
  ContainerHeader,
  Formulario,
  ContainerItemForm,
  ContainerBottom,
} from "./style";
import { UserProps } from "../../../interfaces/userInterface";

interface NovaSenhaRootProps {
  open: boolean;
  data: UserProps;
  onClose: Function;
  onSave: Function;
}

const optionsPlan = [
  "Nenhum",
  "Básico",
  "Intermediário",
  "Avançado",
  "Personalizado",
];

export const NovaSenhaRoot: React.FC<NovaSenhaRootProps> = ({
  open,
  onClose,
  onSave,
  data,
}) => {
  const inputNameRef = useRef<InputRefProps>(null);

  const [errors, setErrors] = useState<ErrorProps[]>([] as ErrorProps[]);

  const Save = useCallback(() => {
    const newPassword = inputNameRef.current?.getInputValue();
    const errors = Verify([
      {
        name: "newPassword",
        value: newPassword,
        required: { message: "Campo obrigatório!", value: true },
      },
    ]);
    setErrors(errors);
    if (errors.length === 0) onSave({ userId: data.id, newPassword });
  }, [inputNameRef]);

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <ContainerHeader>
          <Header>
            Alterar Senha
            <IconDelete onClick={() => onClose()} color="#C4C4C4" />
          </Header>
        </ContainerHeader>

        <Formulario>
          <ContainerItemForm>
            <InputLabel
              errors={errors}
              label="Nome"
              attr="name"
              value={data.name}
              disabled={true}
              id="InputNameCompany"
              containerStyle={{ width: "100%" }}
              inputProps={{
                style: {
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: 18,
                },
              }}
            />
          </ContainerItemForm>
          <ContainerItemForm>
            <InputLabel
              errors={errors}
              label="Email"
              attr="email"
              disabled={true}
              value={data.email}
              id="InputEmailCompany"
              containerStyle={{ width: "100%" }}
              inputProps={{
                style: {
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: 18,
                },
              }}
            />
          </ContainerItemForm>
          <ContainerItemForm>
            <InputLabel
              errors={errors}
              label="Nova Senha"
              ref={inputNameRef}
              attr="newPassword"
              id="InputnewPasswordCompany"
              containerStyle={{ width: "100%" }}
              inputProps={{
                style: {
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: 18,
                },
              }}
            />
          </ContainerItemForm>
        </Formulario>

        <ContainerBottom>
          <ButtonEnd onClick={() => onClose()} style={{ color: "#252323" }}>
            {" "}
            Cancelar{" "}
          </ButtonEnd>
          <ButtonEnd
            onClick={() => Save()}
            style={{ color: "#101A36", cursor: "pointer" }}
          >
            {" "}
            Salvar
          </ButtonEnd>
        </ContainerBottom>
      </Container>
    </Modal>
  );
};

export default NovaSenhaRoot;
