import styled, { keyframes } from "styled-components";
import { animated } from "react-spring";
import { Interface } from "readline";

const RotateAnimation = keyframes`  
  from { transform: rotate(0deg); }
  to { transform: rotate(-360deg); }
`;

export const DisplayFlex = styled.div`
  display: flex;
`
export const JustifyContentFlex = styled(DisplayFlex)`
  justify-content: center;
`
export const CursosPointer = styled.div`
  cursor: pointer;
`
export const Container = styled.div`
  background-image: url("https://res.cloudinary.com/hkkzuevm3/image/upload/v1648757923/Assets-FlashPlayHr-webp/backgroundSoft_xaddse1_gso1lu.webp");
  background-size: cover;
  background-position: center;
  /* height:calc(90vh - 30px); */
  width: 100%;
  max-width: 1000px;
  min-width: 200px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0px;
  box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.3);

  
`;
export const ContainerMain = styled(DisplayFlex)`
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: center;
  margin: 20px;
  margin-top: 0px;
  font-family: Gotham-Medium;
`
export const ContainerHeader = styled(JustifyContentFlex)`
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  min-width: 200px;
  font-size: 3.5vh;
`
export const BoxBackTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #134480;
  font-size: 3.5vh;

  text-align: center;

  @media (max-width: 640px) {
    margin-top: 10px;
    font-size: 1.6vh;

    div {
      top: -15px;
    }
  }
`;

export const BotaoVoltar = styled(DisplayFlex)`
  font-size: 2.15vh;
  color: #6b6b6b;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  min-width: 70px;
  border-radius: 50px;
  border: 1px solid #6b6b6b;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 8px;
  position: absolute;
  left: 0;
  transform: translateY(-50%);

  @media (max-width: 768px) {
    font-size: 1.6vh;
    max-width: 120px;
    padding: 6px;
  }

  @media (max-width: 480px) {
    font-size: 1.3vh;
    max-width: 100px;
    padding: 4px;
  }
  
`

export const TituloBox = styled(DisplayFlex)`
  
  width: 100%;
  max-width: 600px;
  min-width:200px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  
`
export const IntroducaoEQuestao = styled(JustifyContentFlex)`
  width: 100%;
  align-items: center;
`

export const Animated = styled(animated.div)`
  width: 85%;
  margin: auto;
  justify-content: center;
  align-items: center;
`
export const AnimatedContainer = styled(DisplayFlex)`
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: space-between;
  padding: 20px;
`

export const IntroducaoLogo = styled(DisplayFlex)`
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: space-between;
  padding: 20px;
`
export const ContainerrLogo = styled(DisplayFlex)`
  flex-direction: column;
  -webkit-flex-direction: row;
  color: #134480;
  align-items: center;
`
export const Logo = styled(DisplayFlex)`
  height: 70px;
  width: 70px;
  margin-left: -30px;
`
export const LogoLegenda = styled(Logo)`
  margin-left: -40px;
  font-size:14px;
`
export const IntroducaoUm = styled(DisplayFlex)`
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const IntroducaoUmTextos = styled(DisplayFlex)`
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  font-size: 3.2vh;
  color: #FFF;
  font-family: "Gotham-Bold";
`
export const TextoBranco = styled.div`
  color: #FFF;
`
export const TextoAzul = styled.div`
  color: #134480;
 `

export const BotaoIniciarSoft = styled(JustifyContentFlex)`
display: flex;
margin-right: 10px;
flex-direction: row;
-webkit-flex-direction: row;
align-items: center;
padding: 10px;
box-shadow:  0.125rem 0.125rem 0.5rem rgba(0,0,0,1);
background: #134480;
border-radius: 50%;
font-size: 2.15vh;
cursor: pointer;
`

export const ContainerGenerico = styled(DisplayFlex)`
  background: #fff;
  /* height:calc(90vh - 30px); */
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 50px;
  flex-direction: column;
  padding: 20px;
  padding-top: 0px;
  box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.3);

  .flex-wrap-mobile {
    display: flex;
    flex-wrap: nowrap;
    
  }
  
  @media (max-width:375px){
    .flex-wrap-mobile{
      flex-wrap: wrap;
      
    }
  }
  .circulo-quadrado-277{
    height: 250px;
    width: 250px;
    background: #134480;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 100%;
    border: 2px solid #000;
  }
  .quadrado-277{
    width: 176px;
    height: 176px;
    display: flex;
    background: #134480;
    margin-bottom: -2px;
    justify-content: center;
    align-items: center;
    border: 2px solid #000;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    margin: auto;
  }
  .imagem-277{
    height: 200px;
    width: 200px;
    display: flex;
    -webkit-align-items: center;
    padding: 10;
    margin: auto;
   
  }
  .imagem-277 img{
    width: 175px;
    height: 175px;
    margin-top: -30px;
  }
  @media (max-width:360px){
    .circulo-quadrado-277{
      height: 250px;
      width: 250px;
      background: #134480;
      border-radius: 250px;
      padding:10px;
    }
    .quadrado-277{
      width: 165px;
      height: 165px;
    }
    .imagem-277{
      height: 150px;
      width: 150px;
    }
    .imagem-277 img{
      width: 160px;
      height: 160px;
      margin-left:-5px;
      margin-top: -2px;
    }
  }
  @media (max-width:310px){
    .circulo-quadrado-277{
      height: 220px;
      width: 200px;
      background: #134480;
      border-radius: 330px;
      padding:10px;
      margin-left:-30px;
    }
    .quadrado-277{
      width: 150px;
      height: 150px;
    }
    .imagem-277{
      height: 140px;
      width: 140px;
      margin-top: 5px;
    }
    .imagem-277 img{
      width: 150px;
      height: 150px;
      margin-left:-5px;
    }
  }
`;

export const AnimatedContainerUm = styled(DisplayFlex)`

  flex-direction: column;
  -webkit-flex-direction: column;
  color: #134480;
  align-items: center;
  
`
export const AnimatedContainerDois = styled(AnimatedContainerUm)`
  flex-direction: row;
  -webkit-flex-direction: row;
  color: none;
`
export const AnimatedContainerDoisTexto = styled(AnimatedContainerUm)`

  font-size: 2.7vh;
  color: #136EAC;
`
export const LayoutQuestao = styled(DisplayFlex)`
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  color: #FFF;

`
export const Setas = styled(JustifyContentFlex)`
  flex-direction: row;
  -webkit-flex-direction: row;
  -webkit-justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow:  0.125rem 0.125rem 0.5rem rgba(0,0,0,1);
  background: #134480;
  border-radius: 50%;
  font-size: 2.15vh;
  cursor: pointer;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const LayoutInstrucoes = styled(JustifyContentFlex)`
  flex-direction: column;
  -webkit-flex-direction: column;
  -webkit-justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  min-width: 150px;
`

export const LayoutInstrucoesImagem = styled(JustifyContentFlex)`
  height: 30vh;
  width: 100%;
  max-width: 500px;
  min-width: 150px;
  
  img{ 
    max-width: 500px;
    min-width: 100px;

  }
`
export const LayoutInstrucoesTexto = styled(JustifyContentFlex)`
  display: block;
  color: #133770;
  font-family: "Gotham-Bold";
  align-items: center;
  width: 100%;
  max-width: 1000px;
  min-width: 100px;
  text-align: justify;
  padding: 10px;
  div{
    text-align: center;
  }
  
`
export const SetasTwo = styled(Setas)`
  display: none;
  width: 50px;
  height: 50px;
  padding: 20px;
  margin: 10px;
  margin-right: 10px;

  @media (max-width: 480px) {
    display: flex;
  }
`;

export const QuestaoSoftSkill = styled(DisplayFlex)`
  flex-direction: column;
  padding-bottom: 0px;
  padding-top: 0px;
  font-family: "Gotham-Bold";
  width: 100%;
  max-width: 1000px;
  min-width: 200px;

`

export const LayoutEnuciado = styled(DisplayFlex)`
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  min-width: 200px;
`

export const Enuciado = styled(DisplayFlex)`
  display: flex;
  flex-direction: column;
  font-size: 5.4vh;
  width: 50%;
  max-width: 1000px;
  min-width: 200px;
`
interface ImagemQuestionProps {
  item?: string;
}
export const ImganQuestao = styled(JustifyContentFlex) <ImagemQuestionProps>`

  background: ${(props: any) =>
    props.item === "girar" ? "#fff" : "#134480"};
  height: ${(props: any) =>
    props.item === "girar" ? "auto" :
      props.item === "circulo" ? "31vh" : "24vh"};
  width: ${(props: any) =>
    props.item === "girar" ? "250px" :
      props.item === "circulo" ? "31vh" : "24vh"};
  align-items: center;
  -webkit-align-items: center;
  padding: ${(props: any) =>
    props.item === "girar" ? "5px" : "6vh"};
  margin: auto;
`

export const JustifyContentFWRSB = styled(DisplayFlex)`
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  background-color:"red";
  width: 100%;

  max-width: 1000px;
  min-width: 200px;
`
export const Pergunta = styled(DisplayFlex)`
  flex-direction: column;
  font-size: 3.5vh;
  margin:10px;
`
export const TextoPergunta = styled(DisplayFlex)`
  color: #000;
 
  flex-direction: row;
  width: 100%;
  margin:10px;
  max-width: 1000px;
  min-width: 200px;
`
export const Alternativas = styled(JustifyContentFlex)`
  flex-direction: row;
  align-items: center;
  color: #000;
  font-family: "Gotham-Bold";
  width: 50%;
  max-width: 850px;
  min-width: 200px;

`
export const AlternativasParteUm = styled(DisplayFlex)`
  margin: auto;
  margin-top: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  width:400px;

`
export const AlternativasRespondidas = styled(DisplayFlex)`
 width:160px;
  flex-direction: row;
  align-items: center;
`
export const ContainerRotate = styled.div`
  display: flex;
  background: rgb(19, 68, 128) none repeat scroll 0% 0%;
  height: 30vh;
  width: 30vh;
  justify-content: center;
  
  padding: 20px;
  border-radius: 20px;
  animation: ${RotateAnimation} linear infinite 0.05s;
`;
