import React, { useCallback, useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { selectionInterface } from "../../../interfaces/selectionInterface";
import api from "../../../services/api";
import { useHistory } from "react-router";
import { useGlobal } from "../../../hooks/global";
import { CircularProgress } from "@material-ui/core";
import { useAuth } from "../../../hooks/auth";
import { FaFileSignature, FaUser } from "react-icons/fa";
import { useToast } from "../../../hooks/toast";
import {
  CardContainer,
  Container,
  Pagination,
  PaginationButton,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardBodyButton,
  FooterButton,
} from "./style";
import DecisionModal from "../../../components/ConfirmModal/decisionModal";

export const TodasSelecoes: React.FC = () => {
  const [selections, setSelections] = useState<selectionInterface[]>(
    [] as selectionInterface[]
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [atualPage, setAtualPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const { setAtualPageGlobal, languagePage } = useGlobal();
  const [loading, setLoading] = useState<boolean>(true);
  const [arrayPage, setArrayPage] = useState<number[]>([]);
  const { user } = useAuth();
  const { addToast } = useToast();
  const { push } = useHistory();
  const [itemId, setItemId] = useState(null);

  const searchSelections = useCallback(async () => {
    try {
      setSelections([]);
      setArrayPage([]);
      setNumberOfPages(1);
      const resu = await api.get(
        `/company/selections?limit=10&page=${atualPage}`
      );

      setSelections(resu.data.rows);

      for (let i = 0; i < Math.ceil(resu.data.count / 10); i++) {
        setArrayPage((state) => [...state, i + 1]);
      }
    } catch (err: any) {
      if (err.response.data.message === "PLAN_ERROR") {
        const typeError = err.response.data.path;

        if (user.preference !== "consultancy") {
          if (typeError === "noPlan") {
            push("/company/planos");
            addToast({
              title:
                languagePage === "PT"
                  ? "Usuário não possui um plano"
                  : "User does not have a plan",
              description: languagePage === "PT" ? "Escolha um plano" : "Choose a plan",
              icon: "alert",
              type: "error",
            });
          } else if (typeError === "statusPlan") {
            addToast({
              title: "Seu plano está desativado",
              description: "Para ativar, por favor efetuar o pagamento.",
              icon: "alert",
              type: "error",
            });
          } else if (typeError === "expiresIn") {
            push("/company/planos");
            addToast({
              title: "Seu plano expirou",
              description: "Renove seu plano",
              icon: "alert",
              type: "error",
            });
          }
        }
      }
    }
    setLoading(false);
  }, [atualPage]);

  useEffect(() => {
    setAtualPageGlobal("/company/selecoes");
    const timer = setTimeout(searchSelections, 200);
    return () => {
      clearTimeout(timer);
      setSelections([]);
      setArrayPage([]);
    };
  }, [atualPage, searchSelections, setAtualPageGlobal]);

  const removeSelection = useCallback(async (selectionId) => {
    setLoading(true);
    setTimeout(async () => {
      const resu = await api.delete(`/company/selection/${selectionId}`);
      searchSelections();
    }, 200);
  }, []);
  const [openModalCodigo, setOpenModalCodigo] = useState<boolean>(false);
  const [selectionId, setSelectionId] = useState<any>();
  const toggleModal = () => setModalOpen(!modalOpen);
  const handleAction = (id) => {
    setItemId(id);
    toggleModal();
  };

  const confirmDelete = (itemId) => {
    removeSelection(itemId);
    toggleModal();
  };

  return (
    <Container>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          <CardContainer>
            {selections.length === 0 && (
              <h5 className="text-not">Nenhuma seleção criada</h5>
            )}
            {selections.length > 0 &&
              selections.map((selection) => (
                <Card key={selection.id}>
                  <CardHeader>
                    <div className="box-icon">
                      {user.avatar && (
                        <img
                          style={{ height: 50 }}
                          src={user.avatar}
                          alt="Avatar"
                        />
                      )}
                      {!user.avatar && <FaUser size={26} />}
                    </div>
                    <h5>{selection.name}</h5>
                  </CardHeader>
                  <CardBody>
                    <CardBodyButton
                      background="#2F80ED"
                      onClick={() =>
                        push(`/company/solicitacoes/${selection.id}`)
                      }
                    >
                      {languagePage === "PT"
                        ? "Candidatos a serem aprovados"
                        : "Candidates to be approved"}
                    </CardBodyButton>
                    <CardBodyButton
                      background="#2360B2"
                      onClick={() =>
                        push(`/company/candidatos/${selection.id}`)
                      }
                    >
                      {languagePage === "PT"
                        ? "Candidatos Aprovados"
                        : "Approved Candidates"}
                    </CardBodyButton>
                    {/* <CardBodyButton
                      background="#2360B2"
                      onClick={() =>
                        push(`/company/candidatos/${selection.id}`)
                      }
                    >
                      {languagePage === "PT"
                        ? "Relatório coletivo"
                        : "Coletive report"}
                    </CardBodyButton> */}
                  </CardBody>
                  <CardFooter>
                    {selection.public ? null : (
                      <h5>Código de acesso: {selection.codeAccess}</h5>
                    )}
                    <div className="box-footer-buttons">
                      <FooterButton
                        background="#083f86"
                        onClick={() =>
                          push({
                            pathname: "/company/editar",
                            state: {
                              selection: selection,
                            },
                          })
                        }
                      >
                        {languagePage === "PT" ? "Editar" : "Edit"}
                      </FooterButton>
                      <FooterButton
                        background="#f0954b"
                        onClick={() => handleAction(selection.id)}
                      >
                        {languagePage === "PT" ? "Excluir" : "Delete"}
                      </FooterButton>
                    </div>
                  </CardFooter>
                </Card>
              ))}
          </CardContainer>
          <Pagination>
            {!!arrayPage &&
              arrayPage.length > 0 &&
              arrayPage.map((value) => (
                <PaginationButton
                  active={atualPage === value}
                  key={value}
                  onClick={() => {
                    setLoading(true);
                    setAtualPage(value);
                  }}
                >
                  {value}
                </PaginationButton>
              ))}
          </Pagination>
          <DecisionModal
            isOpen={modalOpen}
            toggle={toggleModal}
            onConfirm={() => confirmDelete(itemId)}
            languagePage={languagePage}
            message={languagePage === "PT" ? "Tem certeza que deseja excluir esta seleção?" : "Are you sure you want to delete this selection?"}
          />
        </>

      )}
      {languagePage === "PT" ? (
        <Container
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "center",
            color: "#64666b",
            width: "100%",
            maxWidth: "1000px",
            minWidth: "250px",
            marginBottom: 20,
            fontSize: 26,
            fontFamily: "Gotham-Medium",
            marginTop: selections.length === 0 && !loading ? 20 : 0,
          }}
        >
          <div
            style={{
              textAlign: "justify",
              margin: 20,
              width: "50%",
              minWidth: "250px",
            }}
          >
            <p>
              Caro Cliente, <small style={{ color: "#134480" }}>a</small> nossa
              seleção já vem pronta
              <small style={{ color: "#134480" }}>
                {" "}
                para uma avaliação de{" "}
              </small>{" "}
              Soft Skill e Hard Skill para o colaborador/candidato{" "}
              <small style={{ color: "#134480" }}>
                {" "}
                de forma integrada para uma vaga em que as questões circulares e
                sistêmicas terão um{" "}
              </small>
              peso considerável na avaliação.
            </p>
          </div>

          <div
            style={{
              width: "40%",
              minWidth: "250px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img
              style={{ width: 250, margin: "auto" }}
              src={
                "https://res.cloudinary.com/hkkzuevm3/image/upload/v1648676538/Assets-FlashPlayHr-webp/ilustracao1_n6ekbi_uzpgpg.webp"
              }
              alt="Imagem"
            />
          </div>

        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "center",
            color: "#64666b",
            width: "100%",
            maxWidth: "1000px",
            minWidth: "250px",
            marginBottom: 20,
            fontSize: 26,
            marginTop: selections.length === 0 && !loading ? 20 : 0,
          }}
        >
          <div
            style={{
              textAlign: "left",
              margin: 20,
              width: "50%",
              minWidth: "250px",
            }}
          >
            <p>
              Dear Client, <small style={{ color: "#134480" }}>our</small>{" "}
              selection comes ready
              <small style={{ color: "#134480" }}>
                {" "}
                for an assessment of{" "}
              </small>{" "}
              Soft Skill and Hard Skill for the candidate{" "}
              <small style={{ color: "#134480" }}>
                {" "}
                in an integrated way for a position in which the questions for a
                position in which the circular{" "}
              </small>
              and systemic questions will have a considerable weight in the
              evaluation.
            </p>
          </div>

          <div
            style={{
              width: "40%",
              minWidth: "250px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img
              style={{ width: 250, margin: "auto" }}
              src={
                "https://res.cloudinary.com/hkkzuevm3/image/upload/v1648676538/Assets-FlashPlayHr-webp/ilustracao1_n6ekbi_uzpgpg.webp"
              }
              alt="Imagem"
            />
          </div>
          {/*  */}
          <div
            style={{
              width: "40%",
              minWidth: "250px",
              justifyContent: "center",
              textAlign: "center",
              marginTop: 20,
            }}
          >
            <img
              style={{ width: 250, margin: "auto" }}
              src={
                "https://res.cloudinary.com/hkkzuevm3/image/upload/v1649178813/Assets-FlashPlayHr-webp/ilustracao2_yghqpb2_yhzgl4.webp"
              }
              alt="Imagem"
            />
          </div>
          <div
            style={{
              textAlign: "left",
              margin: 20,
              marginBottom: 0,
              width: "50%",
              minWidth: "250px",
            }}
          >
            <p>
              <small style={{ color: "#134480" }}>Soon after, </small>
              we also present a Hard Skill questionnaire
              <small style={{ color: "#134480" }}>
                {" "}
                that will be carried out for those candidates for a{" "}
              </small>
              vacancy in Circular Economy, ESG, Sustainable Digital Innovation
              and Circular Design.
              <small style={{ color: "#134480" }}>
                {" "}
                In other words, our differential is to integrate Soft Skill and
                Hard Skill.
              </small>
            </p>
          </div>
          <div
            style={{
              textAlign: "justify",
              width: "90%",
              margin: 20,
              padding: 10,
              marginTop: -10,
              maxWidth: 900,
              minWidth: "250px",
            }}
          >
            <p>
              We present a questionnaire structure {" "}
              <small style={{ color: "#134480" }}>
                so that Companies can complement the questions with more
                specific data
              </small>{" "}
              for Agile Methodologies, Systemic Models, Communication and
              Digital Marketing and Innovation, ESG and Circular Economy more
              specific. If you prefer, we can also provide
              <small style={{ color: "#134480" }}>
                {" "}
                consultancy for the preparation of a specific assessment and in
                accordance with the peculiarities of your Company.
              </small>
            </p>
          </div>

        </div>

      )}
    </Container>
  );
};
