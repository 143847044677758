import styled from "styled-components";

export const Container = styled.div`
  //margin: 10px;
  color: #fff;
`;

export const CardDetatalhes = styled.div`
  width: 90%;
  padding: 16px;
  border-radius: 20px;
  background-color: #134480;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 16px auto;
`;
export const ContainerDescriptionAndChat = styled.div`
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 16px;

  //min-height: 400px;

  div {
    //width: 100%;
    //max-width: 500px;
    //min-width: 200px;
    /* background: red; */
    //margin: 5px;
  }
`;

export const CardChat = styled.div`
  background: #fff;
  color: #000;
  height: 100%;
  max-height: 600px;
  min-height: 400px;
`;
interface TituloProps {
  color?: string;
}

export const Titulo = styled.div<TituloProps>`
  font-family: "Gotham-Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  text-align: center;

  color: ${(props: any) => (props.color ? props.color : "#134480")};

  border-bottom: 1px solid #000000;
  margin-bottom: 10px;
`;
export const Button = styled.button`
  cursor: pointer;
  margin: 10px;
  background: transparent;
  border: none;

  font-family: "Gotham-Medium";

  color: rgb(100, 102, 107);

  :hover {
    transition: 1s;
    color: #fff;
  }
`;
export const Descricao = styled.div`
  margin: 10px;
  padding: 10px;
  font-family: "Gotham-Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  background: #00213d;

  /* identical to box height */

  color: #ffffff;
`;
export const Label = styled.div`
  margin: 10px;
  font-family: "Gotham-Medium";
  font-weight: normal;
  font-size: 24px;

  /* identical to box height */

  color: #ffffff;
`;

interface ButtonDetalheProps {
  backgroundColor: any;
}
export const ButtonDetakhes = styled.button<ButtonDetalheProps>`
  background-color: ${(props: any) =>
    props.backgroundColor ? props.backgroundColor : ""};

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 150px;
  height: 40px;

  margin: 10px;
  text-align: center;
  border: none;
  font-family: "Gotham-Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;

  text-align: center;

  color: #000000;

  :hover {
    transition: 1s;
    color: #fff;
  }
`;
