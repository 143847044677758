import { Modal } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useHistory } from "react-router";
import { LoadingSale } from "../../components/Animations/LoadingSale";
import InputLogin, { RefInputLoginProps } from "../../components/InputLogin";
import { useForm } from "../../hooks/form";
import { useGlobal } from "../../hooks/global";
import {
  Button,
  ButtonTextBack,
  ButtonTextBackGreat,
  Container,
} from "./styles";
import * as Yup from "yup";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";

const ForgotPassword: React.FC = () => {
  const { setAtualPageGlobal } = useGlobal();
  const { addToast } = useToast();
  const { push } = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const { setType } = useForm();
  const { languagePage } = useGlobal();
  const inputEmailRef = useRef<RefInputLoginProps>(null);
  const inputPasswordRef = useRef<RefInputLoginProps>(null);

  const ChangeSendEmail = useCallback(
    async (event) => {
      event?.preventDefault();

      const errorEmail = await VerifyEmail();

      if (!!!errorEmail) {
        setLoading(true);
        setTimeout(async () => {
          try {
            const email = inputEmailRef.current?.getValue()
              ? inputEmailRef.current?.getValue()
              : "";

            api
              .post("/forgot-password", {
                email: email,
              })
              .then((res) => {
                if (res.status === 200) {
                  setForgotPassword(true);
                  setLoading(false);
                }
              })
              .catch((err) => {
                setLoading(false);
                addToast({
                  title: "Erro",
                  description:
                    languagePage === "PT"
                      ? "E-mail não encontrado"
                      : "Email not found",
                  icon: "alert",
                  type: "error",
                });
              });
          } catch (err: any) {
            setLoading(false);
            setErrorEmail(
              languagePage === "PT" ? "Email inválido" : "Invalid Email"
            );
          }
        }, 200);
      }
    },
    [inputEmailRef, inputPasswordRef]
  );

  const VerifyEmail = useCallback(async () => {
    let resu;
    setErrorEmail(null);
    const email = inputEmailRef.current?.getValue();
    const schemaEmail = Yup.object().shape({
      email: Yup.string().required(
        languagePage === "PT" ? "Campo obrigatório" : "Required field"
      ),
    });
    try {
      await schemaEmail.validate({ email });
    } catch (err: any) {
      setErrorEmail(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
    }
    return resu;
  }, [inputEmailRef]);

  useEffect(() => {
    setAtualPageGlobal("/forgot-password");
  }, []);
  return (
    <Container>
      {!!forgotPassword && (
        <div
          style={{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              fontWeight: "bold",
              color: "#fff",
              fontSize: 52,
              textAlign: "center",
              width: "80%",
            }}
          >
            Um link de alteração de senha foi enviado para o seu e-mail, por
            favor verifique.
          </h1>
          <ButtonTextBackGreat onClick={() => push("/")}>
            <HiArrowNarrowLeft />
            <p style={{ marginLeft: 8 }}>
              {languagePage === "PT"
                ? "Voltar para tela inicial"
                : "Back to Home "}
            </p>
          </ButtonTextBackGreat>
        </div>
      )}
      {!forgotPassword && (
        <>
          <div
            style={{
              display: "flex",
              position: "relative",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: 500,
                borderRadius: 40,
                justifyContent: "center",
                alignItems: "center",
                background: "#001F33",
                padding: 40,
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", height: 100 }}>
                  <img
                    src={
                      "https://res.cloudinary.com/hkkzuevm3/image/upload/v1634765404/Assets-FlashPlayHr/logo_k4qnwy.png"
                    }
                  />
                </div>
              </div>
              <form
                onSubmit={ChangeSendEmail}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 40,
                }}
              >
                <InputLogin
                  onChange={() => setErrorEmail(null)}
                  error={errorEmail}
                  text="E-mail"
                  type="email"
                  ref={inputEmailRef}
                  onClickContainer={() => inputEmailRef.current?.focus()}
                  styleContainer={{ marginBottom: "1rem" }}
                />
                <Button type="submit" style={{ width: "100%" }}>
                  {" "}
                  {languagePage === "PT" ? "Enviar" : "Send"}
                </Button>
              </form>
            </div>
          </div>
          <ButtonTextBack onClick={() => push("/")}>
            <span>
              <HiArrowNarrowLeft style={{ marginRight: 10 }} />
              {languagePage === "PT"
                ? "Voltar para Tela Inicial"
                : "Back to Home"}
            </span>
          </ButtonTextBack>
          {loading && (
            <Modal open={loading}>
              <div>
                <LoadingSale />
              </div>
            </Modal>
          )}
        </>
      )}
    </Container>
  );
};

export default ForgotPassword;
