import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGlobal } from "../../../hooks/global";

import { FaFileInvoice, FaCreditCard } from "react-icons/fa";

import { Container, Button } from "./styles";
import { StepCard } from "./stepCard";
import { StepBoleto } from "./stepBoleto";

export const Checkout: React.FC = () => {
  const [proposalId, setProposalId] = useState<string>();
  const [amount, setAmount] = useState<number>();
  const [selectedOptions, setSelectedOptions] = useState<string>();
  const { setAtualPageGlobal, languagePage } = useGlobal();
  const { location } = useHistory<any>();

  useEffect(() => {
    if (!location) {
      return;
    }
    if (!location.state) {
      return;
    }

    setAmount(location.state.amount);
    setProposalId(location.state.id);
  }, []);

  useEffect(() => {
    setAtualPageGlobal("/company/checkout");
  }, []);

  if (selectedOptions === "card") {
    return <StepCard amount={amount} proposalId={proposalId} />;
  }

  if (selectedOptions === "boleto") {
    return <StepBoleto amount={amount} proposalId={proposalId} />;
  }

  return (
    <Container>
      <h4 className="title">
        {languagePage === "PT"
          ? "Escolha uma forma de pagamento"
          : "Choose a way to pay"}
      </h4>
      <div className="box-button">
        <Button onClick={() => setSelectedOptions("card")}>
          <FaCreditCard size={56} />
          <p>
            {languagePage === "PT"
              ? "Cartão de crédito ou débito"
              : "Credit or debit card"}
          </p>
        </Button>
        <Button onClick={() => setSelectedOptions("boleto")} disabled>
          <FaFileInvoice size={56} />
          <p>{languagePage === "PT" ? "Boleto" : "Ticket"}</p>
        </Button>
      </div>
    </Container>
  );
};
