import React, { useEffect } from "react";
// import { Container, } from "./style";
import { useHistory, withRouter } from "react-router-dom";
import styled from "styled-components";
// import { Header } from "../../components/Header";
import { useCallback } from "react";
import { useForm } from "../../hooks/form";
import { useGlobal } from "../../hooks/global";
// import {
//   CardImg,
//   Card,
//   CardImgOverlay,
//   CardTitle,
//   Button
// } from 'reactstrap';
// import HeaderTop from "../../components/HomeTop";
// import CardLocal from "../../components/CardLocal";
// import LargeCard from "../../components/LargeCard";
import Alert from "react-bootstrap";
import Header from "../../components/HomeHeader";
import Results from "../../components/HomeResults";
import Footer from "../../components/HomeFooter";
//import bootbox from "bootbox";
import Habilities from "../../components/HomeHabilities";
import GeneratedProducts from "../../components/HomeGeneratedProducts"
import Try from "../../components/HomeTry";
import Contact from "../../components/HomeContact";


import ReactGA from 'react-ga';



const HomeContainer = styled.div`
      width: none;
      
      grid-template-columns: 1fr;
      grid-template-rows: 0.55fr 0.6fr 0.4fr 0.6fr 1fr 0.8fr 0.1fr;
      @media screen and (max-width:768px){
          grid-template-rows: 0.3fr 0.5fr 0.5fr 0.5fr 0.5fr 0.3fr 0.1fr;
      }
      grid-template-areas:
      "header"
      "generated-products"
      "results"
      "habilities"
      "try"
      "contact"
      "footer"
  ;
`

const Home: React.FC = () => {


  const { push } = useHistory();
  const { setType } = useForm();
  const { setAtualPageGlobal, languagePage } = useGlobal();

  const onClick = useCallback(() => {
    push("logon");
    setType("");
  }, []);

  useEffect(() => {
    setAtualPageGlobal("home");
  }, []);

  /*if (window.confirm("Para aplicar o nosso método e realizar o seu planejamento sistêmico acesse nossos planos  ?")) {
    window.location.assign("https://docs.google.com/forms/d/e/1FAIpQLSeNwBDS0ZVXXa_fJGYDn8ZnuAkPUHW5V9ikDrVjGimhCyOekQ/viewform?usp=sf_link");
  }*/


  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    // ReactGA.pageview("/")
  }, [])
  return (
    <HomeContainer>
        <Header />
        <GeneratedProducts />
        <Results />
        <Habilities />
        <Try />
        <Contact />
        <Footer />
    </HomeContainer>


  );

};

export default withRouter(Home);

