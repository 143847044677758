import styled from "styled-components";

const SearchField = styled.input`
  align-self: center;
  border-radius: 2.5px;
  padding: 10px;
  font-size: 16;
  margin-bottom: 32px;
  font-family: sans-serif;
  font-weight: 500;
  width: 32rem;
  border: none;
  &:focus,
  active {
    outline: none;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SearchFieldContainer = styled.div`
  justify-content: center;
  margin: auto;
`;

export { SearchField, SearchFieldContainer };
