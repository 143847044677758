import { Modal } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { LoadingSale } from "../../components/Animations/LoadingSale";
import InputLogin, { RefInputLoginProps } from "../../components/InputLogin";
import { useForm } from "../../hooks/form";
import { useGlobal } from "../../hooks/global";
import { Button, ButtonTextBackGreat, Container } from "./styles";
import * as Yup from "yup";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";

const ResetPassword: React.FC = () => {
  const { setAtualPageGlobal } = useGlobal();
  const { token } = useParams<any>();
  const { addToast } = useToast();
  const { push } = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [errorPassword, setErrorPassword] = useState<string | null>(null);
  const { setType } = useForm();
  const { languagePage } = useGlobal();
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const inputEmailRef = useRef<RefInputLoginProps>(null);
  const inputPasswordRef = useRef<RefInputLoginProps>(null);

  const ChangePassword = useCallback(
    async (event) => {
      event?.preventDefault();

      const errorEmail = await VerifyEmail();

      if (!!!errorEmail) {
        setLoading(true);
        setTimeout(async () => {
          try {
            const email = inputEmailRef.current?.getValue()
              ? inputEmailRef.current?.getValue()
              : "";
            const password = inputPasswordRef.current?.getValue()
              ? inputPasswordRef.current?.getValue()
              : "";
            api
              .post("/reset-password", {
                email: email,
                token: token,
                password: password,
              })
              .then((res) => {
                setLoading(false);
                setResetPassword(true);
              })
              .catch((err) => {
                setLoading(false);
                switch (err.response.status) {
                  case 400:
                    addToast({
                      title: "Erro",
                      description:
                        languagePage === "PT"
                          ? "Usuário não encontrado"
                          : "User not found",
                      icon: "alert",
                      type: "error",
                    });
                    break;
                  case 401:
                    addToast({
                      title: "Erro",
                      description:
                        languagePage === "PT"
                          ? "Token inválido ou expirado"
                          : "Invalid or expired token",
                      icon: "alert",
                      type: "error",
                    });
                    break;
                  default:
                    addToast({
                      title: "Erro",
                      description:
                        languagePage === "PT"
                          ? "Ocorreu um problema interno"
                          : "An internal problem occurred",
                      icon: "alert",
                      type: "error",
                    });
                }
              });
          } catch (err: any) {
            setLoading(false);
            setErrorEmail(
              languagePage === "PT"
                ? "Usuário não encontrado"
                : "User not found"
            );
          }
        }, 200);
      }
    },
    [inputEmailRef, inputPasswordRef]
  );

  const VerifyEmail = useCallback(async () => {
    let resu;
    setErrorEmail(null);
    const email = inputEmailRef.current?.getValue();
    const schemaEmail = Yup.object().shape({
      email: Yup.string().required(
        languagePage === "PT" ? "Campo obrigatório" : "Required field"
      ),
    });
    try {
      await schemaEmail.validate({ email });
    } catch (err: any) {
      setErrorEmail(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
    }
    return resu;
  }, [inputEmailRef]);

  useEffect(() => {
    setAtualPageGlobal("/reset-password/:token");
  }, []);
  return (
    <Container>
      {!!resetPassword && (
        <div
          style={{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 style={{ fontWeight: "bold", color: "#fff", fontSize: 52 }}>
            Senha alterada com sucesso!
          </h1>
          <ButtonTextBackGreat onClick={() => push("/")}>
            <HiArrowNarrowLeft />
            <p style={{ marginLeft: 8 }}>
              {languagePage === "PT"
                ? "Voltar para tela inicial"
                : "Back to Home "}
            </p>
          </ButtonTextBackGreat>
        </div>
      )}
      {!resetPassword && (
        <>
          <div
            style={{
              display: "flex",

              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: 500,
                borderRadius: 40,
                justifyContent: "center",
                alignItems: "center",
                background: "#001F33",
                padding: 40,
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", height: 100 }}>
                  <img
                    src={
                      "https://res.cloudinary.com/hkkzuevm3/image/upload/v1634765404/Assets-FlashPlayHr/logo_k4qnwy.png"
                    }
                  />
                </div>
              </div>
              <form
                onSubmit={ChangePassword}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: 40,
                }}
              >
                <InputLogin
                  onChange={() => setErrorEmail(null)}
                  error={errorEmail}
                  text="E-mail"
                  type="email"
                  ref={inputEmailRef}
                  onClickContainer={() => inputEmailRef.current?.focus()}
                  styleContainer={{ marginBottom: "1rem" }}
                />
                <InputLogin
                  onChange={() => setErrorPassword(null)}
                  error={errorPassword}
                  text={languagePage === "PT" ? "Nova Senha" : "New password"}
                  type="password"
                  ref={inputPasswordRef}
                  onClickContainer={() => inputPasswordRef.current?.focus()}
                />
                <Button type="submit" style={{ width: "100%" }}>
                  {" "}
                  {languagePage === "PT" ? "Alterar senha" : "Change password"}
                </Button>
              </form>
            </div>
          </div>
          {loading && (
            <Modal open={loading}>
              <div>
                <LoadingSale />
              </div>
            </Modal>
          )}
        </>
      )}
    </Container>
  );
};

export default ResetPassword;
