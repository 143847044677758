import React, { useCallback, useEffect, useState } from "react";
import {
  ContainerImageCountry,
  ContainerLanguageOption,
  ContainerLogo,
  ItemMenu,
} from "./style";
import { FaUserAlt } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useGlobal } from "../../hooks/global";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";

const URL_IMG_LANGUAGES: any = {
  PT: "https://res.cloudinary.com/hkkzuevm3/image/upload/v1635024910/Assets-FlashPlayHr/brasil_dw78gl.png",
  EN: "https://res.cloudinary.com/hkkzuevm3/image/upload/v1635721422/Assets-FlashPlayHr/bandeira_americana_bb_jsucol.jpg",
};

export const Header: React.FC = () => {
  const { user, signOut } = useAuth();
  const { push } = useHistory();
  const { openSelect, setLanguagePage, languagePage } = useGlobal();

  const onClick = useCallback(() => {
    push("/login");
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementsByClassName("loginClass")[0]) {
        if (
          document
            .getElementsByClassName("loginClass")[0]
            .getElementsByTagName("path")[0]
        )
          document
            .getElementsByClassName("loginClass")[0]
            .getElementsByTagName("path")[0]
            .setAttribute("class", "loginClass");
        if (
          document
            .getElementsByClassName("loginClass")[0]
            .getElementsByTagName("svg")[0]
        )
          document
            .getElementsByClassName("loginClass")[0]
            .getElementsByTagName("svg")[0]
            .setAttribute("class", "loginClass");
        if (
          document
            .getElementsByClassName("loginClass")[0]
            .getElementsByTagName("polyline")[0]
        )
          document
            .getElementsByClassName("loginClass")[0]
            .getElementsByTagName("polyline")[0]
            .setAttribute("class", "loginClass");
      }
      if (document.getElementsByClassName("languageClass")[0]) {
        if (
          document
            .getElementsByClassName("languageClass")[0]
            .getElementsByTagName("path")[0]
        )
          document
            .getElementsByClassName("languageClass")[0]
            .getElementsByTagName("path")[0]
            .setAttribute("class", "languageClass");
        if (
          document
            .getElementsByClassName("languageClass")[0]
            .getElementsByTagName("svg")[0]
        )
          document
            .getElementsByClassName("languageClass")[0]
            .getElementsByTagName("svg")[0]
            .setAttribute("class", "languageClass");
        if (
          document
            .getElementsByClassName("languageClass")[0]
            .getElementsByTagName("polyline")[0]
        )
          document
            .getElementsByClassName("languageClass")[0]
            .getElementsByTagName("polyline")[0]
            .setAttribute("class", "languageClass");
      }
    }, 200);
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [cor, setCor] = useState("");

  const exec = () => {
    setIsOpen(!isOpen);

    if (cor === "rgb(19, 68, 128)") {
      setCor("");
    }
    if (cor === "") {
      setCor("rgb(19, 68, 128)");
    }
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <Navbar
        dark
        expand="md"
        style={{
          zIndex: 4,
          width: "100%",
          margin: "auto",
          left: -1,
          background: "#021f3a",
          position: "fixed",
        }}
      >
        <NavbarBrand href="/">
          <ContainerLogo>
            <img
              style={{ width: 150, height: 50 }}
              src={
                "https://res.cloudinary.com/hkkzuevm3/image/upload/v1649190946/Assets-FlashPlayHr-webp/logo3_e6ucbq.webp"
              }
              alt="Imagem"
            />
          </ContainerLogo>
        </NavbarBrand>

        <NavbarToggler
          style={{ background: "rgb(39, 103, 181)" }}
          onClick={exec}
        />

        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavItem>
              <NavLink>
                {" "}
                <ItemMenu
                  onClick={() => push("/")}
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontFamily: "Gotham-Light",
                  }}
                >
                  Home
                </ItemMenu>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                {" "}
                <ItemMenu
                  onClick={() => push("/educacao")}
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontFamily: "Gotham-Light",
                  }}
                >
                  {languagePage === "PT" ? "EAD" : "Education"}
                </ItemMenu>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <ItemMenu
                  onClick={() => push("/#saibaMais")}
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontFamily: "Gotham-Light",
                  }}
                >
                  {languagePage === "PT" ? "Saiba mais" : "Know More About Us"}
                </ItemMenu>
              </NavLink>
            </NavItem>

            <UncontrolledDropdown nav inNavbar style={{ marginTop: 7 }}>
              <DropdownToggle
                style={{
                  backgroundColor: "transparent",
                  border: "transparent",
                }}
              >
                <div
                  className="loginClass"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    background: "#134480",
                    borderRadius: "5px",
                    justifyContent: "center",
                    padding: "5px",
                    cursor: "pointer",
                    position: "relative",
                    boxShadow: "8px 8px 20px -8px rgba(0,0,0,0.49)",
                    WebkitBoxShadow: "8px 8px 20px -8px rgba(0,0,0,0.49)",
                  }}
                  onClick={!user.id ? () => onClick() : () => { }}
                >
                  {user.id && <FaUserAlt color={"#fff"} />}
                  <div
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      fontSize: 18,
                      fontWeight: "bold",
                      maxWidth: user.id ? 100 : 132,
                      minWidth: user.id ? 100 : 132,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    className="loginClass"
                  >
                    {user.id ? user.name : "Entrar"}
                  </div>
                  {user.id && (
                    <FiChevronDown className="loginClass" color={"#fff"} />
                  )}
                </div>
              </DropdownToggle>
              {openSelect === "loginClass" && user.id && (
                <DropdownMenu
                  right
                  style={{ width: 150, borderRadius: 20, margin: 5 }}
                >
                  <DropdownItem
                    onClick={() => push("login")}
                    style={{ borderRadius: 20 }}
                  >
                    Dashboard
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => signOut()}
                    style={{ borderRadius: 20 }}
                  >
                    Sair
                  </DropdownItem>
                </DropdownMenu>
              )}
            </UncontrolledDropdown>
          </Nav>
          <NavbarText style={{ borderRadius: 5 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                background: "#fff",
                padding: 0,
                margin: "auto 10px",
                borderRadius: 5,
                alignItems: "center",
                cursor: "pointer",
                boxShadow: "8px 8px 20px -8px rgba(0,0,0,0.49)",
                WebkitBoxShadow: "8px 8px 20px -8px rgba(0,0,0,0.49)",
                width: 100,
              }}
            >
              <ContainerLanguageOption
                style={{ borderRadius: 5 }}
                onClick={() => setLanguagePage("PT")}
              >
                <ContainerImageCountry style={{ borderRadius: 5 }}>
                  <img
                    style={{ borderRadius: 5 }}
                    src={
                      "https://res.cloudinary.com/hkkzuevm3/image/upload/v1635024910/Assets-FlashPlayHr/brasil_dw78gl.png"
                    }
                    alt="Imagem"
                  />
                </ContainerImageCountry>
              </ContainerLanguageOption>
              <ContainerLanguageOption
                style={{ borderRadius: 5 }}
                onClick={() => setLanguagePage("EN")}
              >
                <ContainerImageCountry style={{ borderRadius: 5 }}>
                  <img
                    style={{ borderRadius: 5 }}
                    src={
                      "https://res.cloudinary.com/hkkzuevm3/image/upload/v1635721422/Assets-FlashPlayHr/bandeira_americana_bb_jsucol.jpg"
                    }
                    alt="Imagem"
                  />
                </ContainerImageCountry>
              </ContainerLanguageOption>
            </div>
          </NavbarText>
        </Collapse>
      </Navbar>
    </div>
  );
};
