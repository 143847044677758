import React, { createContext, useState, useEffect, useCallback } from "react";

import api from "../services/api";

type PropsCronometroContext = {
  cronometro: number;
  tempoInicial: {
    horaInicial: number;
    minutoInicial: number;
    segundosIniciais: number;
    miliSegundos: number;
  };

  setTempoInicial: React.Dispatch<
    React.SetStateAction<{
      horaInicial: number;
      minutoInicial: number;
      segundosIniciais: number;
      miliSegundos: number;

    }>
  >;
  setCronometro: React.Dispatch<React.SetStateAction<number>>;
  saveTime: Function;
  getTime: Function;
};

const DEFAULT_VALUE = {
  cronometro: 0,

  tempoInicial: {
    horaInicial: 0,
    minutoInicial: 0,
    segundosIniciais: 0,
    miliSegundos: 0,
  },
  setTempoInicial: () => { },
  setCronometro: () => { },
  saveTime: () => { },
  getTime: () => { }
};
export const CronometroContext =
  createContext<PropsCronometroContext>(DEFAULT_VALUE);

const ContextProvider = ({ children }: any) => {
  const [cronometro, setCronometro] = useState(DEFAULT_VALUE.cronometro);
  const [tempoInicial, setTempoInicial] = useState(DEFAULT_VALUE.tempoInicial);

  const date = new Date()

  let teste = async () => {

    await new Promise(() => setTimeout(() => {
      setTempoInicial({
        horaInicial: date.getHours(),
        minutoInicial: date.getMinutes(),
        segundosIniciais: date.getSeconds(),
        miliSegundos: date.getMilliseconds(),
        })
      }, 3000))}

  const saveTime = useCallback(async () => {
   
      const ob = {
        horaInicial: date.getHours(),
        minutoInicial: date.getMinutes(),
        segundosIniciais: date.getSeconds(),
        miliSegundos: date.getMilliseconds(),
      }
     
     
    
    await api.post("/save-time", JSON.stringify(ob), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }, [tempoInicial]);
  const getTime = async () => {
    const res = await api.get("/get-time");
    const { timeInicial } = res.data;
    const t = JSON.parse(timeInicial)
    setTempoInicial(t);
  };

  return (
    <CronometroContext.Provider
      value={{
        cronometro,
        setCronometro,
        tempoInicial,
        setTempoInicial,
        saveTime,
        getTime

      }}
    >
      {children}
    </CronometroContext.Provider>
  );
};

export default ContextProvider;
