import { relative } from "path";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}
`;

const GeneratedProductsContainer = styled.section`
	grid-area: generated-products;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80%;
	height: 80%;
	background-color: #fff;
	place-items: center;
	gap: 3em;

	@media screen and (max-width: 769px) {
		/* gap: 2em; */
	}
	@media screen and (min-width: 321px) and (max-width: 375px) {
		/* width: 100%; */
	}
`;

const PlanOffer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 16px;
	padding: 12px;
	width: 80%;
	gap: 1em;
	@media screen and (max-width: 769px) {
		width: 100%;
	}
`

const ProductContentContainer = styled.div`
	/* background-color: rgba(0, 1, 36, 0.6); */
	width: 100%;
	height: 100%;

	/* padding: 2em; */
`;

const SectionTitleContainer = styled.div`
	margin-bottom: 2em;
	color: white;
`;

const SectionTitle = styled.p`
	color: #000124;
	font-family: Inter-ExtraBold;
	font-size: x-large;
	text-align: center;
`;

const ProductListContainer = styled.div`
	background-color: rgba(0, 0, 0, 0.2);
	width: 100%;
	border-radius: 16px;
	color: white;
	padding: 1.5em;
	height: 100%;
	justify-content: center;
	animation-name: ${fadeIn};
	animation-duration: 5s;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	@media screen and (min-width: 769px) {
	}
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const ProductListContainerProduct = styled.div`
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	width: 100%;
	border-radius: 16px;
	color: white;
	padding: 10px;
	height: 100%;
	/* justify-content: space-between; */
	gap: 1em;
	animation-name: ${fadeIn};
	animation-duration: 5s;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	@media screen and (max-width: 769px) {
		display:flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const ProductListContainerPaginaInicial = styled.div`
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 16px;
	color: white;
	padding: 1.5em;
	height: 100%;
	justify-content: center;
	animation-name: ${fadeIn};
	animation-duration: 5s;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	@media screen and (max-width: 769px) {
		width: 80%;
		padding: 1em;
	}
`;

const ProductList = styled.ol`
	font-family: Inter-Medium;
	font-size: large;
	@media screen and (min-width: 769px) {
		font-size: x-large;
	}
`;

const ProductListItem = styled.li`
	font-size: large;
	color: #000124;
	margin: 0.8em;
	@media screen and (min-width: 769px) {
		width: 80%;
		font-size: small;
	}
`;

const TextContainer = styled.div`
	font-family: Inter-Medium;
	background-color: rgba(0, 0, 0, 0.2);
	max-width: 100%;
	border-radius: 16px;
	justify-self: right;
	color: white;
	padding: 2em;
	font-size: large;
	max-height: fit-content;
	margin-bottom: 10%;
	align-items: center;
	justify-content: center;
	border: 1px solid rgba(255, 255, 255, 0.24);
	@media screen and (min-width: 769px) {
		font-size: x-large;
		margin-bottom: 0%;
		padding: 10px;
	}
`;

const Text = styled.p`
	font-size: large;
	line-height: 2.3rem;
	color: #000124;
	@media screen and (min-width: 769px) {
		font-size: small;
	}
`;

const Image = styled.img`
	width: 100%;
	border-radius: 16px;
	@media screen and (min-width: 769px) {
		width: 80%;
		height: 50%
	}
`;

const ImageContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const LogOnButton = styled.button`
	width: 200px;
	height: 50px;
	font-family: Inter-Medium;
	color: #fff;
	border: none;
	background-color: #e58417;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

	&:hover {
    	opacity: 0.9;
  	}

	&:active {
		opacity: 0.8;
	}

`;

const GreenButton = styled.button`
	width: 100%;
	max-height: 100%;
	font-family: Inter-Medium;
	color: #fff;
	border: none;
	background-color:#007362;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

	&:hover {
    	opacity: 0.9;
  	}

	&:active {
		opacity: 0.8;
	}

`;

const GetButton = styled.button`
	width: 130px;
    height: 50px;
    background: #134480;
	font-family: Inter-Medium;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

	&:hover {
    	opacity: 0.9;
  	}

	&:active {
		opacity: 0.8;
	}

`;

const Root = styled.div`
    display: flex;  
	flex-direction: row;
	margin: 0;   
	justify-content: center;
	padding: 3%;
	gap: 2em;
	@media screen and (max-width: 769px) {
		display: flex;
    	justify-content: center;
    	align-items: center;
    	flex-direction: column;
		gap: 2em;
		padding: 2%;
	}
`;

const Section = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2em;
	flex-direction: column;
	width: 80%;
	height: 80%;
	@media screen and (max-width: 769px) {
		width: 100%;
	}
`

const BookImage = styled.img`
	height: 170px;
	@media screen and (max-width: 769px) {
		display: none;
	}
`

const Link = styled.a`
	text-decoration: none;
	color: white;
	&:hover {
    	color: white;
  	}
`

const Products: React.CSSProperties = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-around",
	gap: "3em",
	marginTop: "5%"
}

const ContainerButtons: React.CSSProperties = {
	display: "flex",
	justifyContent: "space-around",
}

const Title: React.CSSProperties = {
	color: "#000124",
	fontFamily: "Inter-Bold",
	fontSize: "23px"
}

const Subtitle: React.CSSProperties = {
	color: "#000124",
	fontFamily: "Inter-Medium",
	fontSize: "18px"
}

const Paragraph: React.CSSProperties = {
	color: "#000124",
	fontFamily: "Inter",
	fontSize: "15px",
	fontStyle: "italic"
}


const LearnMoreButton: React.CSSProperties = {
	backgroundColor: "#20b2aa",
	width: "150px"
}

const ButtonClick: React.CSSProperties = {
	backgroundColor: "#007362",
	width: "200px",
	height: "100%"
}

const QuestionsButton: React.CSSProperties = {
	backgroundColor: "#20b2aa",
	width: "35%",
	
}

const ComboButton: React.CSSProperties = {
	width: "80%"
}

const ContainerQuestionsButton: React.CSSProperties = {
	display: "flex",
	alignItems: "space-between",
	justifyContent: "space-between",
	padding: 5
}

const ContainerProducts: React.CSSProperties = {
	display: "flex",
	gap: "30px",
}

export {
	GeneratedProductsContainer,
	SectionTitleContainer,
	SectionTitle,
	ProductContentContainer,
	ProductListContainer,
	ProductListContainerProduct,
	ProductListContainerPaginaInicial,
	ProductList,
	ProductListItem,
	Text,
	TextContainer,
	Image,
	ImageContainer,
	LogOnButton,
	Root,
	Section,
	PlanOffer,
	Products,
	ContainerButtons,
	Title,
	GetButton,
	LearnMoreButton,
	Link,
	ContainerProducts,
	Subtitle,
	Paragraph,
	QuestionsButton,
	ContainerQuestionsButton,
	ComboButton,
	BookImage,
	ButtonClick,
	GreenButton
};
