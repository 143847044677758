import styled from "styled-components";

export const ContainerLoading = styled.div`
  display: flex;
  outline: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  font-family: "Gotham-Medium", sans-serif;

  .button-remove {
    color: #fff;
    cursor: pointer;
    margin: 20px 20px 0;
  }
  .box-upload {
    display: flex;
    border-radius: 10px;
    margin: 0 0 0 20px;
  }
  .box {
    display: flex;
    max-width: 1300px;
    width: 100%;
    min-width: 200px;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
  }
  .orientation-button {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 20px 0;
  }
  .box-in {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .dropzone {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin: 20px;
  }
  .box-in-inputs {
    width: 100%;
  }

  .iframe {
    border: 0;
    overflow: hidden;
    margin: 20px;
    width: 100%;
  }

  @media (max-width: 640px) {
    .box-upload {
      margin: 20px 0 0 0;
    }
    .box {
      max-width: 340px;
    }

    .iframe {
      width: 320px;
      height: 300px;
      margin: 0 auto;
    }
  }
  @media (max-width: 320px) {
    .box {
      max-width: 270px;
    }
  }

  @media (min-width: 760px) {
    .box {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .box-in {
      flex-direction: row;
    }
    .orientation-button {
      width: 100%;
      margin-right: 20px;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .input-responsive {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .dropzone {
      flex-direction: row;
      align-items: center;
      .iframe {
        width: 450px;
        height: 300px;
      }
    }
  }
`;

export const ContainerCard = styled.div`
  @media (max-width: 706px) {
    .flexWOne {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }
    .flexW {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }
    .inputs {
      margin: 10px 0px;
    }
  }
`;

export const ButtonAlterPasswordSave = styled.div`
  cursor: pointer;
  display: flex;
  border-radius: 5px;
  background: #f27823;
  box-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 1);
  color: #fff;
  font-family: Gotham-Bold;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 170px;
  margin-right: 10px;
  padding: 10px;
  margin: 10px;
`;

export const ButtonAlterarPreferencia = styled.div`
  cursor: pointer;
  display: flex;
  border-radius: 5px;
  background: #134480;
  box-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 1);
  color: #fff;
  font-family: Gotham-Bold;
  align-items: center;
  justify-content: center;
  height: 50px;
  max-width: 260px;
  margin-right: 10px;
  padding: 10px;
  margin: 10px;
`;
