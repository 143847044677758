import { isValid, parse, parseISO } from "date-fns"
import { GenerateDate } from "./generalServices"

export interface ErrorProps{
    attr:string;
    message:string;
    valueInitial:any;
}

export interface rulesProps{
    message:any;
    value:any;
}

export interface ItemProps{
    required?:rulesProps;
    exactTam?:rulesProps;
    date?:rulesProps;
    onlyLetters?:rulesProps;
    value:any;
    name:string;
}

const IsNotDate = (value:any) => { 
    if(value.length === 10)
    {
        const date = parse(GenerateDate(value).split(' ')[0].replaceAll('-','.'),'yyyy.MM.dd',new Date())
        return !isValid(new Date(date)) 
    }
    else 
    {
        return !!value 
    }
}

const ExactTam = (value:string,tam:any) => {
    return value.length !== tam && value.length !== 0
}

const Required = (value:any) => { return !value }

const OnlyLetters = (value:string) => {
    for(let i = 0 ; i < value.length ; i++)if(!isAllowedCharacter(value[i]))return true
    return false
}

const isAllowedCharacter = (letter:string) => {
    const letters = ['0','1','2','3','4','5','6','7','8','9','!','"','@','#','$','%','¨','&','*','(',')','-','_','§','\\','|','/','?','°','º','ª','{','[',']','}','´','\'','^','~',':',';',',','.','<','>','+']
    for(let j = 0; j < letters.length ; j++)if(letter === letters[j]) return false
    return true
}

export const Verify = (arg:ItemProps[]) => {

    let errors:ErrorProps[] = []

    for(let i = 0 ; i < arg.length; i++)
    {
        let valueInitial = arg[i].value
        let attr         = arg[i].name
        if(arg[i].required?.value    && Required(valueInitial)                       )errors.push({attr,message:arg[i].required?.message    ,valueInitial})
        if(arg[i].onlyLetters?.value && OnlyLetters(valueInitial)                    )errors.push({attr,message:arg[i].onlyLetters?.message ,valueInitial})
        if(arg[i].exactTam           && ExactTam(valueInitial,arg[i].exactTam?.value))errors.push({attr,message:arg[i].exactTam?.message    ,valueInitial})
        if(arg[i].date?.value        && IsNotDate(valueInitial)                      )errors.push({attr,message:arg[i].date?.message        ,valueInitial})
    }

    return errors;
}
