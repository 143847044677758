import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background: #fff;
  width: 100%;
  max-width: 900px;
  border-radius: 8px;
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #c6c6c6;

  h4 {
    font-family: "Gotham-Bold", sans-serif;
    font-size: 20px;
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 32px 0;

  p {
    font-family: "Gotham-Medium", sans-serif;
    font-size: 16px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background: #134480;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    font-family: "Gotham-Medium", sans-serif;
    font-size: 15px;
    color: #fff;
    transition: all 0.2s;
  }

  button:hover {
    background: #003668;
  }
`;
