import styled from "styled-components";

export const AlertError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  cursor: text;
  background: #0a498c;
  border-radius: 0.3125rem;
  padding: 0.3125rem;
  width: calc(100% - 0.85rem);
  height: 2.6875rem;
  align-items: center;

  @media only screen and (max-width: 360px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  ::placeholder {
    color: #fff;
    opacity: 0.6;
  }
`;

export const ContainerError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const TooltipError = styled.div`
  display: flex;
  font-family: math;
  font-weight: bold;
  font-size: 1rem;
  position: absolute;
  left: 50%;
  color: #fff;
  transform: translatex(-50%);
  bottom: calc(100% + 0.375rem);
  background: #32416b;
  padding: 0.625rem;
  border-radius: 0.3125rem;
  white-space: nowrap;
  ::before {
    border-style: solid;
    border-color: #32416b transparent;
    border-width: 0.375rem 0.375rem 0 0.375rem;
    bottom: -0.3125rem;
    content: "";
    left: 50%;
    transform: translatex(-50%);
    position: absolute;
  }
  box-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 0.7);
  z-index: 999;
`;
