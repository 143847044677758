import React from "react";
import NavBar from "../../../components/HomeNavBar"
import { useGlobal } from "../../../hooks/global";
import {
    GeneralContainer,
    ContainerSection,
    ContainerArticle,
    BookImage,
    ButtonStyle,
    Title,
    Subtitle,
    Paragraph,
    LogoContainer,
    FlashPlayLogo,
    HeaderBar,
    ContainerBody,
    ContainerBodyProduct,
    Container,
    Message,
    ContainerImages,
    ContainerImage,
    Image,
    ContainerCard,
    Category,
    ContainerTitle
} from "./styles"
import { useHistory } from "react-router-dom";
import { Typography, Button } from '@mui/material';

const SaberesCircularesEbook: React.FC = () => {
    const { setLanguagePage, languagePage } = useGlobal();
    const { push } = useHistory();

    return (
        <>
            <GeneralContainer>
                <HeaderBar id="headerbar">
                    {window.matchMedia("(min-width:769px)").matches &&
                        <LogoContainer>
                            <FlashPlayLogo
                                src='https://res.cloudinary.com/dkqcb920e/image/upload/v1655915573/logo3_e6ucbq_pwaatc.webp'
                                alt='EcoRecitec|FlashPlayHR'
                                onClick={() => push("/")}
                            />
                        </LogoContainer>
                    }
                    <NavBar />
                </HeaderBar>

                <ContainerCard>
                    <ContainerBody style={{marginTop: "7%"}}>
                        <ContainerBodyProduct style={ContainerSection}>
                            <img src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1702313848/Assets-FlashPlayHr/rd9mt7srxz7zbdnjcigd.png" alt="saberes-circulares-ebook" style={BookImage} />

                            <Container style={ContainerArticle}>
                                <Typography style={Title}>
                                {languagePage === "PT"
                                        ? "Saberes Circulares"
                                        : "Circular Knowledge"}

                                </Typography>
                                <Typography style={Subtitle}>{languagePage === "PT"
                                        ? "Integrando Conhecimento para a Inovação Circular"
                                        : "Integrating Knowledge for Circular Innovation"}</Typography>

                                <Typography style={Paragraph}>
                                {languagePage === "PT"
                                        ? "O livro foi um ensaio realizado em 2017 pela autora Celene Brito que está com o pseudônimo de Chanda Celene Marchesini. O ensaio é filosófico e psicológico, mas trouxe o debate da economia circular sob novo prisma, inclusive contribuindo para o Doutorado da Autora que associou a psicologia com economia circular."
                                        : "The book was an essay carried out in 2017 by the author Celene Brito, who uses the pseudonym Chanda Celene Marchesini. The essay is philosophical and psychological, but it brought the circular economy debate from a new perspective, including contributing to the Author's PhD, which associated psychology with the circular economy."}  
                                </Typography>

                                <Typography style={Message}>
                                {languagePage === "PT"
                                        ? "Vale a pena conferir!"
                                        : "It is worth checking!"} 
                                </Typography>
                            </Container>
                        </ContainerBodyProduct>


                        <Container>
                            <Typography style={Subtitle}>
                            R$ 37,70
                            </Typography>

                            <Button style={ButtonStyle} target="_blank" href="https://pay.hotmart.com/U88523999Q">{languagePage === "PT"
                                ? "Comprar Produto"
                                : "Buy Product"}</Button>
                        </Container>
                    </ContainerBody >

                    <ContainerTitle>
                        <Typography style={Title}>
                        {languagePage === "PT"
                                ? "Outros Produtos"
                                : "Other products"}
                        </Typography>
                    </ContainerTitle>

                    <ContainerImages>
                        <ContainerImage >
                            <img src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1702313849/Assets-FlashPlayHr/lc4pgdnmzftgs0ynasjn.png" alt="mindset-ebook" style={Image} />
                            <Container style={ContainerArticle}>
                                <Typography style={Subtitle}>Mindset</Typography>
                                <Typography style={Category}>
                                    E-Book
                                </Typography>
                                <Button style={ButtonStyle} onClick={() => push("/mindset")}>{languagePage === "PT"
                                ? "Detalhes do Produto"
                                : "Product details"}</Button>
                            </Container>
                        </ContainerImage>

                        {/* <ContainerImage>
                            <img src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1702313848/Assets-FlashPlayHr/ymnfrhlkenmgipirgt6v.png" alt="combo-de-natal" style={Image} />
                            <Container style={ContainerArticle}>
                                <Typography style={Subtitle}>{languagePage === "PT"
                                    ? "Combo de Natal"
                                    : "Christmas combo"}</Typography>
                                <Typography style={Category}>
                                    E-Book
                                </Typography>
                                <Button style={ButtonStyle} onClick={() => push("/bookCombo")}>{languagePage === "PT"
                                ? "Detalhes do Produto"
                                : "Product details"}</Button>
                            </Container>
                        </ContainerImage> */}

                        <ContainerImage>
                            <img src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1702313848/Assets-FlashPlayHr/jm0zhdkgf60ncfcrrvtj.png" alt="lixo-vivo-ebook" style={Image} />
                            <Container style={ContainerArticle}>
                                <Typography style={Subtitle}>{languagePage === "PT"
                                    ? "A Revolução do Lixo Vivo"
                                    : "The Living Waste Revolution"}</Typography>
                                <Typography style={Category}>
                                    E-Book
                                </Typography>
                                <Button style={ButtonStyle} onClick={() => push("/revolucaoDoLixoVivo")}>{languagePage === "PT"
                                ? "Detalhes do Produto"
                                : "Product details"}</Button>
                            </Container>
                        </ContainerImage>
                    </ContainerImages>
                </ContainerCard>
            </GeneralContainer>
        </>
    );
}

export default SaberesCircularesEbook;