import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-position: bottom;
  transition: all 1s;
  background-image: url("https://res.cloudinary.com/hkkzuevm3/image/upload/v1648759101/Assets-FlashPlayHr-webp/fundoTelaDeEmpresa_h64p94_sotoow.webp");
  background-size: cover;
`;

export const Button = styled.button`
  cursor: pointer;
  border-radius: 5px;
  border: none;
  font-family: "Gotham-Medium", sans-serif;
  font-size: 26px;
  margin-top: 40px;
  padding: 16px;
  color: #fff;
  background: #1e90ff;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: background 0.2s;

  &:hover {
    background: #4682b4;
  }
`;

export const ButtonTextBack = styled.div`
  display: flex;
  position: absolute;
  top: 50px;
  left: 50px;
  cursor: pointer;
  color: #fff;
  font-family: "Gotham-Medium", sans-serif;
  display: inline-block;
  transition: color 0.2s;

  span {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &:hover {
    opacity: 0.75;
  }
`;

export const ButtonTextBackGreat = styled.div`
  display: flex;
  cursor: pointer;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: color 0.2s;

  p {
    font-size: 24px;
    font-family: "Gotham-Regular", sans-serif;
  }
  &:hover {
    opacity: 0.75;
  }
`;
