import React from "react";
import Dropzone from "react-dropzone";
import { ConteudoUpload, DropContainer, UploadMessage } from "./style";
import { FaUser, FaUserSlash } from "react-icons/fa";
import { fileProps } from "./../../interfaces/fileUploadInterface";

interface UploadProps {
  onUpload: any;
  file: fileProps;
}

export const Upload: React.FC<UploadProps> = ({ onUpload, file }) => {
  const renderDragMessage = (isDragActive: boolean, isDragReject: boolean) => {
    if (!isDragActive) {
      if (!file.preview) {
        return (
          <UploadMessage>
            <ConteudoUpload>
              <FaUser size={50} />
            </ConteudoUpload>
          </UploadMessage>
        );
      } else {
        return (
          <UploadMessage color="#000">
            <ConteudoUpload>
              <img style={{ height: 140 }} src={file.preview} />
            </ConteudoUpload>
          </UploadMessage>
        );
      }
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">
          <FaUserSlash size={63} />
        </UploadMessage>
      );
    }

    return <UploadMessage type="success">Solte</UploadMessage>;
  };
  return (
    <Dropzone
      accept="image/png,image/jpeg"
      onDropAccepted={onUpload}
      maxFiles={1}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} />
            {renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        </div>
      )}
    </Dropzone>
  );
};
