import styled from "styled-components";

export const SexOpcao = styled.div`
width: 100%;
min-width: 200px;
max-width: 300px;
margin: 5px;
padding: 10px;

border-radius: 10px;
cursor: pointer;
box-shadow: 4px 4px 20px -2px rgba(0,0,0,0.7);
-webkit-box-shadow: 4px 4px 20px -2px rgba(0,0,0,0.7);
background: #0e294b;

`