import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Button,
  ContainerDropInput,
  Dropdown,
  Input,
  Container,
  Title,
} from "./styles";

import { identifierContracts } from "./contracts";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { useForm } from "../../../hooks/form";
import jsonp from "jsonp";
import { useToast } from "../../../hooks/toast";

interface NumberIdentifierProps {
  onBack: () => any;
  onNext: () => any;
}

const NumberIdentifierDropInput: React.FC<NumberIdentifierProps> = ({
  onBack,
  onNext,
}) => {
  const [identifier, setIdentifier] = useState<string>("CPF");
  const { addToast } = useToast();
  const {
    numberIdentifier,
    setNumberIdentifier,
    setNome,
    setCidade,
    setEstado,
  } = useForm();
  const [value, setValue] = useState("");

  const mask = (v: string) => {
    v = v.replace(/\D/g, "");

    if (identifier === "CPF") {
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else if (identifier === "SSN") {
      v = v.replace(/^(\d{3})/, "$1-");
      v = v.replace(/-(\d{2})/, "-$1-");
      v = v.replace(/(\d)-(\d{4}).*/, "$1-$2");
    } else if (identifier === "SIN") {
      v = v.replace(/(\d{3})(\d)/, "$1 $2");
      v = v.replace(/(\d{3})(\d)/, "$1 $2");
    } else if (identifier === "CNPJ") {
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v;
  };

  const handleIdentifierMax = useCallback(() => {
    switch (identifier) {
      case "CPF":
        return 14;
      case "CNPJ":
        return 18;
      case "SSN":
        return 11;
      case "SIN":
        return 11;
      case "NIF":
        return 9;
      default:
        return 28;
    }
  }, [identifier]);

  const handleOnNext = useCallback(
    (event) => {
      event.preventDefault();
      if (identifier === "CNPJ") {
        jsonp(
          `https://www.receitaws.com.br/v1/cnpj/${numberIdentifier}`,
          {},
          (err, res) => {
            if (err) {
              console.log(err);
            } else {
              const { situacao, nome, municipio, uf } = res;
              if (situacao === "ATIVA") {
                setNome(nome);
                setCidade(municipio);
                setEstado(uf);
                onNext();
              } else {
                addToast({
                  title: "Erro",
                  description: "CNPJ inválido ou não existe",
                  icon: "alert",
                  type: "error",
                });
              }
            }
          }
        );
      } else {
        onNext();
      }
    },
    [
      addToast,
      identifier,
      numberIdentifier,
      onNext,
      setCidade,
      setEstado,
      setNome,
    ]
  );
  return (
    <Container>
      <Title>Precisamos de uma forma de identificação</Title>
      <ContainerDropInput>
        <Dropdown
          value={identifier}
          onChange={(e) => {
            if (identifier !== e.target.value) {
              setNumberIdentifier("");
              setValue("");
            }
            setIdentifier(e.target.value);
          }}
        >
          {identifierContracts.map((identify) => (
            <option value={identify.value}>{identify.title}</option>
          ))}
        </Dropdown>
        <Input
          placeholder="Digite aqui..."
          value={mask(value)}
          maxLength={handleIdentifierMax()}
          onChange={(e) => {
            setNumberIdentifier(e.target.value.replace(/\D/g, ""));
            setValue(e.target.value);
          }}
        />
      </ContainerDropInput>
      <div className="box-button">
        <Button onClick={onBack}>
          <GoChevronLeft style={{ marginRight: 8 }} /> Anterior
        </Button>
        <Button onClick={handleOnNext}>
          Próximo <GoChevronRight style={{ marginLeft: 8 }} />
        </Button>
      </div>
    </Container>
  );
};

export default NumberIdentifierDropInput;
