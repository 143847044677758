import styled from "styled-components";

export const DisplayFlex = styled.div`
  display: flex;
  p,
  h5 {
    margin: 0px;
  }
`;

export const Container = styled(DisplayFlex)`
  flex-direction: column;
  align-items: center;
  //font-family: "Gotham-Bold";
  margin: 20px;
`;

export const Titulo = styled(DisplayFlex)`
  color: #64666b;
  font-family: "Gotham-Medium";
  font-size: 26px;
  justify-content: center;
`;

export const CardSelection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  background: #fff;
  margin: 32px 0;
  border-radius: 2.5px;
  box-shadow: 10px 10px 75px -25px rgba(79, 79, 79, 0.75);
  -webkit-box-shadow: 10px 10px 75px -25px rgba(79, 79, 79, 0.75);
  -moz-box-shadow: 10px 10px 75px -25px rgba(79, 79, 79, 0.75);
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  h5 {
    font-family: "Gotham-Bold", sans-serif;
    margin-left: 8px;
    font-size: 18px;
  }

  @media (max-width: 768px) {
    h5 {
      font-size: 12px;
      margin-left: 0px;
      text-align: left;
      width: 90%;
    }
  }
`;

export const CardBoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #134480;
  width: 50px;
  height: 50px;
  border-radius: 2px;

  @media (max-width: 768px) {
    display: none;
  }
`;
export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  background: #f2f2f2;
  padding: 16px;

  h5 {
    font-family: "Gotham-Medium", sans-serif;
    font-size: 16px;
  }
  p {
    font-family: "Gotham-Light", sans-serif;
    font-size: 16px;
    width: 100%;
    word-break: break-all;
    white-space: normal;
  }

  @media (max-width: 768px) {
    h5 {
      font-size: 11px;
    }

    p {
      font-size: 11px;
    }
  }
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface CardHSProps {
  exist: boolean;
}

export const CardHS = styled.div<CardHSProps>`
  border-radius: 2px;
  background: ${(props: any) => (props.exist === true ? "#a5acb5" : "#134480")};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4px 8px;
  margin-right: 8px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
  }

  h5 {
    font-family: "Gotham-Bold", sans-serif;
    ${(props: any) => (props.exist === true ? "" : "color: #fff")}
  }

  p {
    font-family: "Gotham-Medium", sans-serif;
    ${(props: any) => (props.exist === true ? "" : "color: #fff")}
  }

  @media (max-width: 768px) {
    h5 {
      font-size: 12px;
    }
    p {
      font-size: 12px;
    }
    margin: 2.5px 0;
  }
`;
export const CardButton = styled.button`
  background: #134480;
  border: none;
  color: #fff;
  border-radius: 2px;
  font-family: "Gotham-Medium", sans-serif;
  font-size: 15px;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const Status = styled.div`
  background: #fff;
  font-family: "Gotham-Medium", sans-serif;
  text-align: right;
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
