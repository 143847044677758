import React, { useCallback, useRef } from 'react';
import Modal from '@material-ui/core/Modal';
import InputLabel from '../../InputLabel';
import {
    Container,
    Header,
    IconDelete,
    ButtonEnd,
    ContainerHeader,
    Formulario,
    ContainerItemForm,
    ContainerBottom,
    ContainerTag,
    TitleTag,
    ContainerIconTag,
    Button
} from './style';
import { UserProps } from '../../../interfaces/userInterface';
import { MaskCPF, MaskPhone } from '../../../services/maskServices';
import { FaFeatherAlt } from 'react-icons/fa';
import { MdLibraryBooks } from 'react-icons/md';
import { useGlobal } from '../../../hooks/global';


interface RelatoriosRootProps {
    open: boolean;
    onClose: Function;
    data: UserProps;
}

export const RelatoriosRoot: React.FC<RelatoriosRootProps> = ({ open, onClose, data }) => {
    const refLink = useRef<HTMLAnchorElement>(null)

    const gerarPDF = useCallback((type, tagId = null) => {
        refLink.current?.setAttribute('href', `${process.env.REACT_APP_API}/company/report/${type}/${data.id}/${data.soft.selectionId}/${languagePage}`)
        refLink.current?.click()
    }, [refLink])
    const { setOpenSelect, openSelect, setLanguagePage, languagePage, atualPageGlobal } = useGlobal()
    return (
        <Modal open={open} onClose={() => onClose()} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Container>
                <a style={{ display: "none" }} ref={refLink} target="_blank" />
                <ContainerHeader>
                    <Header>
                        {languagePage === "PT" ? "Candidato" : "Candidate"}
                        
                        <IconDelete onClick={() => onClose()} color="#C4C4C4" />
                    </Header>
                </ContainerHeader>

                <Formulario>
                    <ContainerItemForm>
                        <InputLabel
                            errors={[]}
                            label="Nome"
                            attr="name"
                            value={data.name}
                            disabled={true}
                            id='InputNameCandidate'
                            containerStyle={{ width: "100%" }}
                            inputProps={{ style: { fontFamily: "Poppins", fontWeight: "bold", fontSize: 18 } }}
                        />
                    </ContainerItemForm>
                    <ContainerItemForm>
                        <InputLabel
                            errors={[]}
                            label="Email"
                            attr="email"
                            value={data.email}
                            disabled={true}
                            id='InputEmailCandidate'
                            containerStyle={{ width: "100%" }}
                            inputProps={{ style: { fontFamily: "Poppins", fontWeight: "bold", fontSize: 18 } }}
                        />
                    </ContainerItemForm>
                    <ContainerItemForm>
                        <InputLabel
                            errors={[]}
                            label="Telefone"
                            attr="phone"
                            value={MaskPhone(data.phone)}
                            disabled={true}
                            id='InputPhoneCandidate'
                            containerStyle={{ width: "100%", marginRight: 5 }}
                            inputProps={{ style: { fontFamily: "Poppins", fontWeight: "bold", fontSize: 18 } }}
                        />
                        <InputLabel
                            errors={[]}
                            label="CPF"
                            attr="cpf"
                            value={MaskCPF(data.cpf)}
                            disabled={true}
                            id='InputCPFCandidate'
                            containerStyle={{ width: "100%", marginLeft: 5 }}
                            inputProps={{ style: { fontFamily: "Poppins", fontWeight: "bold", fontSize: 18 } }}
                        />
                    </ContainerItemForm>
                    <ContainerItemForm>
                        <ContainerTag isRespondida={!!data.soft} style={{ marginRight: 5 }}>
                            <TitleTag isRespondida={!!data.soft}>
                                <ContainerIconTag isRespondida={!!data.soft}>
                                    <FaFeatherAlt size={30} />
                                </ContainerIconTag>
                                Exame Soft Skill
                            </TitleTag>
                            <Button onClick={() => !!data.soft ? gerarPDF("soft") : {}} isRespondida={!!data.soft}>
                                <MdLibraryBooks style={{ marginRight: 10 }} />
                                {languagePage === "PT" ? "Ver relatório" : "View report"}
                            </Button>
                        </ContainerTag>
                        <ContainerTag isRespondida={!!data.soft && !!data.hard} style={{ marginLeft: 5 }}>
                            <TitleTag isRespondida={!!data.soft && !!data.hard}>
                                <ContainerIconTag isRespondida={!!data.soft && !!data.hard}>
                                    <FaFeatherAlt size={30} />
                                </ContainerIconTag>
                                {languagePage === "PT" ? "Integração Soft + Hard " : "Soft + Hard Integration"}
                            </TitleTag>
                            <Button onClick={() => !!data.soft && !!data.hard ? gerarPDF("integrado") : {}} isRespondida={!!data.soft && !!data.hard}>
                                <MdLibraryBooks style={{ marginRight: 10 }} />
                                {languagePage === "PT" ? "Ver relatório" : "View report"}
                            </Button>
                        </ContainerTag>
                    </ContainerItemForm>
                </Formulario>

                <ContainerBottom>
                    <ButtonEnd onClick={() => onClose()} style={{ color: "#252323" }}> {languagePage === "PT" ? "Fechar" : "Close"} </ButtonEnd>
                </ContainerBottom>

            </Container>
        </Modal >
    )
}

export default RelatoriosRoot;