import { InputBaseProps } from "@material-ui/core";
import React, { forwardRef, useCallback, useImperativeHandle, CSSProperties, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { AlertError, Container, ContainerError, ContainerInput, InputBase, Label, TooltipError } from "./style";
export interface InputRefProps {
    getInputValue: () => string;
    setInputValue: (value: string) => void;
}

interface InputProps extends InputBaseProps {
    styleInput: CSSProperties;
    styleContainer: CSSProperties;
    diferTooltip?: boolean;
    errors?: string | null;
    label: string;
}

const Input: React.ForwardRefRenderFunction<InputRefProps, InputProps> = ({ styleContainer, diferTooltip = false, errors, styleInput, label, ...props }, ref) => {
    const inputRef = useRef<HTMLDivElement>(null)
    const [hoverError, setHoverError] = useState<boolean>(false)

    useEffect(() => {
        document.getElementsByClassName(label)[0].getElementsByTagName("input")[0].setAttribute("style", "line-height:120px")
    }, [])

    const getInputValue = useCallback(() => {
        return inputRef.current?.getElementsByTagName('input')[0].value ? inputRef.current?.getElementsByTagName('input')[0].value : ""
    }, [inputRef])

    const setInputValue = useCallback((newValue) => {
        document.getElementsByClassName(label)[0].getElementsByTagName("input")[0].value = newValue
    }, [])

    useImperativeHandle(ref, () => {
        return {
            getInputValue,
            setInputValue
        };
    });

    const focusInput = useCallback(() => {
        inputRef.current?.getElementsByTagName('input')[0].focus()
    }, [])

    return (
        <Container ref={(ref: any) => ref = ref}>
            <ContainerInput style={{ ...styleContainer }} error={!!errors}>
                <InputBase className={label} autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" {...props} style={{ ...styleInput }} ref={inputRef} />
                {!!errors &&
                    <ContainerError onClick={focusInput}>
                        <AlertError onMouseEnter={() => setHoverError(true)} onMouseLeave={() => setHoverError(false)} style={{ opacity: errors !== null ? 1 : 0, cursor: errors !== null ? "default" : "text" }}>
                            <FiAlertCircle color={"#AF0708"} size={"1.375rem"} style={{ padding: "0.3125rem", paddingRight: 0 }} />
                        </AlertError>
                        {hoverError &&
                            <TooltipError diferTooltip={diferTooltip}>{errors}</TooltipError>
                        }
                    </ContainerError>
                }
            </ContainerInput>
        </Container>
    )
}

export default forwardRef(Input)