import React, { InputHTMLAttributes, forwardRef, useState, useCallback, useImperativeHandle, useRef, CSSProperties } from 'react';
import { AlertError, Container, ContainerError, Input, TooltipError } from './style';
import { FiAlertCircle } from 'react-icons/fi';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface InputLogonProps extends InputHTMLAttributes<HTMLInputElement> {
    onClickContainer: Function;
    styleContainer?: CSSProperties;
    type: "email" | "password" | "name";
    text: string;
    error: string | null;
    onModify?: Function;
}

export interface RefInputLogonProps {
    focus: () => void;
    blur: () => void;
    getValue: () => string;
}


const InputLogon: React.ForwardRefRenderFunction<RefInputLogonProps, InputLogonProps> = ({ error, onModify = () => { }, onClickContainer, text, type, styleContainer = {}, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [hoverError, setHoverError] = useState<boolean>(false)

    const [showPassword, setShowPassword] = useState(true)

    const [typeInput, setTypeInput] = useState("password")

    const handleShowPassword = () => {
        setShowPassword(!showPassword)
        if (showPassword) {
            setTypeInput("name")
        } else {
            setTypeInput("password")
        }
    }
    const focus = useCallback(() => {
        inputRef.current?.focus()
    }, [inputRef])

    const blur = useCallback(() => {
        inputRef.current?.blur()
    }, [inputRef])

    const getValue = useCallback(() => {
        return inputRef.current?.value ? inputRef.current?.value : ""
    }, [inputRef])

    useImperativeHandle(ref, () => {
        return {
            focus,
            blur,
            getValue
        }
    })

    return (
        <Container onClick={() => onClickContainer()} style={{ ...styleContainer, border: error !== null ? "2px solid #AF0708" : "2px solid #0b243b" }}>
            <Input
                type={type === "password" ? typeInput : "name"} {...props}
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                placeholder={text}
                ref={inputRef}
                style={{ outline: 0, fontFamily: "Gotham-Medium", width: "100%", boxShadow: "0 0 0 0", border: " 0 none", background: "#0b243b", color: "#FFF", fontSize: 16 }}

            />
            {error !== null &&
                <ContainerError>
                    <AlertError onMouseEnter={() => setHoverError(true)} onMouseLeave={() => setHoverError(false)} style={{ opacity: error !== null ? 1 : 0, cursor: error !== null ? "default" : "text" }}>
                        <FiAlertCircle color={"#AF0708"} size={"1.375rem"} style={{ padding: "0.3125rem" }} />
                    </AlertError>
                    {hoverError &&
                        <TooltipError>{error}</TooltipError>
                    }
                </ContainerError>
            }
            {type === "password" &&
                <div onClick={handleShowPassword} style={{ color: error !== null ? "#AF0708" : "#fff", cursor: "pointer", marginRight: 3 }}>
                    {!showPassword ?
                        <Visibility /> :
                        <VisibilityOff />
                    }
                </div>
            }

        </Container>
    )
}

export default forwardRef(InputLogon)