import React, { useEffect, useState } from "react";
import {
  CardDetatalhes,
  Container,
  ContainerDescriptionAndChat,
  Descricao,
  Label,
} from "./style";
import { useHistory } from "react-router-dom";
import { useGlobal } from "../../../hooks/global";
import { Chat } from "../../../components/Chat";
import { io } from "socket.io-client";
export const DetalhesPropostas: React.FC = () => {
  const { push, location } = useHistory<any>();
  const { languagePage } = useGlobal();
  const [propostas, setPropostas] = useState<any>();
  const socket = io(`${process.env.REACT_APP_CHAT}`);
  useEffect(() => {
    if (!location || !location.state) {
      return push("/candidate/propostas");
    }

    const { proposta } = location.state;
    setPropostas(proposta);
  }, [location, push]);

  return (
    <Container>
      <CardDetatalhes>
        {!!propostas && <Chat proposalId={propostas.id} socket={socket} />}
      </CardDetatalhes>
    </Container>
  );
};
