import React from "react"
import { ResultsContainer, TitleContainer, Text, TextTitle, TextContainer, NumberTitle, BlueCardContainer, WhiteCardContainer, LogOnButton, Link, ButtonClick } from "./styles"
import { useGlobal } from "../../hooks/global";
import WhatsAppButton from "../Whatsapp"


const Results: React.FC = () => {
    const { setLanguagePage, languagePage } = useGlobal();
    return (
        <ResultsContainer id="results">
            <BlueCardContainer>
                <TitleContainer>
                    <NumberTitle>
                        +800
                    </NumberTitle>
                </TitleContainer>
                <TextContainer>
                    <Text>
                        {languagePage === "PT" ? "Pessoas realizaram o teste Circular Soft skill com 99% de acertos." : "People took the Circular Soft Skills test with 99% accuracy."}
                    </Text>
                </TextContainer>
            </BlueCardContainer>
            <WhiteCardContainer>
                <TitleContainer>
                    <TextTitle>
                       MVP
                    </TextTitle>
                </TitleContainer>
                <TextContainer>
                    <Text>
                        {languagePage === "PT" ? "Concluído com sucesso em Indústria, Consumidores e Escolas." : "Successfully completed in Industry, Consumers and Schools."}
                    </Text>
                    <LogOnButton style={ButtonClick}>
                        <Link
                            href="https://youtu.be/GTkvbqVFXlQ?feature=shared"
                            target="_blanck"
                        >
                            {languagePage === "PT"
                                ? "Clique aqui"
                                : "Click here"
                            } 
                        </Link>
                    </LogOnButton>
                </TextContainer>
            </WhiteCardContainer>
            <BlueCardContainer>
                <TitleContainer>
                    <NumberTitle>
                        +150
                    </NumberTitle>
                </TitleContainer>
                <TextContainer>
                    <Text>
                        {languagePage === "PT" ? "Mapeamentos de circular soft e hard skills integrados, com resultados satisfatórios." : "Integrated circular soft and hard skills mapping, with satisfactory results."}
                    </Text>
                </TextContainer>
            </BlueCardContainer>
            <WhiteCardContainer>
                <TitleContainer>
                    <NumberTitle>
                        80
                    </NumberTitle>
                </TitleContainer>
                <TextContainer>
                    <Text>
                        {languagePage === "PT" ? " Avaliações individuais, resultando num mapeamento para o planejamento das Empresas." : "Individual assessments, resulting in a mapping for the companies' planning."}
                    </Text>
                </TextContainer>
            </WhiteCardContainer>
            <BlueCardContainer>
                <TitleContainer>
                    <TextTitle>
                        {languagePage === "PT" ? "Artigos" : "Papers"}
                    </TextTitle>
                </TitleContainer>
                <TextContainer>
                    <Text>
                        {languagePage === "PT" ? "Científicos em Industrias gerados a partir da nossa metodologia sistêmica." : "Generated from our systemic methodology."}
                    </Text>
                    <LogOnButton style={ButtonClick}><Link
                        href="https://forms.gle/tJUdrLGfD3Y7mH8g6"
                        target="_blanck"
                      >{languagePage === "PT"
                      ? "Clique aqui"
                      : "Click here"} 
                        </Link></LogOnButton>
                </TextContainer>
            </BlueCardContainer>
            <WhiteCardContainer>
                <TitleContainer>
                    <NumberTitle>
                        80
                    </NumberTitle>
                </TitleContainer>
                <TextContainer>
                    <Text>
                        {languagePage === "PT" ? "Avaliações Individuais, resultando num diagnóstico de Circularidade das Organizações." : "Individual Assessments, resulting in a diagnosis of Circularity of Organizations."}
                    </Text>
                </TextContainer>
                
            </WhiteCardContainer>

        </ResultsContainer>
    )
}

export default Results