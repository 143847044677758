import styled from "styled-components";

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
`;

export const ContainerLogo = styled.div`
  display: flex;
  height: 80px;
  justify-content:center;
  align-items: center;
`;

export const ContainerMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
`;

export const ItemMenu = styled.div`
  margin: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
`;

export const ContainerButtonLogin = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f6921d;
  border-radius: 5px;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  position: relative;
  box-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 0.7);
`;

export const TextLogin = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px;
  font-weight: bold;
`;

export const ContainerButtonLanguage = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  box-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 0.7);
`;

export const ContainerImageCountry = styled.div`
  display: flex;

  height: 15px;
  width: 15px;
  margin-right: 6px;
`;

export const ButtonSpan = styled.div`
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  :hover {
    background-color: #f6921d;
    color: #fff;
  }
`;

export const ContainerLanguageOption = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  width: 50px;
  :hover {
    background-color: #dddddd;
  }
`;
