import styled, { css } from "styled-components";

interface tagProps {
    isRespondida: boolean;
}

export const ContainerTag = styled.div<tagProps>`
    display: flex;
    min-width: 200px;
    width: 20%;
    margin: 10px;
    flex-direction: column;
    background: #081118;
    padding: 20px;
    box-shadow: 0.125rem 0.125rem 0.5rem rgba(0,0,0,1);
    border-radius: 5px;
    ${props => !props.isRespondida && css`
        background: #424242;
        color:#FFF;
    `}
`

export const TitleTag = styled.div<tagProps>`
    display: flex;
    flex-direction: row;
    color: #f15115;
    align-items: center;
    ${props => !props.isRespondida && css`
        color:#FFF;
    `}
`

export const ContainerIconTag = styled.div<tagProps>`
    display: flex;
    background: #FFF;
    padding: 10px;
    border-radius: 5px;
    margin-right: 10px;
    ${props => !props.isRespondida && css`
        color:#000;
    `}
`

export const Button = styled.div<tagProps>`
    display: flex;
    cursor: pointer;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    padding: 10px;
    flex-direction: row;
    border-radius: 5px;
    background: #f15115;
    ${props => !props.isRespondida && css`
        background:#000;
        cursor: not-allowed;
    `}
`


export const ConEmailTel = styled.div`

    .media-277{
        margin: "auto"
    }
    @media(max-width: 300px){
        .media-277{
            margin-left: -20px;
        }
    }
    @media(max-width: 642px){
        .flexWOne{
            flex-wrap: wrap;
            justify-content: center;
        text-align: center;

        }
    }
    @media(max-width: 706px){
        
        .flexW{
            flex-wrap: wrap;
            
            justify-content: center;
            text-align: center;
        }
    }
`