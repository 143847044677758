
import { useParams } from 'react-router-dom';
import { useGlobal } from '../../../hooks/global';
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardGroup,
  CardImg,
  CardText,
  CardTitle,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import api from '../../../services/api';
import { Button, Conteiner, Titulo } from "./style";
export const TrocarConsultor = () => {

    // const id = props.match.params.id;
    // id: proposta
    const [id,  setId] = useState<any>()
    const {tipo, idParam} = useParams<any>();
    const [dados, setDados] = useState<any[]>([]);
    const [selectionId, setSelectionId] = useState<string>("");
    const {push} = useHistory();

    
    useEffect(() => {

    api.get(`/company/list-consultants/${tipo}`).then(response =>{
      const { consultants, selection } = response.data;
      setDados(consultants)

    }).catch(err =>{
      console.log(err)
    })
    const i = "6a66b827-8288-49cb-8d9f-987bb847dd65"
    api.get("/company/proposal/"+ i).then(response =>{
 
    }).catch(error=>{
      console.log(error.message)
    })

        
      }, []);

    return (
      <Conteiner>
      <Titulo>Consultores</Titulo>
      <hr style={{ color: "#000" }} />
      <CardGroup style={{ margin: "auto", justifyContent: "center" }}>
        {dados.map((d) => {
          return (
            <Card className="cards">
              <CardImg
                className="card-img"
                style={{
                  backgroundColor: "#FFFFFF",
                  width: "100%",
                  height: 300,
                }}
                src={
                  d.avatar !== null
                    ? d.avatar
                    : "https://res.cloudinary.com/hkkzuevm3/image/upload/v1645643455/Assets-FlashPlayHr/perfil_f6utrt.png"
                }
              />
              <CardBody style={{ textAlign: "center" }}>
                <CardTitle tag="h5">Valor/Hora: R$ {d.valueHour}</CardTitle>

                <CardText style={{ height: 100 }}>
                  {d.description}
                  <br></br>
                  {/* {decidindoPerfil(d.soft, d.hard)} */}
                </CardText>

                <Button
                  className="buttons"
                  onClick={() => {
                    push({
                      pathname: "/company/perfilconsultor",
                      state: {
                        user: d,
                        tipo: tipo,
                        idProposta: idParam
                      },
                    });
                  }}
                >
                  Ver Perfil
                </Button>
              </CardBody>
            </Card>
          );
        })}
      </CardGroup>
    </Conteiner>
    )

}

