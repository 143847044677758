const Colors = {
  orange: "#f27823",
  orangeLight: " #f08134 ",
  textWhite: "#fff",
  blue: "#3087C3",
  darkBlue: "#00213D",
  darkBlue2: "#010648",
  borderColor: "#B1B1B1",
  backgroundWhite: "#fff",
};

export default Colors;
