import styled from "styled-components";

export const ContainerGeral = styled.div`
   background: #ccc;
    justify-content: center;
    align-items: center;
    margin: auto;
    height:100vh;
    .card-geral{
        
        width: 100%;
        max-width:300px;
        min-width: 200px;
        height: 100%;
        max-height:400px;
        min-height:200px;
        background-color:#134480;
        margin: 10px;
        border-radius: 20px;
        font-family: "Gotham-Medium";

        button{
            width: 100%;
            border: none;
            cursor: pointer;
            justify-content: center;
            color: #fff;
            padding: 10px;
            flex-direction: row;
            border-radius: 5px;
            background: #f08134;
        }
        
    }
    .texto-descr{
    color: #134480;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-y: auto;
    padding: 12px;
    border-radius: 5px;
  
    font-family: Gotham-Medium;
    font-size: 14px;
  }

`
export const Title = styled.h1`
    color:#134480;
    margin: auto;
    text-align: center;
    margin-top: 100px;
    padding: 10px;
    font-family: "Gotham-Medium";
`