import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 20px;
`;

export const ContainerLoadingOne = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-family: "Gotham-Bold";
  color: #FFF;
  font-size: 26px;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
  padding: 10px;
  margin-bottom: 50px;
`