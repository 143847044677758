import React, { useCallback, useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { sectorInterface } from "../../../interfaces/sectorInterface";
import api from "../../../services/api";
import { useHistory } from "react-router";
import { useGlobal } from "../../../hooks/global";
import { CircularProgress } from "@material-ui/core";
import { useAuth } from "../../../hooks/auth";
import { FaFileSignature, FaUser } from "react-icons/fa";
import { useToast } from "../../../hooks/toast";
import {
  CardContainer,
  Container,
  Pagination,
  PaginationButton,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardBodyButton,
  FooterButton,
} from "./style";

export const CompanySectors: React.FC = () => {
  const [sectors, setSectors] = useState<sectorInterface[]>(
    [] as sectorInterface[]
  );
  const [atualPage, setAtualPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const { setAtualPageGlobal, languagePage } = useGlobal();
  const [loading, setLoading] = useState<boolean>(true);
  const [arrayPage, setArrayPage] = useState<number[]>([]);
  const { user } = useAuth();
  const { addToast } = useToast();
  const { push } = useHistory();

  const searchSector = useCallback(async () => {
    try {
      setSectors([]);
      setArrayPage([]);
      setNumberOfPages(1);
      const resu = await api.get(
        `/company/getCompanySector/${user.id}`
      );

      setSectors(resu.data.sectorList);
      for (let i = 0; i < Math.ceil(resu.data.count / 10); i++) {
        setArrayPage((state) => [...state, i + 1]);
      }
    } catch (err: any) {
      console.log(err); 
      if (err.response.data.message === "PLAN_ERROR") {
        const typeError = err.response.data.path;

        if (user.preference !== "consultancy") {
          if (typeError === "noPlan") {
            push("/company/planos");
            addToast({
              title:
                languagePage === "PT"
                  ? "Usuário não possui um plano"
                  : "User does not have a plan",
              description: languagePage === "PT" ? "Escolha um plano" : "Choose a plan",
              icon: "alert",
              type: "error",
            });
          } else if (typeError === "statusPlan") {
            addToast({
              title: "Seu plano está desativado",
              description: "Para ativar, por favor efetuar o pagamento.",
              icon: "alert",
              type: "error",
            });
          } else if (typeError === "expiresIn") {
            push("/company/planos");
            addToast({
              title: "Seu plano expirou",
              description: "Renove seu plano",
              icon: "alert",
              type: "error",
            });
          }
        }
      }
    }
    setLoading(false);
  }, [atualPage]);

  useEffect(() => {
    setAtualPageGlobal("/company/sector");
    const timer = setTimeout(searchSector, 200);
    return () => {
      clearTimeout(timer);
      setSectors([]);
      setArrayPage([]);
    };
  }, [atualPage, searchSector, setAtualPageGlobal]);

  const removeSelection = useCallback(async (selectionId) => {
    setLoading(true);
    setTimeout(async () => {
      const resu = await api.delete(`/company/selection/${selectionId}`);
      searchSector();
    }, 200);
  }, []);
  const [openModalCodigo, setOpenModalCodigo] = useState<boolean>(false);
  const [selectionId, setSelectionId] = useState<any>();

  return (
    <Container>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          <CardContainer>
            {sectors.length === 0 && (
              <h5 className="text-not">Nenhuma seleção criada</h5>
            )}
            {sectors.length > 0 &&
              sectors.map((sector) => (
                <Card key={sector.name}>
                  <CardHeader>
                    <div className="box-icon">
                      {user.avatar && (
                        <img
                          style={{ height: 50 }}
                          src={user.avatar}
                          alt="Avatar"
                        />
                      )}
                      {!user.avatar && <FaUser size={26} />}
                    </div>
                    <h5>{sector.name}</h5>
                  </CardHeader>
                  <CardBody>
                    <CardBodyButton
                      background="#2360B2"
                      onClick={() =>
                        push(`/company/sectorCandidates/${sector.name}`)
                      }
                    >
                      {languagePage === "PT"
                        ? "Candidatos do Setor"
                        : "Sector Candidates"}
                    </CardBodyButton>
                    {/* <CardBodyButton
                      background="#2360B2"
                      onClick={() =>
                        push(`/company/candidatos/${selection.id}`)
                      }
                    >
                      {languagePage === "PT"
                        ? "Relatório coletivo"
                        : "Coletive report"}
                    </CardBodyButton> */}
                  </CardBody>
                  {/* <CardFooter>
                    {selection.public ? null : (
                      <h5>Código de acesso: {selection.codeAccess}</h5>
                    )}
                    <div className="box-footer-buttons">
                      <FooterButton
                        background="#083f86"
                        onClick={() =>
                          push({
                            pathname: "/company/editar",
                            state: {
                              selection: selection,
                            },
                          })
                        }
                      >
                        {languagePage === "PT" ? "Editar" : "Edit"}
                      </FooterButton>
                      <FooterButton
                        background="#f0954b"
                        onClick={() => removeSelection(selection.id)}
                      >
                        {languagePage === "PT" ? "Excluir" : "Delete"}
                      </FooterButton>
                    </div>
                  </CardFooter> */}
                </Card>
              ))}
          </CardContainer>
          <Pagination>
            {!!arrayPage &&
              arrayPage.length > 0 &&
              arrayPage.map((value) => (
                <PaginationButton
                  active={atualPage === value}
                  key={value}
                  onClick={() => {
                    setLoading(true);
                    setAtualPage(value);
                  }}
                >
                  {value}
                </PaginationButton>
              ))}
          </Pagination>
        </>
      )}
      {languagePage === "PT" ? (
        <Container
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "center",
            color: "#64666b",
            width: "100%",
            maxWidth: "1000px",
            minWidth: "250px",
            marginBottom: 20,
            fontSize: 26,
            fontFamily: "Gotham-Medium",
            marginTop: sectors.length === 0 && !loading ? 20 : 0,
          }}
        >
          <div
            style={{
              textAlign: "justify",
              margin: 20,
              width: "50%",
              minWidth: "250px",
            }}
          >
            <p>
              Caro Cliente, <small style={{ color: "#134480" }}>a</small> nossa
              seleção já vem pronta
              <small style={{ color: "#134480" }}>
                {" "}
                para uma avaliação de{" "}
              </small>{" "}
              Soft Skill e Hard Skill para o colaborador/candidato{" "}
              <small style={{ color: "#134480" }}>
                {" "}
                de forma integrada para uma vaga em que as questões circulares e
                sistêmicas terão um{" "}
              </small>
              peso considerável na avaliação.
            </p>
          </div>

          <div
            style={{
              width: "40%",
              minWidth: "250px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img
              style={{ width: 250, margin: "auto" }}
              src={
                "https://res.cloudinary.com/hkkzuevm3/image/upload/v1648676538/Assets-FlashPlayHr-webp/ilustracao1_n6ekbi_uzpgpg.webp"
              }
              alt="Imagem"
            />
          </div>
          {/*  */}
          {/*
          <div
            style={{
              width: "40%",
              minWidth: "250px",
              justifyContent: "center",
              textAlign: "center",
              marginTop: 20,
            }}
          >
            <img
              style={{ width: 250, margin: "auto" }}
              src={
                "https://res.cloudinary.com/hkkzuevm3/image/upload/v1649178813/Assets-FlashPlayHr-webp/ilustracao2_yghqpb2_yhzgl4.webp"
              }
              alt="Imagem"
            />
          </div>
          <div
            style={{
              textAlign: "left",
              margin: 20,
              marginBottom: 0,
              width: "50%",
              minWidth: "250px",
            }}
          >
            <p>
              <small style={{ color: "#134480", margin: 0, padding: 0 }}>
                Logo em seguida também{" "}
              </small>
              apresentamos um questionário Hard Skill
              <small style={{ color: "#134480" }}>
                {" "}
                que será realizado para aqueles candidatos a uma{" "}
              </small>
              vaga em Economia Circular, ESG, Inovação Digital Sustentável e
              Design Circular. 
              <small style={{ color: "#134480" }}>
                Ou seja, o nosso diferencial é integrar a Soft Skill e Hard
                skill. Apresentamos uma estrutura
              </small>
            </p>
          </div>
          <div
            style={{
              textAlign: "left",
              width: "90%",
              margin: 20,
              padding: 10,
              marginTop: -10,
              maxWidth: 900,
              minWidth: "250px",
            }}
          >
            <p>
              de questionário para que as Empresas{" "}
              <small style={{ color: "#134480" }}>
                possam complementar as questões com dados mais específicos para
              </small>
              &ensp; metodologias ágeis, modelos sistêmicos, comunicação e
              Marketing Digital e Inovação, ESG e Economia Circular.
              <br />
              Caso prefira, poderemos também prestar uma&ensp;
              <small style={{ color: "#134480" }}>
                consultoria para elaboração de uma avaliação específica e de
                acordo com as peculiaridades da sua Empresa.
              </small>
            </p>
          </div>
          */}
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "center",
            color: "#64666b",
            width: "100%",
            maxWidth: "1000px",
            minWidth: "250px",
            marginBottom: 20,
            fontSize: 26,
            marginTop: sectors.length === 0 && !loading ? 20 : 0,
          }}
        >
          <div
            style={{
              textAlign: "left",
              margin: 20,
              width: "50%",
              minWidth: "250px",
            }}
          >
            <p>
              Dear Client, <small style={{ color: "#134480" }}>our</small>{" "}
              selection comes ready
              <small style={{ color: "#134480" }}>
                {" "}
                for an assessment of{" "}
              </small>{" "}
              Soft Skill and Hard Skill for the candidate{" "}
              <small style={{ color: "#134480" }}>
                {" "}
                in an integrated way for a position in which the questions for a
                position in which the circular{" "}
              </small>
              and systemic questions will have a considerable weight in the
              evaluation.
            </p>
          </div>

          <div
            style={{
              width: "40%",
              minWidth: "250px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img
              style={{ width: 250, margin: "auto" }}
              src={
                "https://res.cloudinary.com/hkkzuevm3/image/upload/v1648676538/Assets-FlashPlayHr-webp/ilustracao1_n6ekbi_uzpgpg.webp"
              }
              alt="Imagem"
            />
          </div>
          {/*  */}
          <div
            style={{
              width: "40%",
              minWidth: "250px",
              justifyContent: "center",
              textAlign: "center",
              marginTop: 20,
            }}
          >
            <img
              style={{ width: 250, margin: "auto" }}
              src={
                "https://res.cloudinary.com/hkkzuevm3/image/upload/v1649178813/Assets-FlashPlayHr-webp/ilustracao2_yghqpb2_yhzgl4.webp"
              }
              alt="Imagem"
            />
          </div>
          <div
            style={{
              textAlign: "left",
              margin: 20,
              marginBottom: 0,
              width: "50%",
              minWidth: "250px",
            }}
          >
            <p>
              <small style={{ color: "#134480" }}>Soon after, </small>
              we also present a Hard Skill questionnaire
              <small style={{ color: "#134480" }}>
                {" "}
                that will be carried out for those candidates for a{" "}
              </small>
              vacancy in Circular Economy, ESG, Sustainable Digital Innovation
              and Circular Design.
              <small style={{ color: "#134480" }}>
                {" "}
                In other words, our differential is to integrate Soft Skill and
                Hard Skill.
              </small>
            </p>
          </div>
          <div
            style={{
              textAlign: "justify",
              width: "90%",
              margin: 20,
              padding: 10,
              marginTop: -10,
              maxWidth: 900,
              minWidth: "250px",
            }}
          >
            <p>
              We present a questionnaire structure {" "}
              <small style={{ color: "#134480" }}>
                so that Companies can complement the questions with more
                specific data
              </small>{" "}
              for Agile Methodologies, Systemic Models, Communication and
              Digital Marketing and Innovation, ESG and Circular Economy more
              specific. If you prefer, we can also provide
              <small style={{ color: "#134480" }}>
                {" "}
                consultancy for the preparation of a specific assessment and in
                accordance with the peculiarities of your Company.
              </small>
            </p>
          </div>
        </div>
      )}
    </Container>
  );
};
