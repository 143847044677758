import styled from "styled-components";
import { TiDelete } from "react-icons/ti";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  width: 50rem;
  position: relative;
  border-radius: 0.3125rem;
  outline: 0;
  * {
    font-family: "math";
    font-weight: bold;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.625rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;
  width: 100%;
  color: #fff;
  font-size: 1.5rem;

  border-bottom: 0.0625rem solid #dddddd;
  margin-bottom: 0.625rem;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #101a36;
  font-family: "Gotham-Medium", sans-serif;
`;

export const IconDelete = styled(TiDelete)`
  cursor: pointer;
`;

export const ButtonEnd = styled.div`
  margin: 0.625rem;
  margin-top: 0rem;
  color: #252323;
  cursor: pointer;
  font-family: "Gotham-Medium", sans-serif;
`;

export const Formulario = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const ContainerItemForm = styled.div`
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: row;
`;

export const ContainerBottom = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const Select = styled.select`
  display: flex;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #c9c9c9;
  background: #fff;
  color: #2b2b2b;
  font-family: "Gotham-Medium", sans-serif;
  margin: 16px 0;
`;
