import React, { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import {
  FaBuilding,
  FaCheckDouble,
  FaUserPlus,
  FaUserTie,
} from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { useForm } from "./../../hooks/form";
import { useGlobal } from "../../hooks/global";
import { Container, DecisionMenu, ContainerTitulo } from "./style";

interface DecisionData {
  onClickType: Function;
}

const Decision: React.FC<DecisionData> = ({ onClickType }) => {
  const {
    setType,
    setCidade,
    setCnpj,
    setCpf,
    setEmail,
    setEstado,
    setNome,
    setPais,
    setPosNumFunc,
    setPosPorte,
    setPosSexo,
    setSenha,
    setTelefone,
  } = useForm();

  const onClick = useCallback((newType) => {
    setCidade("");
    setCnpj("");
    setCpf("");
    setEmail("");
    setEstado("");
    setNome("");
    setPais("");
    setPosNumFunc(0);
    setPosPorte(0);
    setPosSexo(0);
    setSenha("");
    setTelefone("");
    setType(newType);
    onClickType();
  }, []);

  useEffect(() => {
    setType("");
  }, []);
  const { languagePage, setPreference } = useGlobal();
  const [notCompany, SetNotCompany] = useState(false);
  const [yesCompany, setYesCompany] = useState(false);

  const escolhendo = (es: string | any) => {
    if (es === "consultor") {
      setPreference("consultant");
    }
    if (es === "candidato") {
      setPreference("candidate");
    }
    if (es === "both") {
      setPreference("both");
      setType("empresa");
    }
    if (es === "selections") {
      setPreference("selections");
      setType("empresa");
    }
    if (es === "consultancy") {
      setPreference("consultancy");
      setType("empresa");
    }

    onClick(es);
  };
  const decisionCompany = () => {
    setYesCompany(true);
  };

  return (
    <Container>
      <div style={{ textAlign: "center", color: "#021f3a"}}>
        <ContainerTitulo>
        {languagePage === "PT"
          ? "Olá! gostaria de conhecer nosso metódo circular"
          : "Hello! I would like to know our circular method"}
        </ContainerTitulo>
      </div>
      {notCompany === false && yesCompany === false ? (
        <DecisionMenu style={{}}>
          <div style={{height: "19vh"}}  onClick={decisionCompany} className="decisao_menu">
            <span className="web-icons">
              <FaBuilding className="mobil-font-aw" size={18} color="#fff" />
              {languagePage === "PT" ? "Sou Empresa" : "I'm a company"}
            </span>
            <span className="mobil-icons">
              <FaBuilding style={{}} size={98} color="#fff" />
            </span>
          </div>
          <div style={{height: "19vh"}}  className="decisao_menu" onClick={() => SetNotCompany(true)}>
            {/* Sou Candidato  I'm a candidate*/}
            <span className="web-icons">
              <ImProfile className="mobil-font-aw" size={18} color="#fff" />
              {languagePage === "PT" ? "Sou colaborador / Consultor" : "I'm a collaborator / consultant"}
            </span>
            <span className="mobil-icons">
              <ImProfile className="mobil-font-aw" size={98} color="#fff" />
            </span>
          </div>
        </DecisionMenu>
      ) : notCompany === true ? (
        <DecisionMenu>
          <div onClick={() => escolhendo("candidato")} className="decisao_menu">
            <span className="web-icons">
              <FaUserPlus className="mobil-font-aw" size={18} color="#fff" />
              {languagePage === "PT" ? "Permanente" : "Permanent"}
            </span>
            <span className="mobil-icons">
              <FaUserPlus className="mobil-font-aw" size={98} color="#fff" />
            </span>
          </div>
          <div className="decisao_menu" onClick={() => escolhendo("consultor")}>
            {/* Sou Candidato  I'm a candidate*/}
            <span className="web-icons">
              <FaUserTie className="mobil-font-aw" size={18} color="#fff" />
              {languagePage === "PT" ? "Consultor" : "Consultant"}
            </span>
            <span className="mobil-icons">
              <FaUserTie className="mobil-font-aw" size={98} color="#fff" />
            </span>
          </div>
        </DecisionMenu>
      ) : (
        <DecisionMenu style={{}}>
          <div
            style={{height: "19vh"}} 
            onClick={() => escolhendo("selections")}
            className="decisao_menu decisao_menu_company"
          >
            <span className="web-icons">
              <FaBuilding className="mobil-font-aw" size={18} color="#fff" />
              {languagePage === "PT" ? "Planejamento estratégico e sistemico" : "Strategic and systemic planning"}
            </span>
            <span className="mobil-icons_company">
              <FaBuilding className="mobil-font-aw" size={98} color="#fff" />
            </span>
          </div>
          {/* Código do botão para cadastro de consultoria */}
          {/* <div
            style={{height: "19vh"}} 
            className="decisao_menu decisao_menu_company"
            onClick={() => escolhendo("both")}
          >
            <span className="web-icons">
              <ImProfile className="mobil-font-aw" size={18} color="#fff" />
              {languagePage === "PT" ? "Consultoria / Cursos" : "Consultancy / Courses"}
            </span>
            <span className="mobil-icons_company">
              <ImProfile className="mobil-font-aw" size={98} color="#fff" />
            </span>
          </div> */}
          
        </DecisionMenu>
      )}
    </Container>
  );
};
/* Botão de todas as opções caso necessite
<div
  className="decisao_menu decisao_menu_company"
  onClick={() => escolhendo("consultancy")}
>
  <span className="web-icons">
    <FaCheckDouble className="mobil-font-aw" size={18} color="#fff" />
      {languagePage === "PT" ? "Ambos" : "Both"}
    </span>
    <span className="mobil-icons_company">
      <FaCheckDouble className="mobil-font-aw" size={98} color="#fff" />
    </span>
</div>*/

export default Decision;
