import {
  useElements,
  CardElement,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";

import { CircularProgress } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import { Container } from "./styles";
import { useGlobal } from "../../../hooks/global";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`);

interface StepProps {
  amount: number | undefined;
  proposalId: string | undefined;
}

const CardPaymentElement = ({
  amount,
  proposalId,
  paymentId,
  setPaymentId,
  selectedPlan,
  setPaymentIntent,
  setAvailablePlans,
  availablePlans,
  state,
  setState,
}: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const { push } = useHistory();
  const { languagePage } = useGlobal();

  const verify = (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (state === "plans") {
      confirmPayment();
    } else {
      handleSubmit();
    }
  };

  const confirmPayment = async () => {
    const res = await api.post("/company/confirm-proposal-invoice", {
      paymentId,
      selectedPlan,
    });

    const { success, status } = res.data;

    if (success) {
      addToast({
        type: "success",
        title: "Pagamento finalizado!",
        description: status,
        icon: "info",
      });
      setLoading(false);
      push("/company/consultoria/minhas-propostas");
    }
  };
  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement) as any,
    });

    if (error) {
      addToast({
        type: "error",
        description: error.message ? error.message : "",
        title: "",
        icon: "info",
      });
      return;
    }
    const response = await api.post("/company/check-details", {
      proposalValue: amount,
      proposalId: proposalId,
      paymentMethodId: paymentMethod?.id,
    });

    const { paymentIntent, availablePlans, paymentId } = response.data;

    if (paymentIntent && availablePlans) {
      setPaymentIntent(paymentIntent);
      setAvailablePlans(availablePlans);
      setPaymentId(paymentId);
      setState("plans");
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <CardElement className="card" />
      {state === "plans" &&
        availablePlans.length !== 0 &&
        availablePlans.map((plan: any) => <div>{plan.count}</div>)}
      {state === "plans" && availablePlans.length === 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "24px 0px 0px",
          }}
        >
          <p>
            {languagePage === "PT"
              ? "Nenhuma opção de parcelamento"
              : "No installment option"}
          </p>
        </div>
      )}
      <button onClick={verify}>
        {loading === false ? (
          state === "plans" ? (
            languagePage === "PT" ? (
              "Finalizar compra"
            ) : (
              "Checkout"
            )
          ) : languagePage === "PT" ? (
            "Avançar"
          ) : (
            "Advance"
          )
        ) : (
          <CircularProgress size={18} color="inherit" />
        )}
      </button>
    </div>
  );
};

export const StepCard: React.FC<StepProps> = ({
  amount,
  proposalId,
}: StepProps) => {
  const [paymentIntent, setPaymentIntent] = useState<any>();
  const [paymentId, setPaymentId] = useState<any>();
  const { push } = useHistory();
  const [selectedPlan, setSelectedPlan] = useState<any>();
  const [availablePlans, setAvailablePlans] = useState<any[]>([]);
  const [state, setState] = useState<string>("");
  const { languagePage } = useGlobal();

  return (
    <Container>
      <div className="box-card">
        <h4>
          {languagePage === "PT" ? "Valor do pagamento:" : "Payment amount:"}{" "}
          {Intl.NumberFormat("pt-BR", {
            currency: "BRL",
            style: "currency",
          }).format(amount as number)}
        </h4>
        <Elements stripe={stripePromise}>
          <CardPaymentElement
            amount={amount}
            proposalId={proposalId}
            setState={setState}
            setAvailablePlans={setAvailablePlans}
            setPaymentIntent={setPaymentIntent}
            state={state}
            selectedPlan={selectedPlan}
            paymentId={paymentId}
            setPaymentId={setPaymentId}
            availablePlans={availablePlans}
          />
        </Elements>
        <button
          className="voltar"
          onClick={() => {
            if (state === "plans") {
              setState("");
            } else {
              push("/company/consultoria/minhas-propostas");
            }
          }}
        >
          {languagePage === "PT" ? "Voltar" : "Value"}
        </button>
      </div>
    </Container>
  );
};
