import styled from "styled-components";

export const Setas = styled.div`
  display: flex;

  @media (max-width: 433px) {
    display: none;
  }
`;

export const SetasTwo = styled.div`
  display: none;
  width: 50px;
  height: 50px;
  padding: 20px;
  margin: 10px;

  @media (max-width: 433px) {
    display: flex;
  }
`;
