import React from "react"
import { useGlobal } from "../../hooks/global";

import SocialBar from "../HomeSocialMedia"
import { TextContainer } from "./styles"
import { FooterContainer, Text } from "./styles"

const Footer: React.FC = () => {
    const { setLanguagePage, languagePage } = useGlobal();
    return (
        <FooterContainer>

            <TextContainer>
                <Text>
                    {languagePage === "PT" ? "FlashPlayHR/EcoRecitec 2022 - Todos os Direitos Reservados" : "FlashPlayHR/EcoRecitec 2022 - All Rights Reserved"}
                </Text>
            </TextContainer>
            <SocialBar />

        </FooterContainer>
    )
}

export default Footer