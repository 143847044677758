import React from "react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { useForm } from "../../../hooks/form";
import { useGlobal } from "../../../hooks/global";
import { BotoesForm, Container, ContainerButton, Titulo } from "../style";
import { SexOpcao } from "./style";

interface SexCandidateData {
  onNext: Function;
  onBack: Function;
}

const SexCandidate: React.FC<SexCandidateData> = ({ onNext, onBack }) => {
  const { setPosSexo, posSexo } = useForm();
  const { languagePage } = useGlobal();


  return (
    <Container>
      <div>
        <Titulo>
          {languagePage === "PT" ? "Selecione seu gênero" : " Select your gender:"}
        </Titulo>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            fontFamily: "Gotham-Medium",
            textAlign: "center",
            justifyContent: "center",
            padding: 10,
          }}
        >
          <SexOpcao
            onClick={() => setPosSexo(0)}
            style={{
              border:
                posSexo === 0 ? "4px solid #FFF" : "4px solid #134480",
            }}>
            {languagePage === "PT" ? "  Masculino" : "Male"}
          </SexOpcao>
          <SexOpcao
            onClick={() => setPosSexo(1)}
            style={{
             border:
                posSexo === 1 ? "4px solid #FFF" : "4px solid #134480",
             }}>
            {languagePage === "PT" ? "Feminino" : "Female"}
          </SexOpcao>
          <SexOpcao
            onClick={() => setPosSexo(2)}
            style={{
             border:
                posSexo === 2 ? "4px solid #FFF" : "4px solid #134480",
            }}>
            {languagePage === "PT" ? "Outros" : "Other"}
          </SexOpcao>
        </div>
        <ContainerButton>
          <BotoesForm
            onClick={() => onBack()}>
            <GoChevronLeft />
            {languagePage === "PT" ? "Anterior" : "Back"}

          </BotoesForm>
          <BotoesForm
            onClick={() => onNext()}>
            {languagePage === "PT" ? "Próximo" : "Next"}

            <GoChevronRight style={{ marginLeft: 5 }} />
          </BotoesForm>
        </ContainerButton>
      </div>
    </Container>
  );

};

export default SexCandidate;
