import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface FormContextData {
  type: string;
  nome: string;
  responsavel: string;
  email: string;
  senha: string;
  cnpj: string;
  cpf: string;
  numberIdentifier: string;
  telefone: string;
  cidade: string;
  estado: string;
  pais: string;
  posNumFunc: number;
  posPorte: number;
  posSexo: number;
  setType(value: string): void;
  setResponsavel(value: string): void;
  setSenha(value: string): void;
  setNome(value: string): void;
  setEmail(value: string): void;
  setCnpj(value: string): void;
  setCpf(value: string): void;
  setTelefone(value: string): void;
  setCidade(value: string): void;
  setEstado(value: string): void;
  setPais(value: string): void;
  setPosNumFunc(value: number): void;
  setPosPorte(value: number): void;
  setPosSexo(value: number): void;
  setNumberIdentifier(value: string): void;
}

const FormContext = createContext<FormContextData>({} as FormContextData);

export const FormProvider: React.FC = ({ children }) => {
  const [type, setType] = useState("");
  const [senha, setSenha] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cpf, setCpf] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [pais, setPais] = useState("");
  const [posNumFunc, setPosNumFunc] = useState(0);
  const [posPorte, setPosPorte] = useState(0);
  const [posSexo, setPosSexo] = useState(0);
  const [responsavel, setResponsavel] = useState("");
  const [numberIdentifier, setNumberIdentifier] = useState("");
  return (
    <FormContext.Provider
      value={{
        senha,
        responsavel,
        setResponsavel,
        setSenha,
        cidade,
        cnpj,
        cpf,
        email,
        estado,
        nome,
        pais,
        posNumFunc,
        posPorte,
        posSexo,
        setCidade,
        setCnpj,
        setCpf,
        setEmail,
        setEstado,
        setNome,
        setPais,
        setPosNumFunc,
        setPosPorte,
        setPosSexo,
        setTelefone,
        telefone,
        setType,
        type,
        numberIdentifier,
        setNumberIdentifier,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export function useForm(): FormContextData {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useForm must be used within an FormProvider");
  }
  return context;
}
