import React, { useCallback, useEffect, useState } from "react";
import { FaFire } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { useGlobal } from "../../../hooks/global";
import { selectionInterface } from "../../../interfaces/selectionInterface";
import api from "../../../services/api";
import { animated, useTransition } from "@react-spring/web";
import {
  Alternativas,
  BotaoNext,
  BotaoVoltar,
  BoxBackTitle,
  BoxTextButton,
  Container,
  ContainerGenerico,
  Conteudo,
  DisplayFlexDirectionColumn,
  DisplayJustfyCenter,
  Enuciado,
  HardSkillTitulo,
  ImagemAdnTexto,
  Instrucoes,
  Logo,
  LogoAndLegenda,
  Questão,
  Setas,
  TextoExameHard,
} from "./style";
import { GoChevronRight } from "react-icons/go";
import Cronometro from "../../../components/Cronometro";

interface Questoes {
  title: string;
  a: string;
  b: string;
  c: string;
  d: string;
}

export const HardSkillNovo: React.FC = () => {
  const [atualPage, setAtualPage] = useState(0);
  const { push } = useHistory();
  const { selectionId }: any = useParams();
  const [selection, setSelection] = useState<selectionInterface>(
    {} as selectionInterface
  );
  const {
    setAtualPageGlobal,
    languagePage,
    tempoEncerrado,
    setParaCronometro,
  } = useGlobal();

  const [respostas, setRespostas] = useState<any[]>([]);
  const [questions, setQuestions] = useState<Questoes[]>([]);
  const [questionsEn, setQuestionsEn] = useState<Questoes[]>([]);
  const [umaQuestaoEn, setUmaQuestaoEn] = useState<Questoes>({} as Questoes);
  const [umaQuestao, setUmaQuestao] = useState<Questoes>({} as Questoes);
  const [escolido, setEscolhido] = useState("");
  const [posicao, setPosicao] = useState(1);
  const [selecionado, setSelecionado] = useState(0);

  useEffect(() => {
    setAtualPageGlobal("/candidate/minhasSelecoes");
  }, []);

  const res = async () => {
    try {
      const resu = await api.get(`/common/selection/${selectionId}`);
      const hard = resu.data.hard;
      if (!hard) {
        const dados = await api.get("/common/sel/questions/hard/PT");
        const dadosEn = await api.get("/common/sel/questions/hard/EN");
        setQuestions(dados.data.questions);
        setQuestionsEn(dadosEn.data.questions);
        setUmaQuestao(dados.data.questions[0]);
        setUmaQuestaoEn(dadosEn.data.questions[0]);

        setPosicao(1);
        setRespostas([]);
      } else {
        push("/candidate/minhasSelecoes");
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    res();
  }, []);
  const searchSelection = useCallback(async () => {
    const resu = await api.get(`/common/selectionData/${selectionId}`);
    setUmaQuestao(questions[0]);
    setUmaQuestaoEn(questions[0]);
    setSelection(resu.data);
  }, []);

  useEffect(() => {
    searchSelection();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("navBarDash")?.scroll({
        top: window.innerHeight,
        behavior: "smooth",
      });
    }, 200);
  }, [atualPage]);

  const transitions = useTransition(atualPage, {
    keys: null,
    from: { opacity: 0 },
    enter: { opacity: 1, config: { duration: 400 } },
    leave: { opacity: 0, config: { duration: 0 } },
  });
  const nada = (valor: string) => {
    return valor === undefined ? "" : valor;
  };

  const nextQuest = async (
    position: number,
    title: string,
    alternativa: string
  ) => {
    if (escolido !== "") {
      setPosicao(posicao + 1);
      setSelecionado(0);
      var ob = {
        title: title,
        marked: alternativa,
      };
      respostas.push(ob);
      if (posicao === 30) {
        try {
          const res = await api.post("/common/correction/question/hard", {
            questions: respostas,
          });
          const { total, theme_um, theme_dois, theme_tres } = res.data;

          const resposta: any = {
            question1: nada(respostas[0].marked),
            question2: nada(respostas[1].marked),
            question3: nada(respostas[2].marked),
            question4: nada(respostas[3].marked),
            question5: nada(respostas[4].marked),
            question6: nada(respostas[5].marked),
            question7: nada(respostas[6].marked),
            question8: nada(respostas[7].marked),
            question9: nada(respostas[8].marked),
            question10: nada(respostas[9].marked),
            question11: nada(respostas[10].marked),
            question12: nada(respostas[11].marked),
            question13: nada(respostas[12].marked),
            question14: nada(respostas[13].marked),
            question15: nada(respostas[14].marked),
            question16: nada(respostas[15].marked),
            question17: nada(respostas[16].marked),
            question18: nada(respostas[17].marked),
            question19: nada(respostas[18].marked),
            question20: nada(respostas[19].marked),
            question21: nada(respostas[20].marked),
            question22: nada(respostas[21].marked),
            question23: nada(respostas[22].marked),
            question24: nada(respostas[23].marked),
            question25: nada(respostas[24].marked),
            question26: nada(respostas[25].marked),
            question27: nada(respostas[26].marked),
            question28: nada(respostas[27].marked),
            question29: nada(respostas[28].marked),
            question30: nada(respostas[29].marked),
            value: total,
            valueT1: theme_um,
            valueT2: theme_dois,
            valueT3: theme_tres,
          };
          await api.post(`/common/answer/hard/${selectionId}`, resposta);

          setParaCronometro(true);
          localStorage.removeItem("timeFinal");
          localStorage.removeItem("tempoTotal");
          push("/candidate/minhasSelecoes");
        } catch (err) {
          console.log(err);
        }
      }
      if (tempoEncerrado === "true") {
        // fim do exame
        try {
          const res = await api.post("/common/correction/question/hard", {
            questions: respostas,
          });
          const { total, theme_um, theme_dois, theme_tres } = res.data;
          const resposta: any = {
            question1: nada(respostas[0].marked),
            question2: nada(respostas[1].marked),
            question3: nada(respostas[2].marked),
            question4: nada(respostas[3].marked),
            question5: nada(respostas[4].marked),
            question6: nada(respostas[5].marked),
            question7: nada(respostas[6].marked),
            question8: nada(respostas[7].marked),
            question9: nada(respostas[8].marked),
            question10: nada(respostas[9].marked),
            question11: nada(respostas[10].marked),
            question12: nada(respostas[11].marked),
            question13: nada(respostas[12].marked),
            question14: nada(respostas[13].marked),
            question15: nada(respostas[14].marked),
            question16: nada(respostas[15].marked),
            question17: nada(respostas[16].marked),
            question18: nada(respostas[17].marked),
            question19: nada(respostas[18].marked),
            question20: nada(respostas[19].marked),
            question21: nada(respostas[20].marked),
            question22: nada(respostas[21].marked),
            question23: nada(respostas[22].marked),
            question24: nada(respostas[23].marked),
            question25: nada(respostas[24].marked),
            question26: nada(respostas[25].marked),
            question27: nada(respostas[26].marked),
            question28: nada(respostas[27].marked),
            question29: nada(respostas[28].marked),
            question30: nada(respostas[29].marked),
            value: total,
            valueT1: theme_um,
            valueT2: theme_dois,
            valueT3: theme_tres,
          };
          await api.post(`/common/answer/hard/${selectionId}`, resposta);
          setParaCronometro(true);
          localStorage.removeItem("timeFinal");
          localStorage.removeItem("tempoTotal");

          push("/candidate/minhasSelecoes");
        } catch (err) {
          console.log(err);
        }
      }

      setUmaQuestao(questions[posicao]);
      setUmaQuestaoEn(questionsEn[posicao]);
      setEscolhido("");
    }
  };
  return (
    <Container>
      <BoxBackTitle>

        <HardSkillTitulo>
          {languagePage === "PT"
            ? "Exame Hard Skill da Seleção"
            : "Selection Hard Skill Exam "}{" "}
          "{selection.name ? selection.name : ""}"
        </HardSkillTitulo>
        <BotaoVoltar onClick={() => push("/candidate/minhasSelecoes")}>
          {languagePage === "PT" ? "Voltar" : "Come back"}
        </BotaoVoltar>
      </BoxBackTitle>

      <ContainerGenerico>
        <div className="box-logo">
          <LogoAndLegenda>
            <div>
              <Logo>
                <img
                  src={
                    "https://res.cloudinary.com/hkkzuevm3/image/upload/v1648750223/Assets-FlashPlayHr-webp/logo_k4qnwy_3_xspara.webp"
                  }
                  alt="Imagem"
                />
              </Logo>
            </div>
          </LogoAndLegenda>
          <div className="box-fire">
            <FaFire size={"9vh"} color="#8dcaee" />
            <TextoExameHard>
              <div>Exame quiz</div>
              <div>Hard Skill</div>
            </TextoExameHard>
          </div>
        </div>
        <Instrucoes>
          {transitions((style, i) => {
            if (i === 0) {
              return (
                <animated.div
                  style={{
                    width: "100%",
                    ...style,
                  }}
                >
                  <Conteudo>
                    <ImagemAdnTexto>
                      <div>
                        <div className="box-image">
                          <img
                            src={
                              "https://res.cloudinary.com/hkkzuevm3/image/upload/v1635024402/Assets-FlashPlayHr/hardSkill01_glrc2v.png"
                            }
                            alt="Imagem"
                          />
                        </div>
                      </div>
                      {languagePage === "PT" ? (
                        <div className="box-text">
                          Caro candidato, ao responder as perguntas hardskill de
                          Economia Cirular, ESG e Modelos Circulares, estará
                          fornecendo ao contratante uma visão integrada do seu
                          perfil: Habilidades soft skill e hard skill
                        </div>
                      ) : (
                        <div className="box-text">
                          Dear candidate, by answering the Circular Economy, ESG
                          and Circular Models hard skill questions, you are
                          providing the contractor with an integrated view of
                          your profile: Soft Skill and Hard Skill
                        </div>
                      )}

                      <div
                        onClick={() => {
                          setAtualPage(1);
                        }}
                        className="left-button-back"
                      >
                        <GoChevronRight />
                      </div>
                    </ImagemAdnTexto>
                    <BotaoNext>
                      <div
                        onClick={() => {
                          setAtualPage(1);
                        }}
                        className="left-button"
                      >
                        <GoChevronRight />
                      </div>
                    </BotaoNext>
                  </Conteudo>
                </animated.div>
              );
            } else {
              return (
                <animated.div
                  style={{
                    width: "100%",
                    ...style,
                  }}
                >
                  <Questão>
                    <DisplayFlexDirectionColumn>
                      <Enuciado>
                        {languagePage === "PT"
                          ? posicao + " - " + umaQuestao?.title
                          : posicao + " - " + umaQuestaoEn?.title}
                      </Enuciado>
                    </DisplayFlexDirectionColumn>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DisplayFlexDirectionColumn>
                        <BoxTextButton onClick={() => setSelecionado(1)}>
                          <Alternativas
                            onClick={() => setEscolhido("a")}
                            style={{
                              background:
                                selecionado === 1 ? "#0a213d" : "#133770",
                            }}
                          >
                            a
                          </Alternativas>
                          {languagePage === "PT"
                            ? umaQuestao?.a
                            : umaQuestaoEn?.a}
                        </BoxTextButton>
                        <BoxTextButton onClick={() => setSelecionado(2)}>
                          <Alternativas
                            onClick={() => setEscolhido("b")}
                            style={{
                              background:
                                selecionado === 2 ? "#0a213d" : "#133770",
                            }}
                          >
                            b
                          </Alternativas>
                          {languagePage === "PT"
                            ? umaQuestao?.b
                            : umaQuestaoEn?.b}
                        </BoxTextButton>
                        <BoxTextButton onClick={() => setSelecionado(3)}>
                          <Alternativas
                            onClick={() => setEscolhido("c")}
                            style={{
                              background:
                                selecionado === 3 ? "#0a213d" : "#133770",
                            }}
                          >
                            c
                          </Alternativas>
                          {languagePage === "PT"
                            ? umaQuestao?.c
                            : umaQuestaoEn?.c}
                        </BoxTextButton>
                        <BoxTextButton onClick={() => setSelecionado(4)}>
                          <Alternativas
                            onClick={() => setEscolhido("d")}
                            style={{
                              background:
                                selecionado === 4 ? "#0a213d" : "#133770",
                            }}
                          >
                            d
                          </Alternativas>
                          {languagePage === "PT"
                            ? umaQuestao?.d
                            : umaQuestaoEn?.d}
                        </BoxTextButton>
                        <DisplayJustfyCenter>
                          <div
                            onClick={() =>
                              nextQuest(
                                i,
                                languagePage === "PT"
                                  ? umaQuestao?.title
                                  : umaQuestaoEn?.title,
                                escolido
                              )
                            }
                            style={{
                              marginRight: 10,

                              background:
                                escolido === "" ? "#C4C4C4" : "#134480",

                              cursor:
                                escolido === "" ? "not-allowed" : "pointer",
                            }}
                            className="left-button-back"
                          >
                            <GoChevronRight />
                          </div>
                        </DisplayJustfyCenter>
                      </DisplayFlexDirectionColumn>
                      <div>
                        <Setas
                          onClick={() =>
                            nextQuest(
                              i,
                              languagePage === "PT"
                                ? umaQuestao?.title
                                : umaQuestaoEn?.title,
                              escolido
                            )
                          }
                          style={{
                            marginRight: 10,
                            background: escolido === "" ? "#C4C4C4" : "#134480",

                            cursor: escolido === "" ? "not-allowed" : "pointer",
                          }}
                          className="left-button"
                        >
                          <GoChevronRight />
                        </Setas>
                      </div>
                    </div>
                  </Questão>

                  <div style={{ float: "left", marginTop: 20 }}>
                    {tempoEncerrado !== "true" ? (
                      <Cronometro
                        background="#133770"
                        width="80px"
                        height="50px"
                        fontSize="22px"
                        tempoFinal={30}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </animated.div>
              );
            }
          })}
        </Instrucoes>
      </ContainerGenerico>
    </Container>
  );
};
