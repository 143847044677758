import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Header } from "../../components/Header";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import { ContainerGeral, Title } from "./style";
import * as Yup from "yup";
import { InputRefProps } from "../../components/Input";
import { useGlobal } from "../../hooks/global";
import SetorTypo from "../../components/Modals/SetorTypo";
import { FaUserAlt } from "react-icons/fa";
import { selectionInterface } from "../../interfaces/selectionInterface";
import { useToast } from "../../hooks/toast";
const PublicSelecoes: React.FC = () => {
  const { user, setUser } = useAuth();
  const { push } = useHistory();
  const [selectionId, setSelectionId] = useState<any>();
  const [selection, setSelection] = useState<any>();
  const { addToast } = useToast();
  const [errorCodeAccess, setErrorCodeAccess] = useState<string | null>(null);
  const inputCodeAccessRef = useRef<InputRefProps>(null);
  const { languagePage } = useGlobal();
  const [dataPublic, setDataPublic] = useState<selectionInterface[]>([]);
  const [dataConsultant, setDataConsultant] = useState<selectionInterface[]>(
    []
  );
  const [openModalCodigo, setOpenModalCodigo] = useState<boolean>(false);
  const SenhaAlterada = useCallback((data) => {
    setOpenModalCodigo(false);
  }, []);
  const buscarSelecoes = async () => {
    try {
      const resuPublic = await api.get(`/selections`);
      setDataPublic(resuPublic.data);
    } catch (err) {}
  };
  useEffect(() => {
    
    buscarSelecoes();
  }, []);

  const VerifyCodeAccess = useCallback(async () => {
    let resu;
    setErrorCodeAccess(null);

    const title = inputCodeAccessRef.current?.getInputValue();
    const schemaTitle = Yup.object().shape({
      title: Yup.string().required(
        languagePage === "PT" ? "Campo obrigatório" : "Required field"
      ),
    });
    try {
      await schemaTitle.validate({ title });
    } catch (err: any) {
      setErrorCodeAccess(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
    }
    return resu;
  }, [inputCodeAccessRef]);

  const solicitarInscricao = useCallback(
    async (selectionId: any, selectionPublic: boolean) => {
      if (user.preference === "consultant") {
        addToast({
          type: "info",
          icon: "alert",
          title: "Você não pode se candidatar a vaga",
          description: "vaga para candidato",
        });
        return;
      }
      if (selectionPublic === true) {
        setTimeout(async () => {
          await api.post(`/common/subscribeInSelection/${selectionId}`);
        }, 200);
      } else {
        const errorCodeAccess = await VerifyCodeAccess();

        if (!!!errorCodeAccess) {
          const codeAccessVerified =
            inputCodeAccessRef.current?.getInputValue();

          setTimeout(async () => {
            await api.post(`/common/subscribeInSelection/${selectionId}`, {
              codeAccess: codeAccessVerified,
            });
          }, 200);
        }
      }
    },
    [VerifyCodeAccess, user.preference]
  );
  const solicitando = (id: any, publi: any) => {
      if (publi === false) {
        setSelectionId(id);
        setOpenModalCodigo(true);
        push("/candidate/buscarSelecoes");
      } else {
        solicitarInscricao(id, publi);
        push("/candidate/buscarSelecoes");
      }
      push("/");
  };
  return (
    <>
      <Header />
      <ContainerGeral>
        {dataPublic.length === 0 ? (
          <Title>Não existe Seleções</Title>
        ) : (
          <Title>Seleções</Title>
        )}

        <SetorTypo
          open={openModalCodigo}
          selectionId={selectionId}
          onClose={() => setOpenModalCodigo(false)}
          onSave={SenhaAlterada}
        ></SetorTypo>
        <Container style={{ margin: "auto", marginTop: 40 }}>
          <Row style={{ justifyContent: "center", alignItems: "center" }}>
            {dataPublic.map((e) => {
              return (
                <Col xs={4} className="card-geral">
                  <div style={{ display: "flex", color: "#fff" }}>
                    <div style={{ 
                      width: "50px", 
                    height: 50, 
                    margin: "10px" }}>
                      <FaUserAlt size={40}></FaUserAlt>
                    </div>
                    <div  style={{
                      color: "#000",
                      width: "200px",
                      height: 100,
                      borderRadius: 10,
                      padding: 10,
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>{e.name}</div>
                  </div>

                  <div style={{ color: "#fff" }}>Descrição</div>
                  <div
                    className="texto-descr"
                    style={{
                      color: "#000",
                      width: "100%",
                      height: 130,
                      background: "#fff",
                      borderRadius: 10,
                      padding: 10,
                    }}
                  >
                    {e.description}
                  </div>

                  <div style={{ margin:10 ,marginTop: "10px" }}>
                    {user.id ? (
                      <button
                        type="button"
                        onClick={() => solicitando(e.id, e.public)}
                      >
                        Solicitar Inscricão
                      </button>
                    ) : (
                      <button type="button" onClick={() => push("/logon")}>
                        Faça sua conta e inscreva-se
                      </button>
                    )}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </ContainerGeral>
    </>
  );
};

export default PublicSelecoes;
