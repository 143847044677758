// ProfileMapping.tsx
import React, { useEffect, useState } from 'react';
import { Tabela } from './style';
import { useGlobal } from '../../hooks/global';
import api from '../../services/api';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
// Importe outros componentes, hooks e estilos necessários aqui

const ProfileMapping: React.FC = () => {
  const { push } = useHistory();
  const { setAtualPageGlobal, languagePage } = useGlobal();
  const [classifications, setClassifications] = useState<any[]>([]);
  const { selectionId }: any = useParams();

  useEffect(() => {
    api
      .get(`/company/generateClassification/${selectionId}`)
      .then((res) => {
        const { classification } = res.data;
        const classificationOrdenate = classification.sort(function (
          a: any,
          b: any
        ) {
          if (a.position > b.position) {
            return 1;
          }
          if (a.position < b.position) {
            return -1;
          }
          return 0;
        });

        setClassifications(classificationOrdenate);
      })
      .catch((err) => console.log(err));
  }, []);


  return (
    <>
      <div style={{
        fontSize: '2.15vh',
        color: '#6b6b6b',
        cursor: 'pointer',
        width: '100%',
        maxWidth: '200px',
        minWidth: '70px',
        borderRadius: '50px',
        border: '1px solid #6b6b6b',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '8px',
        position: 'absolute',
        left: '15px',
        top: '20px',
        transform: 'translateY(-50%)',
      }}
        className="voltar" onClick={() => push("/company/selecoes")}>
        {languagePage === "PT" ? "Voltar" : "Return"}
      </div>
      <Tabela
        style={{
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
          width: "100%",
          minWidth: 200,
          maxWidth: 1000,
          overflow: "auto",
          margin: "auto",
          marginBottom: 30,
        }}
      >
        <h1 style={{ textAlign: "center" }}>
          {languagePage === "PT" ? "Tab. Circular Skills - Integrado" : "Circular Skills Tab - Integrated"}
        </h1>
        {classifications.length !== 0 ? (
          <table
            style={{
              width: "100%",
              maxWidth: 1000,
              minWidth: 500,
            }}
          >
            <thead style={{ backgroundColor: "#134480", color: "#fff" }}>
              <tr>
                <th
                  style={{
                    borderTopLeftRadius: 10,
                    padding: 16,
                    fontFamily: "Gotham-Medium",
                    fontSize: 22,
                    textAlign: "center",
                  }}
                >
                  #
                </th>
                <th className="th">{languagePage === "PT" ? "Nome" : "Name"}</th>
                <th
                  className="th"
                  style={{
                    width: "30%",
                  }}
                >
                  MindSet
                </th>
                <th
                  className="th"
                  style={{
                    width: "100%",
                    maxWidth: 450,
                    minWidth: 100,
                    borderTopRightRadius: 10,
                  }}
                >
                  {languagePage === "PT" ? "Grupo" : "Group"}
                </th>
              </tr>
            </thead>
            <tbody>
              {classifications.map((classificated, index) => {
                return (
                  <tr style={{ backgroundColor: "#FFFFFF" }} key={index}>
                    <th className="th-dinamico">{index + 1}</th>
                    <td className="th-dinamico">{classificated.user.name}</td>
                    <td className="th-dinamico">{classificated.score}</td>
                    <td className="th-dinamico">{classificated.group}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "center", fontSize:'20px' }}>
            {languagePage === "PT" ? "Não existem usuários que realizaram o teste de hard skills" : "There are no users who took the hard skills test"}
          </p>
        )}
      </Tabela>
    </>
  );
};

export default ProfileMapping;