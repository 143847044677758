import styled from "styled-components";

const GeneralContainer = styled.div`
  min-height: 100vh;
  width: 100vw;
  background-color: #000124;
  @media only screen and (min-width: 200px) and (max-width: 769px){
    background-color: #000124 !important;
    min-height: 100vh;
  }
`

const LogoContainer = styled.div`
	height: auto;
	display: flex;
	position: absolute;
	/* align-items: center;
	justify-content: center; */
	max-width: 20%;
	padding: 30px 0px 0px 35px;
	@media screen and (max-width: 768px) {
		z-index: 4;
		top: 0px;
		right: 170px;
	}
	@media screen and (min-width: 769px) {
		padding-top: 70px;
		position: relative;
		align-items: flex-end;
	}
`;

const FlashPlayLogo = styled.img`
	cursor: pointer;
	width: 100%;
	min-width: 160px;
	
`;

const Container = styled.div`
  margin: 0;
  padding: 0;
`

const ContainerForImg = styled.div`
  margin: 0;
  padding: 0;

  @media only screen and (min-width: 200px) and (max-width: 769px){
    display: inline !important;
  }
`

const ContainerTitle = styled.div`
  margin-left: 5%;
  margin-top: 2%;
  @media only screen and (min-width: 200px) and (max-width: 769px){
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const HeaderBar = styled.div`
	width: 100vw;
	position: fixed;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	padding: 25px;
	align-content: center;
	justify-content: space-between;
	z-index: 10;
	@media only screen and (min-device-width: 769px) {
		height: 100px;
		background: rgba(61, 60, 60, 0.2);
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(9.2px);
		-webkit-backdrop-filter: blur(9.2px);
		/* border-bottom: 1px solid rgba(61, 60, 60, 0.1); */
	}
`
const ContainerBody = styled.div`
  padding: 0 !important;
  display: flex !important;
  justify-content: space-around !important;
  gap:50 !important;

  @media only screen and (min-width: 200px) and (max-width: 769px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  `

  const ContainerBodyProduct = styled.div`
    margin: 0;
    padding: 0;
    display: flex ;
    gap: 20px;
    padding: 10px;
    border-radius: 10px;
    background: #000135 ;
    width: 70% ;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset ;

    @media only screen and (min-width: 200px) and (max-width: 769px){
      display: flex ;
      flex-direction: column;
    }
  `
;

const ContainerSection: React.CSSProperties = {
  display: "flex",
  gap: 20,
  padding: 10,
  borderRadius: "10px",
  background: "#000135",
  width: "70%",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
}

const ContainerImages = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 2%;
  @media only screen and (min-width: 200px) and (max-width: 769px){
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      align-items: center;
    }
`


const ContainerImage= styled.div`
  display: flex;
  gap: 30px;
  background: #000145;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 4px 4px 0px #000160 inset;
  width: 400px; 
  @media only screen and (min-width: 200px) and (max-width: 769px){
      width: 90%;
    }
`

const ContainerCard = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  @media only screen and (min-width: 200px) and (max-width: 769px){
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
`

const ContainerArticle: React.CSSProperties = {
  margin: 0,
  padding: 0,
  display: "flex",
  justifyContent:"space-around",
  flexDirection: "column",
  width: "100%",
  height: "100%"

}

const BookImage: React.CSSProperties = {
  height: "250px",
  borderRadius: "10px",
}

const Image: React.CSSProperties = {
  height: "150px",
  borderRadius: "10px",
}

const ButtonStyle: React.CSSProperties = {
  color: "white",
  background: "green",
  textTransform: "capitalize"
}

const Title: React.CSSProperties = {
  color: "white",
  fontFamily: "Inter-Bold",
  fontSize: "30px",

}

const Subtitle: React.CSSProperties = {
  color: "white",
  fontFamily: "Inter-Medium",
  fontSize: "24px",
  marginTop: 10
}

const Paragraph: React.CSSProperties = {
  color: "white",
  fontFamily: "Inter",
  fontSize: "15px",
  marginTop: 20
}

const Message: React.CSSProperties = {
  color: "white",
  fontFamily: "Inter",
  fontSize: "15px",
  fontStyle: "italic",
  marginTop: 20
}

const Category: React.CSSProperties = {
  color: "white",
  fontFamily: "Inter",
  fontSize: "15px",
  fontStyle: "italic",
}



export { GeneralContainer, 
LogoContainer, 
FlashPlayLogo, 
HeaderBar,  
ContainerSection,
ContainerBodyProduct, 
ContainerArticle, 
BookImage, 
ButtonStyle, 
Title, 
Subtitle, 
Paragraph, 
ContainerBody, 
ContainerForImg,
Container, 
Message, 
ContainerImages,
Image,
ContainerImage,
ContainerCard,
Category,
ContainerTitle
};
