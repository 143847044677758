import React from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { useForm } from "../../../hooks/form";
import Input, { InputRefProps } from "../../Input";
import { useGlobal } from "../../../hooks/global";
import { BotoesForm, ButtonNextBack, Container, Titulo } from "../style";

interface AddressCompanyData {
  onNext: Function;
  onBack: Function;
}

const AddressCompany: React.FC<AddressCompanyData> = ({ onNext, onBack }) => {
  const cidadeRef = useRef<InputRefProps>(null);
  const estadoRef = useRef<InputRefProps>(null);
  const paisRef = useRef<InputRefProps>(null);

  const { cidade, estado, pais, setCidade, setEstado, setPais } = useForm();

  const onClickNext = useCallback(() => {
    if (cidade !== "" && estado !== "" && pais !== "") onNext();
  }, [cidade, estado, pais]);

  const onClickBack = useCallback(() => {
    onBack();
  }, []);

  const onChangeCidade = useCallback(() => {
    const name = cidadeRef.current?.getInputValue()
      ? cidadeRef.current.getInputValue()
      : "";
    setCidade(name);
  }, [cidadeRef]);

  const onChangeEstado = useCallback(() => {
    const name = estadoRef.current?.getInputValue()
      ? estadoRef.current.getInputValue()
      : "";
    setEstado(name);
  }, [estadoRef]);

  const onChangePais = useCallback(() => {
    const name = paisRef.current?.getInputValue()
      ? paisRef.current.getInputValue()
      : "";
    setPais(name);
  }, [paisRef]);
  const { setAtualPageGlobal, languagePage } = useGlobal();


  return (
    <Container
      style={{
        textAlign: "center",
        marginTop: 20,
      }}>
      <div>
        <Titulo>
          {languagePage === "PT" ? "Sua localização é crucial para que" : "Your location is important "}
          {languagePage === "PT" ? <div>saibamos onde você está.</div> :
            <div>so we know where you are.</div>}
        </Titulo>
        <Input
          ref={cidadeRef}
          styleInput={{
            fontFamily: "Gotham-Bold",
            color: "#FFF",
            background: "#0b243b",
            fontSize: 16,
          }}
          styleContainer={{ background: "#0b243b" }}
          placeholder="Cidade"
          label="Cidade"
          onChange={onChangeCidade}
          defaultValue={cidade}
        />
        <ButtonNextBack>
          <Input
            styleInput={{
              fontFamily: "Gotham-Bold",
              color: "#FFF",
              background: "#0b243b",
              fontSize: 16,
            }}
            styleContainer={{ background: "#0b243b", marginRight: 5 }}
            label="Estado"
            ref={estadoRef}
            placeholder="Estado"
            onChange={onChangeEstado}
            defaultValue={estado}
          />
          <Input
            styleInput={{
              fontFamily: "Gotham-Bold",
              color: "#FFF",
              background: "#0b243b",
              fontSize: 16,
            }}
            styleContainer={{ background: "#0b243b", marginLeft: 5 }}
            ref={paisRef}
            label="País"
            placeholder="País"
            onChange={onChangePais}
            defaultValue={pais}
          />
        </ButtonNextBack>
        <ButtonNextBack>
          <BotoesForm
            onClick={onClickBack}

          >
            <GoChevronLeft />
            {languagePage === "PT" ? "Anterior" : "Back"}
          </BotoesForm>
          <BotoesForm
            onClick={onClickNext}
            style={{

              cursor:
                cidade !== "" && estado !== "" && pais !== ""
                  ? "pointer"
                  : "not-allowed",
            }}
          >
            {languagePage === "PT" ? " Próximo" : "Next"}
            <GoChevronRight style={{ marginLeft: 5 }} />
          </BotoesForm>
        </ButtonNextBack>
      </div>
    </Container>
  );

};

export default AddressCompany;
