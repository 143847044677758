import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import Decision from "../../components/Decision";
import NameCompany from "../../components/FormCompany/NameCompany";
import { Header } from "../../components/Header";
import { Bolinha, Button, Container } from "./style";
import { useTransition, animated } from "react-spring";
import { useForm } from "./../../hooks/form";
import EmailCompany from "../../components/FormCompany/Email";
import CNJPCompany from "../../components/FormCompany/CNPJ";
import TelefoneCompany from "../../components/FormCompany/Telefone";
import AddressCompany from "../../components/FormCompany/AddressCompany";
import NumFuncCompany from "../../components/FormCompany/NumFuncCompany";
import PortCompany from "../../components/FormCompany/PortCompany";
import NameCandidate from "../../components/FormCandidate/NameCandidate";
import CPFCandidate from "../../components/FormCandidate/CPF";
import SexCandidate from "../../components/FormCandidate/SexCandidate";
import EmailCandidate from "../../components/FormCandidate/EmailCandidate";
import TelefoneCandidate from "../../components/FormCandidate/Telefone";
import NameReaponsavel from "../../components/FormCompany/NameReaponsavel";

import Colors from "../../styles";
import NumberIdentifierDropInput from "../../components/DropdownInput/NumberIdentifier";

export const Logon: React.FC = () => {
  const [atualPage, setAtualPage] = useState(0);
  const [escolha, setEscolha] = useState("CNPJ");

  const { type } = useForm();

  const yesCompany =
    type === "empresa" ||
    type === "both" ||
    type === "selections" ||
    type === "consultancy";

  const onClickItemForm = useCallback((newPage) => {
    setAtualPage(newPage);
  }, []);

  const transitions = useTransition(atualPage, {
    keys: null,
    from: { opacity: 0 },
    enter: { opacity: 1, config: { duration: 400 } },
    leave: { opacity: 0, config: { duration: 0 } },
  });
  const decidindo = () => {
    if (escolha === "CPF") {
      setEscolha("CNPJ");
    } else {
      setEscolha("CPF");
    }
  };
  return (
    <Container type={type}>
      <Header />

      <div style={{ display: "flex", position: "relative", height: "100%" }}>
        {transitions((style, i) => {
          if (i === 0)
            return (
              <animated.div
                className="form-logon"
                style={{
                  ...style,
                }}
              >
                <Decision onClickType={() => onClickItemForm(atualPage + 1)} />
              </animated.div>
            );
          else if (i === 1 && yesCompany === true)
            return (
              <animated.div
                className="form-logon"
                style={{
                  ...style,
                }}
              >
                <CNJPCompany
                  onNext={() => onClickItemForm(atualPage + 1)}
                  onBack={() => onClickItemForm(atualPage - 1)}
                />
              </animated.div>
            );
          else if (i === 2 && yesCompany === true)
            return (
              <animated.div
                className="form-logon"
                style={{
                  ...style,
                }}
              >
                <NameReaponsavel
                  onNext={() => onClickItemForm(atualPage + 1)}
                  onBack={() => onClickItemForm(atualPage - 1)}
                />
              </animated.div>
            );
          else if (i === 3 && yesCompany === true)
            return (
              <animated.div
                className="form-logon"
                style={{
                  ...style,
                }}
              >
                <NameCompany
                  onNext={() => onClickItemForm(atualPage + 1)}
                  onBack={() => onClickItemForm(atualPage - 1)}
                />
              </animated.div>
            );
          else if (i === 4 && yesCompany === true)
            return (
              <animated.div
                className="form-logon"
                style={{
                  ...style,
                }}
              >
                <TelefoneCompany
                  onNext={() => onClickItemForm(atualPage + 1)}
                  onBack={() => onClickItemForm(atualPage - 1)}
                />
              </animated.div>
            );
          else if (i === 5 && yesCompany === true)
            return (
              <animated.div
                className="form-logon"
                style={{
                  ...style,
                }}
              >
                <AddressCompany
                  onNext={() => onClickItemForm(atualPage + 1)}
                  onBack={() => onClickItemForm(atualPage - 1)}
                />
              </animated.div>
            );
          else if (i === 6 && yesCompany === true)
            return (
              <animated.div
                className="form-logon"
                style={{
                  marginBottom: 20,
                  ...style,
                }}
              >
                <PortCompany
                  onNext={() => onClickItemForm(atualPage + 1)}
                  onBack={() => onClickItemForm(atualPage - 1)}
                />
              </animated.div>
            );
          else if (i === 7 && yesCompany === true)
            return (
              <animated.div
                className="form-logon"
                style={{
                  ...style,
                }}
              >
                <NumFuncCompany
                  onNext={() => onClickItemForm(atualPage + 1)}
                  onBack={() => onClickItemForm(atualPage - 1)}
                />
              </animated.div>
            );
          else if (i === 8 && yesCompany === true)
            return (
              <animated.div
                className="form-logon"
                style={{
                  ...style,
                }}
              >
                <EmailCompany
                  onNext={() => onClickItemForm(atualPage + 1)}
                  onBack={() => onClickItemForm(atualPage - 1)}
                />
              </animated.div>
            );
          else if (i === 1 && (type === "candidato" || type === "consultor"))
            return (
              <animated.div
                className="form-logon"
                style={{
                  ...style,
                }}
              >
                <NameCandidate
                  onNext={() => onClickItemForm(atualPage + 1)}
                  onBack={() => onClickItemForm(atualPage - 1)}
                />
              </animated.div>
            );
          else if (i === 2 && (type === "candidato" || type === "consultor"))
            // if (type === "candidato") {
            //   <CPFCandidate
            //     onNext={() => onClickItemForm(atualPage + 1)}
            //     onBack={() => onClickItemForm(atualPage - 1)}
            //   />
            // } else {
            return (
              <animated.div
                className="form-logon"
                style={{
                  ...style,
                  display: "block",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <Button onClick={() => setEscolha("CPF")} style={{ marginTop: "100px" }}>CPF</Button>
<Button onClick={() => setEscolha("CNPJ")} style={{ marginTop: "100px", }}>CNPJ</Button> */}
                {/* <Button onClick={() => setEscolha("CPF")} style={{ marginTop: "100px" }}>CPF</Button> */}

                {/* <div style={{display: "flex", margin: "auto", width: "300px",justifyContent: "center",
                  alignItems: "center"}}>
                  <div className="opcoes" style={{ color: escolha==="CPF" ? "#0b243b" : "#fff" }}>CPF</div>
                  <div className="form-check form-switch" style={{ padding: 10, marginTop: "100px" }}>

                    <input 
                    className="form-check-input" 
                    type="checkbox" 
                    role="switch" 
                    id="flexSwitchCheckDefault" 
                    onClick={() =>decidindo()}
                    style={{ padding: 10, width: 50 }}
                    />

                  </div>
                  <div className="opcoes" style={{ color: escolha==="CNPJ" ? "#0b243b" : "#fff" }}>CNPJ</div>
                </div> */}

                {/* {escolha === "CNPJ" ?
                  <div >

                    <CPFCandidate
                      onNext={() => onClickItemForm(atualPage + 1)}
                      onBack={() => onClickItemForm(atualPage - 1)}
                    />
                  </div> :
                  <div>


                    <CNJPCompany
                      onNext={() => onClickItemForm(atualPage + 1)}
                      onBack={() => onClickItemForm(atualPage - 1)}
                    />
                  </div>} */}

                <NumberIdentifierDropInput
                  onNext={() => onClickItemForm(atualPage + 1)}
                  onBack={() => onClickItemForm(atualPage - 1)}
                />
              </animated.div>
            );
          // }
          else if (i === 3 && (type === "candidato" || type === "consultor"))
            return (
              <animated.div
                className="form-logon"
                style={{
                  ...style,
                }}
              >
                <SexCandidate
                  onNext={() => onClickItemForm(atualPage + 1)}
                  onBack={() => onClickItemForm(atualPage - 1)}
                />
              </animated.div>
            );
          else if (i === 4 && (type === "candidato" || type === "consultor"))
            return (
              <animated.div
                className="form-logon"
                style={{
                  ...style,
                }}
              >
                <TelefoneCandidate
                  onNext={() => onClickItemForm(atualPage + 1)}
                  onBack={() => onClickItemForm(atualPage - 1)}
                />
              </animated.div>
            );
          else if (i === 5 && (type === "candidato" || type === "consultor"))
            return (
              <animated.div
                className="form-logon"
                style={{
                  ...style,
                }}
              >
                <EmailCandidate
                  onNext={() => onClickItemForm(atualPage + 1)}
                  onBack={() => onClickItemForm(atualPage - 1)}
                />
              </animated.div>
            );
        })}
        {atualPage !== 0 && atualPage !== 8 && type === "empresa" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "absolute",
              width: "100%",
              justifyContent: "center",
              bottom: 20,
              marginTop: 20,
              marginBottom: -10,
            }}
          >
            <Bolinha
              isActive={atualPage > 0}
              style={{ marginRight: 10 }}
            ></Bolinha>
            <Bolinha
              isActive={atualPage > 1}
              style={{ marginRight: 10 }}
            ></Bolinha>
            <Bolinha
              isActive={atualPage > 2}
              style={{ marginRight: 10 }}
            ></Bolinha>
            <Bolinha
              isActive={atualPage > 3}
              style={{ marginRight: 10 }}
            ></Bolinha>
            <Bolinha
              isActive={atualPage > 4}
              style={{ marginRight: 10 }}
            ></Bolinha>
            <Bolinha
              isActive={atualPage > 5}
              style={{ marginRight: 10 }}
            ></Bolinha>
            <Bolinha
              isActive={atualPage > 6}
              style={{ marginRight: 10 }}
            ></Bolinha>
          </div>
        )}
        {atualPage !== 0 &&
          atualPage !== 6 &&
          (type === "candidato" || type === "consultor") && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                position: "absolute",
                width: "100%",
                justifyContent: "center",
                bottom: 20,
              }}
            >
              <Bolinha
                isActive={atualPage > 0}
                style={{ marginRight: 10 }}
              ></Bolinha>
              <Bolinha
                isActive={atualPage > 1}
                style={{ marginRight: 10 }}
              ></Bolinha>
              <Bolinha
                isActive={atualPage > 2}
                style={{ marginRight: 10 }}
              ></Bolinha>
              <Bolinha
                isActive={atualPage > 3}
                style={{ marginRight: 10 }}
              ></Bolinha>
              <Bolinha isActive={atualPage > 4}></Bolinha>
            </div>
          )}
      </div>
    </Container>
  );
};
