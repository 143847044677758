import React from 'react'
import Lottie from 'react-lottie';
import animationData from './LoadingSale.json';
import { Container } from '../style';

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};

export const LoadingSale:React.FC = () => {

    return(
        <Container style={{}}>
            <Lottie options={defaultOptions}
              height={"20rem"}
              width={"20rem"}
              isStopped={false}
              isPaused={false}/>
        </Container>
    );
}