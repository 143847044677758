import React, { useCallback, useRef, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { MdQuestionAnswer } from 'react-icons/md';
import { ErrorProps, Verify } from '../../../services/schemaServices';
import {
    Container,
    Header,
    IconDelete,
    ButtonEnd,
    ContainerHeader,
    ContainerTitle,
    Formulario,
    ContainerItemForm,
    ContainerBottom
} from './style';
import InputLabel, { InputRefProps } from '../../InputLabel';
import { useGlobal } from "../../../hooks/global";

interface AlteraSenhaProps {
    open: boolean;
    onClose: Function;
    onSave: Function;
}

export const AlteraSenha: React.FC<AlteraSenhaProps> = ({ open, onClose, onSave }) => {
    const [errors, setErrors] = useState<ErrorProps[]>([] as ErrorProps[])
    const inputCurrentPassword = useRef<InputRefProps>(null)
    const inputNewPassword = useRef<InputRefProps>(null)
    
    const Save = useCallback(() => {
        const newPassword = inputNewPassword.current?.getInputValue()
       
        const errors = Verify(
            [
                {
                    name: 'newPassword',
                    value: newPassword,
                    required: { message: 'Campo obrigatório!', value: true }
                }

            ])
        setErrors(errors)
        if (errors.length === 0) onSave({ newPassword})
    }, [inputCurrentPassword, inputNewPassword])
const {languagePage} = useGlobal()
    return (
        <Modal open={open} onClose={() => onClose()} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Container>

                <ContainerHeader>
                    <Header>
                        <ContainerTitle>
                            <MdQuestionAnswer style={{ marginRight: "0.625rem" }} color="#101A36" />
                             {languagePage === "PT" ? "Alterar Senha" : "Change Password"}
                        </ContainerTitle>
                        <IconDelete onClick={() => onClose()} color="#C4C4C4" />
                    </Header>
                </ContainerHeader>

                <Formulario>
                    <ContainerItemForm>
                        <InputLabel
                            errors={errors}
                            type="password"
                            label={ languagePage === "PT" ? "Digite sua nova senha" : "Enter your new password"}
                            attr="newPassword"
                            id='inputNewPassword'
                            ref={inputNewPassword}
                            containerStyle={{ width: "100%" }}
                            inputProps={{ style: { fontFamily: "math", fontWeight: "bold" } }}
                        />
                    </ContainerItemForm>
                    
                    <ContainerItemForm style={{ fontStyle: "italic", color: "#333333" }}>
                        {languagePage === "PT" ? "Observação: Preencha os campos!" : "Note: Fill in the fields!"}
                    </ContainerItemForm>
                </Formulario>

                <ContainerBottom>
                    <ButtonEnd onClick={() => onClose()} style={{ color: "#252323" }}> {languagePage === "PT" ? "Cancelar" : "Cancel"} </ButtonEnd>
                    <ButtonEnd onClick={() => Save() } style={{ color: "#101A36", cursor: "pointer" }}> {languagePage === "PT" ? "Salvar" : "To save"}</ButtonEnd>
                </ContainerBottom>

            </Container>
        </Modal>
    )
}

export default AlteraSenha;