import React, { useCallback, useRef } from "react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import Input, { InputRefProps } from "../../Input";
import { FaCheckCircle } from "react-icons/fa";
import { ImRadioUnchecked } from "react-icons/im";
import { useState } from "react";
import { useForm } from "../../../hooks/form";
import { candidateProps, useAuth } from "../../../hooks/auth";
import api from "../../../services/api";
import * as Yup from "yup";
import { Modal } from "@material-ui/core";
import { LoadingSale } from "../../Animations/LoadingSale";
import { ContainerLoading, ContainerRadio } from "./style";
import { useGlobal } from "../../../hooks/global";
import { useToast } from "../../../hooks/toast";
import { BotoesForm, Container, ContainerButton, Titulo } from "../style";
import { Link } from "react-router-dom";

interface EmailCandidateData {
  onNext: Function;
  onBack: Function;
}

const EmailCandidate: React.FC<EmailCandidateData> = ({ onNext, onBack }) => {
  const [termos, setTermos] = useState(false);
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [errorPassword, setErrorPassword] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const emailRef = useRef<InputRefProps>(null);
  const passwordRef = useRef<InputRefProps>(null);
  const { signIn } = useAuth();

  const {
    senha,
    email,
    nome,
    telefone,
    setSenha,
    setEmail,
    posSexo,
    numberIdentifier,
  } = useForm();

  const { preferencia } = useGlobal();

  const onClickNext = useCallback(async () => {
    const sex = ["Homem", "Mulher", "Outros"][posSexo];
    if (email !== "" && senha !== "" && termos) {
      const emailError = await VerifyEmail();
      const passError = await VerifyPass();
      if (!!!emailError && !!!passError) {
        setLoading(true);
        setTimeout(async () => {
          try {
            const resposta = await api.post("/common", {
              name: nome,
              email,
              numberIdentifier,
              preference: preferencia,
              password: senha,
              phone: telefone,
              sex,
            });
            if (resposta.status === 404) {
              setLoading(false);
              return;
            }
            await signIn({ email, password: senha });
          } catch (error) {
            setErrorEmail(
              languagePage === "PT"
                ? "Email ou CPF já cadastrado"
                : "Email or ID already registered"
            );
            console.log(error);
            addToast({
              title: "Erro",
              description:
                languagePage === "PT"
                  ? "Email ou CPF já cadastrado"
                  : "Email or ID already registered",
              icon: "alert",
              type: "error",
            });
            setLoading(false);
          }
        }, 200);
      }
    }
  }, [nome, email, senha, telefone, posSexo, termos, numberIdentifier]);

  const onClickBack = useCallback(() => {
    onBack();
  }, []);

  const VerifyEmail = useCallback(async () => {
    let resu;
    setErrorEmail(null);
    const email = emailRef.current?.getInputValue();
    const schemaEmail = Yup.object().shape({
      email: Yup.string().email(
        languagePage === "PT"
          ? "Digite um e-mail válido"
          : "Insert a valid e-mail"
      ),
    });
    try {
      await schemaEmail.validate({ email });
    } catch (err: any) {
      setErrorEmail(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
      addToast({
        title: "Erro",
        description: err.errors[0],
        icon: "alert",
        type: "error",
      });
    }
    return resu;
  }, [emailRef]);
  const VerifyPass = useCallback(async () => {
    let resu;
    setErrorPassword(null);

    const password = passwordRef.current?.getInputValue();
    const schemaPass = Yup.object({
      password: Yup.string()
        .required(
          languagePage === "PT"
            ? "A senha é requerida"
            : "A password is required"
        )
        .min(
          6,
          languagePage === "PT"
            ? "A senha precisa ter no mínimo 6 caracteres"
            : "The password must have at least 6 characters"
        )
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
          languagePage === "PT"
            ? "A senha precisa ter uma letra maiúscula, uma minúscula, um número e um caractere esepecial(#*/+!@?&$)."
            : "The password must have an uppercase letter, a lowercase letter, a number and a special character (#*/+!@?&$)."
        ),
    });
    try {
      await schemaPass.validate({ password });
    } catch (err: any) {
      setErrorPassword(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
      addToast({
        title: "Erro",
        description: err.errors[0],
        icon: "alert",
        type: "error",
      });
    }
    return resu;
  }, [passwordRef]);

  const onChangeEmail = useCallback(() => {
    setErrorEmail(null);
    const email = emailRef.current?.getInputValue()
      ? emailRef.current.getInputValue()
      : "";
    setEmail(email);
  }, [emailRef]);

  const onChangePassword = useCallback(() => {
    setErrorPassword(null);
    const password = passwordRef.current?.getInputValue()
      ? passwordRef.current.getInputValue()
      : "";
    setSenha(password);
  }, [passwordRef]);
  const { setAtualPageGlobal, languagePage } = useGlobal();

  return (
    <Container>
      <div>
        <Titulo>
          {languagePage === "PT"
            ? "Insira seu email e crie uma senha"
            : "  Enter your email and create a password"}
        </Titulo>
        <Input
          styleInput={{
            fontFamily: "Gotham-Bold",
            color: "#FFF",
            background: "#0b243b",
            fontSize: 16,
          }}
          styleContainer={{
            background: "#0b243b",
            border: "2px solid #0b243b",
          }}
          label="Email"
          placeholder="Email"
          errors={errorEmail}
          onChange={onChangeEmail}
          defaultValue={email}
          ref={emailRef}
        />
        <Input
          styleInput={{
            fontFamily: "Gotham-Bold",
            color: "#FFF",
            background: "#0b243b",
            fontSize: 16,
          }}
          styleContainer={{
            background: "#0b243b",
            marginTop: 20,
            border: "2px solid #0b243b",
          }}
          placeholder={languagePage === "PT" ? "Senha" : "Password"}
          label={languagePage === "PT" ? "Senha" : "Password"}
          type="password"
          errors={errorPassword}
          onChange={onChangePassword}
          defaultValue={senha}
          ref={passwordRef}
        />
        <ContainerRadio>
          {termos && (
            <FaCheckCircle
              color="#00DF71"
              onClick={() => setTermos(!termos)}
              style={{
                boxShadow: "4px 4px 18px 0px rgba(0,0,0,0.5)",
                WebkitBoxShadow: "4px 4px 18px 0px rgba(0,0,0,0.5)",
                borderRadius: 9,
              }}
              cursor="pointer"
              size={18}
            />
          )}
          {!termos && (
            <ImRadioUnchecked
              color="#00DF71"
              onClick={() => setTermos(!termos)}
              style={{
                boxShadow: "4px 4px 18px 0px rgba(0,0,0,0.5)",
                WebkitBoxShadow: "4px 4px 18px 0px rgba(0,0,0,0.5)",
                borderRadius: 9,
              }}
              cursor="pointer"
              size={18}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: 16,
              marginLeft: 10,
            }}
          >
            <div>
              {languagePage === "PT"
                ? "Declaro que li e concordo com os"
                : "I declare that I have read and agree to s"}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  marginRight: 5,
                  color: "#19BCF4",
                  cursor: "pointer",
                }}
              >
                <Link to="/pdf" target="_blank">
                  {languagePage === "PT"
                    ? "Termos de uso"
                    : " the Flash Play HR"}
                </Link>
              </div>
              {languagePage === "PT" ? "da Flash Play HR" : " Terms of Use"}
            </div>
          </div>
        </ContainerRadio>
        <ContainerButton>
          <BotoesForm onClick={onClickBack}>
            <GoChevronLeft />
            {languagePage === "PT" ? "Anterior" : "Back"}
          </BotoesForm>
          <BotoesForm
            onClick={onClickNext}
            style={{
              cursor:
                termos && email !== "" && senha !== ""
                  ? "pointer"
                  : "not-allowed",
            }}
          >
            {languagePage === "PT" ? "Próximo" : "Next"}
            <GoChevronRight style={{ marginLeft: 5 }} />
          </BotoesForm>
        </ContainerButton>
        {loading && (
          <Modal open={loading}>
            <ContainerLoading>
              <LoadingSale />
            </ContainerLoading>
          </Modal>
        )}
      </div>
    </Container>
  );
};

export default EmailCandidate;
