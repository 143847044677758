import styled from "styled-components";

const HabilitiesContainer = styled.section`
	grid-area: habilities;
	width: 100%;
	/* min-height: 400px !important;
	min-height: 600px !important; */
	align-items: center;
	justify-content: center;
	
	object-fit: fill;
	color: white;
	background-image: url(https://res.cloudinary.com/dkqcb920e/image/upload/v1657306960/chamaleon-habilities-comprimida_gh6a3h.webp);
	background-repeat: no-repeat;
	background-position: 75% 50%;
	background-size: cover;
	background-color: rgba(0, 1, 36, 0.6);
	@media screen and (min-width: 769px) {
		background-position: 50% 75%;
		height: 70vh;
	}
	@media screen and (min-width: 321px) and (max-width: 375px) {
		width: 101%;
	}
`;
const ContentContainerDiv = styled.div`
	dISPLAY: flex;
	background-color: rgba(0, 1, 36, 0.6);
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const ContentContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	@media screen and (min-width: 769px) {
		grid-template-columns: 1fr 1fr;
		/*grid-template-rows: 1fr;*/
		gap: 2em;
	}

	background: rgba(0, 1, 36, 0.6);
	width: 100%;
	min-height: 800px;
	padding: 2em;
	align-items: center;
	justify-content: center;
`;

const TextCenter = styled.h3`
	text-align: center ;
	align-items: center ;
	justify-content: center ;
	color: #E58417;
	font-size: 35px;
	font-family: Inter-Bold;
	background-color: rgba(0, 1, 36, 0.6);
`;
const TextCenterP = styled.div`
	text-align: center ;
	align-items: center ;
	justify-content: center ;
	font-size: 25px;
	font-family: Inter-Bold;
	background-color: rgba(0, 1, 36, 0.6);
	padding-left: 10%;
	padding-right: 10%;
	color: #ffffff !important;
`;

const TextContainerLeft = styled.div`
	margin-left: 4em;
	justify-content: center;
`;

const TitleLeft = styled.p`
	font-family: Inter-Bold;
	margin-bottom: 1em;
	font-size: x-large;
	@media screen and (min-width: 769px) {
		font-size: xx-large;
	}
`;
const ListLeft = styled.ul`
	font-family: Inter-Medium;
	font-size: large;
	@media screen and (min-width: 769px) {
		margin-left: 20px;
		font-size: xx-large;
	}
`;
const ListItemLeft = styled.li`
	margin: 5px 0px 5px 0px;
`;

const TextContainerRight = styled.div`
	margin-left: 4em;
	justify-content: center;
`;

const TitleRight = styled.p`
	font-family: Inter-Bold;
	margin-bottom: 1em;
	font-size: x-large;
	@media screen and (min-width: 769px) {
		font-size: xx-large;
	}
`;
const ListRight = styled.ul`
	font-family: Inter-Medium;
	font-size: large;
	@media screen and (min-width: 769px) {
		margin-left: 20px;
		font-size: xx-large;
	}
`;
const ListItemRight = styled.li`
	margin: 5px 0px 5px 0px;
`;

export {
	HabilitiesContainer,
	TextContainerLeft,
	TextCenter,
	TitleLeft,
	ListLeft,
	ListItemLeft,
	TextContainerRight,
	TitleRight,
	ListRight,
	ListItemRight,
	ContentContainer,
	ContentContainerDiv,
	TextCenterP
};
