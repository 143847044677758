import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  CardBody,
  CardGroup,
  CardText,
  CardTitle,
  Card,
  CardFooter,
} from "reactstrap";
import {
  Button,
  Conteiner,
  Conteudo,
  Titulo,
  Status,
  BoxButton,
  FooterText,
} from "./style";
import api from "../../../services/api";
import { useGlobal } from "../../../hooks/global";
import { FaCommentDots } from "react-icons/fa";

export const Propostas: React.FC = () => {
  const { push, go } = useHistory();
  const [dados, setDados] = useState<any[]>([]);
  const { languagePage } = useGlobal();
  const [recusedDescription, setRecusedDescription] = useState("");
  const [accept, setAccept] = useState<boolean>();
  const [id, setId] = useState<string>("");

  const formator = new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
    minimumFractionDigits: 2,
  });

  useEffect(() => {
    api
      .get("/common/list-proposals")
      .then((response) => {
        const { proposals } = response.data;
        var aceitos = [];
        var aguardo = [];
        var cancelado = [];
        var recusado = [];
        var ordenado: any[] = [];

        for (var i = 0; i < proposals.length; i++) {
          if (proposals[i].accept === true && proposals[i].canceled === null) {
            aceitos.push(proposals[i]);
          } else if (
            proposals[i].accept === null &&
            proposals[i].canceled === null
          ) {
            aguardo.push(proposals[i]);
          } else if (
            proposals[i].accept === null &&
            proposals[i].canceled === true
          ) {
            cancelado.push(proposals[i]);
          } else if (
            proposals[i].accept === false &&
            proposals[i].canceled === null
          ) {
            recusado.push(proposals[i]);
          }
        }
        ordenado = ordenado.concat(aceitos, aguardo, cancelado, recusado);
        setDados(ordenado);
      })
      .catch((error) => {
        console.log("Erro " + error);
      });
  }, [setDados]);

  const decisao = useCallback(
    (valor: boolean, proposta: any) => {
      setAccept(valor);
      setId(proposta.id);
      if (valor === true) {
        api
          .post(`/common/accept-proposal/${proposta.id}`, {
            accept: valor,
          })
          .then((response) => {
            go(0);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (valor === false && recusedDescription.length !== 0) {
        api
          .post(`/common/accept-proposal/${proposta.id}`, {
            accept: valor,
            recusedDescription: recusedDescription,
          })
          .then((response) => {
            go(0);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    [push, recusedDescription]
  );

  return (
    <Conteiner>
      <Titulo style={{ borderBottom: "1px solid", textAlign: "center" }}>
        {languagePage === "PT" ? "Propostas" : "Proposals"}
      </Titulo>
      {/* <div>
        <Button onClick={() => push("/candidate/painelDoConsultor")}>
          {languagePage === "PT" ? "Voltar" : "Return"}
        </Button>
      </div> */}
      <Conteudo>
        <CardGroup
          style={{
            margin: "auto",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {dados.length === 0 ? (
            <Titulo style={{ textAlign: "center", margin: "auto" }}>
              {languagePage === "PT"
                ? "Você não possui nenhuma proposta!"
                : "You don't have any offers!"}
            </Titulo>
          ) : (
            dados.map((e) => {
              return (
                // <div
                //   className="cards"
                //   style={{
                //     maxWidth: 300,
                //     minWidth: 200,
                //     width: "100%",
                //     height: 400,
                //     margin: "10px auto",
                //   }}
                // >
                //   <CardBody>
                //     <CardTitle style={{ textAlign: "center" }}>
                //       {e.accept === true && e.canceled === null ? (
                //         <span>
                //           {languagePage === "PT" ? "Decisão:" : "Decision"}
                //           <span style={{ color: " #1C993F" }}>
                //             {languagePage === "PT" ? "Aceito" : "Accepted"}
                //             <div
                //               style={{ cursor: "pointer" }}
                //               onClick={() =>
                //                 push({
                //                   pathname: "/candidate/detalhespropostas",
                //                   state: { proposta: e },
                //                 })
                //               }
                //             >
                //               <abbr style={{ cursor: "pointer" }} title="Chat">
                //                 <FaCommentDots size={25} />
                //                 <sup style={{ color: "#fff" }}>+</sup>
                //               </abbr>
                //             </div>
                //           </span>
                //         </span>
                //       ) : e.accept === null && e.canceled === null ? (
                //         languagePage === "PT" ? (
                //           <span>
                //             Decisão: <span>Aguardando</span>
                //           </span>
                //         ) : (
                //           <span>
                //             Decision: <span>Waiting</span>
                //           </span>
                //         )
                //       ) : e.canceled === true ? (
                //         languagePage === "PT" ? (
                //           <span>
                //             Decisão:{" "}
                //             <span style={{ color: "red" }}>Cancelado</span>
                //           </span>
                //         ) : (
                //           <span>
                //             Decision:{" "}
                //             <span style={{ color: "red" }}>Called off</span>
                //           </span>
                //         )
                //       ) : languagePage === "PT" ? (
                //         <span>
                //           Decisão:{" "}
                //           <span style={{ color: "red" }}>Recusado</span>
                //         </span>
                //       ) : (
                //         <span>
                //           Decision:{" "}
                //           <span style={{ color: "red" }}>Refused</span>
                //         </span>
                //       )}
                //     </CardTitle>

                //     <CardText>
                //       <div
                //         className="scrollEstilo"
                //         style={{
                //           overflowX: "hidden",
                //           textOverflow: "ellipsis",
                //           maxWidth: 250,
                //           minWidth: 200,
                //           width: "100%",
                //           height: 250,
                //           textAlign: "center",
                //         }}
                //       >
                //         {e.canceled !== true ? (
                //           e.description
                //         ) : (
                //           <div>
                //             {languagePage === "PT"
                //               ? "Motivo do cancelamento"
                //               : "Reason for cancellation"}

                //             <hr />
                //             {e.canceledDescription}
                //           </div>
                //         )}
                //       </div>
                //     </CardText>
                //   </CardBody>
                //   <div
                //     style={{
                //       margin: "auto",
                //       textAlign: "center",
                //       width: 250,
                //       justifyContent: "center",
                //       alignItems: "center",
                //     }}
                //   >
                //     {e.accept === false ? (
                //       <Button
                //         style={{
                //           backgroundColor: "#2F4F4F",
                //           cursor: "no-drop",
                //         }}
                //         className="buttons"
                //         disabled
                //       >
                //         {languagePage === "PT"
                //           ? "Não pode ver os detalhes"
                //           : "Can't see the details"}
                //       </Button>
                //     ) : (
                //       <Button
                //         className="buttons "
                //         onClick={() =>
                //           push({
                //             pathname: "/candidate/detalhespropostas",
                //             state: { proposta: e },
                //           })
                //         }
                //       >
                //         {languagePage === "PT" ? "Ver Detalhes" : "See details"}
                //       </Button>
                //     )}
                //   </div>
                // </div>
                <Card style={{ marginBottom: 32, borderRadius: 5 }}>
                  <CardTitle
                    style={{
                      padding: 16,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5 style={{ color: "#4e4e4e", fontFamily: "Gotham-Bold" }}>
                      {e.category}
                    </h5>
                    <Status
                      state={
                        e.canceled
                          ? e.canceled
                          : e.accept === false
                          ? true
                          : false
                      }
                    >
                      <p>
                        {e.canceled === null && e.accept === null
                          ? null
                          : e.canceled === true
                          ? "Cancelado"
                          : e.accept === false
                          ? "Recusado"
                          : null}
                      </p>
                    </Status>
                  </CardTitle>
                  <CardBody
                    style={{
                      padding: 16,
                      display: "flex",
                      flexDirection:
                        window.innerWidth <= 768 ? "column" : "row",
                      alignItems:
                        window.innerWidth <= 768 ? "flex-start" : "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <label
                        style={{
                          color: "#4e4e4e",
                          fontFamily: "Gotham-Medium",
                          fontSize: 16,
                        }}
                      >
                        Descrição
                      </label>
                      <p
                        style={{
                          color: "#4e4e4e",
                          fontFamily: "Gotham-Light",
                          fontSize: 15,
                          maxWidth: 350,
                        }}
                      >
                        {e.description}
                      </p>
                      <label
                        style={{
                          color: "#4e4e4e",
                          fontFamily: "Gotham-Medium",
                          fontSize: 16,
                        }}
                      >
                        Quantidade de horas
                      </label>
                      <p
                        style={{
                          color: "#4e4e4e",
                          fontFamily: "Gotham-Light",
                          fontSize: 15,
                        }}
                      >
                        {e.hoursEmployed} horas
                      </p>
                      <label
                        style={{
                          color: "#4e4e4e",
                          fontFamily: "Gotham-Medium",
                          fontSize: 16,
                        }}
                      >
                        Valor que irá receber
                      </label>
                      <p
                        style={{
                          color: "#4e4e4e",
                          fontFamily: "Gotham-Light",
                          fontSize: 15,
                        }}
                      >
                        {!!e.consultantAmountEstimate
                          ? formator.format(e.consultantAmountEstimate)
                          : null}
                      </p>
                    </div>
                    {!!e.company && (
                      <div>
                        <div>
                          {!!e.company && (
                            <>
                              <img
                                src={
                                  !!e.company.avatar
                                    ? e.company.avatar
                                    : "https://res.cloudinary.com/hkkzuevm3/image/upload/v1645643455/Assets-FlashPlayHr/perfil_f6utrt.png"
                                }
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  height: "100%",
                                  maxWidth: 80,
                                  maxHeight: 80,
                                  borderRadius: 8,
                                  marginBottom: 8,
                                }}
                              />
                              <label
                                style={{
                                  color: "#4e4e4e",
                                  fontFamily: "Gotham-Medium",
                                  fontSize: 16,
                                }}
                              >
                                Nome da empresa
                              </label>
                              {!!e.company.name && (
                                <p
                                  style={{
                                    color: "#4e4e4e",
                                    fontFamily: "Gotham-Light",
                                    fontSize: 15,
                                  }}
                                >
                                  {e.company.name}
                                </p>
                              )}
                              <label
                                style={{
                                  color: "#4e4e4e",
                                  fontFamily: "Gotham-Medium",
                                  fontSize: 16,
                                }}
                              >
                                Tipo de empresa
                              </label>
                              <p
                                style={{
                                  color: "#4e4e4e",
                                  fontFamily: "Gotham-Light",
                                  fontSize: 15,
                                }}
                              >
                                {!!e.company.companySize
                                  ? e.company.companySize
                                  : null}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </CardBody>
                  <CardFooter style={{ padding: 16 }}>
                    <BoxButton>
                      {!e.canceled &&
                        !e.conclused &&
                        !!e.accept &&
                        e.accept === true && (
                          <Button
                            onClick={() => {
                              push({
                                pathname: "/candidate/proposta/conversacao",
                                state: { proposta: e },
                              });
                            }}
                            background="#3e8f8b"
                            backgroundHover="#327774"
                          >
                            Conversar com a empresa
                          </Button>
                        )}
                      {id === e.id && accept === false && (
                        <textarea
                          className="form-control"
                          id="Descricao"
                          placeholder="DIga o motivo por esta recusando"
                          value={recusedDescription}
                          onChange={(e) =>
                            setRecusedDescription(e.target.value)
                          }
                        ></textarea>
                      )}
                      {!e.canceled && !e.conclused && e.accept === null && (
                        <>
                          <Button
                            onClick={() => decisao(false, e)}
                            background="#DB2016"
                            backgroundHover="#96140e"
                          >
                            Recusar
                          </Button>
                          <Button
                            onClick={() => decisao(true, e)}
                            background="#58bb4d"
                            backgroundHover="#4f9947"
                          >
                            Aceitar
                          </Button>
                        </>
                      )}

                      {!e.canceled && !!e.conclused && e.conclused === true && (
                        <FooterText>Esta proposta já foi concluída</FooterText>
                      )}
                      {!!e.canceled && !!e.canceledDescription && (
                        <FooterText>{e.canceledDescription}</FooterText>
                      )}
                      {e.accept === false && (
                        <FooterText>{e.recusedDescription}</FooterText>
                      )}
                    </BoxButton>
                  </CardFooter>
                </Card>
              );
            })
          )}
        </CardGroup>
      </Conteudo>
    </Conteiner>
  );
};
