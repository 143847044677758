import React, { useCallback, useEffect, useRef, useState } from "react";

import InputPerfil, { InputRefProps } from "../../../components/InputPerfil";
import { MaskCNPJ, MaskCPF, MaskPhone } from "../../../services/maskServices";
import ModalAlteraSenha from "../../../components/Modals/AlteraSenha";
import { useAuth } from "../../../hooks/auth";
import SelectFly from "../../../components/SelectFly";
import { findPosByKey } from "../../../services/generalServices";
import {
  DeleteContentBackward,
  IsNumber,
} from "../../../services/inputServices";
import api from "../../../services/api";
import { useGlobal } from "../../../hooks/global";
import { Upload } from "../../../components/Upload";
import { fileProps } from "../../../interfaces/fileUploadInterface";
import { uniqueId } from "lodash";
import filesize from "filesize";
import { Modal } from "@material-ui/core";
import { LoadingSale } from "../../../components/Animations/LoadingSale";
import {
  ButtonAlterarPreferencia,
  ButtonAlterPasswordSave,
  CardContainer,
  ContainerLoading,
} from "./style";
import { storage } from "../../../firebase";
import CancelContaPlano from "../../../components/Modals/CancelContaPlano";
import { AiOutlineCloseCircle } from "react-icons/ai";

import DropZoneUpload from "../../../components/DropZoneUpload";
import DropZoneUploadContracts from "../../../components/DropZoneUploadContract";
import { useToast } from "../../../hooks/toast";
import { useHistory } from "react-router-dom";
import ModalAlterarPreferencia from "../../../components/Modals/AlterarPreferencia";

const genderOptions = ["Homem", "Mulher", "Outros"];
const genderOptionsUS = ["Man", "Woman", "Others"];

export const PerfilCandidate: React.FC = () => {
  const { user, setUser, signOut } = useAuth();
  const { push } = useHistory();
  const [posNumFunc, setPosNumFunc] = useState(
    findPosByKey(user.sex, genderOptions) !== -1
      ? findPosByKey(user.sex, genderOptions)
      : 0
  );
  const { addToast } = useToast();
  const [modal, setModal] = useState<boolean>(false);
  const [cpfNumber, setCpfNumber] = useState<string>(user.cpf || user.cnpj);
  const [preferencia, setPreference] = useState<string>(user.preference);
  const [openModalAlteraSenha, setOpenModalAlteraSenha] =
    useState<boolean>(false);
  const [openModalAlterarPreferencia, setOpenModalAlterarPreferencia] =
    useState<boolean>(false);
  const [cpfString, setCpfString] = useState<string>(
    !!user.cpf ? MaskCPF(user.cpf) : MaskCNPJ(user.cnpj)
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(user.phone);
  const [phoneString, setPhoneString] = useState<string>(MaskPhone(user.phone));
  const [uploadedFiles, setUploadedFiles] = useState<fileProps>(
    user.avatar ? ({ preview: user.avatar } as fileProps) : ({} as fileProps)
  );
  const [pdf, setPdf] = useState<any>(null);
  const [contrato, setContrato] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState<any>();
  const inputPreferencial = useRef<InputRefProps>(null);
  const inputValorHora = useRef<InputRefProps>(null);
  const inputNameRef = useRef<InputRefProps>(null);
  const inputEmailRef = useRef<InputRefProps>(null);
  const inputLinkedinRef = useRef<InputRefProps>(null);
  const { setAtualPageGlobal, languagePage } = useGlobal();

  useEffect(() => {
    setAtualPageGlobal("/candidate/perfil");
  }, []);

  const handleVerifyContract = async () => {
    const res = await api.get("/common/consultant/contract");

    const contracts = res.data;

    if (!contracts) {
      return;
    }
    setContract(contracts);
  };
  useEffect(() => {
    if (user.preference === "consultant") {
      handleVerifyContract();
    }
  }, []);

  const OnChangeInputPhone = useCallback(
    (event) => {
      const char = event.nativeEvent.data;
      const eventType = event.nativeEvent.inputType;
      if (
        eventType === "insertText" &&
        phoneNumber.length < 11 &&
        IsNumber(char)
      ) {
        setPhoneNumber(phoneNumber + char);
        setPhoneString(MaskPhone(phoneNumber + char));
      } else if (eventType === "deleteContentBackward" && phoneNumber) {
        setPhoneNumber(DeleteContentBackward(phoneNumber));
        setPhoneString(MaskPhone(DeleteContentBackward(phoneNumber)));
      }
    },
    [phoneNumber, setPhoneNumber, setPhoneString]
  );

  const deleteAccount = async () => {
    const res = await api.post("/common/delete-account");

    const { deleted, error } = res.data;

    if (deleted) {
      addToast({
        type: "success",
        icon: "info",
        title:
          languagePage === "PT"
            ? "Sua conta foi excluida!"
            : "Your account has been deleted!",
        description:
          languagePage === "PT"
            ? "Foi um prazer ter você na nossa plataforma."
            : "It was a pleasure having you on our platform.",
      });
      signOut();
      push("/#");
    } else {
      addToast({
        type: "error",
        icon: "alert",
        title: languagePage === "PT" ? "Aviso" : "Notice",
        description: error,
      });
    }
  };
  const mask = (v: string) => {
    v = v.replace(/\D/g, "");

    if (v.length > 8 && v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v;
  };
  const OnChangeInputCPForCNPJ = useCallback(
    (event) => {
      const char = event.nativeEvent.data;
      const eventType = event.nativeEvent.inputType;
      if (
        eventType === "insertText" &&
        cpfNumber.length < 14 &&
        IsNumber(char)
      ) {
        setCpfNumber(cpfNumber + char);
        setCpfString(mask(cpfNumber + char));
      } else if (eventType === "deleteContentBackward" && cpfNumber) {
        setCpfNumber(DeleteContentBackward(cpfNumber));
        setCpfString(mask(DeleteContentBackward(cpfNumber)));
      }
    },
    [cpfNumber, setCpfNumber, setCpfString]
  );

  const onClickSave = useCallback(async () => {
    const date = Date.now().toString();
    setLoading(true);
    const name = inputNameRef.current?.getInputValue();
    const email = inputEmailRef.current?.getInputValue();
    const linkedin = inputLinkedinRef.current?.getInputValue();
    const cpf = cpfNumber;
    const phone = phoneNumber;
    const sex = genderOptions[posNumFunc];
    const valueHour = inputValorHora;
    setTimeout(async () => {
      if (uploadedFiles.file) {
        const data = new FormData();
        data.append("avatar", uploadedFiles.file, uploadedFiles.name);
        await api.patch("/avatar", data);
      } else if (!!contrato && contrato.path !== null) {
        const uploadTask = storage
          .ref(`contracts/${date}-${contrato.name}`)
          .put(contrato);

        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            console.log(error);
          },
          () => {
            storage
              .ref("contracts")
              .child(`${date}-${contrato.name}`)
              .getDownloadURL()
              .then(async (url) => {
                await api.post("/common/confirm/contract", {
                  url: url,
                  uploaded: true,
                });
                setLoading(false);
              });
          }
        );
      } else if (!!pdf && pdf.path !== null) {
        const uploadTask = storage
          .ref(`curriculum/${date}-${pdf.name}`)
          .put(pdf);

        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {},
          () => {
            storage
              .ref("curriculum")
              .child(`${date}-${pdf.name}`)
              .getDownloadURL()
              .then(async (url) => {
                const newUser = await api.put("/common", {
                  name,
                  email,
                  linkedin,
                  cpf,
                  phone,
                  sex,
                  curriculum: url,
                  valueHour,
                  preferencia,
                });
                setUser(newUser.data);
                localStorage.setItem(
                  "@flashPlayHR:user",
                  JSON.stringify(newUser.data)
                );
                setLoading(false);
              });
          }
        );
      } else {
        const newUser = await api.put("/common", {
          name,
          email,
          linkedin,
          cpf,
          phone,
          sex,
          valueHour,
          preferencia,
        });
        setUser(newUser.data);
        localStorage.setItem("@flashPlayHR:user", JSON.stringify(newUser.data));
        setLoading(false);
      }
    }, 1000);
  }, [
    inputNameRef,
    inputEmailRef,
    inputLinkedinRef,
    inputValorHora,
    inputPreferencial,
    cpfNumber,
    phoneNumber,
    posNumFunc,
    uploadedFiles,
    pdf,
    contrato,
  ]);

  const handleUpload = useCallback(
    (files: any) => {
      const newUploadedFiles = {
        file: files[0],
        id: uniqueId(),
        name: files[0].name,
        readableSize: filesize(files[0].size),
        preview: URL.createObjectURL(files[0]),
        progress: 0,
        uploaded: false,
        error: false,
        url: null,
      };
      setUploadedFiles(newUploadedFiles);
    },
    [uploadedFiles]
  );

  const handleUploadCurriculum = (file: any) => {
    if (file[0]) {
      setPdf(file[0]);
    }
  };

  const handleUploadContracts = (file: any) => {
    if (file[0]) {
      setContrato(file[0]);
    }
  };

  const SenhaAlterada = useCallback((data) => {
    setOpenModalAlteraSenha(false);
    setLoading(true);
    setTimeout(async () => {
      try {
        await api.post("/editPassword", data);
      } catch (e) {}
      setLoading(false);
    }, 1000);
  }, []);

  const alterarPreferencia = useCallback(async () => {
    if (preferencia === user.preference)
      return addToast({
        title: "Aviso",
        description: "Usuário não pode selecionar o mesmo tipo de perfil",
        icon: "alert",
        type: "info",
      });
    const res = await api.post("/common/profile/update-preference", {
      preference: preferencia,
    });
    const { usuario } = res.data;

    if (!usuario)
      return addToast({
        title: "Ocorreu um erro!",
        description: "Não foi possível fazer alteração",
        icon: "alert",
        type: "error",
      });

    setUser(usuario);

    setOpenModalAlterarPreferencia(false);
    return addToast({
      title: "Sucesso!",
      description: "Sua perfil foi alterado",
      icon: "alert",
      type: "success",
    });
  }, [preferencia]);

  return (
    <CardContainer>
      <CancelContaPlano
        open={modal}
        model="account"
        onClose={() => setModal(false)}
        onExclude={deleteAccount}
      />
      <ModalAlteraSenha
        open={openModalAlteraSenha}
        onClose={() => setOpenModalAlteraSenha(false)}
        onSave={SenhaAlterada}
      />
      <ModalAlterarPreferencia
        open={openModalAlterarPreferencia}
        setPreference={setPreference}
        preference={preferencia}
        onClose={() => setOpenModalAlterarPreferencia(false)}
        onFunction={alterarPreferencia}
      />
      {loading && (
        <Modal open={loading}>
          <ContainerLoading>
            <LoadingSale />
          </ContainerLoading>
        </Modal>
      )}
      <div className="box">
        <div
          className="button-remove"
          onClick={() => {
            setModal(true);
          }}
        >
          <AiOutlineCloseCircle />
        </div>
        <div className="box-in">
          <div
            className="box-upload"
            style={{
              justifyContent: "center",
              alignItems: "center",
              background: "#FFF",
              boxShadow: " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
              minWidth: 166,
              minHeight: 166,
              maxWidth: 166,
              maxHeight: 166,
            }}
          >
            <Upload file={uploadedFiles} onUpload={handleUpload} />
          </div>
          <div className="box-in-inputs">
            <InputPerfil
              styleInput={{
                fontFamily: "Gotham-Medium",
                color: "#000",
                background: "#FFF",
                fontSize: 16,
              }}
              styleContainer={{
                background: "#FFF",
                margin: 20,
              }}
              placeholder={
                languagePage === "PT" ? "Nome do candidato" : "Candidate's name"
              }
              defaultValue={user.name}
              ref={inputNameRef}
              label="nome"
            />
            <div className="input-responsive">
              <InputPerfil
                styleInput={{
                  fontFamily: "Gotham-Medium",
                  color: "#000",
                  background: "#FFF",
                  fontSize: 16,
                }}
                styleContainer={{
                  background: "#FFF",
                  margin: 20,
                  marginTop: 0,
                }}
                placeholder="Email"
                label="email"
                ref={inputEmailRef}
                defaultValue={user.email}
              />
              <InputPerfil
                styleInput={{
                  fontFamily: "Gotham-Medium",
                  color: "#000",
                  background: "#FFF",
                  fontSize: 16,
                }}
                styleContainer={{
                  background: "#FFF",
                  margin: 20,
                  marginTop: 0,
                }}
                placeholder={languagePage === "PT" ? "Telefone" : "Phone"}
                onChange={OnChangeInputPhone}
                label="telefone"
                value={phoneString}
              />
            </div>
            <div className="input-responsive">
              <InputPerfil
                styleInput={{
                  fontFamily: "Gotham-Medium",
                  color: "#000",
                  background: "#FFF",
                  fontSize: 16,
                }}
                styleContainer={{
                  background: "#FFF",
                  margin: 20,
                  marginTop: 0,
                }}
                placeholder="cpf"
                label="cpf"
                onChange={OnChangeInputCPForCNPJ}
                value={cpfString}
              />
              <SelectFly
                label="gênero"
                styleContainer={{
                  fontFamily: "Gotham-Medium",
                  color: "#000",

                  margin: "auto",
                  marginBottom: 20,

                  width: "100%",
                  maxWidth: "600px",
                  minWidth: "150px",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
                styleSelect={{
                  background: "#FFF",
                  margin: "auto",
                  height: 42,
                  width: "90%",
                }}
                onChangeOption={(num: number) => setPosNumFunc(num)}
                posOption={posNumFunc}
                options={
                  languagePage === "PT" ? genderOptions : genderOptionsUS
                }
                className="Select-Fly-genero"
              />
            </div>
          </div>
        </div>
        <InputPerfil
          styleInput={{
            fontFamily: "Gotham-Medium",
            color: "#000",
            background: "#FFF",
            fontSize: 16,
          }}
          styleContainer={{
            background: "#FFF",
            margin: 20,
            marginTop: 0,
          }}
          placeholder={
            languagePage === "PT"
              ? "Coloque o link do seu LinkedIn"
              : "Put your LinkedIn link"
          }
          label="linkedIn"
          ref={inputLinkedinRef}
          defaultValue={user.linkedin}
        />
        <div className="dropzone">
          {!!user.curriculum && (
            <iframe
              src={user.curriculum}
              className="iframe"
              title="Curriculo"
            />
          )}
          <div style={{ margin: 20, background: "grey", borderRadius: 10 }}>
            <DropZoneUpload onUploadCurriculum={handleUploadCurriculum} />
            {!!pdf && (
              <p style={{ marginLeft: 8, color: "#000000" }}>{pdf.name}</p>
            )}
          </div>
          {!!contract && contract.uploaded === false && (
            <div style={{ margin: 20, background: "grey", borderRadius: 10 }}>
              <DropZoneUploadContracts
                onUploadContracts={handleUploadContracts}
              />
              {!!contrato && (
                <p style={{ marginLeft: 8, color: "#000000" }}>
                  {contrato.name}
                </p>
              )}
            </div>
          )}
        </div>
        <div className="orientation-button">
          <ButtonAlterarPreferencia
            onClick={() => setOpenModalAlterarPreferencia(true)}
          >
            {languagePage === "PT" ? "Mudar perfil" : "Change profile"}
          </ButtonAlterarPreferencia>
          <ButtonAlterPasswordSave
            onClick={() => setOpenModalAlteraSenha(true)}
          >
            {languagePage === "PT" ? "Alterar senha" : "Change Password"}
          </ButtonAlterPasswordSave>
          <ButtonAlterPasswordSave onClick={onClickSave}>
            {languagePage === "PT" ? "Salvar Alterações" : "Save Data"}
          </ButtonAlterPasswordSave>
        </div>
      </div>
    </CardContainer>
  );
};
