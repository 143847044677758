import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Gotham-Medium;
    font-size: 14px;
    
    width: calc(100% - 40px);
    padding-bottom: 25px;
    margin: 20px;
    margin-top: 0px;
`
export const SubContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: row;
    background: #FFF;
    padding: 20px;
    border-radius: 10px;

    position: relative;
    border: 2px solid #6a8099;
    border-style: dashed;
`

export const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #fff;
    background: #134480;
    border-radius: 8px;
    flex-direction: column;
    width: 200px;
    height: 200px;
`