import { Modal } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { LoadingSale } from "../../../components/Animations/LoadingSale";
import Input, { InputRefProps } from "../../../components/Input";
import InputMultiline from "../../../components/InputMultiline";
import { useGlobal } from "../../../hooks/global";
import api from "../../../services/api";
import { ContainerLoading } from "./style";
import * as Yup from "yup";
import { useAuth } from "../../../hooks/auth";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const CreateSelection: React.FC = () => {
  const [selectpublic, setSelectPublic] = useState<boolean>(false);
  const [codeAccess, setCodeAccess] = useState("");
  const [errorTitle, setErrorTitle] = useState<string | null>(null);
  const [errorDescription, setErrorDescription] = useState<string | null>(null);
  const inputTitleRef = useRef<InputRefProps>(null);
  const { atualPageGlobal, languagePage } = useGlobal();
  const inputDescriptionRef = useRef<InputRefProps>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const onClickButton = useCallback(async () => {
    const errorTitle = await VerifyTitle();
    const errorDescription = await VerifyDescription();
    if (!!!errorTitle && !!!errorDescription) {
      const name = inputTitleRef.current?.getInputValue();
      const description = inputDescriptionRef.current?.getInputValue();
      setLoading(true);
      setTimeout(async () => {
        try {
          if (selectpublic === false) {
            const resu = await api.post("/company/selection", {
              codeAccess,
              public: selectpublic,
              name,
              description,
            });
          } else {
            const resu = await api.post("/company/selection", {
              public: selectpublic,
              name,
              description,
            });
          }
          inputTitleRef.current?.setInputValue("");
          inputDescriptionRef.current?.setInputValue("");
          toast.success(
            languagePage === "PT" ? "Seleção criada" : "Selection created"
          );
          clickOnAba("/company/selecoes")
                } catch (err: any) {
          if (err.response.data.message === "PLAN_ERROR") {
            const typeError = err.response.data.path;

            if (typeError === "statusPlan") {
              if (languagePage === "PT") {
                setErrorDescription("Seu plano não está ativo");

                setErrorTitle("Seu plano não está ativo");
              } else {
                setErrorDescription("Your plan is not active");
                setErrorTitle("Your plan is not active");
              }
            }
            if (typeError === "vacancyLimit") {
              if (languagePage === "PT") {
                toast.error(
                  "Você atingiu o limite de seleções ou não possui um plano"
                );
                setErrorDescription(
                  "Você atingiu o limite de seleções ou não possui um plano"
                );

                setErrorTitle(
                  "Você atingiu o limite de seleções ou não possui um plano"
                );
              } else {
                setErrorDescription(
                  "You've reached the selection limit or you don't have a plan"
                );
                setErrorTitle(
                  "You've reached the selection limit or you don't have a plan"
                );
              }
            }
            if (typeError === "expiresIn") {
              if (languagePage === "PT") {
                setErrorDescription("Seu plano expirou");

                setErrorTitle("Seu plano expirou");
              } else {
                setErrorDescription("Your plan expired");
                setErrorTitle("Your plan expired");
              }
            }
          }
        }
        setLoading(false);
      }, 200);
    }
  }, [selectpublic, languagePage, codeAccess]);

  const { setAtualPageGlobal } = useGlobal();
  const checkUserPlan = async () => {
    if (user.planName === "NoPlan") {
      toast.error(
        languagePage === "PT"
          ? "Você não possui um plano ativo"
          : "You don't have an active plan"
      );
      push("/company/planos");
    }
  };
  useEffect(() => {
    checkUserPlan();
    setAtualPageGlobal("/company/novaSelecao");
  }, []);

  const VerifyTitle = useCallback(async () => {
    let resu;
    setErrorTitle(null);
    const title = inputTitleRef.current?.getInputValue();
    const schemaTitle = Yup.object().shape({
      title: Yup.string().required(
        languagePage === "PT" ? "Campo obrigatório" : "Required field"
      ),
    });
    try {
      await schemaTitle.validate({ title });
    } catch (err: any) {
      setErrorTitle(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
    }
    return resu;
  }, [languagePage]);

  const VerifyDescription = useCallback(async () => {
    let resu;
    setErrorDescription(null);
    const description = inputDescriptionRef.current?.getInputValue();

    const schemaDescription = Yup.object().shape({
      description: Yup.string().required(
        languagePage === "PT" ? "Campo obrigatório" : "Required field"
      ),
    });
    try {
      await schemaDescription.validate({ description });
    } catch (err: any) {
      setErrorDescription(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
    }
    return resu;
  }, [inputDescriptionRef]);
  const { user } = useAuth();

  const { push } = useHistory();

  const clickOnAba = useCallback(
    (newAba) => {
      if (atualPageGlobal !== newAba) {
        push(newAba);
      }
    },
    [atualPageGlobal]
  );

  useEffect(() => {
    if (selectpublic === false) {
      api
        .get("/company/create-code-access")
        .then((e) => {
          const { codeAccess } = e.data;
          setCodeAccess(codeAccess);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectpublic]);

  const reqTipoSelecao = () => {
    setSelectPublic(!selectpublic);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3 style={{ margin: "auto" }}>
        {languagePage === "PT"
          ? "Escolha o tipo da sua seleção"
          : "Choose your selection type"}
      </h3>
      <div
        className="form-check form-switch"
        style={{
          padding: 10,
          marginTop: "100px",
          margin: "auto",
          display: "flex",
        }}
      >
        <div style={{ margin: 10 }}>
          {languagePage === "PT" ? "Mapeamento Interno" : "Internal Mapping"}
        </div>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          onClick={() => reqTipoSelecao()}
          style={{ padding: 10, width: 50, margin: "auto" }}
        />
        <div style={{ margin: 10 }}>
          {languagePage === "PT" ? "Seleção Externa" : "External Selection"}
        </div>
      </div>
      <ToastContainer></ToastContainer>

      {selectpublic === false ? (
        <Input
          styleInput={{
            fontFamily: "Gotham-Medium",
            color: "#0d3463",
            background: "#FFF",
            fontSize: 16,
          }}
          styleContainer={{
            background: "#fff",
            margin: 20,
            marginBottom: 10,
          }}
          value={
            languagePage === "PT"
              ? "Código da seleção: " + codeAccess
              : "Selection code:" + codeAccess
          }
          label="titulo"
        />
      ) : (
        ""
      )}

      <Input
        styleInput={{
          fontFamily: "Gotham-Medium",
          color: "#0d3463",
          background: "#FFF",
          fontSize: 16,
        }}
        styleContainer={{
          background: "#fff",
          margin: 20,
          marginBottom: 10,
        }}
        placeholder={
          languagePage === "PT" ? "Título da Seleção" : "Selection Title"
        }
        label="titulo"
        onChange={() => setErrorTitle(null)}
        diferTooltip={true}
        errors={errorTitle}
        ref={inputTitleRef}
      />
      <InputMultiline
        placeholder={
          languagePage === "PT"
            ? "Descrição da Seleção"
            : "Selection Description"
        }
        errors={errorDescription}
        styleContainer={{
          margin: 20,
          background: "#fff",
          marginTop: 10,
        }}
        onChange={() => setErrorDescription(null)}
        label="description"
        id="InputDescription"
        styleInput={{
          fontFamily: "Gotham-Medium",
          color: "#0d3463",
          background: "#FFF",
        }}
        diferTooltip={true}
        multiline
        ref={inputDescriptionRef}
        rows={10}
        rowsMax={10}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          fontFamily: "Gotham-Medium",
          color: "#FFF",
          marginRight: 20,
        }}
      >
        <div
          onClick={
            user.planName === "NoPlan"
              ? () => clickOnAba("/company/planos")
              : onClickButton
          }
          style={{
            cursor: "pointer",
            padding: 10,
            borderRadius: 5,
            background: "#f27823",
            boxShadow: "2px 2px 20px -4px rgba(0, 0, 0, 0.7)",
            WebkitBoxShadow: "2px 2px 20px -4px rgba(0, 0, 0, 0.7)",
          }}
        >
          {languagePage === "PT" ? "Criar Seleção" : "Create Selection"}
        </div>
      </div>
      {loading && (
        <Modal open={loading}>
          <ContainerLoading>
            <LoadingSale />
          </ContainerLoading>
        </Modal>
      )}
    </div>
  );
};
