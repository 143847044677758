import React, { createContext, useCallback, useContext, useState } from "react";
import { UserProps } from "../interfaces/userInterface";
import api from "./../services/api";

interface credentialsProps {
  email: string;
  password: string;
}

export interface companyProps {
  name: string;
  email: string;
  password: string;
  cnpj: string;
  phone: string;
  companySize: "Pequeno Porte" | "Médio Porte" | "Grande Porte" | "Startup";
  employeesRange: "0 - 10" | "11 - 21" | "22 - 50" | "51 - 100" | "100+";
  city: string;
  state: string;
  country: string;
  
}

export interface candidateProps {
  name: string;
  email: string;
  password: string;
  cpf: string;
  phone: string;
  sex: "Homem" | "Mulher" | "Outros";
  
}

interface AuthContextData {
  user: UserProps;
  setUser(value: UserProps): void;
  signIn(credentials: credentialsProps): Promise<void>;
  logonCompany(company: companyProps): Promise<void>;
  logonCandidate(candidate: candidateProps): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<UserProps>(() => {
    const userLocal = localStorage.getItem("@flashPlayHR:user");
    if (userLocal) return JSON.parse(userLocal);
    return {} as UserProps;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const res = await api.post("/login", { email, password });

    const { token, user } = res.data;

    localStorage.setItem("@flashPlayHR:token", token);
    localStorage.setItem("@flashPlayHR:user", JSON.stringify(user));
    setUser(user);
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@flashPlayHR:token");
    localStorage.removeItem("@flashPlayHR:user");
    setUser({} as UserProps);
  }, []);

  const logonCompany = useCallback(async (company: companyProps) => {
    await api.post("/company", company);
    const { email, password } = company;
    await signIn({ email, password });
  }, []);

  const logonCandidate = useCallback(async (company: candidateProps) => {
    await api.post("/candidate", company);
    const { email, password } = company;
    await signIn({ email, password });
  }, []);

  return (
    <AuthContext.Provider
      value={{ signIn, signOut, user, setUser, logonCompany, logonCandidate }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
