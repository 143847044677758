import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 100%;
  position: relative;
  .chat-header {
    background: #f2f2f2;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "Gotham-Medium", sans-serif;
    display: flex;
    padding: 8px 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #134480;
  }
  .chat-body {
    overflow-y: scroll;
    max-height: 325px;
    -webkit-max-height: 325px;
    width: 100%;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
  }
  .chat-body::-webkit-scrollbar {
    display: none;
  }
  .chat-footer {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 0;
    input {
      width: 100%;
      display: flex;
      border: none;
      padding: 4px 8px;
      font-size: 15px;
      border-bottom-left-radius: 8px;
    }
    button {
      border: none;
      padding: 4px;
      width: 50px;
      background: #6495ed;
      border-bottom-right-radius: 8px;
      transition: background 0.2s;
      &:hover {
        background: #add8e6;
      }
    }
  }
  @media (max-width: 768px) {
    margin-top: 12px;
    .chat-body {
      padding-bottom: 35px;
    }
    .chat-footer {
      input {
        font-size: 12px;
      }
    }
  }
  @media (min-width: 1024px) {
    max-width: 650px;
    min-width: 600px;
  }
`;

interface BoxMessageProps {
  left: boolean;
}

export const BoxMessage = styled.div<BoxMessageProps>`
  margin: 6px 8px;
  max-width: 320px;
  padding: 5px 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  //align-items: flex-start;
  ${(props: BoxMessageProps) => {
    return props.left === true ? "align-self: end" : "align-self: start";
  }};
  ${(props: BoxMessageProps) => {
    return props.left === true
      ? "align-items: flex-end"
      : "align-items: flex-start";
  }};
  ${(props: BoxMessageProps) => {
    return props.left === true ? "background: #6495ed" : "background: #5F9EA0";
  }};
  //  props.left === true ? "align-self: start" : "align-self: end"};
  .message-name {
    font-size: 10px;
    margin-bottom: 2.5px;
    font-family: "Gotham-Regular", sans-serif;
    color: #fff;
  }
  .message-text {
    font-size: 14px;
    font-family: "Gotham-Medium", sans-serif;
    color: #fff;
    word-wrap: break-word;
  }
  .message-date {
    font-size: 9px;
    font-family: "Gotham-Bold", sans-serif;
    color: #fff;
    align-self: flex-end;
  }
  @media (max-width: 768px) {
    margin-top: 12px;
    .message-name {
      font-size: 8px;
      margin-bottom: 2.5px;
      font-family: "Gotham-Regular", sans-serif;
      color: #fff;
    }
    .message-text {
      font-size: 11px;
      font-family: "Gotham-Medium", sans-serif;
      color: #fff;
      word-wrap: break-word;
    }
    .message-date {
      font-size: 8px;
      font-family: "Gotham-Bold", sans-serif;
      color: #fff;
      align-self: flex-end;
    }
  }
`;
