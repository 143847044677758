import React from "react";
import { useGlobal } from "../../hooks/global";
import { HabilitiesContainer, ContentContainer, TextContainerLeft, ListLeft, ListItemLeft, TitleLeft, TextContainerRight, ListRight, ListItemRight, TitleRight, TextCenter, TextCenterP, ContentContainerDiv} from "./styles";

const Habilities: React.FC = () => {
    const { setLanguagePage, languagePage } = useGlobal();
    return (
        <HabilitiesContainer >
            <ContentContainerDiv >

                <TextCenter>
                    
                        {languagePage === "PT"
                            ? "Avaliação através do 'Integrated Circular Skills Method' e" 
                            : "Assessment through the 'Integrated Circular Skills Method' and"
                        } <br />

                        {languagePage === "PT"
                            ? "aplicação do 'Circular Training'" 
                            : "application of the 'Circular Training'"
                        }
                    <br/>
                 </TextCenter>
                 <TextCenterP>
                        
                            {languagePage === "PT"
                                    ? "Oferecemos cursos para o desenvolvimento de habilidades orientadas para o Planejamento Circular sistêmico," 
                                    : "We offer courses for the development of skills oriented towards Circular Planning systemic,"
                            }
                       
                        {languagePage === "PT"
                                ? "Como também o método de avaliação é baseado nas Circular Skills, desenvolvido  no ano de 2020 e utilizado no App Flashplay360. (Brito, Celene; método baseado em estudo de Doutorado).  As habilidades circulares a partir desta plataforma estão sendo compreendidas como uma premissa para a transição de 'linear ao circular', inclusive se transformando em Programas na Europa." 
                                : "As well as the evaluation method is based on Circular Skills, developed in the year 2020 and used in the Flashplay360 App. (Brito, Celene; method based on doctoral study). Circular skills from this platform are being understood as a premise for the transition from 'linear to circular', including becoming a UNESCO Program."
                        }
                        
                    </TextCenterP>
                    <TextCenterP>
                            {languagePage === "PT"
                                    ? "Direitos autorais, reservados" 
                                    : "Copyright, reserved"
                            }
                    </TextCenterP>
            </ContentContainerDiv>
        </HabilitiesContainer>
    )

}

export default Habilities