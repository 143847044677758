import React, { useCallback, useEffect, useState } from "react";
import { FaPlay, FaCheckCircle, FaUser } from "react-icons/fa";

import api from "../../../services/api";
import { useHistory } from "react-router";
import { useGlobal } from "../../../hooks/global";

import {
  Container,
  CardSelection,
  CardHeader,
  CardBody,
  CardFooter,
  CardButton,
  CardHS,
  Status,
  CardBoxIcon,
} from "./style";

import { useAuth } from "../../../hooks/auth";

import { PopupModal } from "react-calendly";

export const MinhasSelecoes: React.FC = () => {
  const [selections, setSelections] = useState<[]>([]);
  const { user } = useAuth();
  const [atualPage, setAtualPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const [loading, setLoading] = useState(true);

  const { push } = useHistory();

  const { setAtualPageGlobal, languagePage } = useGlobal();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setAtualPageGlobal("/candidate/minhasSelecoes");
  }, []);

  const searchSelections = useCallback(() => {
    setLoading(true);
    setTimeout(async () => {
      setNumberOfPages(0);
      const resu = await api.get(
        `/common/mySelections?limit=10&page=${atualPage}`
      );
      setSelections(resu.data.rows);
      setNumberOfPages(Math.ceil(resu.data.count / 3));
      setLoading(false);
    }, 200);
  }, [atualPage]);

  useEffect(() => {
    searchSelections();
    return () => {
      setSelections([]);
    };
  }, [atualPage, searchSelections]);

  return (
    <Container>
      <PopupModal
        url="https://calendly.com/flashplayhrc/entrevista"
        open={open}
        onModalClose={() => setOpen(false)}
        rootElement={document.getElementById("root") as HTMLElement}
      />
      {!!selections &&
        selections.length > 0 &&
        selections.map((mySelection: any) => (
          <CardSelection>
            <CardHeader>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CardBoxIcon>
                  {!!mySelection.selection.company.avatar && (
                    <img
                      style={{ height: 50 }}
                      src={mySelection.selection.company.avatar}
                      alt="Imagem"
                    />
                  )}
                  {!mySelection.selection.company.avatar && (
                    <FaUser
                      color="#FFF"
                      size={window.innerWidth <= 768 ? 20 : 32}
                    />
                  )}
                </CardBoxIcon>
                <h5>{mySelection.selection.company.name}</h5>
              </div>
              <Status>
                {!!mySelection.selection.approved &&
                  mySelection.selection.approved.length > 0 &&
                  mySelection.selection.approved.map((status: any) =>
                    status.approved === true &&
                      status.approved_interview === true
                      ? "Aprovado"
                      : status.approved === true &&
                        status.approved_interview === null
                        ? "Aprovado na etapa 1"
                        : status.approved === false &&
                          status.approved_interview === null
                          ? "Reprovado"
                          : null
                  )}
              </Status>
            </CardHeader>
            <CardBody>
              <h5>{mySelection.selection.name}</h5>
              <p>{mySelection.selection.description}</p>
            </CardBody>
            <CardFooter>
              {!mySelection.selection.hard[0] && (
                <CardHS exist={false}>
                  <div>
                    <h5>Exame Hardskill</h5>
                  </div>
                  <div>
                    <CardButton
                      onClick={() =>
                        push(`/candidate/hardSkill/${mySelection.selection.id}`)
                      }
                    >
                      <FaPlay style={{ marginRight: 10 }} />
                      Iniciar Exame
                    </CardButton>
                  </div>
                </CardHS>
              )}
              {!!mySelection.selection.hard[0] && (
                <CardHS exist={true}>
                  <div>
                    <h5>Exame Hardskill</h5>
                  </div>
                  <div>
                    <FaCheckCircle style={{ marginRight: 10 }} />
                    <p>Realizado</p>
                  </div>
                </CardHS>
              )}
              {!mySelection.selection.soft[0] && (
                <CardHS exist={false}>
                  <div>
                    <h5>Exame Softskill</h5>
                  </div>
                  <div>
                    <CardButton
                      onClick={() =>
                        push(`/candidate/softSkill/${mySelection.selection.id}`)
                      }
                    >
                      <FaPlay style={{ marginRight: 10 }} />
                      Iniciar Exame
                    </CardButton>
                  </div>
                </CardHS>
              )}
              {!!mySelection.selection.tags &&
                mySelection.selection.tags.length > 0 &&
                mySelection.selection.tags.map((tag: any) => {
                  if (tag.answers.length > 0 && tag.questions.length > 0) {
                    return (
                      <CardHS exist={true}>
                        <div>
                          <h5>Exame Específico</h5>
                        </div>
                        <div>
                          <FaCheckCircle style={{ marginRight: 10 }} />
                          <p>Realizado</p>
                        </div>
                      </CardHS>
                    );
                  }
                  else if (tag.questions.length > 0) {
                    return (
                      <CardHS exist={false}>
                        <div>
                          <h5>Exame Específico</h5>
                        </div>
                        <div>
                          <CardButton
                            onClick={() =>
                              push(`/candidate/especifico/${tag.id}`)
                            }
                          >
                            <FaPlay style={{ marginRight: 10 }} />
                            Iniciar Exame
                          </CardButton>
                        </div>
                      </CardHS>
                    );
                  }
                })}
              {!!mySelection.selection.soft[0] && (
                <CardHS exist={true}>
                  <div>
                    <h5>Exame Softskill</h5>
                  </div>
                  <div>
                    <FaCheckCircle style={{ marginRight: 10 }} />
                    <p>Realizado</p>
                  </div>
                </CardHS>
              )}
              {!!mySelection.selection.approved &&
                mySelection.selection.approved.length > 0 &&
                mySelection.selection.approved.map((status: any) => {
                  if (
                    status.approved === true &&
                    status.approved_interview === null
                  ) {
                    return (
                      <CardHS exist={false}>
                        <div>
                          <h5>Entrevista</h5>
                        </div>
                        <div>
                          <CardButton onClick={() => setOpen(true)}>
                            <FaPlay style={{ marginRight: 10 }} />
                            Agendar
                          </CardButton>
                        </div>
                      </CardHS>
                    );
                  }
                  if (
                    status.approved === true &&
                    status.approved_interview === true
                  ) {
                    return (
                      <CardHS exist={true}>
                        <div>
                          <h5>Entrevista</h5>
                        </div>
                        <div>
                          <FaCheckCircle style={{ marginRight: 10 }} />
                          <p>Realizado</p>
                        </div>
                      </CardHS>
                    );
                  }
                })}
            </CardFooter>
          </CardSelection>
        ))}
    </Container>
  );
};
