import styled from "styled-components";

export const DisplayFlex = styled.div`
  display: flex;
`;

export const Container = styled(DisplayFlex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Pagination = styled(DisplayFlex)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 32px 0 64px;
`;

interface PaginationButtonProps {
  active: boolean;
}
export const PaginationButton = styled.button<PaginationButtonProps>`
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  margin: 0 4px;
  background: ${(props) => (props.active ? "#134480" : "#6086b5")};
  color: #fff;
  font-family: "Gotham-Bold", sans-serif;
`;

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 32px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 16px 32px;
  flex-wrap: wrap;
  min-height: 300px;

  .text-not {
    font-family: "Gotham-Medium", sans-serif;
    font-size: 18px;
    color: #134480;
  }
`;

export const Card = styled.div`
  width: 100%;
  max-width: 600px;
  background: #fff;
  border-radius: 2.5px;
  padding: 16px;
  margin-bottom: 42px;
  box-shadow: 10px 10px 70px -30px rgba(79, 79, 79, 0.75);
  -webkit-box-shadow: 10px 10px 70px -30px rgba(79, 79, 79, 0.75);
  -moz-box-shadow: 10px 10px 70px -30px rgba(79, 79, 79, 0.75);
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .box-icon {
    width: 40px;
    height: 40px;
    border-radius: 2.5px;
    background: #134480;
    color: #fff;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h5 {
    font-family: "Gotham-Bold", sans-serif;
    font-size: 20px;
    margin: 0px;
  }
  @media (max-width: 768px) {
    h5 {
      font-size: 16px;
    }
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f2f2f2;
  padding: 16px;
  margin: 16px 0;
`;

interface ButtonProps {
  background: string;
}

export const CardBodyButton = styled.button<ButtonProps>`
  background: ${(props) => props.background};
  border: none;
  border-radius: 5px;
  height: 56px;
  width: 100%;
  font-family: "Gotham-Medium", sans-serif;
  font-size: 15px;
  color: #fff;
  transition: opacity 0.2s;

  &:last-child {
    margin-left: 8px;
  }

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h5 {
    margin: 0;
    font-family: "Gotham-Medium", sans-serif;
    font-size: 15px;
  }

  .box-footer-buttons {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    h5 {
      font-size: 12px;
    }
  }
`;

export const FooterButton = styled.button<ButtonProps>`
  background: ${(props) => props.background};
  padding: 4px 16px;
  border: none;
  border-radius: 2.5px;
  font-family: "Gotham-Medium", sans-serif;
  color: #fff;
  font-size: 14px;
  transition: opacity 0.2s;

  &:last-child {
    margin-top: 8px;
  }

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
