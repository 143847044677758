import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { MdQuestionAnswer } from "react-icons/md";

import {
  Container,
  Header,
  ButtonEnd,
  ContainerHeader,
  ContainerTitle,
  ContainerBottom,
} from "./styles";

import { useGlobal } from "../../../hooks/global";

interface CancelarContaPlanoProps {
  open: boolean;
  onClose: Function;
  onExclude: Function;
  model: string;
}

export const CancelContaPlano: React.FC<CancelarContaPlanoProps> = ({
  open,
  onClose,
  onExclude,
  model,
}) => {
  const { languagePage } = useGlobal();

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <ContainerHeader>
          <Header>
            <ContainerTitle>
              <MdQuestionAnswer
                style={{ marginRight: "0.625rem" }}
                color="#101A36"
              />
              {model === "account"
                ? languagePage === "PT"
                  ? "Deseja Realmente cancelar a conta?"
                  : "Do you really want to cancel the account?"
                : languagePage === "PT"
                ? "Deseja Realmente cancelar o plano?"
                : "Do you really want to cancel the plan?"}
            </ContainerTitle>
          </Header>
        </ContainerHeader>

        <ContainerBottom>
          <ButtonEnd onClick={() => onClose()} style={{ color: "#252323" }}>
            {" "}
            {languagePage === "PT" ? "Cancelar" : "Cancel"}{" "}
          </ButtonEnd>
          <ButtonEnd
            onClick={() => onExclude()}
            style={{ color: "#101A36", cursor: "pointer" }}
          >
            {" "}
            {languagePage === "PT" ? "Prosseguir" : "Proceed"}
          </ButtonEnd>
        </ContainerBottom>
      </Container>
    </Modal>
  );
};

export default CancelContaPlano;
