import { useTransition } from "@react-spring/core";
import { animated } from "@react-spring/web";
import React, { useCallback, useEffect, useState } from "react";
import { Switch, useHistory, useLocation, Redirect } from "react-router";
import { Header } from "../components/Header";
import { useGlobal } from "../hooks/global";
import { Aba, ContainerAbas } from "./style";
import { BuscarSelecoes } from "../pages/DashBoardCandidate/BuscarSelecoes";
// import { Cursos } from "../pages/DashBoardCandidate/Cursos";
import { PerfilCandidate } from "../pages/DashBoardCandidate/Perfil";
import { useAuth } from "../hooks/auth";
import { MinhasSelecoes } from "../pages/DashBoardCandidate/MinhasSelecoes";
// import { SoftSkill } from "../pages/DashBoardCandidate/SoftSkill";
import { SoftSkillNovo } from "../pages/DashBoardCandidate/SoftSkillNovo";
import { HardSkillNovo } from "../pages/DashBoardCandidate/HardSkillNovo";
import { Especifico } from "../pages/DashBoardCandidate/Especifico";
import { Route } from "./route";
import { Propostas } from "../pages/DashBoardCandidate/Propostas";
import { DetalhesPropostas } from "../pages/DashBoardCandidate/DetalhesPropostas";

import { FaSearch, FaUserCog, FaWpforms } from "react-icons/fa";
import PainelDoConsultor from "../pages/DashBoardCandidate/PianelDoConsultor";
import api from "../services/api";

const DashboardCandidateRoutes: React.FC = () => {
  const location = useLocation();

  const { user } = useAuth();

  const transitions = useTransition(location, {
    from: { opacity: 0, config: { duration: 0 } },
    enter: { opacity: 1, config: { duration: 400 } },
    leave: { opacity: 0, config: { duration: 0 } },
  });

  const { atualPageGlobal, languagePage } = useGlobal();
  const [aprovado, setAprovado] = useState(false);

  const { push } = useHistory();

  const clickOnAba = useCallback(
    (newAba) => {
      if (atualPageGlobal !== newAba) push(newAba);
    },
    [atualPageGlobal]
  );
  useEffect(() => {
    if (user.preference === "consultant") {
      api.get("/common/approved-consultant")
        .then((res) => {
          setAprovado(res.data.approved);
        })
        .catch((err) => {
          console.log("Error: " + err);
        });
    }
  });

  return (
    <div
      id="navBarDash"
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        background: "#fff",
        // Lembrar
        overflowY: "scroll",
        flexDirection: "column",
      }}
    >
      <div style={{ marginBottom: 70 }}>
        <Header />
      </div>
      <ContainerAbas>
        <Aba
          isAtual={atualPageGlobal === "/candidate/buscarSelecoes"}
          onClick={() => clickOnAba("/candidate/buscarSelecoes")}
        >
          <FaSearch className="icone_res"></FaSearch>
          <small className="icone_nores">
            {languagePage === "PT" ? "Buscar Seleções" : "Search Selections"}
          </small>
        </Aba>
        <Aba
          isAtual={atualPageGlobal === "/candidate/minhasSelecoes"}
          onClick={() => clickOnAba("/candidate/minhasSelecoes")}
        >
          <FaWpforms className="icone_res"></FaWpforms>
          <small className="icone_nores">
            {languagePage === "PT" ? "Minhas Seleções" : "My Selections"}
          </small>
        </Aba>

        <Aba
          isAtual={atualPageGlobal === "/candidate/perfil"}
          onClick={() => clickOnAba("/candidate/perfil")}
        >
          <FaUserCog className="icone_res"></FaUserCog>
          <small className="icone_nores">
            {languagePage === "PT" ? "Meu Perfil" : "My Profile"}
          </small>
        </Aba>

        {aprovado === true && user.preference === "consultant"}

        {aprovado === true && user.preference === "consultant" ? (
          <Aba
            isAtual={atualPageGlobal === "/candidate/painelDoConsultor"}
            onClick={() => clickOnAba("/candidate/painelDoConsultor")}
          >
            <FaUserCog className="icone_res"></FaUserCog>
            <small className="icone_nores">
              {languagePage === "PT" ? "Painel do Consultor" : "My Profile"}
            </small>
          </Aba>
        ) : (
          ""
        )}

        {/* <Aba
          isAtual={atualPageGlobal === "/candidate/painelDoConsultor"}
          onClick={() => clickOnAba("/candidate/painelDoConsultor")}
        >
          <FaUserCog className="icone_res"></FaUserCog>
          <small className="icone_nores">
            {languagePage === "PT" ? "Painel do Consultor" : "Teste"}l
          </small>
        </Aba> */}

        {/* <Aba isAtual={atualPageGlobal === "/candidate/cursos"} onClick={() => clickOnAba("/candidate/cursos")}>Cursos</Aba> */}
      </ContainerAbas>
      <div
        style={{
          display: "flex",
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {transitions((props, item) => (
          <animated.div
            style={{
              ...props,
              position: "absolute",
              width: "100%",
              height: "100%",
              flexDirection: "row",
            }}
          >
            <Switch location={item}>
              <Route
                typeAccess="common"
                exact
                path="/candidate/buscarSelecoes"
                component={BuscarSelecoes}
              />
              <Route
                typeAccess="common"
                exact
                path="/candidate/minhasSelecoes"
                component={MinhasSelecoes}
              />
              {/* <Route typeAccess="candidate" exact path='/candidate/cursos' component={Cursos} /> */}
              <Route
                typeAccess="common"
                exact
                path="/candidate/perfil"
                component={PerfilCandidate}
              />
              <Route
                typeAccess="common"
                exact
                path="/candidate/painelDoConsultor"
                component={PainelDoConsultor}
              />
              <Route
                typeAccess="common"
                exact
                path="/candidate/softSkill/:selectionId"
                component={SoftSkillNovo}
              />
              <Route
                typeAccess="common"
                exact
                path="/candidate/hardSkill/:selectionId"
                component={HardSkillNovo}
              />
              {/*  */}
              <Route
                typeAccess="common"
                exact
                path="/candidate/softSkillnovo/:selectionId"
                component={SoftSkillNovo}
              />
              {/*  */}
              <Route
                typeAccess="common"
                exact
                path="/candidate/especifico/:tagId"
                component={Especifico}
              />
              <Route
                typeAccess="common"
                exact
                path="/candidate/propostas"
                component={Propostas}
              />
              <Route
                typeAccess="common"
                exact
                path="/candidate/proposta/conversacao"
                component={DetalhesPropostas}
              />
              <Redirect
                to={{
                  pathname: "/company/selecoes",
                  state: { from: location },
                }}
              />
              ;
            </Switch>
          </animated.div>
        ))}
      </div>
    </div>
  );
  // } else {
  //   return (
  //     <div
  //       id="navBarDash"
  //       style={{
  //         display: "flex",
  //         minWidth: 1220,
  //         height: "100%",
  //         background: "#fff",
  //         overflowY: "scroll",
  //         flexDirection: "column",
  //       }}
  //     >
  //       <Header />
  //       <ContainerAbas>
  //         <Aba
  //           isAtual={atualPageGlobal === "/candidate/buscarSelecoes"}
  //           onClick={() => clickOnAba("/candidate/buscarSelecoes")}
  //         >
  //           Search Selections
  //         </Aba>
  //         <Aba
  //           isAtual={atualPageGlobal === "/candidate/minhasSelecoes"}
  //           onClick={() => clickOnAba("/candidate/minhasSelecoes")}
  //         >
  //           My Selections
  //         </Aba>
  //         <Aba
  //           isAtual={atualPageGlobal === "/candidate/perfil"}
  //           onClick={() => clickOnAba("/candidate/perfil")}
  //         >
  //           My Profile
  //         </Aba>
  //         {/* <Aba isAtual={atualPageGlobal === "/candidate/cursos"} onClick={() => clickOnAba("/candidate/cursos")}>Cursos</Aba> */}
  //       </ContainerAbas>
  //       <div
  //         style={{
  //           display: "flex",
  //           position: "relative",
  //           width: "100%",
  //           height: "100%",
  //         }}
  //       >
  //         {transitions((props, item) => (
  //           <animated.div
  //             style={{
  //               ...props,
  //               position: "absolute",
  //               width: "100%",
  //               height: "100%",
  //               flexDirection: "row",
  //             }}
  //           >
  //             <Switch location={item}>
  //               <Route
  //                 typeAccess="candidate"
  //                 exact
  //                 path="/candidate/buscarSelecoes"
  //                 component={BuscarSelecoes}
  //               />
  //               <Route
  //                 typeAccess="candidate"
  //                 exact
  //                 path="/candidate/minhasSelecoes"
  //                 component={MinhasSelecoes}
  //               />
  //               {/* <Route typeAccess="candidate" exact path='/candidate/cursos' component={Cursos} /> */}
  //               <Route
  //                 typeAccess="candidate"
  //                 exact
  //                 path="/candidate/perfil"
  //                 component={PerfilCandidate}
  //               />
  //               <Route
  //                 typeAccess="candidate"
  //                 exact
  //                 path="/candidate/softSkill/:selectionId"
  //                 component={SoftSkill}
  //               />
  //               <Route
  //                 typeAccess="candidate"
  //                 exact
  //                 path="/candidate/hardSkill/:selectionId"
  //                 component={HardSkill}
  //               />
  //               <Route
  //                 typeAccess="candidate"
  //                 exact
  //                 path="/candidate/especifico/:tagId"
  //                 component={Especifico}
  //               />
  //               <Redirect
  //                 to={{
  //                   pathname: "/company/selecoes",
  //                   state: { from: location },
  //                 }}
  //               />
  //               ;
  //             </Switch>
  //           </animated.div>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // }
};

export default DashboardCandidateRoutes;
