import React, { useCallback, useRef } from "react";
import { GoChevronLeft } from "react-icons/go";
import { HiChevronRight } from "react-icons/hi";
import { useForm } from "../../../hooks/form";
import Input, { InputRefProps } from "../../Input";
import { useGlobal } from "../../../hooks/global";
import { BotoesForm, ButtonNextBack, Container, Titulo } from "../style";

interface NameReaponsavelData {
  onNext: Function;
  onBack: Function;
}

const NameReaponsavel: React.FC<NameReaponsavelData> = ({ onNext, onBack }) => {
  const nameRef = useRef<InputRefProps>(null);

  const { responsavel, setResponsavel, setType } = useForm();

  const onClickNext = useCallback(() => {
    if (responsavel != "") onNext();
  }, [responsavel]);

  const onClickBack = useCallback(() => {
    onBack();
  }, []);

  const onChange = useCallback(() => {
    const name = nameRef.current?.getInputValue()
      ? nameRef.current.getInputValue()
      : "";
    setResponsavel(name);
  }, [nameRef]);
  const { setAtualPageGlobal, languagePage } = useGlobal();


    return (
      <Container
        style={{
          textAlign:"center"
        }}
      >
        <div>
          <Titulo>
            {languagePage === "PT" ? "Ótimo, só precisamos do nome do responsável de sua empresa" : "Great, we just need some data from your company"}
          </Titulo>
          <Input
            ref={nameRef}
            label="Nome do Responsável"
            defaultValue={responsavel}
            onChange={onChange}
            styleInput={{
              fontFamily: "Gotham-Bold",
              color: "#FFF",
              background: "#0b243b",
              fontSize: 16,
            }}
            styleContainer={{ background: "#0b243b" }}
            placeholder="Nome do Responsável"
          />
          <ButtonNextBack style={{flexWrap:"wrap", textAlign:"center" }}>
            <BotoesForm
              onClick={onClickBack}
             
            >
              <GoChevronLeft />
              {languagePage === "PT" ? "Anterior" : "Back"}
            </BotoesForm>
            <BotoesForm
              onClick={onClickNext}
              style={{
    
                cursor: responsavel ? "pointer" : "not-allowed",
              }}
            >
              {languagePage === "PT" ? " Próximo" : "Next"}
              <HiChevronRight style={{ marginLeft: 10 }} />
            </BotoesForm>
          </ButtonNextBack>
        </div>
      </Container>
    );
 
};

export default NameReaponsavel;
