import {
    HeaderContainer,
    VideoBackground,
    HeaderBar,
    Woman,
    GuaraWolf,
    Chamaleon,
    Butterfly,
    FlashPlayLogo,
    LogoContainer,
    HeaderInfoContainer,
    InfoButton,
    ConsultPlansButton,
    ButtonsContainer,
    Text,
    Title,
    TextContainer,
    ImagesContainer,
    StoreButton,
    SaleButton,
    Container
} from "./styles";
import NavBar from "../HomeNavBar";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useGlobal } from "../../hooks/global";
import { Grid, Button } from '@mui/material';
import WhatsAppButton from "../Whatsapp"

const videoBg =
    "https://res.cloudinary.com/dkqcb920e/video/upload/v1656858921/background_video_1080_mww6l7.mp4";

const Header: React.FC = () => {
    const { setLanguagePage, languagePage } = useGlobal();
    const { push } = useHistory();

    return (
        <HeaderContainer>

            <HeaderBar id="headerbar">
                {window.matchMedia("(min-width:769px)").matches &&
                    <LogoContainer>
                        <FlashPlayLogo
                            src='https://res.cloudinary.com/dkqcb920e/image/upload/v1655915573/logo3_e6ucbq_pwaatc.webp'
                            alt='EcoRecitec|FlashPlayHR'
                            onClick={() => push("/")}
                        />
                    </LogoContainer>
                }
                <NavBar />
            </HeaderBar>

            <div style={{ display: "flex",zIndex:"1", width:"100%", height: "100vh", gap:"1em", backgroundColor: "rgba(0, 1, 36, 0.8)"}}>
               
                    <Woman
                        src='https://res.cloudinary.com/dkqcb920e/image/upload/v1655828276/Modelo_om0ekv.png'
                        alt='girl-logo'
                    />
                

                <HeaderInfoContainer>
                    <TextContainer>
                        <div style={{ display: "flex", marginTop: "8%" }}>
                            <Title>{languagePage === "PT" ? `"Mindset" do Linear ao Circular` : `MindSet and Circular Skills`}</Title>
                        </div>
                        <Text>
                            {languagePage === "PT" ? `Consiga engajamento, performance e agilidade do seu time frente as mudanças.Cultura Circular, Governança, projetos sociais e ambientais com direcionamento estratégico. Economia Circular e ESG.` : `Achieve engagement, performance and agility from your team in the face of changes. Circular Culture, Governance, social and environmental projects with strategic direction. Circular Economy and ESG.`}

                        </Text>

                    </TextContainer>
                    <Grid style={StoreButton}>
                        <ButtonsContainer>
                            <InfoButton onClick={() => push("/educacao")}>{languagePage === "PT" ? "Saiba mais" : "Learn more"}</InfoButton>
                            <ConsultPlansButton onClick={() => push("/planos")}>{languagePage === "PT" ? "Nossos Planos" : "Our Plans"}</ConsultPlansButton>
                        </ButtonsContainer>
                        <Button href="#generatedProduct" style={SaleButton}>{languagePage === "PT"
                            ? "Promoções"
                            : "Promotions"}</Button>

                    </Grid>

                </HeaderInfoContainer>

                <ImagesContainer>
                    <Butterfly
                        src='https://res.cloudinary.com/dkqcb920e/image/upload/v1655914864/borboleta-sem-fundo_cefcum.png'
                        alt='butterfly-logo'
                    />
                    <Chamaleon
                        src='https://res.cloudinary.com/dkqcb920e/image/upload/v1655914864/camale%C3%A3o-sem-fundo_bhe0vx.png'
                        alt='chamaleon-logo'
                    />
                    <GuaraWolf
                        src='https://res.cloudinary.com/dkqcb920e/image/upload/v1655828490/lobo_fotxth.png'
                        alt='guara-wolf-logo'
                    />
                </ImagesContainer>
                <WhatsAppButton />
            </div>
            <VideoBackground src={videoBg} autoPlay loop muted />
        </HeaderContainer>
    );
};

export default Header;
