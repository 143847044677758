import React from "react";
import { useState } from "react";
import { send } from "emailjs-com";
import { useGlobal } from "../../hooks/global";
import { useToast } from "../../hooks/toast";

//import Vibra from "../../assets/fonts/provaSocial/vibra.jpeg";

import { ContactContainer, InnerContainer, ImageEntrada3, Form, Input, TextArea, SectionTitle, SectionTitleMarca, SectionTitleMarcaEntrada, SectionTitleContainer, SubmitButton, Text, TextEntrada, TextContainer, Image, ImageEntrada, ImageContainer, FormContainer, InfoContainer, ContainerFlex, TextContainerColumn } from "./styles";
import SocialBar from "../HomeSocialMedia";

const Contact: React.FC = () => {
    const { setLanguagePage, languagePage } = useGlobal();
    const [toSend, setToSend] = useState({
        from_name: "",
        reply_to: "",
        message: "",
        phone: ""
    })
    const { addToast } = useToast()

    const Submit = (e: React.FormEventHandler<HTMLFormControlsCollection> | any) => {
        e.preventDefault()
        setTimeout(async () => {
            send(
                "service_l5o1a7q",
                "template_wwrjq32",
                toSend,
                "lYLle34vbXoFWG_Sw"
            )
                .then((response) => {
                    if (response.status === 200) {
                        // alert("Email enviado com sucesso")
                        addToast({
                            title: languagePage === "PT" ? "Email enviado" : "Email sent",
                            description: languagePage === "PT"
                                ? "Mensagem recebida, entraremos em contato em breve."
                                : "Message received, we will contact you shortly.",
                            icon: "info",
                            type: "success",
                        })
                    } else {
                        // alert("Erro ao enviar o email")
                        addToast({
                            title: languagePage === "PT" ? "Erro" : "Error",
                            description: languagePage === "PT"
                                ? "Pedimos desculpas, algo de errado aconteceu e seu email não pode ser enviado, entre em contato pelas redes sociais ou informações ao lado."
                                : "We apologize, something went wrong and your email could not be sent, please contact us via social media or information on the side.",
                            icon: "alert",
                            type: "error",
                        })
                    }

                })
                .catch((err) => {
                    console.log("Error on sending email: ", err)
                })
        }, 200)

    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value })
    }

    /*Formulário removido para testar funcionalidade posteriormente
    <Form onSubmit={Submit}>
        <Input type="text" name="from_name" placeholder={languagePage === "PT" ? "Nome" : "Name"} value={toSend.from_name} onChange={handleChange} required />
        <Input type="email" name="reply_to" placeholder="Email" value={toSend.reply_to} onChange={handleChange} required />
        <Input type="phone" name="phone" placeholder={languagePage === "PT" ? "Telefone" : "Phone Number"} value={toSend.phone} onChange={handleChange} />
        <TextArea name="message" placeholder={languagePage === "PT" ? "Mensagem" : "Message"} value={toSend.message} onChange={handleChange} required />
        <SubmitButton value="Submit" type="submit"> {languagePage === "PT" ? "Enviar" : "Send"}</SubmitButton>
    </Form>
    */

    return (
        <ContactContainer>
            <InnerContainer>
                <InfoContainer>
                    
                    <SectionTitleContainer>
                        <SectionTitleMarca>
                            {languagePage === "PT" ? "Instituições e Projetos da EcoRecitec" : "EcoRecitec Institutions and Projects"}
                        </SectionTitleMarca>
                    </SectionTitleContainer>

                    <ContainerFlex>
                        <TextContainer>
                            <TextEntrada>{languagePage === "PT" ? "Projeto MINTEGRA: Educação Ambiental direcionada para a Reciclagem e Coleta Seletiva em Praia do Forte - Litoral Norte da Bahia com parceria com a Caixa Econômica Federal." : "MINTEGRA Project: Environmental Education aimed at Recycling and Selective Collection in Praia do Forte - North Coast of Bahia"}</TextEntrada>
                        </TextContainer>
                        {/* <ImageEntrada3
                            src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1690828414/Assets-FlashPlayHr/krnuuzjacmy24fefnw4k.png"
                            alt="caixa-economica-federal-logo"
                            width={'60%'}
                        /> */}
                    </ContainerFlex>
                    
                    <ContainerFlex>
                        <TextContainer>
                            <TextEntrada> {languagePage === "PT" ? "Projeto de Educação Ambiental Dona Verde para os funcionários da Petrobás - Contratação realizada na Bahia com parceria com a Petrobrás" : "Dona Verde Environmental Education Project for Petrobás employees - Hiring carried out in Bahia"}.</TextEntrada>
                        </TextContainer>
                        {/* <ImageEntrada3
                            src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1690830114/Assets-FlashPlayHr/petrobras-logo-png-836-removebg-preview_oqjnoj.png"
                            alt="petrobras-logo"
                            width={'60%'}
                        /> */}
                    </ContainerFlex>
                    <ContainerFlex>
                        <TextContainer>
                            <TextEntrada>{languagePage === "PT" ? "Projeto Pró-Subaé: Educação Ambiental direcionada ao Saneamento desenvolvido em Feira de Santana na Bahia com parceria com a Embasa." : "Pró-Subaé Project: Environmental Education aimed at Sanitation developed in Feira de Santana in Bahia."}</TextEntrada>
                        </TextContainer>
                        {/* <ImageEntrada3
                            src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1690828414/Assets-FlashPlayHr/ximbnqda6lxzadfttwuy.png"
                            alt="embasa-logo"
                            width={'60%'}
                            height={'60%'}
                        /> */}
                    </ContainerFlex>
                    <ContainerFlex>
                        <TextContainer>
                            <TextEntrada>{languagePage === "PT" ? "Piloto desenvolvido em uma escola da Região da Chapada Diamantina com êxito." : "Pilot successfully developed in a school in the Chapada Diamantina Region."}</TextEntrada>
                        </TextContainer>
                        {/* <ImageEntrada3
                            src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1690828414/Assets-FlashPlayHr/ximbnqda6lxzadfttwuy.png"
                            alt="embasa-logo"
                            width={'60%'}
                            height={'60%'}
                        /> */}
                    </ContainerFlex>
                    <br /><br />
                    <TextContainer>
                        <TextEntrada>Governo da Bahia: Projeto de Educação Ambiental Bahia Azul</TextEntrada>
                        <TextEntrada></TextEntrada>
                        <TextEntrada></TextEntrada>
                    </TextContainer>
                    <br />

                    <ContainerFlex>
                        <SectionTitleContainer>
                            <SectionTitleMarcaEntrada>{languagePage === "PT" ? "Piloto desenvolvido em uma indústria de eletrônicos em São Paulo" : "Pilot developed in an electronics industry in São Paulo"}</SectionTitleMarcaEntrada>
                        </SectionTitleContainer>
                        <ImageEntrada3
                            src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1695943425/nknz68t3f2llrur31cew.jpg"
                            alt="mulheres-empreededoras-VIBRA-logo"
                            width={'80%'}
                        />
                    </ContainerFlex>

                </InfoContainer>
                <FormContainer>
                    <InfoContainer>
                        <SectionTitleContainer>
                            <SectionTitleMarca>
                                {languagePage === "PT" ? "A EcoRecitec e a Plataforma Flashplayhr agora fazem parte da Ellen MacArthur Foundation Community" : "EcoRecitec and the Flashplayhr Platform are now part of the Ellen MacArthur Foundation Community'"}
                            </SectionTitleMarca>
                        </SectionTitleContainer>
                        <TextContainerColumn>
                            <Text>
                                {languagePage === "PT" ? "Informações para contato" : "Contact information"}
                            </Text>
                            <Text>
                                Email: eco.recitec@gmail.com <br />
                                <a
                                    href="https://api.whatsapp.com/send?phone=5571986956150"
                                    style={{ textDecoration: "none", color: "white" }}
                                >{languagePage === "PT" ? "WhatsApp" : "WhatsApp"}</a>: +55 (71) 99974-8068
                            </Text>
                        </TextContainerColumn>
                        <SocialBar />
                        <ImageContainer>
                            <Image />
                        </ImageContainer>
                        <Text>
                            <br /><br /><br />
                            {languagePage === "PT" ? "Universidades, Artigos e Pesquisas:" : "Universities, Articles and Research:"}
                        </Text>
                        <Text>
                            {languagePage === "PT" ? "Universidade de Ferrara, Itália " : "University of Ferrara, Italy "}
                            {languagePage === "PT" ? "Artigos Apresentados no Evento RemTechEurope- 2020 e 2021" : "Papers Presented at the RemTechEurope Event - 2020 and 2021"}
                        </Text>
                        <Text>
                            {languagePage === "PT" ? "UCES- \"Universidade de Ciências Sociais e Empresariais\"" : "\"UCES- 'University of Social and Business Sciences\""}
                        </Text>
                        <Text>
                            {languagePage === "PT" ? "Pesquisa de Tese- Brito, Celene (2018).  Buenos Aires - Argentina" : "Thesis Research- Brito, Celene (2018). Buenos Aires - Argentina"}
                        </Text>
                    </InfoContainer>
                </FormContainer>
            </InnerContainer>
        </ContactContainer>
    )
}

export default Contact