import { Modal } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { LoadingSale } from "../../../components/Animations/LoadingSale";
import Input, { InputRefProps } from "../../../components/Input";
import InputMultiline from "../../../components/InputMultiline";
import { useGlobal } from "../../../hooks/global";
import api from "../../../services/api";
import { ContainerLoading } from "./style";
import * as Yup from "yup";
import { useAuth } from "../../../hooks/auth";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

export const CriarSelecao: React.FC = () => {
  const [errorTitle, setErrorTitle] = useState<string | null>(null);
  const [errorDescription, setErrorDescription] = useState<string | null>(null);
  const [errorType, setErrorType] = useState<string | null>(null);
  const inputTitleRef = useRef<InputRefProps>(null);
  const inputDescriptionRef = useRef<InputRefProps>(null);
  const inputTypeRef = useRef<InputRefProps>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const onClickButton = useCallback(async () => {
    const errorTitle = await VerifyTitle();
    const errorDescription = await VerifyDescription();
    const errorType = await VerifyType();

    if (!!!errorTitle && !!!errorDescription && !!!errorType) {
      const name = inputTitleRef.current?.getInputValue();
      const description = inputDescriptionRef.current?.getInputValue();
      const type = inputTypeRef.current?.getInputValue();
      setLoading(true);
      setTimeout(async () => {
        try {
          const resu = await api.post("/root/create-selection", {
            name,
            description,
            type,
          });
          inputTitleRef.current?.setInputValue("");
          inputDescriptionRef.current?.setInputValue("");
          inputTypeRef.current?.setInputValue("");
          toast.success("Seleção criada");
        } catch (err: any) {
          console.log(err.message);
        }
        setLoading(false);
      }, 200);
    }
  }, [inputDescriptionRef, inputTitleRef]);

  const { setAtualPageGlobal } = useGlobal();
  
  useEffect(() => {
    setAtualPageGlobal("/root/criar-selecao");
  }, []);

  const VerifyTitle = useCallback(async () => {
    let resu;
    setErrorTitle(null);
    const title = inputTitleRef.current?.getInputValue();
    const schemaTitle = Yup.object().shape({
      title: Yup.string().required(
        languagePage === "PT" ? "Campo obrigatório" : "Required field"
      ),
    });
    try {
      await schemaTitle.validate({ title });
    } catch (err: any) {
      setErrorTitle(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
    }
    return resu;
  }, [inputTitleRef]);

  const VerifyType = useCallback(async () => {
    let resu;
    setErrorType(null);
    const type = inputTypeRef.current?.getInputValue();
    const schemaTitle = Yup.object().shape({
      type: Yup.string().required(
        languagePage === "PT" ? "Campo obrigatório" : "Required field"
      ),
    });
    try {
      await schemaTitle.validate({ type });
    } catch (err: any) {
      setErrorType(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
    }
    return resu;
  }, [inputTypeRef]);

  const VerifyDescription = useCallback(async () => {
    let resu;
    setErrorDescription(null);
    const description = inputDescriptionRef.current?.getInputValue();

    const schemaDescription = Yup.object().shape({
      description: Yup.string().required(
        languagePage === "PT" ? "Campo obrigatório" : "Required field"
      ),
    });
    try {
      await schemaDescription.validate({ description });
    } catch (err: any) {
      setErrorDescription(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
    }
    return resu;
  }, [inputDescriptionRef]);

  const { languagePage } = useGlobal();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ToastContainer></ToastContainer>

      <Input
        styleInput={{
          fontFamily: "Gotham-Medium",
          color: "#0d3463",
          background: "#FFF",
          fontSize: 16,
        }}
        styleContainer={{
          background: "#fff",
          margin: 20,
          marginBottom: 10,
        }}
        placeholder={languagePage === "PT" ? "Para que área" : "For what area"}
        label="titulo"
        onChange={() => setErrorType(null)}
        diferTooltip={true}
        errors={errorType}
        ref={inputTypeRef}
      />
      <Input
        styleInput={{
          fontFamily: "Gotham-Medium",
          color: "#0d3463",
          background: "#FFF",
          fontSize: 16,
        }}
        styleContainer={{
          background: "#fff",
          margin: 20,
          marginBottom: 10,
        }}
        placeholder={
          languagePage === "PT" ? "Título da Seleção" : "Selection Title"
        }
        label="titulo"
        onChange={() => setErrorTitle(null)}
        diferTooltip={true}
        errors={errorTitle}
        ref={inputTitleRef}
      />
      <InputMultiline
        placeholder={
          languagePage === "PT"
            ? "Descrição da Seleção"
            : "Selection Description"
        }
        errors={errorDescription}
        styleContainer={{
          margin: 20,
          background: "#fff",
          marginTop: 10,
        }}
        onChange={() => setErrorDescription(null)}
        label="description"
        id="InputDescription"
        styleInput={{
          fontFamily: "Gotham-Medium",
          color: "#0d3463",
          background: "#FFF",
        }}
        diferTooltip={true}
        multiline
        ref={inputDescriptionRef}
        rows={10}
        rowsMax={10}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          fontFamily: "Gotham-Medium",
          color: "#FFF",
          marginRight: 20,
        }}
      >
        <div
          onClick={onClickButton}
          style={{
            cursor: "pointer",
            padding: 10,
            borderRadius: 5,
            background: "#f27823",
            boxShadow: "2px 2px 20px -4px rgba(0, 0, 0, 0.7)",
            WebkitBoxShadow: "2px 2px 20px -4px rgba(0, 0, 0, 0.7)",
          }}
        >
          {languagePage === "PT" ? "Criar Seleção" : "Create Selection"}
        </div>
      </div>
      {loading && (
        <Modal open={loading}>
          <ContainerLoading>
            <LoadingSale />
          </ContainerLoading>
        </Modal>
      )}
    </div>
  );
};
