import { Modal } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import { Container, Input } from "./style";

interface ModalProps {
  open: boolean;
  onClose: () => any;
  proposalId: string;
}

export const ModalCancelarProposta: React.FC<ModalProps> = ({
  open,
  onClose,
  proposalId,
}) => {
  const [canceledDescription, setCanceledDescription] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const { push } = useHistory();
  const { addToast } = useToast();

  const handleFinalized = useCallback(
    async (event: any) => {
      event.preventDefault();
      if (canceledDescription.length === 0) {
        setError(true);
        return;
      }
      try {
        const res = await api.post(`/company/cancel-proposal/${proposalId}`, {
          cancel: true,
          cancelDescription: canceledDescription,
        });
        const { canceled } = res.data;

        if (canceled) {
          addToast({
            title: "Sucesso!",
            icon: "info",
            description: "Sua proposta foi cancelada.",
            type: "info",
          });
          onClose();
          push("/company/consultoria/minhas-propostas");
        }
      } catch (err) {
        addToast({
          title: "Erro!",
          icon: "info",
          description:
            "Houve um problema ao efetuar o cancelamento, por favor tente novamente.",
          type: "info",
        });
      }
    },
    [addToast, canceledDescription, onClose, proposalId, push]
  );
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: 1000,
        margin: "0 auto",
      }}
    >
      <Container>
        <h5>
          <strong>OBS:</strong> caso cancele a proposta, se tiver passado mais
          de 7 dias depois do primeiro pagamento ou tenha feito o pagamento por
          boleto não será reembolsado.
        </h5>
        <div className="input-box">
          <Input
            value={canceledDescription}
            onChange={(e) => {
              if (e.target.value.length !== 0) {
                setError(false);
              }
              setCanceledDescription(e.target.value);
            }}
            error={error}
            placeholder="diga o motivo por está cancelando"
          />
          {!!error && <p>Campo obrigatório!</p>}
        </div>
        <div>
          <button onClick={() => onClose()}>Cancelar</button>
          <button onClick={handleFinalized}>Finalizar</button>
        </div>
      </Container>
    </Modal>
  );
};
