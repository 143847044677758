import { useTransition } from "@react-spring/core";
import { animated } from "@react-spring/web";
import React, { Component } from "react";
import { Switch, useLocation, Redirect, useHistory } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import { Finish } from "../pages/Finish";
import ForgotPassword from "../pages/ForgotPassword";
import Home  from "../pages/Home";
import { Login } from "../pages/Login";
import { Logon } from "../pages/Logon";
import { Planos } from "../pages/Planos";
import { Educacao } from "../pages/Educacao";
import Publicselecoes from "../pages/PublicSelecoes";
import ResetPassword from "../pages/ResetPassword";
import DashboardCandidateRoutes from "./dashboardCandidateRoutes";
import DashboardRootRoutes from "./dashboardRoot";
import DashboardRoutes from "./dashboardRoutes";
import { Route } from "./route";
import { Pdf } from "../components/PDF";
import PublicSelecoesExpecifica from "../pages/PublicSelecoesExpecifica";
import Publicselecoesconsultores from "../pages/PublicSelecoesConsultores";
import { Publica } from "../pages/DashboardCompany/Publica";
import { BuscarSelecoes } from "../pages/DashBoardCandidate/BuscarSelecoes";
import BookCombo from "../pages/Products/BookCombo"
import LivoVivoBook from "../pages/Products/LixoVivoBook"
import Mindset from "../pages/Products/Mindset"
import SaberesCirculares from "../pages/Products/SaberesCirculares"


export const PrincipalRoutes: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();
  const { push } = useHistory();

  const transitions = useTransition(location, {
    from: { opacity: 1, config: { duration: 0 } },
    enter: { opacity: 1, config: { duration: 400 } },
    leave: { opacity: 0, config: { duration: 0 } },
  });

  return transitions((props, item) => (
    <animated.div
      style={{
        ...props,
        position: "absolute",
        width: "100%",
        height: "100%",
        flexDirection: "row",
      }}
    >
      <Switch location={location}>
        <Route typeAccess="geral" exact path="/" component={Home} />
        <Route typeAccess="credenciais" exact path="/logon" component={Logon} />
        <Route typeAccess="credenciais" exact path="/login" component={Login} />
        <Route typeAccess="geral" exact path="/finish" component={Finish} />
        <Route typeAccess="geral" exact path="/planos" component={Planos} />
        <Route typeAccess="geral" exact path="/educacao" component={Educacao} />
        <Route typeAccess="geral" exact path="/pdf" component={Pdf} />
        <Route typeAccess="geral" exact path="/bookCombo" component={BookCombo} />
        <Route typeAccess="geral" exact path="/revolucaoDoLixoVivo" component={LivoVivoBook} />
        <Route typeAccess="geral" exact path="/mindset" component={Mindset} />
        <Route typeAccess="geral" exact path="/saberesCirculares" component={SaberesCirculares} />
        <Route
          typeAccess="geral"
          exact
          path="/public/selecoes"
          component={Publicselecoes}
        />
        <Route
          typeAccess="geral"
          exact
          path="/public/selecoes/consultores"
          component={Publicselecoesconsultores}
        />
        <Route
          typeAccess="geral"
          exact
          path="/public/selecoes/consultores/:id"
          component={PublicSelecoesExpecifica}
        />
        <Route
          typeAccess="geral"
          exact
          path="/public/selecoes/:id"
          component={PublicSelecoesExpecifica}
        />
        <Route
          typeAccess="company"
          path="/company"
          component={DashboardRoutes}
        />
        <Route
          typeAccess="common"
          path="/candidate"
          component={DashboardCandidateRoutes}
        />
        <Route typeAccess="root" path="/root" component={DashboardRootRoutes} />
        <Route
          typeAccess="reset"
          exact
          path="/reset-password/:token"
          component={ResetPassword}
        />
        <Route
          typeAccess="reset"
          exact
          path="/forgot-password"
          component={ForgotPassword}
        />
        {!!user && user.type === "common" && (
          <Redirect
            to={{
              pathname: "/candidate/buscarSelecoes",
              state: { from: location },
            }}
          />
        )}
        <Redirect to={{ pathname: "/", state: { from: location } }} />;
      </Switch>
    </animated.div>
  ));
};
