import { CircularProgress } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { FaTrashAlt, FaUser, FaUserAlt } from "react-icons/fa";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Route, Switch, useHistory, useParams, useRouteMatch } from "react-router";
import { useGlobal } from "../../../hooks/global";
import { requestInterface } from "../../../interfaces/requestInterface";
import { selectionInterface } from "../../../interfaces/selectionInterface";
import api from "../../../services/api";
import { Doughnut, Pie, Bar } from 'react-chartjs-2';
// import Chart from 'chart.js/auto';
import {
  BoxBackTitle,
  Setas,
  SetasTwo,
  Tabela,
  Container,
  SubContainer,
  AbatarContainer,
} from "./style";
import { barGraphDataInterface, candidateGraphInterface } from "../../../interfaces/candidateGraphInterface";
import { Chart, ArcElement, Tooltip, CategoryScale, LinearScale, BarElement, Title, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Selections } from "../Questionarios/style";
Chart.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

export const Candidatos: React.FC = () => {
  const { selectionId }: any = useParams();
  const { push } = useHistory();

  const goToTabelaPage = () => {
    push(`/company/candidatos/${selectionId}/profileMapping`);
  };

  const goToSoftTabelaPage = () => {
    push(`/company/candidatos/${selectionId}/softProfileMapping`);
  };

  let { path } = useRouteMatch();
  const [atualPage, setAtualPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [requests, setRequests] = useState<requestInterface[]>(
    [] as requestInterface[]
  );
  const [selection, setSelection] = useState<selectionInterface>(
    {} as selectionInterface
  );
  const [loading, setLoading] = useState(true);
  const [classifications, setClassifications] = useState<any[]>([]);
  const [companyReportIntegrate, setCompanyReportIntegrate] = useState<string>('');
  const [qtdHardProfile, setHardProfile] = useState<barGraphDataInterface>({ minus: 0, minusMinus: 0, plus: 0, plusPlus: 0 });

  const [circleGraphData, setGraphData] = useState<candidateGraphInterface>({ circleGraphValues: [], selectionId: '', companyReport: '' } as candidateGraphInterface);
  const { setAtualPageGlobal, languagePage } = useGlobal();

  const searchRequests = useCallback(() => {
    setLoading(true);
    setTimeout(async () => {
      setNumberOfPages(0);
      const resu = await api.get(
        `/company/resquest/allCandidates/${selectionId}?limit=3&page=${atualPage}`
      );
      setSelection(resu.data.selection);
      setRequests(resu.data.rows);
      setNumberOfPages(Math.ceil(resu.data.count / 3));
      setLoading(false);
    }, 200);
  }, [atualPage]);

  useEffect(() => {
    setAtualPageGlobal("/company/selecoes");
    searchRequests();
  }, [atualPage]);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/company/generateClassification/${selectionId}`)
      .then((res) => {
        const { classification } = res.data;
        setCompanyReportIntegrate(res.data['companyReport']);
        setHardProfile(res.data['qtdHardProfile']);
        const classificationOrdenate = classification.sort(function (
          a: any,
          b: any
        ) {
          if (a.position > b.position) {
            return 1;
          }
          if (a.position < b.position) {
            return -1;
          }
          return 0;
        });

        setClassifications(classificationOrdenate);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/company/generateSoftGraphDatas/${selectionId}`)
      .then((res) => {
        setGraphData({
          selectionId: res.data['selectionId'],
          circleGraphValues: res.data['circleGraphData'],
          companyReport: res.data['companyReport'],
        });
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);



  const changePage = useCallback(
    (type) => {
      if (type === "proxima") {
        if (atualPage < numberOfPages) setAtualPage(atualPage + 1);
      } else if (atualPage !== 1) setAtualPage(atualPage - 1);
    },
    [atualPage, numberOfPages]
  );

  const accept = useCallback(async (requestId) => {
    await api.post(`/company/request/accept/${requestId}`);
    searchRequests();
  }, []);

  const decline = useCallback(async (requestId) => {
    await api.post(`/company/request/decline/${requestId}`);
    searchRequests();
  }, []);

  return (
    <Container>
      <BoxBackTitle>
        <div className="voltar" onClick={() => push("/company/selecoes")}>
          {languagePage === "PT" ? "Voltar" : "Return"}
        </div>
        <div className="nome">
          {languagePage === "PT"
            ? "Candidatos - Avaliação"
            : "Selection Candidates"}{" "}
          "{selection.name}"
        </div>
      </BoxBackTitle>
      <SubContainer>
        <div className="conteudo">
          <Setas
            onClick={() => changePage("anterior")}
            style={{
              background: atualPage === 1 ? "#C4C4C4" : "#134480",
              cursor: atualPage === 1 ? "default" : "pointer",
            }}
          >
            <FiChevronLeft size={28} />
          </Setas>
        </div>
        {!loading && requests.length > 0 && (
          <div className="avatar-nome">
            {requests.map((request) => (
              <div className="candidados" key={request.id}>
                <AbatarContainer>
                  {/* Essa div está sendo estilizada */}
                  <div>
                    {request.candidate.avatar && (
                      <img
                        style={{ height: 30 }}
                        src={request.candidate.avatar}
                        alt="Imagem de perfil"
                      />
                    )}
                    {!request.candidate.avatar && (
                      <FaUser color="#134475" size={30} />
                    )}
                  </div>
                  {request.candidate.name}
                </AbatarContainer>
                <div
                  onClick={() =>
                    push(
                      `/company/candidatos/perfilCandidato/${selectionId}/${request.candidate.id}`
                    )
                  }
                  className="pointer-flex-fff-10-center-row-5"
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    background: "#134475",
                  }}
                >
                  <FaUserAlt style={{ marginRight: 10 }} />
                  {languagePage === "PT" ? "Ver Candidato" : "See Candidate"}
                </div>
                <div
                  onClick={() => decline(request.id)}
                  className="pointer-flex-fff-10-center-row-5"
                  style={{
                    background: "#ee3a3a",
                  }}
                >
                  <FaTrashAlt style={{ marginRight: 10 }} />
                  {languagePage === "PT" ? "Remover" : "Remove"}
                </div>
              </div>
            ))}
          </div>

        )}

        {!loading && requests.length === 0 && (
          <div
            style={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Gotham-Medium-Italic",
              opacity: 0.8,
              fontSize: 16,
            }}
          >
            {languagePage === "PT"
              ? "Esta Seleção não possui nenhum candidato"
              : "This Selection does not have any candidates"}
          </div>
        )}
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <div style={{ display: "flex" }}>
          <Setas
            onClick={() => changePage("proxima")}
            style={{
              marginLeft: 10,
              background:
                atualPage === numberOfPages || numberOfPages === 0
                  ? "#C4C4C4"
                  : "#134480",
              cursor:
                atualPage === numberOfPages || numberOfPages === 0
                  ? "default"
                  : "pointer",
            }}
          >
            <FiChevronRight size={28} />
          </Setas>
        </div>
      </SubContainer>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <SetasTwo
          onClick={() => changePage("anterior")}
          style={{
            background: atualPage === 1 ? "#C4C4C4" : "#f27823",
            cursor: atualPage === 1 ? "default" : "pointer",
          }}
        >
          <FiChevronLeft size={28} />
        </SetasTwo>

        <SetasTwo
          onClick={() => changePage("proxima")}
          style={{
            background: atualPage >= numberOfPages ? "#C4C4C4" : "#f27823",
            cursor: atualPage >= numberOfPages ? "default" : "pointer",
          }}
        >
          <FiChevronRight size={28} />
        </SetasTwo>
      </div>



      {!loading && requests.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <div
            onClick={goToTabelaPage}
            className="pointer-flex-fff-10-center-row-5"
            style={{
              marginTop: 10,
              marginBottom: 10,
              margin: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              background: "#134480",
              padding: '10px 20px',
            }}
          >
            <FaUserAlt style={{ marginRight: 10 }} />
            {languagePage === "PT" ? "Tab. Circular Skills - Integrado" : "Circular Skills Tab - Integrated"}
          </div>

          <div
            onClick={goToSoftTabelaPage}
            className="pointer-flex-fff-10-center-row-5"
            style={{
              marginTop: 10,
              marginBottom: 10,
              margin: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              background: "#134480",
              padding: '10px 20px', 
            }}
          >
            <FaUserAlt style={{ marginRight: 10 }} />
            {languagePage === "PT" ? "Mapeamento de perfil Soft" : "Soft Profile mapping"}
          </div>
        </div>
      )}


      {
        !loading &&
        circleGraphData.circleGraphValues.length > 0 &&
        !circleGraphData.circleGraphValues.every(item => item.value === null) && (

          <div
            className="pointer-flex-fff-10-center-row-5"
            style={{
              marginTop: 10,
              marginBottom: 10,
              maxWidth: 800,
              margin: '10px auto',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              background: "#4cbce850",
            }}
          >
            <h2 style={{
              color: '#134480',
              textAlign: 'center',
              fontSize: '32px',
              fontWeight: 'bold',
              margin: '20px 0',
              textTransform: 'uppercase',
              letterSpacing: '1px',
              fontFamily: 'Gotham-Medium',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)'
            }}>
              Mapeamento coletivo SoftSkill
            </h2>
            <div className='MyPieChart'>
              <MyPieChart selectionId={circleGraphData.selectionId} circleGraphValues={circleGraphData.circleGraphValues} companyReport={circleGraphData.companyReport} />
            </div>
            <h4 style={{
              color: '#134480',
              textAlign: 'center',
              fontSize: '20px',
              fontWeight: 'normal',
              margin: '10px 0',
              textTransform: 'capitalize',
              letterSpacing: '0.5px',
              fontFamily: 'Gotham-Medium',
              lineHeight: '1.4',
            }}>
              {circleGraphData.companyReport}
            </h4>
          </div>
        )}

      {

        !loading && classifications.length > 2 && (

          <div
            className="pointer-flex-fff-10-center-row-5"
            style={{
              marginTop: 10,
              marginBottom: 10,
              maxWidth: 800,
              margin: '10px auto',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              background: "#4cbce850",
            }}
          >
            <h2 style={{
              color: '#134480',
              textAlign: 'center',
              fontSize: '32px',
              fontWeight: 'bold',
              margin: '20px 0',
              textTransform: 'uppercase',
              letterSpacing: '1px',
              fontFamily: 'Gotham-Medium', // Família da fonte
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)'
            }}>Mapeamento coletivo integrado</h2>
            <MyBarChart minus={qtdHardProfile.minus} minusMinus={qtdHardProfile.minusMinus} plus={qtdHardProfile.plus} plusPlus={qtdHardProfile.plusPlus} />
            <h4 style={{
              color: '#134480',
              textAlign: 'center',
              fontSize: '20px',
              fontWeight: 'normal',
              margin: '10px 0',
              textTransform: 'capitalize',
              letterSpacing: '0.5px',
              fontFamily: 'Gotham-Medium',
              lineHeight: '1.4',
            }}>{companyReportIntegrate}</h4>
          </div>
        )}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <SetasTwo
          onClick={() => changePage("anterior")}
          style={{
            background: atualPage === 1 ? "#C4C4C4" : "#f27823",
            cursor: atualPage === 1 ? "default" : "pointer",
          }}
        >
          <FiChevronLeft size={28} />
        </SetasTwo>

        <SetasTwo
          onClick={() => changePage("proxima")}
          style={{
            background: atualPage >= numberOfPages ? "#C4C4C4" : "#f27823",
            cursor: atualPage >= numberOfPages ? "default" : "pointer",
          }}
        >
          <FiChevronRight size={28} />
        </SetasTwo>
      </div>


      {/* <Switch>
        <Route exact path={`${path}/profileMapping`} component={ProfileMapping} />
      </Switch> 

      <button onClick={goToTabelaPage}>Ver Tabela</button>
      */}


    </Container>
  );
};

function MyPieChart(circleGraphData: candidateGraphInterface) {

  const options = {
    maintainAspectRatio: true, // Pode definir como false para permitir que o gráfico preencha o contêiner
    layout: {
      padding: {
        bottom: 10, // Ajuste o valor conforme necessário
        left: 10,
        right: 10,
        top: 10
      }
    },
    scales: {
      x: {
        ticks: {
          display: false,
          font: {
            size: 16, // Aumenta o tamanho da fonte para 14 para a escala X
          }
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          display: false,
          font: {
            size: 14, // Aumenta o tamanho da fonte para 14 para a escala X
            weight: 'bold' as const,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true, // Se desejar exibir a legenda
        labels: {
          boxWidth: 10,
          padding: 10,
          usePointStyle: true,
        }
      },
      // title: {
      //   display: false,
      // },
      datalabels: {
        display: false,
        align: 'end' as const,
        anchor: 'end' as const,
        font: {
          size: 18,
          weight: 'bold' as const,
        },
        formatter: function (value, context) {
          return context.chart.data.labels[context.dataIndex];
        }
      }

    },
  };

  return <div style={{ width: '350px', height: '350px' }}>
    <Pie options={options} data={getPieGraphData(circleGraphData)} />
  </div>;
}

function MyBarChart(bar: barGraphDataInterface) {


  let values = [bar.minus, bar.minusMinus, bar.plus, bar.plusPlus];


  let maxVal = Math.max(...values);

  const options = {
    maintainAspectRatio: false, // Pode definir como false para permitir que o gráfico preencha o contêiner
    layout: {
      padding: {
        bottom: 10, // Ajuste o valor conforme necessário
        left: 10,
        right: 10,
        top: 10
      }
    },
    scales: {
      x: {
        ticks: {
          display: false,
          font: {
            size: 16, // Aumenta o tamanho da fonte para 14 para a escala X
          }
        },
      },
      y: {
        beginAtZero: true,
        max: maxVal + 1,
        ticks: {
          font: {
            size: 14, // Aumenta o tamanho da fonte para 14 para a escala X
            weight: 'bold' as const,
          },
          // Mostra apenas o rótulo do maior tick (o último na escala)
          callback: function (value, index, values) {
            // O último valor na matriz values é o valor máximo da escala
            if (value === maxVal) {
              return value;
            } else {
              return ''; // Retorna uma string vazia para todos os outros valores
            }
          }
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Se desejar exibir a legenda
      },
      title: {
        display: false,
      },
      datalabels: {
        align: 'end' as const,
        anchor: 'end' as const,
        font: {
          size: 18,
          weight: 'bold' as const,
        },
        formatter: function (value, context) {
          return context.chart.data.labels[context.dataIndex];
        }
      }

    },
  };

  return <div style={{ width: '500px', height: '300px' }}>
    <Bar options={options} data={getBarGraphData(bar)} />
  </div>;
}

function getPieGraphData(circleGraphData: candidateGraphInterface) {

  let list = circleGraphData.circleGraphValues;

  const labels = list.map(item => item.label);
  const values = list.map(item => item.value);

  const data = {
    labels: labels,
    datasets: [{
      label: '% dos candidatos',
      data: values,
      backgroundColor: [
        'rgba(153, 102, 255, 0.75)',
        'rgba(255, 255, 255, 0.75)',
        'rgba(23, 28, 114, 0.75)',
        'rgba(238, 58, 58, 0.75)',
        'rgba(89, 235, 26, 0.75)',
        'rgba(50, 32, 110, 0.75)',
        'rgba(76, 188, 232, 0.75)',
      ],
      borderColor: [
        'rgba(153, 102, 255, 1)',
        'rgba(255, 255, 255, 1)',
        'rgba(23, 28, 114, 1)',
        'rgba(238, 58, 58, 1)',
        'rgba(89, 235, 26, 1)',
        'rgba(50, 32, 110, 1)',
        'rgba(76, 188, 232, 1)',
      ],
      borderWidth: 1
    }]
  };
  return data;
}

function getBarGraphData(bar: barGraphDataInterface) {
  const labels = [
    '--',
    '-',
    '++',
    '+'
  ];
  const values = [
    bar.minusMinus,
    bar.minus,
    bar.plusPlus,
    bar.plus,
  ];

  const data = {
    labels: labels,
    plugins: [ChartDataLabels],
    datasets: [{
      label: 'qtd de candidatos',
      data: values,
      backgroundColor: [
        'rgba(153, 102, 255, 0.75)',
        'rgba(255, 255, 255, 0.75)',
        'rgba(23, 28, 114, 0.75)',
        'rgba(238, 58, 58, 0.75)',
        'rgba(89, 235, 26, 0.75)',
        'rgba(50, 32, 110, 0.75)',
        'rgba(76, 188, 232, 0.75)',
      ],
      borderColor: [
        'rgba(153, 102, 255, 1)',
        'rgba(255, 255, 255, 1)',
        'rgba(23, 28, 114, 1)',
        'rgba(238, 58, 58, 1)',
        'rgba(89, 235, 26, 1)',
        'rgba(50, 32, 110, 1)',
        'rgba(76, 188, 232, 1)',
      ],
      borderWidth: 1
    }]
  };
  return data;
}