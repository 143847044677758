import React, { useCallback, useRef, useState } from "react";
import {
  ButtonLogin,
  ButtonText,
  ButtonTextBack,
  ButtonTextRegister,
  Container,
  ContainerLoading,
} from "./style";
import InputLogin, { RefInputLoginProps } from "../../components/InputLogin";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useHistory } from "react-router";
import { useAuth } from "../../hooks/auth";
import { Modal } from "@material-ui/core";
import { LoadingSale } from "../../components/Animations/LoadingSale";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import { useForm } from "../../hooks/form";
import { useGlobal } from "../../hooks/global";


export const Login: React.FC = () => {
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [errorPassword, setErrorPassword] = useState<string | null>(null);
  const { setType } = useForm();
  const { languagePage } = useGlobal();
  const { addToast } = useToast()

  const inputEmailRef = useRef<RefInputLoginProps>(null);
  const inputPasswordRef = useRef<RefInputLoginProps>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const { push } = useHistory();
  const { signIn } = useAuth();

  const Logar = useCallback(
    async (event) => {
      event?.preventDefault();

      const errorEmail = await VerifyEmail();
      const errorPassword = await VerifyPassword();

      if (!!!errorEmail && !!!errorPassword) {
        setLoading(true);
        setTimeout(async () => {
          try {
            const email = inputEmailRef.current?.getValue()
              ? inputEmailRef.current?.getValue()
              : "";
            const password = inputPasswordRef.current?.getValue()
              ? inputPasswordRef.current?.getValue()
              : "";
            await signIn({ email, password });
          } catch (err: any) {
            setLoading(false);
            setErrorEmail(
              languagePage === "PT"
                ? "Email ou senha inválida"
                : "Invalid email or password"
            );
            setErrorPassword(
              languagePage === "PT"
                ? "Email ou senha inválida"
                : "Invalid email or password"
            );
            addToast({
              title: "Erro",
              description: languagePage === "PT"
                ? "Email ou senha inválida"
                : "Invalid email or password",
              icon: "alert",
              type: "error",
            })
          }
        }, 200);
      }
    },
    [inputEmailRef, inputPasswordRef]
  );

  const VerifyEmail = useCallback(async () => {
    let resu;
    setErrorEmail(null);
    const email = inputEmailRef.current?.getValue();
    const schemaEmail = Yup.object().shape({
      email: Yup.string().required(
        languagePage === "PT" ? "Insira seu email" : "Enter your email"
      ),
    });
    try {
      await await schemaEmail.validate({ email });
    } catch (err: any) {
      setErrorEmail(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
      addToast({
        title: "Erro",
        description: err.errors[0],
        icon: "alert",
        type: "error",
      })
    }
    return resu;
  }, [inputEmailRef]);

  const VerifyPassword = useCallback(async () => {
    let resu;
    setErrorPassword(null);
    const password = inputPasswordRef.current?.getValue();

    const schemaPassword = Yup.object().shape({
      password: Yup.string().required(
        languagePage === "PT" ? "Insira sua senha" : "Enter your password"
      ),
    });
    try {
      await schemaPassword.validate({ password });
    } catch (err: any) {
      setErrorPassword(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
      addToast({
        title: "Erro",
        description: err.errors[0],
        icon: "alert",
        type: "error",
      })
    }
    return resu;
  }, [inputPasswordRef]);
  const onClick = useCallback(() => {
    push("logon");
    setType("");
  }, []);
  return (
    <Container>
      <div className="sub-container">
        <ButtonTextBack onClick={() => push("/")}>
          <span>
            <HiArrowNarrowLeft style={{ marginRight: 10 }} />
            {languagePage === "PT"
              ? "Voltar para Tela Inicial"
              : "Back to Home"}
          </span>
        </ButtonTextBack>
        <div className="form-login">
          <div
            style={{
              display: "flex",
              height: 100,
              width: 150,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
              <img
                src={
                  "https://res.cloudinary.com/hkkzuevm3/image/upload/v1634765404/Assets-FlashPlayHr/logo_k4qnwy.png"
                }
              />
            </div>
          </div>
          <form
            onSubmit={Logar}
            style={{
              display: "flex",
              flexDirection: "column",

              width: "100%",
              marginTop: 40,
            }}
          >
            <InputLogin
              onChange={() => setErrorEmail(null)}
              error={errorEmail}
              text="E-mail"
              type="email"
              ref={inputEmailRef}
              onClickContainer={() => inputEmailRef.current?.focus()}
              styleContainer={{ marginBottom: "1rem" }}
            />

            <InputLogin
              onChange={() => setErrorPassword(null)}
              error={errorPassword}
              text={languagePage === "PT" ? "Senha" : "Password"}
              type="password"
              ref={inputPasswordRef}
              onClickContainer={() => inputPasswordRef.current?.focus()}
            />
            <ButtonText onClick={() => push("/forgot-password")}>
              <span>
                {languagePage === "PT"
                  ? "Esqueceu sua senha?"
                  : "Forgot password?"}
              </span>
            </ButtonText>

            <ButtonLogin type="submit" style={{ width: "100%" }}>
              {" "}
              {languagePage === "PT" ? "Entrar" : "To enter"}
            </ButtonLogin>
            <div
              style={{
                display: "flex",
                marginTop: 24,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color: "#fff",
                  fontFamily: "Gotham-Medium",
                  display: "inline-block",
                  fontSize: 20,
                  marginRight: 5,
                }}
              >
                {" "}
                {languagePage === "PT"
                  ? "Não possui uma conta? "
                  : "don't have an account?"}
              </span>
              <ButtonTextRegister onClick={onClick}>
                <span style={{ fontSize: 20, color: "#ff4459" }}>
                  {languagePage === "PT" ? "Cadastre-se" : "Register"}
                </span>
              </ButtonTextRegister>
            </div>
          </form>
        </div>
      </div>

      {loading && (
        <Modal open={loading}>
          <ContainerLoading>
            <LoadingSale />
          </ContainerLoading>
        </Modal>
      )}
    </Container>
  );
};
