import React, { useCallback, useRef } from "react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import Input, { InputRefProps } from "../../Input";
import { FaCheckCircle } from "react-icons/fa";
import { ImRadioUnchecked } from "react-icons/im";
import { useState } from "react";
import { useForm } from "../../../hooks/form";
import { companyProps, useAuth } from "../../../hooks/auth";
import api from "../../../services/api";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { Modal } from "@material-ui/core";
import { ContainerLoading } from "./style";
import { LoadingSale } from "../../Animations/LoadingSale";
import { useGlobal } from "../../../hooks/global";
import { BotoesForm, Container } from "../style";
import { useToast } from "../../../hooks/toast";
import InputLogon, { RefInputLogonProps } from "../../InputLogon/index"

import { Link } from "react-router-dom";
interface EmailCompanyData {
  onNext: Function;
  onBack: Function;
}

const EmailCompany: React.FC<EmailCompanyData> = ({ onNext, onBack }) => {
  const [termos, setTermos] = useState(false);
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [errorPassword, setErrorPassword] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const inputEmailRef = useRef<RefInputLogonProps>(null);
  const inputPasswordRef = useRef<RefInputLogonProps>(null);
  const { signIn } = useAuth();
  const { push } = useHistory();
  const { addToast } = useToast();
  const { preferencia } = useGlobal();
  const {
    cidade,
    cnpj,
    senha,
    email,
    estado,
    nome,
    pais,
    telefone,
    posNumFunc,
    posPorte,
    responsavel,
    setSenha,
    setEmail,
  } = useForm();

  const onClickNext = useCallback(async () => {
    const companySize = [
      "Pequeno Porte",
      "Médio Porte",
      "Grande Porte",
      "Startup",
    ][posPorte];
    const employeesRange = ["0 - 10", "11 - 21", "22 - 50", "51 - 100", "100+"][
      posNumFunc
    ];

    if (email !== "" && senha !== "" && termos) {
      const emailError = await VerifyEmail();
      const passError = await VerifyPass();
      if (!!!emailError && !!!passError) {
        setLoading(true);
        setTimeout(async () => {
          try {
            const resu = await api.post("/company", {
              name: nome,
              email,
              city: cidade,
              responsible: responsavel,
              state: estado,
              country: pais,
              cnpj,
              companySize,
              employeesRange,
              password: senha,
              phone: telefone,
              preference: preferencia,
            } as companyProps);

            if (resu.status === 404) {
              setLoading(false);
              return;
            }
            await signIn({ email, password: senha });
          } catch (e) {
            setErrorEmail(languagePage === "PT"
              ? "Email ou CNPJ já possuem cadastro"
              : "Email or ID already registered",);
            addToast({
              title: "Erro",
              description:
                languagePage === "PT"
                  ? "Email ou CNPJ já possuem cadastro"
                  : "Email or ID already registered",
              icon: "alert",
              type: "error",
            })
            setLoading(false);
            return
          }
          push("/finish");
        }, 200);
      }
    }
  }, [
    posPorte,
    posNumFunc,
    nome,
    responsavel,
    email,
    cidade,
    estado,
    pais,
    cnpj,
    senha,
    telefone,
    termos,
    preferencia,
  ]);


  const onClickBack = useCallback(() => {
    onBack();
  }, []);

  const onChangeEmail = useCallback(() => {
    setErrorEmail(null);
    const email = inputEmailRef.current?.getValue()
      ? inputEmailRef.current.getValue()
      : "";
    setEmail(email);
  }, [inputEmailRef]);

  const onChangePassword = useCallback(() => {
    setErrorPassword(null);
    const password = inputPasswordRef.current?.getValue()
      ? inputPasswordRef.current.getValue()
      : "";
    setSenha(password);
  }, [inputPasswordRef]);

  const VerifyEmail = useCallback(async () => {
    let resu;
    setErrorEmail(null);
    const email = inputEmailRef.current?.getValue();
    const schemaEmail = Yup.object().shape({
      email: Yup.string().email(languagePage === "PT" ? "Digite um e-mail válido" : "Insert a valid e-mail"),
    });
    try {
      await schemaEmail.validate({ email });
    } catch (err: any) {
      setErrorEmail(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
      addToast({
        title: "Erro",
        description: err.errors[0],
        icon: "alert",
        type: "error",
      })
    }
    return resu;
  }, [inputEmailRef]);

  const VerifyPass = useCallback(async () => {
    let resu;
    setErrorPassword(null);

    const password = inputPasswordRef.current?.getValue();
    const schemaPass = Yup.object({
      password: Yup.string()
        .required(languagePage === "PT" ? "A senha é requerida" : "A password is required")
        .min(6, languagePage === "PT" ? "A senha precisa ter no mínimo 6 caracteres" : "The password must have at least 6 characters")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
          languagePage === "PT"
            ? "A senha precisa ter uma letra maiúscula, uma minúscula, um número e um caractere esepecial(#*/+!@?&$)."
            : "The password must have an uppercase letter, a lowercase letter, a number and a special character (#*/+!@?&$)."
        ),
    });
    try {
      await schemaPass.validate({ password });
    } catch (err: any) {
      setErrorPassword(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
      addToast({
        title: "Erro",
        description: err.errors[0],
        icon: "alert",
        type: "error",
      })
    }
    return resu;
  }, [inputPasswordRef]);
  const { setAtualPageGlobal, languagePage } = useGlobal();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  return (
    <Container
      style={{
        flexWrap: "wrap",
        height: "90%",
        width: "90%",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginBottom: 10,
            textAlign: "center",
            marginTop: 100,
          }}
        >
          {languagePage === "PT"
            ? " Insira seu email e crie uma senha"
            : " Enter your e-mail and generate a password"}
        </div>
        <InputLogon

          onChange={onChangeEmail}
          error={errorEmail}
          text="E-mail"
          type="email"
          defaultValue={email}
          ref={inputEmailRef}
          onClickContainer={() => inputEmailRef.current?.focus()}
          styleContainer={{ marginBottom: "1rem" }}
        />

        <InputLogon
          onChange={onChangePassword}
          error={errorPassword}
          text={languagePage === "PT" ? "Senha" : "Password"}
          type="password"
          defaultValue={senha}
          ref={inputPasswordRef}
          onClickContainer={() => inputPasswordRef.current?.focus()}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginTop: 20,
          }}
        >
          {termos && (
            <FaCheckCircle
              color="#00DF71"
              onClick={() => setTermos(!termos)}
              style={{
                boxShadow: "4px 4px 18px 0px rgba(0,0,0,0.5)",
                WebkitBoxShadow: "4px 4px 18px 0px rgba(0,0,0,0.5)",
                borderRadius: 9,
              }}
              cursor="pointer"
              size={18}
            />
          )}
          {!termos && (
            <ImRadioUnchecked
              color="#00DF71"
              onClick={() => setTermos(!termos)}
              style={{
                boxShadow: "4px 4px 18px 0px rgba(0,0,0,0.5)",
                WebkitBoxShadow: "4px 4px 18px 0px rgba(0,0,0,0.5)",
                borderRadius: 9,
              }}
              cursor="pointer"
              size={18}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: 16,
              marginLeft: 10,
            }}
          >
            <div>
              {languagePage === "PT"
                ? "Declaro que li e concordo com os"
                : "I declare that I have read and agree to"}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  marginRight: 5,
                  color: "#19BCF4",
                  cursor: "pointer",
                }}
              >
                <Link to="/pdf" target="_blank">
                  {languagePage === "PT"
                    ? "Termos de uso"
                    : "Terms of Use"}
                </Link>
              </div>
              {languagePage === "PT" ? "da Flash Play HR" : " of the Flash Play HR"}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
          <BotoesForm onClick={onClickBack}>
            <GoChevronLeft />
            {languagePage === "PT" ? "Anterior" : "Back"}
          </BotoesForm>
          <BotoesForm
            onClick={onClickNext}
            style={{
              cursor:
                termos && email !== "" && senha !== ""
                  ? "pointer"
                  : "not-allowed",
            }}
          >
            {languagePage === "PT" ? "Próximo" : "Next"}
            <GoChevronRight style={{ marginLeft: 5 }} />
          </BotoesForm>
        </div>
      </div>
      {loading && (
        <Modal open={loading}>
          <ContainerLoading>
            <LoadingSale />
          </ContainerLoading>
        </Modal>
      )}
    </Container>
  );
};

export default EmailCompany;
