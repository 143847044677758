import React, { useEffect, useState } from "react";
import { Container } from "./style";
import { useGlobal } from "../../hooks/global";
import { useHistory } from "react-router";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import { IoLogoWhatsapp } from "react-icons/io5";
import { BsFlower1, BsFlower2 } from "react-icons/bs";
import { IoFlowerOutline } from "react-icons/io5";
import { GiFlowers } from "react-icons/gi";
import { CircularProgress } from "@material-ui/core";
import { Header } from "../../components/Header";

export const Planos: React.FC = () => {
  const { setAtualPageGlobal, languagePage } = useGlobal();
  const [plans, setPlans] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { push } = useHistory();
  const { user, setUser } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    setAtualPageGlobal("/company/planos");
    api.get("/company/plans").then((response) => {
      const { data } = response.data;
      setPlans(data);
      setLoading(false);
    });
  }, []);

  const handleSubscription = async (
    priceId: string,
    namePlan: string,
    value: number
  ) => {
    if (!!user.subscriptionId) {
      const res = await api.post("/company/update-subscription", {
        priceId: priceId,
        planName: namePlan,
      });
      const { planName, subscription, status, candidateLimit, vacancyLimit } =
        res.data;

      setUser({
        ...user,
        planName: planName,
        candidateLimit: candidateLimit,
        vacancyLimit: vacancyLimit,
        subscriptionId: subscription,
      });

      if (status === "past_due") {
        addToast({
          title: "Efetue o pagamento",
          description: "Para não perder o acesso ao plano.",
          icon: "alert",
          type: "error",
        });
      } else if (status === "active") {
        addToast({
          title: "Sucesso",
          description: `Seu plano foi trocado para o ${planName}`,
          icon: "products",
          type: "success",
        });
      }
      localStorage.setItem(
        "@flashPlayHR:user",
        JSON.stringify({
          ...user,
          planName: planName,
          candidateLimit: candidateLimit,
          vacancyLimit: vacancyLimit,
          subscriptionId: subscription,
        })
      );
      return push({
        pathname: "/company/selecoes",
      });
    } else {
      const res = await api.post("/company/create-subscription", {
        customerId: user.customerId,
        priceId: priceId,
        planName: namePlan,
      });
      const {
        clientSecret,
        subscription,
        status,
        planName,
        candidateLimit,
        vacancyLimit,
      } = res.data;

      setUser({
        ...user,
        planName: planName,
        candidateLimit: candidateLimit,
        vacancyLimit: vacancyLimit,
        subscriptionId: subscription,
      });

      localStorage.setItem(
        "@flashPlayHR:user",
        JSON.stringify({
          ...user,
          planName: planName,
          candidateLimit: candidateLimit,
          vacancyLimit: vacancyLimit,
          subscriptionId: subscription,
        })
      );
      localStorage.setItem(
        "@flashplayHR:clientSecret",
        JSON.stringify({ clientSecret: clientSecret })
      );

      return push({
        pathname: "/company/pagamento",
        state: {
          clientSecret: clientSecret,
          planName: namePlan,
          value: value,
        },
      });
    }
  };
  if (languagePage === "PT") {
    return (
      <Container>
        <div
          style={{
            background: "red",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Header />
        </div>
        <div style={{ marginTop: 150 }}>
          {loading && (
            <div
              style={{
                display: "flex",

                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          )}

          {!loading && (
            <>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  fontFamily: "Gotham-Bold",
                  color: "#FFF",
                  fontSize: 26,
                  alignItems: "center",
                  justifyContent: "center",

                  marginTop: -15,
                  padding: 10,
                  marginBottom: 50,
                }}
              >
                
                {plans.map((plan) => {
                  if (plan.currency === "brl") {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "column",
                          padding: 20,
                          margin: "20px 10px",
                          maxWidth: 300,
                          minHeight: 584.5,
                          //marginRight: 10,
                          //marginLeft: 10,
                          justifyContent: "space-between",
                          alignItems: "center",
                          background: "#FFF",
                          borderRadius: 20,
                          boxShadow: "2px 2px 20px -6px rgba(0, 0, 0, 0.5)",
                          WebkitBoxShadow:
                            "2px 2px 20px -6px rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <div
                          style={{
                            color: "#081118",
                            fontSize: 30,
                            marginBottom: 20,
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          {plan.name === "Básico" ? (
                            <BsFlower2 />
                          ) : plan.name === "Intermediário" ? (
                            <IoFlowerOutline />
                          ) : (
                            <BsFlower1 />
                          )}
                          &#32;{plan.name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            color: "#081118",
                            fontSize: 16,
                            marginBottom: 5,
                            textAlign: "left",
                            maxWidth: 280,
                            minHeight: 216,
                          }}
                        >
                          <ul>
                            {plan.description.split(". ").map((line: any) => {
                              return (
                                <li style={{ marginBottom: 5 }}>{line}</li>
                              );
                            })}
                          </ul>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            color: "#134480",
                            marginTop: 30,
                          }}

                          /*
                          {Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 2,
                          }).format(plan.amount / 100)}
                          / {languagePage === "PT" ? "mês" : plan.interval}
                          {plan.name !== "Básico" && (
                          <div style={{ color: "#081118", fontSize: 15 }}>
                            (Até 3 meses)
                          </div>
                        )}
                          */
                        >
                        </div>


                        <div
                          onClick={() =>
                            // user.id
                            //   ? handleSubscription(
                            //       plan.id,
                            //       plan.name,
                            //       plan.amount / 100
                            //     )
                            //   : push("/logon")
                            /*
                            ? plan.name=== "Básico" ? (window.location.href=`https://pay.greenn.com.br/8754`):(window.location.href = `https://wa.me/5571986956150?text=nome%20do%20assinante:%20${user.name
                            ? (window.location.href = `https://wa.me/5571986956150?text=nome%20do%20assinante:%20${user.name
                                },%20plano:%20${plan.name
                                },%20valor:%20${Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                  minimumFractionDigits: 2,
                                }).format(plan.amount / 100)}}`)
                            */
                            user.id
                              ? (window.location.href = `https://wa.me/5571986956150?text=Seja%20bem%20vindo%20a%20plataforma%20FlashplayHR-%20Aproveite%20o%20método%20de%20Circular%20Skills%20e%20Mindset`)
                              : (window.location.href = `https://wa.me/5555719974806?text=Ol%C3%A1%2C+gostaria+de+conhecer+os+planos+intermedi%C3%A1rios`)
                          }
                          style={{
                            background: "#134480",
                            cursor: "pointer",
                            fontSize: 16,
                            padding: 10,
                            borderRadius: 10,
                            marginTop: 20,
                            boxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                            WebkitBoxShadow:
                              "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                          }}
                        >
                          {user.planName === "NoPlan" && plan.name === "Básico"
                            ? "Teste Grátis"
                            : "Adquirir plano"}
                            
                        </div>
                      </div>
                    );
                  }
                })}
                {/* plano personalizado */}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    padding: 20,
                    margin: "20px 10px",
                    maxWidth: 300,
                    height: 350,
                    //marginRight: 10,
                    //marginLeft: 10,
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#FFF",
                    borderRadius: 20,
                    boxShadow: "2px 2px 20px -6px rgba(0, 0, 0, 0.5)",
                    WebkitBoxShadow: "2px 2px 20px -6px rgba(0, 0, 0, 0.5)",
                  }}
                >
                  <div
                    style={{
                      color: "#081118",
                      fontSize: 30,
                      alignItems: "center",
                    }}
                  >
                    <GiFlowers />
                    &#32;Personalizado
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      color: "#081118",
                      fontSize: 16,
                      marginBottom: 5,
                      textAlign: "center",
                      maxWidth: 280,
                    }}
                  >
                    Entre em contato para discutirmos seu plano personalizado
                  </div>

                  <div
                    style={{
                      background: "#134480",
                      cursor: "pointer",
                      fontSize: 16,
                      padding: 10,
                      borderRadius: 10,
                      marginTop: 20,
                      boxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                      WebkitBoxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                    }}
                  >
                    <a
                      href="https://api.whatsapp.com/send?phone=5571986956150"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Fale com a gente{" "}
                      <IoLogoWhatsapp color="white" size={20} />
                    </a>
                  </div>
                  <div
                    style={{
                      color: "#081118",
                      fontSize: "small",
                      maxWidth: 260,
                      textAlign: "center",
                    }}
                  >
                    <p>
                      *Obs.: O plano personalizado consiste em diagnóstico e
                      capacitação
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Container >
    );
  } else {
    return (
      <Container>
        <div
          style={{
            background: "red",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Header />
        </div>
        <div style={{ marginTop: 150 }}>
          {loading && (
            <div
              style={{
                display: "flex",

                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          )}

          {!loading && (
            <>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  fontFamily: "Gotham-Bold",
                  color: "#FFF",
                  fontSize: 26,
                  alignItems: "center",
                  justifyContent: "center",

                  marginTop: -15,
                  padding: 10,
                  marginBottom: 50,
                }}
              >
                {plans.map((plan) => {
                  if (plan.currency === "usd" && plan.name !== "myproduct") {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "column",
                          padding: 20,
                          margin: "20px 10px",
                          maxWidth: 300,
                          minHeight: 584.5,
                          //marginRight: 10,
                          //marginLeft: 10,
                          justifyContent: "space-between",
                          alignItems: "center",
                          background: "#FFF",
                          borderRadius: 20,
                          boxShadow: "2px 2px 20px -6px rgba(0, 0, 0, 0.5)",
                          WebkitBoxShadow:
                            "2px 2px 20px -6px rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <div
                          style={{
                            color: "#081118",
                            fontSize: 30,
                            marginBottom: 20,
                          }}
                        >
                          {plan.name === "Basic" ? (
                            <BsFlower2 />
                          ) : plan.name === "Intermediary" ? (
                            <IoFlowerOutline />
                          ) : (
                            <BsFlower1 />
                          )}
                          &#32;{plan.name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            color: "#081118",
                            fontSize: 16,
                            marginBottom: 5,
                            textAlign: "left",
                            maxWidth: 280,
                            minHeight: 216,
                          }}
                        >
                          <ul>
                            {plan.description?.split("; ").map((line: any) => {
                              return (
                                <li style={{ marginBottom: 5 }}>{line}</li>
                              );
                            })}
                          </ul>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            color: "#134480",
                            marginTop: 30,
                          }}

                          /*
                          {Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                          }).format(plan.amount / 100)}
                          / {languagePage === "PT" ? "mês" : plan.interval}
                                                  {plan.name !== "Basic" && (
                          <div style={{ color: "#081118", fontSize: 15 }}>
                            (up to 3 months)
                          </div>
                        )}
                          */
                        >
                        </div>

                        <div
                          onClick={() =>
                            //user.id
                              //? handleSubscription(
                                //plan.id,
                                //plan.name,
                                //plan.amount / 100
                              //)
                              //: push("/logon")
                              user.id
                              ? (window.location.href = `https://wa.me/5571986956150?text=nome%20do%20assinante:%20${user.name
                                },%20plano:%20${plan.name
                                },%20valor:%20${Intl.NumberFormat("en", {
                                  style: "currency",
                                  currency: "USD",
                                  minimumFractionDigits: 2,
                                }).format(plan.amount / 100)}}`)
                                : (window.location.href = `https://wa.me/5555719974806?text=Ol%C3%A1%2C+gostaria+de+conhecer+os+planos+intermedi%C3%A1rios`)
                          }
                          style={{
                            background: "#134480",
                            cursor: "pointer",
                            fontSize: 16,
                            padding: 10,
                            borderRadius: 10,
                            marginTop: 20,
                            boxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                            WebkitBoxShadow:
                              "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                          }}
                        >
                          {user.planName === "NoPlan" && plan.name === "Básico"
                            ? "Free Trial"
                            : "Buy plan"}
                        </div>
                      </div>
                    );
                  }
                })}
                {/* plano personalizado */}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    padding: 20,
                    margin: "20px 10px",
                    maxWidth: 300,
                    height: 350,
                    //marginRight: 10,
                    //marginLeft: 10,
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#FFF",
                    borderRadius: 20,
                    boxShadow: "2px 2px 20px -6px rgba(0, 0, 0, 0.5)",
                    WebkitBoxShadow: "2px 2px 20px -6px rgba(0, 0, 0, 0.5)",
                  }}
                >
                  <div style={{ color: "#081118", fontSize: 30 }}>
                    <GiFlowers />
                    &#32;Custom
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      color: "#081118",
                      fontSize: 16,
                      marginBottom: 5,
                      textAlign: "center",
                      maxWidth: 280,
                    }}
                  >
                    Contact us to discuss your custom plan
                  </div>

                  <div
                    style={{
                      background: "#134480",
                      cursor: "pointer",
                      fontSize: 16,
                      padding: 10,
                      borderRadius: 10,
                      marginTop: 20,
                      boxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                      WebkitBoxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                    }}
                  >
                    <a
                      href="https://api.whatsapp.com/send?phone=5571986956150"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Contact us <IoLogoWhatsapp color="white" size={20} />
                    </a>
                  </div>
                  <div
                    style={{
                      color: "#081118",
                      fontSize: "small",
                      maxWidth: 260,
                      textAlign: "center",
                    }}
                  >
                    <p>
                      *Note: The custom plan consists of diagnosis and
                      qualification
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Container>
    );
  }
};
