import { useTransition } from "@react-spring/core";
import { animated } from "@react-spring/web";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { GoChevronRight, GoGear } from "react-icons/go";
import { useHistory, useParams } from "react-router";
import { UserProps } from "../../../interfaces/userInterface";
import api from "../../../services/api";
import { questionInterface } from "./../../../interfaces/questionsInterface";
import { selectionInterface } from "./../../../interfaces/selectionInterface";
import { Alternativa, Bolinha, Container, ContainerGenerico, ContainerGenericoHeader, ConteudoIntroducao, IconExame, Imagem, Img, IntroducaoOrientaco, IntroducaoQuestionario, LocalizacaoPagina, NextQuestoes, OrientacaoTexto, OrientacoImagem, QuestContainer, SubContainer, TextIcon, Titulo, Voltar } from "./style";
import Questao, { questionRefProps } from "../../../components/Questao";
import { useGlobal } from "../../../hooks/global";

export const Especifico: React.FC = () => {
  const [atualPage, setAtualPage] = useState(0);

  const ref = useRef<questionRefProps>(null);

  const { tagId }: any = useParams();
  const [questions, setQuestions] = useState<questionInterface[]>(
    [] as questionInterface[]
  );
  const [company, setCompany] = useState<UserProps>({} as UserProps);
  const [selection, setSelection] = useState<selectionInterface>(
    {} as selectionInterface
  );
  const [answers, setAnswers] = useState<number[]>([] as number[]);
  const { push } = useHistory();

  const { setAtualPageGlobal, languagePage } = useGlobal();

  useEffect(() => {
    setAtualPageGlobal("/candidate/minhasSelecoes");
  }, []);

  const searchQuestions = useCallback(async () => {
    const resu = await api.get(`/common/questions/${tagId}`);

    const numberOfQuestions = resu.data.questions.length;
    setQuestions(resu.data.questions);
    setSelection(resu.data.questions[0].tag.selection);
    setCompany(resu.data.company);

    const newAnswers: number[] = [];

    for (let i = 0; i < numberOfQuestions; i++) newAnswers[i] = -1;

    setAnswers(newAnswers);
  }, []);

  useEffect(() => {
    searchQuestions();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("navBarDash")?.scroll({
        top: window.innerHeight,
        behavior: "smooth",
      });
    }, 200);
  }, [atualPage]);

  const transitions = useTransition(atualPage, {
    keys: null,
    from: { opacity: 0 },
    enter: { opacity: 1, config: { duration: 400 } },
    leave: { opacity: 0, config: { duration: 0 } },
  });

  const nextPage = useCallback(
    (newPage, resp) => {
      const atualPage = newPage - 1;

      const newAnswers = answers;
      for (let i = 0; i < newAnswers.length; i++)
        if (i === atualPage - 1) newAnswers[i] = resp;
      setAnswers(newAnswers);

      if (resp !== -1 && newPage !== questions.length + 1)
        setAtualPage(newPage);
      if (resp !== -1 && newPage === questions.length + 1)
        sendAnswer(newAnswers);
    },
    [questions, answers]
  );

  const anteriorPage = useCallback(
    (newPage, resp) => {
      setAtualPage(newPage);
      const atualPage = newPage + 1;
      const newAnswers = answers;
      for (let i = 0; i < newAnswers.length; i++)
        if (i === atualPage - 1) newAnswers[i] = resp;
      setAnswers(newAnswers);
    },
    [answers]
  );

  const sendAnswer = useCallback(
    async (OldAnswers) => {
      const resu = await api.post("/common/answer", {
        questions,
        answers: OldAnswers,
      });
      push("/candidate/minhasSelecoes");
    },
    [questions]
  );

  return (
    <Container>
      <SubContainer>
        <Voltar onClick={() => push("/candidate/minhasSelecoes")}>
          {languagePage === "PT" ? "Voltar" : " Come back"}
        </Voltar>
        <Titulo>
          {languagePage === "PT"
            ? "Exame Específico da Seleção "
            : "Specific Selection Examination "}{" "}
          "{selection.name ? selection.name : ""}"
        </Titulo>
      </SubContainer>
      <QuestContainer>
        <ContainerGenerico>
          <ContainerGenericoHeader>
            <Imagem>
              <div>
                <Img>
                  <img
                    src={
                      "https://res.cloudinary.com/hkkzuevm3/image/upload/v1634765487/Assets-FlashPlayHr/logo_jdh5ei.svg"
                    }
                    alt="Imagem"
                  />
                </Img>
              </div>
              FlashPlayHR
            </Imagem>
            <IconExame>
              <GoGear size={60} color="#D1EAF8" />
              <TextIcon className="sumir-texto">
                <div style={{ fontFamily: "Gotham-Medium" }}>Exame</div>
                <div style={{ fontFamily: "Gotham-Bold", fontSize: "3vh" }}>
                  {languagePage === "PT" ? "Específico" : " Specific"}
                </div>
              </TextIcon>
            </IconExame>
          </ContainerGenericoHeader>

          <IntroducaoQuestionario>
            {transitions((style, i) => {
              if (i === 0)
                return (
                  <animated.div
                    style={{
                      width: "85%",
                      margin: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                      ...style,
                    }}
                  >
                    <ConteudoIntroducao>
                      <IntroducaoOrientaco>
                        <div>
                          <OrientacoImagem>
                            <img
                              src={
                                "https://res.cloudinary.com/hkkzuevm3/image/upload/v1634764955/Assets-FlashPlayHr/02_nxxxjv.png"
                              }
                              alt='Imagem'
                            />
                          </OrientacoImagem>
                        </div>
                        <OrientacaoTexto>
                          {languagePage === "PT"
                            ? " Caro candidato, você está prestes a realizar um exame específico elaborado pela"
                            : "Dear candidate, you are about to perform as specific exam prepared by the"}

                          <div>
                            {company.name}.
                          </div>
                        </OrientacaoTexto>
                      </IntroducaoOrientaco>
                      <NextQuestoes>
                        <Alternativa
                          onClick={() => setAtualPage(1)}
                          style={{
                            boxShadow:
                              " 0.125rem 0.125rem 0.5rem rgba(0,0,0,1)",
                          }}
                        >
                          <GoChevronRight />
                        </Alternativa>
                      </NextQuestoes>
                    </ConteudoIntroducao>
                  </animated.div>
                );
              else
                return (
                  <animated.div
                    style={{
                      width: "100%",
                      maxWidth: 1000,
                      minWidth: 200,
                      ...style,
                    }}
                  >
                    <Questao
                      numberOfQuestion={i}
                      onBack={() => {
                        anteriorPage(i - 1, ref.current?.getResp());
                      }}
                      onGo={() => {
                        nextPage(i + 1, ref.current?.getResp());
                      }}
                      question={questions[i - 1]}
                      respostaInicial={answers[i - 1]}
                      ref={ref}
                    />
                  </animated.div>
                );
            })}
          </IntroducaoQuestionario>
          {atualPage !== 0 && (
            <LocalizacaoPagina>
              {questions.map((question, index) => (
                <Bolinha
                  key={question.id}
                  isActive={atualPage > index}
                  style={{ marginRight: 10 }}
                ></Bolinha>
              ))}
            </LocalizacaoPagina>
          )}
        </ContainerGenerico>
      </QuestContainer>
    </Container>
  );
};
