import styled from "styled-components";

export const Conteiner = styled.div`
  margin: 10px;
  color: #fff;
  font-family: "Gotham-Medium";

  .cards {
    margin: 10px;

    background: #134480;
    max-width: 300px;
    min-width: 200px;
    width: 100%;
    // border-radius: 10px;
  }

  @media (max-width: 576px) {
    .cards {
      margin: auto;
      margin-bottom: 10px;
    }
  }

  .buttons {
    color: #fff;
    background: #1c993f;
    //border: 1px solid #000000;
    box-sizing: border-box;
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }

  .card-img {
    padding: 10px;
  }
`;

export const Titulo = styled.div`
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;

  color: #134480;
`;

export const Button = styled.button`
  cursor: pointer;
  //margin: 10px;
  padding: 10px;
  background: transparent;
  border: none;

  font-family: "Gotham-Bold";
  font-size: 14px;

  color: rgb(100, 102, 107);

  :hover {
    transition: 1s;
    color: #fff;
  }
`;

export const Conteudo = styled.div`
  margin: 10px;
`;
