import React from "react";
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from "react-router-dom";
import { useAuth } from "./../hooks/auth";

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  typeAccess: "company" | "geral" | "credenciais" | "common" | "root" | "reset";
  component: React.ComponentType;
}

export const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  typeAccess,
  component: Component,
  ...props
}) => {
  const { user } = useAuth();
  return (
    <ReactDOMRoute
      {...props}
      render={({ location }) => {
        if (typeAccess === "company" && user.type === "company")
          return <Component />;
        if (typeAccess === "common" && user.type === "common")
          return <Component />;
        if (typeAccess === "root" && user.type === "root") return <Component />;
        if (typeAccess === "credenciais" && !!user.id)
          if (user.preference !== "consultancy") {
            return (

              <Redirect
                to={{
                  pathname:
                    user.type === "company"
                      ? "/company/selecoes"
                      : user.type === "common"
                        ? "/candidate/buscarSelecoes"
                        : "/root/empresas",
                  state: { from: location },
                }}
              />
            );
          }else{
            return (

              <Redirect
                to={{
                  pathname:
                    user.type === "company"
                      ? "/company/perfil"
                      : user.type === "common"
                        ? "/candidate/buscarSelecoes"
                        : "/root/empresas",
                  state: { from: location },
                }}
              />
            );

          }

        if (typeAccess === "credenciais" && !user.id) return <Component />;
        if (typeAccess === "reset" && !user.id) return <Component />;
        else if (typeAccess === "geral") return <Component />;
        else
          return (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          );

        
      }}
    />
  );
};
