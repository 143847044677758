import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { Chat } from "../../../components/Chat";

import { useGlobal } from "../../../hooks/global";
import api from "../../../services/api";

import { Container, Card, Button } from "./styles";

export const Proposal: React.FC = () => {
  const { setAtualPageGlobal } = useGlobal();
  const { proposalId } = useParams<any>();
  const [conclused, setConclused] = useState<boolean>(false);
  const socket = io(`${process.env.REACT_APP_CHAT}`);

  useEffect(() => {
    setAtualPageGlobal(`/company/consultoria/conversacao/${proposalId}`);
  }, []);

  const loadProposal = async () => {
    const { data: payment } = await api.get(
      `/company/verify-payment-success/${proposalId}`
    );

    const { finance } = payment;

    if (finance.finalProposalInvoicePaid === true) {
      setConclused(finance.finalProposalInvoicePaid);
    }
  };

  useEffect(() => {
    loadProposal();
  }, []);

  return (
    <Container>
      <Card>
        {!conclused && <Chat proposalId={proposalId} socket={socket} />}
      </Card>
    </Container>
  );
};
