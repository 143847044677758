const float : React.CSSProperties =  {
    position:"fixed", 
	width:"60px",
	height:"60px",
	bottom:"40px",
	right:"40px",
	backgroundColor:"#25d366",
	color:"#FFF",
	borderRadius:"50px",
	textAlign:"center",
    fontSize:"30px",
	boxShadow: "2px 2px 3px #999",
    zIndex:100
}

const myFloat : React.CSSProperties =  {
	textDecoration: "none",
	color: "white" 
}

export {
    myFloat,
    float
}