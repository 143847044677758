import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    height:100%;
    pointer-events:none;
    width:100%;
    justify-content:center;
    align-items:center
`
export const ContainerTwo = styled.div`
    pointer-events:none;

`