import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { ModalVisualizarProposta } from "../../../components/Modals/VisualizarProposta";
import PaginationCustom, {
  PaginationRefProps,
} from "../../../components/PaginationCustom";
import { useGlobal } from "../../../hooks/global";
import api from "../../../services/api";
import { Container, ContainerOptions, Span } from "./style";

export const PropostasRoot: React.FC = () => {
  const { setAtualPageGlobal } = useGlobal();
  const AnchorsRef = useRef<HTMLDivElement[]>([] as HTMLDivElement[]);
  const [loadingProposals, setLoadingProposals] = useState<boolean>(true);
  const [proposalsList, setProposals] = useState<any[]>();
  const paginationRef = useRef<PaginationRefProps>(null);
  const [atualPage, setAtualPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [hover, setHover] = useState<number>(-1);
  const [open, setOpen] = useState<boolean>(false);
  const [proposal, setProposal] = useState<any>();

  const handleClickProposal = useCallback((proposta: any) => {
    setProposal(proposta);
    setOpen(true);
  }, []);

  useEffect(() => {
    api
      .get(`root/list-proposals?limit=11&&page=${atualPage}`)
      .then((response) => {
        const { proposals, count } = response.data;
        setProposals(proposals);
        setNumberOfPages(Math.ceil(count / 11));
        setLoadingProposals(false);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    setAtualPageGlobal("/root/propostas");
  }, []);

  const currency = Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
  const changePage = useCallback(
    (newPage) => {
      if (atualPage !== newPage) setAtualPage(newPage);
    },
    [atualPage]
  );
  return (
    <Container>
      <ModalVisualizarProposta
        open={open}
        onClose={() => setOpen(false)}
        proposal={proposal}
      />
      {!loadingProposals && (
        <table
          style={{
            width: "100%",
            borderRadius: 5,
            borderCollapse: "collapse",
            textAlign: "center",
          }}
        >
          <thead>
            <tr style={{ color: "#FFF" }}>
              <th
                style={{
                  background: "#302c2c",
                  width: "12%",
                  borderTopLeftRadius: 10,
                  padding: 16,
                }}
              >
                Empresa
              </th>
              <th
                style={{
                  background: "#302c2c",
                  width: "8%",
                }}
              >
                Categoria
              </th>
              <th
                style={{
                  background: "#302c2c",
                  width: "5%",
                }}
              >
                Horas empregadas
              </th>
              <th
                style={{
                  background: "#302c2c",
                  width: "5%",
                }}
              >
                Gasto Estimado
              </th>
              <th
                style={{
                  background: "#302c2c",
                  width: "5%",
                }}
              ></th>
            </tr>
          </thead>
          <tbody style={{ background: "#FFF" }}>
            {!!proposalsList &&
              proposalsList.map((proposal, index) => (
                <tr key={proposal.id}>
                  <td>{proposal.company.name}</td>
                  <td>{proposal.category}</td>
                  <td>{proposal.hoursEmployed}</td>
                  <td>{currency.format(Number(proposal.amountEstimate))}</td>
                  <td>
                    <ContainerOptions
                      ref={(ref) =>
                        ref ? (AnchorsRef.current[index] = ref) : null
                      }
                    >
                      <AiOutlineEye
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(-1)}
                        onClick={() => handleClickProposal(proposal)}
                        style={{
                          minWidth: "1.25rem",
                          minHeight: "1.25rem",
                          cursor: "pointer",
                        }}
                        color="#101A36"
                      />
                      {hover === index && <Span>Visualizar</Span>}
                    </ContainerOptions>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {loadingProposals && (
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          padding: 10,
        }}
      >
        <PaginationCustom
          ref={paginationRef}
          pages={numberOfPages}
          disable={loadingProposals}
          onChange={changePage}
        />
      </div>
    </Container>
  );
};
