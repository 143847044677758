import styled, { css } from "styled-components";

interface ContainerNumFuncProps {
  isSelected: boolean;
}

export const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  cursor: default;
  height: 90%;
  flex-direction: column;
  font-family: "Gotham-Bold", sans-serif;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 26px;
  margin-top: 0px;
`;

export const ContainerNumFunc = styled.div<ContainerNumFuncProps>`
  display: flex;
  margin: 5px;
  flex-direction: column;
  box-hadow: 4px 4px 20px -2px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 4px 4px 20px -2px rgba(0, 0, 0, 0.7);
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  background: #0e294b;
  /* background:red; */
  width: 170px;
  height: 110px;
  border: 4px solid #134480;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isSelected &&
    css`
      border: 4px solid #0b243b;
    `}
    
    .font-gotham-bold-size-30{
      font-size: 30px;
      font-family: "Gotham-Bold";
      
    }
    .font-gotham-medium-size-20{
      font-size: 20px;
      font-family: "Gotham-Medium"
    }
`;

export const IconFaUserAlt = styled.div`
    display: flex;
    flex-direction: row;
    marginTop: 10px;
`