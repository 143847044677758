import { useTransition } from "@react-spring/core";
import { animated } from "@react-spring/web";

import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Switch, useHistory, useLocation } from "react-router";
import { Header } from "../components/Header";
import { useGlobal } from "../hooks/global";
import { CreateSelection } from "../pages/DashboardCompany/CreateSelection";
import { Cursos } from "../pages/DashboardCompany/Cursos";
import { Perfil } from "../pages/DashboardCompany/Perfil";
import { Planos } from "../pages/DashboardCompany/Planos";
import { Questionarios } from "../pages/DashboardCompany/Questionarios";
import { Quiz } from "../pages/DashboardCompany/Quiz";
import { Candidatos } from "../pages/DashboardCompany/Candidatos";
import { Solicitacoes } from "../pages/DashboardCompany/Solicitacoes";
import { TodasSelecoes } from "../pages/DashboardCompany/TodasSelecoes";
import { CompanySectors } from "../pages/DashboardCompany/CompanySectors";
import { Aba, ContainerAbas } from "./style";
import { PerfilCandidatoS } from "../pages/DashboardCompany/PerfilCandidatoS";
import { PerfilCandidato } from "../pages/DashboardCompany/PerfilCandidato";
import { Route } from "./route";
import { Pagamento } from "../pages/DashboardCompany/Pagamento";
import {
  FaArchive,
  FaBookOpen,
  FaClipboardList,
  FaCog,
  FaCubes,
  FaPlusCircle,
  FaRegCommentDots,
  FaShoppingBasket,
  FaUserAlt,
  FaUserCog,
  FaUsers,
} from "react-icons/fa";
import { useAuth } from "../hooks/auth";
import api from "../services/api";
import { useForm } from "../hooks/form";
import { Link } from "react-router-dom";
import { ListaConsultores } from "../pages/DashboardCompany/ListaConsultores";
import { PerfilConsultor } from "../pages/DashboardCompany/PerfilConsultor";
import { Pdf } from "../components/PDF";
import { MinhasPropostas } from "../pages/DashboardCompany/MinhasPropostas";
import { EditarProposta } from "../pages/DashboardCompany/EditarPropostas";
import { TrocarConsultor } from "../pages/DashboardCompany/TrocarConsultor";
import { EditarSelecao } from "../pages/DashboardCompany/EditarSelecao";
import { Checkout } from "../pages/DashboardCompany/Checkout";
import { Proposal } from "../pages/DashboardCompany/Proposal";
import { CriarProposta } from "../pages/DashboardCompany/CriarProposta";
import ProfileMapping from "../pages/ProfileMapping";
import SoftProfileMapping from "../pages/SoftProfileMapping";
import { SectorsCandidates } from "../pages/DashboardCompany/SectorsCandidates";
import SoftProfileMappingSector from "../pages/SoftProfileMappingSector";
import ProfileMappingSector from "../pages/ProfileMappingSector";
const DashboardRoutes: React.FC = () => {
  const location = useLocation();
  const transitions = useTransition(location, {
    from: { opacity: 0, config: { duration: 0 } },
    enter: { opacity: 1, config: { duration: 400 } },
    leave: { opacity: 0, config: { duration: 0 } },
  });
  const { atualPageGlobal, languagePage } = useGlobal();
  const { type } = useForm();

  const { push } = useHistory();
  const { user } = useAuth();

  const clickOnAba = useCallback(
    (newAba) => {
      const plan = localStorage.getItem("@flashplayHR:plans");
      if (plan) {
        return push("/company/pagamento");
      }
      if (atualPageGlobal !== newAba) {
        return push(newAba);
      }
    },
    [atualPageGlobal]
  );
  const [fleg, SetFleg] = useState("none");
  const [categorias, setCategorias] = useState<[]>([]);

  useEffect(() => {
    api
      .get("/company/list-category-consultants")
      .then((res) => {
        const { categories } = res.data;

        setCategorias(categories);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        background: "#fff",
        //Lembrar
        overflowY: "scroll",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div style={{ marginBottom: 70 }}>
        <Header />
      </div>
      <ContainerAbas>
        {(user.preference === "both" ||
          user.preference === "selections" ||
          user.preference === null) &&
          user.type === "company" ? (
          <Aba
            isAtual={atualPageGlobal === "/company/selecoes"}
            onClick={() => clickOnAba("/company/selecoes")}
          >
            <FaUsers className="icone_res"></FaUsers>
            <small className="icone_nores">
              {languagePage === "PT" ? "Suas Seleções" : "Your Selections"}
            </small>

            {/* <FaBookOpen></FaBookOpen> */}
          </Aba>
        ) : (
          ""
        )}

        {(user.preference === "both" ||
          user.preference === "selections" ||
          user.preference === null) &&
          user.type === "company" ? (
          <Aba
            isAtual={atualPageGlobal === "/company/sectors"}
            onClick={() => clickOnAba("/company/sectors")}
          >
            <FaUsers className="icone_res"></FaUsers>
            <small className="icone_nores">
              {languagePage === "PT" ? "Seus Setores" : "Your Sectors"}
            </small>

            {/* <FaBookOpen></FaBookOpen> */}
          </Aba>
        ) : (
          ""
        )}

        <Aba
          isAtual={atualPageGlobal === "/company/perfil"}
          onClick={() => clickOnAba("/company/perfil")}
        >
          <small className="icone_nores">
            {languagePage === "PT" ? "Meu Perfil " : "My Profile "}
          </small>
          <FaUserCog className="icone_res"></FaUserCog>
        </Aba>
        {/* <Aba isAtual={atualPageGlobal === "/company/cursos"} onClick={() => clickOnAba("/company/cursos")}>Cursos</Aba> */}
        {(user.preference === "both" ||
          user.preference === "selections" ||
          user.preference === null) &&
          user.type === "company" ? (
          <Aba
            isAtual={atualPageGlobal === "/company/novaSelecao"}
            onClick={() => clickOnAba("/company/novaSelecao")}
          >
            <small className="icone_nores">
              {languagePage === "PT" ? "Criar Seleção" : "Create Selection"}
            </small>
            <FaPlusCircle className="icone_res"></FaPlusCircle>
          </Aba>
        ) : (
          ""
        )}
        {(user.preference === "both" ||
          user.preference === "selections" ||
          user.preference === null) &&
          user.type === "company" ? (
          <Aba
            isAtual={atualPageGlobal === "/company/questionarios"}
            onClick={() => clickOnAba("/company/questionarios")}
          >
            <small className="icone_nores">Circular Skills</small>
            <FaCubes className="icone_res"></FaCubes>
          </Aba>
        ) : (
          ""
        )}
        {(user.preference === "both" ||
          user.preference === "selections" ||
          user.preference === null) &&
          user.type === "company" ? (
          <Aba
            isAtual={atualPageGlobal === "/company/planos"}
            onClick={() => clickOnAba("/company/planos")}
          >
            <small className="icone_nores">
              {languagePage === "PT" ? "Planos" : "Plans"}
            </small>
            {/* <i className="fas fa-cog"></i> */}
            <FaShoppingBasket className="icone_res"></FaShoppingBasket>
          </Aba>
        ) : (
          ""
        )}
        {/* {user.preference === "consultancy" || user.preference === "both" ? (
          <Aba isAtual={atualPageGlobal === ""}>
            <ul className="nav icone_nores">
              <li>
                <small className="icone_nores">
                  {languagePage === "PT" ? "Consultoria" : "Consultancy"}
                </small>
                <ul className="dentro">
                  <li>
                    <a
                      onClick={() =>
                        push("/company/consultoria/criar-proposta")
                      }
                    >
                      Criar Proposta
                    </a>
                  </li>

                  <li
                    onClick={() =>
                      push("/company/consultoria/minhas-propostas")
                    }
                  >
                    <a>Minhas Propostas</a>
                  </li>
                </ul>
              </li>
            </ul>

            <FaArchive className="icone_res"></FaArchive>
          </Aba>
        ) : (
          ""
        )} */}
        {/* <Aba isAtual={atualPageGlobal === "/company/quiz"} onClick={() => clickOnAba("/company/quiz")}>Quiz</Aba> */}
      </ContainerAbas>
      <div
        style={{
          display: "flex",
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {transitions((props, item) => (
          <animated.div
            style={{
              ...props,
              position: "absolute",
              width: "100%",
              flexDirection: "row",
              height: "100%",
            }}
          >
            {(user.preference === "both" ||
              user.preference === "selections" ||
              user.preference === null) &&
              user.type === "company" ? (
              <Switch location={item}>
                <Route
                  typeAccess="company"
                  exact
                  path="/company/selecoes"
                  component={TodasSelecoes}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/sectors"
                  component={CompanySectors}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/editar"
                  component={EditarSelecao}
                />
                {/* <Route typeAccess="company" exact path='/company/cursos' component={Cursos} /> */}
                <Route
                  typeAccess="company"
                  exact
                  path="/company/perfil"
                  component={Perfil}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/planos"
                  component={Planos}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/pagamento"
                  component={Pagamento}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/questionarios"
                  component={Questionarios}
                />
                {/* <Route typeAccess="company" exact path='/company/quiz' component={Quiz} /> */}
                <Route
                  typeAccess="company"
                  exact
                  path="/company/novaSelecao"
                  component={CreateSelection}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/candidatos/:selectionId"
                  component={Candidatos}
                />

                <Route
                  typeAccess="company"
                  exact
                  path="/company/sectorCandidates/:sectorName"
                  component={SectorsCandidates}
                />

                <Route
                  typeAccess="company"
                  exact
                  path="/company/solicitacoes/:selectionId"
                  component={Solicitacoes}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/candidatos/perfilCandidato/:selectionId/:userId"
                  component={PerfilCandidato}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/candidatos/:selectionId/profileMapping"
                  component={ProfileMapping}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/candidatos/:sectorName/profileMappingSector"
                  component={ProfileMapping}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/candidatos/:selectionId/softProfileMapping"
                  component={SoftProfileMapping}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/candidatos/:sectorName/softProfileMappingSector"
                  component={SoftProfileMappingSector}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/solicitacoes/perfilCandidato/:selectionId/:requestId"
                  component={PerfilCandidatoS}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/consultoria/criar-proposta"
                  component={CriarProposta}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/consultoria/consultores/:proposalId"
                  component={ListaConsultores}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/consultoria/minhas-propostas"
                  component={MinhasPropostas}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/consultoria/conversacao/:proposalId"
                  component={Proposal}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/perfilconsultor"
                  component={PerfilConsultor}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/consultoria/editar-proposta"
                  component={EditarProposta}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/trocar-consultor/:tipo/:idParam"
                  component={TrocarConsultor}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/checkout"
                  component={Checkout}
                />
                <Redirect
                  to={{
                    pathname: "/candidate/selecoes",
                    state: { from: location },
                  }}
                />
                ;
              </Switch>
            ) : (
              <Switch location={item}>
                {/* <Route typeAccess="company" exact path='/company/cursos' component={Cursos} /> */}
                <Route
                  typeAccess="company"
                  exact
                  path="/company/perfil"
                  component={Perfil}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/consultoria/criar-proposta"
                  component={CriarProposta}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/consultoria/editar-proposta"
                  component={EditarProposta}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/consultores/:proposalId"
                  component={ListaConsultores}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/consultoria/minhas-propostas"
                  component={MinhasPropostas}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/consultoria/conversacao/:proposalId"
                  component={Proposal}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/perfilconsultor"
                  component={PerfilConsultor}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/checkout"
                  component={Checkout}
                />
                <Route
                  typeAccess="company"
                  exact
                  path="/company/trocar-consultor/:id"
                  component={TrocarConsultor}
                />
                {/* <Route typeAccess="company" exact path='/company/quiz' component={Quiz} /> */}
                ;
              </Switch>
            )}
            <Route
              typeAccess="company"
              exact
              path="/company/pdf"
              component={Pdf}
            />

            {/* <Switch location={item}>

            

           </Switch> */}
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default DashboardRoutes;
