import styled from "styled-components";

export const ContainerConteudo = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #FFF;
    .conteudo{
        display: flex; 
        flex-direction: column; 
        width: 100%;
        .number-questio{
            color: #134480;
            font-family: Gotham-Bold;
            font-size: 3.5vh;
            margin-bottom: 5vh;
            display: flex;
            justify-content: center;           
        }
        .painel-questao{
               display: flex;
                flex-direction: row;
                align-items: center;
                margin: 9px;
                margin-left: 18px;
                margin-right: 18px;
                color: #133770;
                font-family: Gotham-Medium;

                .alternativa{
                    display: flex;
                    cursor: pointer;
                    box-shadow: 0.125rem 0.125rem 0.5rem rgba(0,0,0,0.7);
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    color: #FFF;
                    min-width: 30px;
                    min-height: 30px;
                    margin-right: 10px;
                }

            }

    }

`
export const Seta = styled.div`
    display: flex;
    margin-right: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 50%;
    font-size: 2.15vh;
    @media (max-width: 410px){
        display: none;
    }
`
export const SetasTwo = styled.div`
    display: none;
    margin-right: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 50%;
    font-size: 2.15vh;
    @media (max-width: 410px){
        display: flex;
    }
`