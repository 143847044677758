import React, { useCallback, useRef, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { MdQuestionAnswer } from "react-icons/md";
import { ErrorProps, Verify } from "../../../services/schemaServices";
import {
    Container,
    Header,
    IconDelete,
    ButtonEnd,
    ContainerHeader,
    ContainerTitle,
    Formulario,
    ContainerItemForm,
    ContainerBottom,
} from "./style";
import InputLabel, { InputRefProps } from "../../InputLabel";
import { useGlobal } from "../../../hooks/global";
import api from "../../../services/api";
import Toastify from "toastify-js";
import { useHistory } from "react-router-dom";

interface SetorTypoProps {
    open: boolean;
    onClose: Function;
    onSave: Function;
    selectionId: any;
}

export const SetorTypo: React.FC<SetorTypoProps> = ({
    open,
    onClose,
    onSave,
    selectionId,
}) => {
    const [errors, setErrors] = useState<ErrorProps[]>([] as ErrorProps[]);
    const setor = useRef<InputRefProps>(null);
    const codigo = useRef<InputRefProps>(null);
    const {push} = useHistory();
    const { languagePage,setSolicitacaoPrivada} = useGlobal();
    const [coloaboradorOuLider, setColoaboradorOuLider] =
        useState<boolean>(false);

    const Save = useCallback(async () => {
        const sector = setor.current?.getInputValue();
        const code = codigo.current?.getInputValue();


        try {
            if (code && sector) {
                const resposta = await api.post(`/common/subscribeInSelection/${selectionId}`, {
                    codeAccess: code,
                    sector: sector,
                    type: coloaboradorOuLider === true ? "leader" : "collaborator",
                });
                setSolicitacaoPrivada(true);
                push("/candidate/buscarSelecoes")
                Toastify({

                    text: "Solicitação feita com sucesso!",

                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",

                    }

                }).showToast();
                
                
            } else {
                Toastify({

                    text: "Prencha todos os campos!",

                    duration: 3000,
                    style: {
                        background: "linear-gradient(to right, red, #ccc)",

                    }

                }).showToast();
            }

        } catch (err) {
            Toastify({
                text: "Erro ao se inscrever na seleção, verifique o código ou se já é inscrito!",
                duration: 3000,
                style: {
                    background: "linear-gradient(to right, red, #ccc)",
                }
            }).showToast();
        }

        return onClose();
    }, [setor, coloaboradorOuLider, codigo, selectionId]);

    const reqTipoSelecao = () => {
        if (coloaboradorOuLider === true) {
            setColoaboradorOuLider(false);
        } else {
            setColoaboradorOuLider(true);
        }
    };
    return (
        <Modal
            open={open}
            onClose={() => onClose()}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Container>
                <ContainerHeader>
                    <Header>
                        <ContainerTitle>
                            <MdQuestionAnswer
                                style={{ marginRight: "0.625rem" }}
                                color="#101A36"
                            />
                            {languagePage === "PT" ? "Setor e tipo" : "Change Password"}
                        </ContainerTitle>
                        <IconDelete onClick={() => onClose()} color="#C4C4C4" />
                    </Header>
                </ContainerHeader>

                <Formulario>
                    <ContainerItemForm>
                        <InputLabel
                            errors={errors}
                            type="setortypo"
                            label={
                                languagePage === "PT"
                                    ? "Código de acesso"
                                    : "Enter your new password"
                            }
                            attr="newSetortypo"
                            id="inputSetortypo"
                            ref={codigo}
                            containerStyle={{ width: "100%" }}
                            inputProps={{ style: { fontFamily: "math", fontWeight: "bold" } }}
                        />
                    </ContainerItemForm>
                    <ContainerItemForm>
                        <InputLabel
                            errors={errors}
                            type="setortypo"
                            label={
                                languagePage === "PT"
                                    ? "Digite seu setor"
                                    : "Enter your new password"
                            }
                            attr="newSetortypo"
                            id="inputSetortypo"
                            ref={setor}
                            containerStyle={{ width: "100%" }}
                            inputProps={{ style: { fontFamily: "math", fontWeight: "bold" } }}
                        />
                    </ContainerItemForm>
                    <div
                        className="form-check form-switch"
                        style={{
                            padding: 10,
                            marginTop: "100px",
                            margin: "auto",
                            display: "flex",
                        }}
                    >
                        <div style={{ margin: 10 }}>Colaborador</div>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            onClick={() => reqTipoSelecao()}
                            style={{ padding: 10, width: 50, margin: "auto" }}
                        />
                        <div style={{ margin: 10 }}>Lider</div>
                    </div>

                    <ContainerItemForm style={{ fontStyle: "italic", color: "#333333" }}>
                        {languagePage === "PT"
                            ? "Observação: Preencha os campos!"
                            : "Note: Fill in the fields!"}
                    </ContainerItemForm>
                </Formulario>

                <ContainerBottom>
                    <ButtonEnd onClick={() => onClose()} style={{ color: "#252323" }}>
                        {" "}
                        {languagePage === "PT" ? "Cancelar" : "Cancel"}{" "}
                    </ButtonEnd>
                    <ButtonEnd
                        onClick={() => Save()}
                        style={{ color: "#101A36", cursor: "pointer" }}
                    >
                        {" "}
                        {languagePage === "PT"
                            ? "Solicitar Inscrição"
                            : "Request Registration"}
                    </ButtonEnd>
                </ContainerBottom>
            </Container>
        </Modal>
    );
};

export default SetorTypo;
