import { CircularProgress, Modal } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaUser } from "react-icons/fa";
import { useHistory } from "react-router";

import { LoadingSale } from "../../../components/Animations/LoadingSale";
import { InputRefProps } from "../../../components/Input";
import { useGlobal } from "../../../hooks/global";
import { selectionInterface } from "../../../interfaces/selectionInterface";
import {
  SearchFieldContainer,
  SearchField,
} from "../../../components/SearchField";
import api from "../../../services/api";

import * as Yup from "yup";

import {
  AgurdandoApro,
  CardDescription,
  CardsConteudos,
  CardsConteudosAvatar,
  CardsConteudosName,
  CardsConteudosNameAvatar,
  Container,
  ContainerLoading,
  ContainerTexto,
  IrParaSelecao,
  NenhumaSolicitao,
  RecuSolicitaInsc,
  SolicitaInsc,
  SubContainer,
  SubContainerLoading,
  Button,
  Pagination,
  PaginationButton,
} from "./style";
import SetorTypo from "../../../components/Modals/SetorTypo";
import { useAuth } from "../../../hooks/auth";

export const BuscarSelecoes: React.FC = () => {
  const [selections, setSelections] = useState<selectionInterface[]>(
    [] as selectionInterface[]
  );
  const [filteredSelections, setFilteredSelections] = useState<
    selectionInterface[]
  >([] as selectionInterface[]);

  const [atualPage, setAtualPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingInscricao, setLoadingInscricao] = useState<boolean>(false);
  const {
    setAtualPageGlobal,
    languagePage,
    solicitacaoPrivada,
    setSolicitacaoPrivada,
  } = useGlobal();
  const [errorCodeAccess, setErrorCodeAccess] = useState<string | null>(null);
  const inputCodeAccessRef = useRef<InputRefProps>(null);
  const [publicSelection, setPublic] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState(1); // Total de páginas
  const { push } = useHistory();
  const { user } = useAuth();

  useEffect(() => {
    setAtualPageGlobal("/candidate/buscarSelecoes");
  }, []);

  const VerifyCodeAccess = useCallback(async () => {
    let resu;
    setErrorCodeAccess(null);

    const title = inputCodeAccessRef.current?.getInputValue();
    const schemaTitle = Yup.object().shape({
      title: Yup.string().required(
        languagePage === "PT" ? "Campo obrigatório" : "Required field"
      ),
    });
    try {
      await schemaTitle.validate({ title });
    } catch (err: any) {
      setErrorCodeAccess(err.errors ? err.errors[0] : null);
      resu = err.errors ? err.errors[0] : null;
    }
    return resu;
  }, [languagePage]);

  //Repensesar essa query
  const searchSelections = useCallback(
    async (page) => {
      try {
        const resu = await api.get(
          `/common/selections/${user.preference === "candidate" ? publicSelection : ""
          }?limit=10&page=${page}`
        );
        const { rows, count } = resu.data;
        const pages = Math.ceil(count / 10);
        setTotalPages(pages);
        setSelections(resu.data.rows);
        setFilteredSelections(resu.data.rows);
        setLoading(false);
        setLoadingInscricao(false);
      } catch (e) { }
    },
    [publicSelection, user.preference]
  );

  useEffect(() => {
    setTimeout(() => searchSelections(atualPage), 200);
  }, [atualPage, searchSelections]);


  const solicitarInscricao = useCallback(
    async (selectionId, selectionPublic, page) => {
      if (user.preference === "consultant") {
        await api.post(`/common/subscribeInSelection/${selectionId}`);
        searchSelections(page);
        return;
      }
      if (selectionPublic === true) {
        setLoadingInscricao(true);
        setTimeout(async () => {
          await api.post(`/common/subscribeInSelection/${selectionId}`);

          searchSelections(page);
        }, 200);
      } else {
        const errorCodeAccess = await VerifyCodeAccess();

        if (!!!errorCodeAccess) {
          const codeAccessVerified =
            inputCodeAccessRef.current?.getInputValue();
          setLoadingInscricao(true);
          setTimeout(async () => {
            await api.post(`/common/subscribeInSelection/${selectionId}`, {
              codeAccess: codeAccessVerified,
            });

            searchSelections(page);
          }, 200);
        }
      }
    },
    [VerifyCodeAccess, searchSelections, user.preference]
  );
  const [openModalCodigo, setOpenModalCodigo] = useState<boolean>(false);
  const [selectionId, setSelectionId] = useState<any>();
  const SenhaAlterada = useCallback((data) => {
    setOpenModalCodigo(false);
    setLoading(true);
  }, []);

  const solicitando = (id: any, publi: any, atul: any) => {
    if (publi === false) {
      setSelectionId(id);
      setOpenModalCodigo(true);
    } else {
      solicitarInscricao(id, publi, atul);
    }
  };
  useEffect(() => {
    if (solicitacaoPrivada === true) {
      window.location.reload();
      setSolicitacaoPrivada(false);
    }
  }, [setSolicitacaoPrivada, solicitacaoPrivada]);

  const handleChange = useCallback(
    (event) => {
      if (event.target.value !== "") {
        const filteredData = selections.filter((selection) => {
          return selection.name
            .toLowerCase()
            .includes(event.target.value.toLowerCase());
        });
        setFilteredSelections(filteredData);
      } else {
        setFilteredSelections(selections);
      }
    },
    [selections]
  );
  const handleChangePage = (page: number) => {
    setLoading(true);
    setAtualPage(page); 
  };

  return (
    <Container>
      {user.preference === "candidate" && (
        <div className="button-type-selection">
          <Button
            publicSelection={!publicSelection}
            onClick={() => setPublic(false)}
          >
            Mapeamento Interno
          </Button>
          <Button
            publicSelection={publicSelection}
            onClick={() => setPublic(true)}
          >
            Seleção Externa
          </Button>
        </div>
      )}
      <SetorTypo
        open={openModalCodigo}
        selectionId={selectionId}
        onClose={() => setOpenModalCodigo(false)}
        onSave={SenhaAlterada}
      ></SetorTypo>

      {/* SearchField */}
      <SearchFieldContainer>
        <SearchField
          type="search"
          placeholder={languagePage === "PT" ? "Pesquisar" : "Search"}
          onChange={(e) => handleChange(e)}
        />
      </SearchFieldContainer>

      <SubContainer>
        <SubContainerLoading>
          {!loading &&
            filteredSelections &&
            filteredSelections.map((selection) => {
              if (
                selection.public === publicSelection &&
                selection.preference === "candidate"
              ) {
                return (
                  <CardsConteudos key={selection.id}>
                    <CardsConteudosNameAvatar>
                      <CardsConteudosAvatar>
                        {selection.company.avatar && (
                          <img
                            style={{ height: 50 }}
                            src={selection.company.avatar}
                            alt="Avatar"
                          />
                        )}
                        {!selection.company.avatar && (
                          <FaUser color="#FFF" size={50} />
                        )}
                      </CardsConteudosAvatar>
                      <CardsConteudosName>{selection.name}</CardsConteudosName>
                    </CardsConteudosNameAvatar>
                    <CardDescription>
                      <div className="title-descr">
                        {selection.company.name}
                      </div><br />
                      <div className="title-descr">
                        {languagePage === "PT" ? "Descrição" : "Description"}
                      </div>
                      <div className="texto-descr">{selection.description}</div>
                    </CardDescription>
                    {selection.request &&
                      selection.request.status === "pending" && (
                        <AgurdandoApro>
                          {languagePage === "PT"
                            ? "Aguarde a aprovação"
                            : "Wait for approval"}
                        </AgurdandoApro>
                      )}
                    {selection.request &&
                      selection.request.status === "accept" && (
                        <IrParaSelecao
                          onClick={() => push("/candidate/minhasSelecoes")}
                        >
                          {languagePage === "PT"
                            ? "Ir para a seleção"
                            : "Go to selection"}
                        </IrParaSelecao>
                      )}
                    {((selection.request &&
                      selection.request.status === "cancel") ||
                      !selection.request) && (
                        <SolicitaInsc
                          onClick={() =>
                            solicitando(selection.id, selection.public, atualPage)
                          }
                        >
                          {languagePage === "PT"
                            ? "Solicitar Inscrição"
                            : "Register"}
                        </SolicitaInsc>
                      )}
                    {selection.request &&
                      selection.request.status === "refused" && (
                        <RecuSolicitaInsc>
                          {languagePage === "PT"
                            ? "Inscrição Recusada"
                            : "Application refused"}
                        </RecuSolicitaInsc>
                      )}
                  </CardsConteudos>
                );
              } else if (selection.preference === "consultant") {
                return (
                  <CardsConteudos>
                    <CardsConteudosNameAvatar>
                      <CardsConteudosAvatar>
                        {selection.company.avatar && (
                          <img
                            style={{ height: 50 }}
                            src={selection.company.avatar}
                            alt="Avatar"
                          />
                        )}
                        {!selection.company.avatar && (
                          <FaUser color="#FFF" size={50} />
                        )}
                      </CardsConteudosAvatar>
                      <CardsConteudosName>{selection.name}</CardsConteudosName>
                    </CardsConteudosNameAvatar>
                    <CardDescription>
                      <div className="title-descr">
                        {selection.company.name}
                      </div><br />
                      <div className="title-descr">
                        {languagePage === "PT" ? "Descrição" : "Description"}
                      </div>
                      <div className="texto-descr">{selection.description}</div>
                    </CardDescription>
                    {selection.request &&
                      selection.request.status === "pending" && (
                        <AgurdandoApro>
                          {languagePage === "PT"
                            ? "Aguarde a aprovação"
                            : "Wait for approval"}
                        </AgurdandoApro>
                      )}
                    {selection.request &&
                      selection.request.status === "accept" && (
                        <IrParaSelecao
                          onClick={() => push("/candidate/minhasSelecoes")}
                        >
                          {languagePage === "PT"
                            ? "Ir para a seleção"
                            : "Go to selection"}
                        </IrParaSelecao>
                      )}
                    {((selection.request &&
                      selection.request.status === "cancel") ||
                      !selection.request) && (
                        <SolicitaInsc
                          onClick={() =>
                            solicitando(selection.id, selection.public, atualPage)
                          }
                        >
                          {languagePage === "PT"
                            ? "Solicitar Inscrição"
                            : "Register"}
                        </SolicitaInsc>
                      )}
                    {selection.request &&
                      selection.request.status === "refused" && (
                        <RecuSolicitaInsc>
                          {languagePage === "PT"
                            ? "Inscrição Recusada"
                            : "Application refused"}
                        </RecuSolicitaInsc>
                      )}
                  </CardsConteudos>
                );
              }
            })}
          {!loading && selections.length === 0 && (
            <NenhumaSolicitao>
              {languagePage === "PT"
                ? "Nenhuma Seleção Cadastrada na Plataforma"
                : "No Selection Registered on the Platform"}
            </NenhumaSolicitao>
          )}
          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          )}
        </SubContainerLoading>
      </SubContainer>
      <Pagination>
        {Array.from({ length: totalPages }, (_, index) => (
          <PaginationButton
            key={index + 1}
            active={atualPage === index + 1}
            onClick={() => handleChangePage(index + 1)}
          >
            {index + 1}
          </PaginationButton>
        ))}
      </Pagination>
      <ContainerTexto
        style={{
          WebkitJustifyContent: "center",
          WebkitAlignItems: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          <div className="nosso_diferencial">
            {languagePage === "PT" ? "Nosso Diferencial" : "Our Differential"}
          </div>
        </div>
        <div
          className="p_nosso_diferencial"
          style={{ WebkitFlexDirection: "row" }}
        >
          <div className="img_nosso_diferencial">
            <img
              src={
                "https://res.cloudinary.com/hkkzuevm3/image/upload/v1635023104/Assets-FlashPlayHr/ilustra%C3%A7%C3%A3o5_p56ca8.png"
              }
              alt="Imagem"
            />
          </div>
        </div>
      </ContainerTexto>

      {loadingInscricao && (
        <Modal open={loadingInscricao}>
          <ContainerLoading>
            <LoadingSale />
          </ContainerLoading>
        </Modal>
      )}
    </Container>
  );
};
