import React, { useCallback } from "react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import Input from "../../Input";
import { useState } from "react";
import { useForm } from "../../../hooks/form";
import { MaskCNPJ } from "../../../services/maskServices";
import {
  DeleteContentBackward,
  IsNumber,
} from "../../../services/inputServices";
import { useGlobal } from "../../../hooks/global";
import jsonp from "jsonp";
import { useToast } from "../../../hooks/toast";
import { BotoesForm, ButtonNextBack, Container, Titulo } from "../style";

interface CNJPCompanyData {
  onNext: Function;
  onBack: Function;
}

const CNJPCompany: React.FC<CNJPCompanyData> = ({ onNext, onBack }) => {
  const { cnpj, setCnpj, setNome, setResponsavel, setCidade, setEstado } =
    useForm();
  const { addToast } = useToast();
  const [cnpjNumber, setCnpjNumber] = useState<string>(cnpj);
  const [cnpjString, setCnpjString] = useState<string>(MaskCNPJ(cnpj));

  const OnChangeInputCNPJ = useCallback(
    (event) => {
      const char = event.nativeEvent.data;
      const eventType = event.nativeEvent.inputType;
      if (
        eventType === "insertText" &&
        cnpjNumber.length < 14 &&
        IsNumber(char)
      ) {
        setCnpj(cnpjNumber + char);
        setCnpjNumber(cnpjNumber + char);
        setCnpjString(MaskCNPJ(cnpjNumber + char));
      } else if (eventType === "deleteContentBackward" && cnpjNumber) {
        setCnpj(DeleteContentBackward(cnpjNumber));
        setCnpjNumber(DeleteContentBackward(cnpjNumber));
        setCnpjString(MaskCNPJ(DeleteContentBackward(cnpjNumber)));
      }
    },
    [cnpjNumber, setCnpjNumber, setCnpjString]
  );

  const onClickNext = useCallback(() => {
    if (cnpj.length === 14) {
      jsonp(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          const { situacao, nome, municipio, uf, qsa } = res;

          if (situacao === "ATIVA") {
            setNome(nome);
            setCidade(municipio);
            setEstado(uf);
            setResponsavel(!!qsa && qsa.length !== 0 ? qsa[0].nome : "");
            onNext();
          } else {
            addToast({
              title: "Erro",
              description:
                languagePage === "PT"
                  ? "CNPJ inválido ou não existe"
                  : "Invalid CNPJ or does not exist",
              icon: "alert",
              type: "error",
            });
          }
        }
      });
    }
  }, [cnpj]);
  const { setAtualPageGlobal, languagePage } = useGlobal();

    return (
      <Container>
        <div >
          <Titulo>
          {languagePage == "PT" ? "  O seu CNPJ é essencial para identificarmos a sua empresa." :
          "Your CRN is essential for us to identify your Company."}
          </Titulo>
          <Input
            styleInput={{
              fontFamily: "Gotham-Bold",
              color: "#FFF",
              background: "#0b243b",
              fontSize: 16,
            }}
            styleContainer={{ background: "#0b243b" }}
            placeholder="Digite seu CNPJ"
            label="Digite seu CNPJ"
            value={cnpjString}
            onChange={OnChangeInputCNPJ}
          />
          <ButtonNextBack style={{ flexWrap:"wrap", textAlign:"center" }}>
            <BotoesForm
              onClick={() => onBack()}
            >
              <GoChevronLeft />
              {languagePage === "PT" ? "Anterior" : "Back"}
            </BotoesForm>
            <BotoesForm
              onClick={onClickNext}
              style={{
                
                cursor: cnpj.length === 14 ? "pointer" : "not-allowed",
              }}
            >  {languagePage === "PT" ? " Próximo" : "Next"}
             
              <GoChevronRight style={{ marginLeft: 5 }} />
            </BotoesForm>
          </ButtonNextBack>
        </div>
      </Container>
    );
};

export default CNJPCompany;