import styled from "styled-components";

const HeaderContainer = styled.header`
	grid-area: header;
	display: grid;
	max-height: 100%;
	position: relative;
	.toggledMobileNavBarButton {
		border-top-left-radius: 16px;
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 0px;
		border-top-right-radius: 0px;
		border-right: 0px;
	}
`;

const Container = styled.div`
	display: flex;
`;

const ImagesContainer = styled.div`
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 2em;
	width:50%;
	align-self: center;
	@media screen and (max-width: 320px) {
		width: 100%;
	}
	@media screen and (min-width: 321px) and (max-width: 375px) {
		width: 100%;
	}
`;

const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	align-items: space-between;
	text-align: center;
	gap: 0.5em;
	@media screen and (max-width: 600px) {
		flex-direction: column;
		justify-items: center;
		align-content: center;
	}
`;

const StoreButton : React.CSSProperties = {
	display: "flex",
	flexDirection: "column",
	gap: "0.5em",
	width: "45%"
}

const Img = styled.img``;

const GuaraWolf = styled(Img)`
	max-width: 100%;
	 height: auto; 
	align-self: center;
	z-index: 50;
	margin-right:100%;
	@media screen and (max-width: 800px) {
		display: none;
	}
`;

const Butterfly = styled(Img)`
	transform: scaleX(-1);
	height: auto; 
	align-self: center;
	z-index: 1;
	max-width: 100%;
	margin-right:100%;
	@media screen and (max-width:  800px) {
		display: none;
	}
`;

const Chamaleon = styled(Img)`
	align-self: center;
	max-width: 100%;
	height: auto; 
	z-index: 1;

	@media screen and (max-width:  800px) {
		display: none;
	}
`;

const Woman = styled(Img)`
	z-index: 1;
	height: 85%;
	align-self: end;
	@media screen and (orientation: portrait) and (max-width: 1023px) {
		display: none;
	}
	@media screen and (max-width: 1024px) and (min-width: 769px) {
		max-width: 30%;
	}
	@media screen and (max-width: 1440px) and (min-width: 1025px) {
		max-width: 25%;
	}
`;





const LogoContainer = styled.div`
	height: auto;
	display: flex;
	position: absolute;
	max-width: 20%;
	padding: 30px 0px 0px 35px;
	@media screen and (max-width: 768px) {
		z-index: 4;
		top: 0px;
		right: 170px;
	}
	@media screen and (min-width: 769px) {
		padding-top: 70px;
		position: relative;
		align-items: flex-end;
	}
`;

const FlashPlayLogo = styled(Img)`
	cursor: pointer;
	width: 100%;
	min-width: 160px;
	
`;

const VideoBackground = styled.video`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	overflow: hidden;
	z-index: 0;
	@media screen and (min-width: 321px) and (max-width: 375px) {
		width: 101%;
	}
`;

const HeaderBar = styled.div`
	width: 100vw;
	position: fixed;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	padding: 25px;
	align-content: center;
	justify-content: space-between;
	z-index: 10;
	@media only screen and (min-device-width: 769px) {
		height: 100px;
		background: rgba(61, 60, 60, 0.2);
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(9.2px);
		-webkit-backdrop-filter: blur(9.2px);
	}
`;

const Button = styled.button`
	display: inline-block;
	font-family: Inter-ExtraBold;
	color: white;
	font-size: 1em;
	width: 100%;
	height: 100%;
	min-width: 140px;
	min-height: 40px; 
	padding: 10px 0px 10px 0px;
	border-radius: 8px;
	cursor: pointer;
	border: none;
	@media screen and (max-width: 768px) {
		margin-bottom: 20px;
		width: 100%;
	}
	@media screen and (min-width: 769px) and (max-width: 1024px) {
		width: 100%;
	}
	@media screen and (max-width: 1440px) and (min-width: 1025px) {
		width: 34%;
	}
	@media screen and (max-width: 2560px) and (min-width: 1441px) {
		width: 35%;
	}
`;

const SaleButton: React.CSSProperties = {
	fontFamily: "Inter-ExtraBold",
	backgroundColor:"#b81414",
	color: "white",
	textTransform: "capitalize",
	fontSize: "1em",
	borderRadius: "8px",
	boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
	width: "100%"
} 

const InfoButton = styled(Button)`
	background-color: #044de1;
`;

const ConsultPlansButton = styled(Button)`
	background-color: #e58417;
	/* margin-left: 17%; */

	@media screen and (max-width: 600px) {
		margin-left: 0;
	}
`;

const HeaderInfoContainer = styled.div`
	z-index: 1;
	align-self: center;
	@media screen and (max-width: 600px) {
		margin: 0px;
	}
`;

const TextContainer = styled.div`
	margin-bottom: 3rem;
	@media screen and (max-width: 600px) {
		margin-top: 5rem;
		margin-bottom: 2rem;
	}
	@media screen and (orientation: landscape) and (max-device-width: 1000px) {
		margin-bottom: 0;
	}
`;

const Title = styled.p`
	font-family: Inter-ExtraBold;
	color: #e58417;
	margin-bottom: 1rem;
	font-size: 3vw;
	@media screen and (max-width: 600px) {
		font-size: xx-large;
	}
	@media screen and (min-width: 601px) and (max-width: 1200px) {
		font-size: xxx-large;
	}
	@media screen and (max-width: 320px) {
		font-size: x-large;
	}
	@media screen and (max-width: 425px) and (min-width: 376px) {
		font-size: 2.5rem;
	}
	@media screen and (max-width: 768px) and (min-width: 426px) {
		font-size: 2.5rem;
	}
	@media screen and (max-width: 1024px) and (min-width: 769px) {
		font-size: 2.4rem;
	}
`;

const Text = styled.p`
	font-family: Inter-Bold;
	color: white;
	margin-top: 1rem;
	font-size: 2vw;
	line-height: 1.3;
	@media screen and (max-width: 600px) {
		font-size: large;
	}
	@media screen and (min-width: 601px) and (max-width: 1200px) {
		font-size: 1.8vw;
	}
	@media screen and (max-width: 320px) {
		font-size: 1rem;
	}
	@media screen and (max-width: 768px) and (min-width: 426px) {
		font-size: 1.5rem;
	}
	@media screen and (max-width: 1024px) and (min-width: 769px) {
	}
`;

export {
	HeaderContainer,
	Button,
	VideoBackground,
	HeaderBar,
	InfoButton,
	ButtonsContainer,
	TextContainer,
	ConsultPlansButton,
	Title,
	Text,
	HeaderInfoContainer,
	Woman,
	GuaraWolf,
	Chamaleon,
	FlashPlayLogo,
	Butterfly,
	LogoContainer,
	ImagesContainer,
	StoreButton,
	SaleButton,
	Container
};
