import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Header } from "../../components/Header";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import { ContainerGeral, Title } from "./style";
import * as Yup from "yup";
import { InputRefProps } from "../../components/Input";
import { useGlobal } from "../../hooks/global";
import SetorTypo from "../../components/Modals/SetorTypo";
import { FaUserAlt } from "react-icons/fa";
import { selectionInterface } from "../../interfaces/selectionInterface";
import { useToast } from "../../hooks/toast";
const Publicselecoesconsultores: React.FC = () => {
  const { user, setUser } = useAuth();
  const { push } = useHistory();
  const { addToast } = useToast();
  const [selectionId, setSelectionId] = useState<any>();
  const [selection, setSelection] = useState<any>();
  const [errorCodeAccess, setErrorCodeAccess] = useState<string | null>(null);
  const inputCodeAccessRef = useRef<InputRefProps>(null);
  const { languagePage } = useGlobal();
  const [dataPublic, setDataPublic] = useState<selectionInterface[]>([]);
  const [dataConsultant, setDataConsultant] = useState<selectionInterface[]>(
    []
  );
  const [openModalCodigo, setOpenModalCodigo] = useState<boolean>(false);
  const SenhaAlterada = useCallback((data) => {
    setOpenModalCodigo(false);
  }, []);
  const buscarSelecoes = async () => {
    try {
      const resuConsultant = await api.get(`/selections/consultant`);
      setDataConsultant(resuConsultant.data);
    } catch (err) { }
  };
  useEffect(() => {
    buscarSelecoes();
  }, []);

  const solicitarInscricao = useCallback(
    async (selectionId: any, selectionPublic: boolean) => {
      if (user.preference === "candidate") {
        addToast({
          type: "info",
          icon: "alert",
          title: "Você não pode se candidatar a vaga",
          description: "vaga para consultor",
        });
        return;
      }
      if (selectionPublic === true) {
        setTimeout(async () => {
          await api.post(`/common/subscribeInSelection/${selectionId}`);
        }, 200);
      }
    },
    [user.preference]
  );
  const solicitando = (id: any, publi: any) => {
    if (publi === false) {
      setSelectionId(id);
      setOpenModalCodigo(true);
      push("/candidate/buscarSelecoes");
    } else {
      solicitarInscricao(id, publi);
      push("/candidate/buscarSelecoes");
    }
    push("/");
  };
  return (
    <>
      <Header />
      <ContainerGeral>
        <SetorTypo
          open={openModalCodigo}
          selectionId={selectionId}
          onClose={() => setOpenModalCodigo(false)}
          onSave={SenhaAlterada}
        ></SetorTypo>

        {dataConsultant.length === 0 ? (
          <Title>Não existe Seleções para Consultores</Title>
        ) : (
          <Title style={{ marginTop: 10 }}>Seleções para Consultores</Title>
        )}

        <Container style={{ margin: "auto", marginTop: 40 }}>
          <Row style={{ justifyContent: "center", alignItems: "center" }}>
            {dataConsultant.map((e) => {
              return (
                <Col xs={4} className="card-geral">
                  <div style={{ display: "flex", color: "#fff", height: 100, margin: 10 }}>
                    <div
                      style={{
                        width: "50px",
                        height: 90,

                        margin: "10px",

                      }}
                    >
                      <FaUserAlt size={40}></FaUserAlt>
                    </div>
                    <div   className="texto-descr"
                    style={{
                      color: "#000",
                      width: "200px",
                      height: 100,
                      borderRadius: 10,
                      padding: 10,
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                    // style={{
                    //   margin: "10px", overflow: "hidden",
                    //   textOverflow: "ellipsis"
                    // }}
                    >{e.name}</div>
                  </div>

                  <div style={{ color: "#fff" }}>Descrição</div>
                  <div
                    className="texto-descr"
                    style={{
                      color: "#000",
                      width: "100%",
                      height: 130,
                      background: "#fff",
                      borderRadius: 10,
                      padding: 10,
                    }}
                  >
                    {e.description}
                  </div>

                  <div style={{ margin: 10, marginTop: "10px", }}>
                    {user.id ? (
                      <button
                        type="button"
                        onClick={() => solicitando(e.id, e.public)}
                      >
                        Solicitar Inscricão
                      </button>
                    ) : (
                      <button type="button" onClick={() => push("/logon")}>
                        Faça sua conta e inscreva-se
                      </button>
                    )}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </ContainerGeral>
    </>
  );
};

export default Publicselecoesconsultores;
