import { CircularProgress } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import {
  FaEye,
  FaFilePdf,
  FaRemoveFormat,
  FaTrashAlt,
  FaUser,
  FaUserAlt,
} from "react-icons/fa";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { MdNotInterested } from "react-icons/md";
import { useHistory, useParams } from "react-router";
import { useGlobal } from "../../../hooks/global";
import { requestInterface } from "../../../interfaces/requestInterface";
import { selectionInterface } from "../../../interfaces/selectionInterface";
import api from "../../../services/api";
import {
  BoxBackTitle,
  Setas,
  SetasTwo,
  Tabela,
  Container,
  SubContainer,
} from "./style";

export const CandidatosConsultores: React.FC = () => {
  const { push } = useHistory();
  const [atualPage, setAtualPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const { selectionId }: any = useParams();
  const [requests, setRequests] = useState<requestInterface[]>(
    [] as requestInterface[]
  );
  const [selection, setSelection] = useState<selectionInterface>(
    {} as selectionInterface
  );
  const [loading, setLoading] = useState(true);
  const [classifications, setClassifications] = useState<any[]>([]);
  const { setAtualPageGlobal, languagePage } = useGlobal();

  const searchRequests = useCallback(() => {
    setLoading(true);
    setTimeout(async () => {
      setNumberOfPages(0);
      const resu = await api.get(
        `/root/resquest/allCandidates/${selectionId}?limit=3&page=${atualPage}`
      );
      setSelection(resu.data.selection);
      setRequests(resu.data.rows);
      setNumberOfPages(Math.ceil(resu.data.count / 3));
      setLoading(false);
    }, 200);
  }, [atualPage]);

  useEffect(() => {
    setAtualPageGlobal("/root/consultores/:selectionId");
    searchRequests();
  }, [atualPage]);

  useEffect(() => {
    api
      .get(`/root/generateClassification/${selectionId}`)
      .then((res) => {
        const { classification } = res.data;
        const classificationOrdenate = classification.sort(function (
          a: any,
          b: any
        ) {
          if (a.position > b.position) {
            return 1;
          }
          if (a.position < b.position) {
            return -1;
          }
          return 0;
        });

        setClassifications(classificationOrdenate);
      })
      .catch((err) => console.log(err));
  }, []);

  const decline = useCallback(async (requestId) => {
    const resu = await api.post(`/root/request/decline/${requestId}`);
    searchRequests();
  }, []);

  const changePage = useCallback(
    (type) => {
      if (type === "proxima") {
        if (atualPage < numberOfPages) setAtualPage(atualPage + 1);
      } else if (atualPage !== 1) setAtualPage(atualPage - 1);
    },
    [atualPage, numberOfPages]
  );

  const handleApprovedCandidate = useCallback(async (id: string) => {
    await api.post(`/root/approved-candidate/${id}`, {
      approved: true,
    });
  }, []);
  return (
    <Container>
      <BoxBackTitle>
        <div className="voltar" onClick={() => push("/root/consultores")}>
          Voltar
        </div>
        <div className="nome">
          {languagePage === "PT"
            ? "Candidatos da Seleção"
            : "Selection Candidates"}{" "}
          "{selection.name}"
        </div>
      </BoxBackTitle>
      <SubContainer>
        <div className="conteudo">
          <Setas
            onClick={() => changePage("anterior")}
            style={{
              background: atualPage === 1 ? "#C4C4C4" : "#134480",
              cursor: atualPage === 1 ? "default" : "pointer",
            }}
          >
            <FiChevronLeft size={28} />
          </Setas>
        </div>
        {!loading && requests.length > 0 && (
          <div className="avatar-nome">
            {requests.map((request) => (
              <div className="candidados" key={request.id}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    color: "#FFF",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      background: "#FFF",
                      padding: 10,
                      borderRadius: 5,
                      marginRight: 10,
                    }}
                  >
                    {request.candidate.avatar && (
                      <img
                        style={{ height: 30 }}
                        src={request.candidate.avatar}
                      />
                    )}
                    {!request.candidate.avatar && (
                      <FaUser color="#134480" size={30} />
                    )}
                  </div>
                  {request.candidate.name}
                </div>
                <div
                  onClick={() =>
                    push(
                      `/root/consultores/perfilConsultor/${selectionId}/${request.candidate.id}`
                    )
                  }
                  className="pointer-flex-fff-10-center-row-5"
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    background: "#081118",
                  }}
                >
                  <FaUserAlt style={{ marginRight: 10 }} />
                  {languagePage === "PT" ? "Ver Candidato" : "See Candidate"}
                </div>
                {classifications.length !== 0 &&
                  classifications
                    .filter(
                      (item) =>
                        item.user.name === request.candidate.name &&
                        item.approved === true &&
                        item.approved_interview === true &&
                        !request.candidate.approved
                    )
                    .map((item) => (
                      <div
                        onClick={() =>
                          handleApprovedCandidate(request.candidate.id)
                        }
                        className="pointer-flex-fff-10-center-row-5"
                        style={{
                          marginBottom: 10,
                          background: "#081118",
                        }}
                      >
                        <FaUserAlt style={{ marginRight: 10 }} />
                        Aprovar consultor
                      </div>
                    ))}
                <div
                  onClick={() => decline(request.id)}
                  className="pointer-flex-fff-10-center-row-5"
                  style={{
                    background: "#ee3a3a",
                  }}
                >
                  <FaTrashAlt style={{ marginRight: 10 }} />
                  {languagePage === "PT" ? "Remover" : "Remove"}
                </div>
              </div>
            ))}
          </div>
        )}
        {!loading && requests.length === 0 && (
          <div
            style={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Gotham-Medium-Italic",
              opacity: 0.8,
              fontSize: 16,
            }}
          >
            Esta Seleção não possui nenhum candidato
          </div>
        )}
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <div style={{ display: "flex" }}>
          <Setas
            onClick={() => changePage("proxima")}
            style={{
              marginLeft: 10,
              background:
                atualPage === numberOfPages || numberOfPages === 0
                  ? "#C4C4C4"
                  : "#134480",
              cursor:
                atualPage === numberOfPages || numberOfPages === 0
                  ? "default"
                  : "pointer",
            }}
          >
            <FiChevronRight size={28} />
          </Setas>
        </div>
      </SubContainer>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <SetasTwo
          onClick={() => changePage("anterior")}
          style={{
            background: atualPage === 1 ? "#C4C4C4" : "#f27823",
            cursor: atualPage === 1 ? "default" : "pointer",
          }}
        >
          <FiChevronLeft size={28} />
        </SetasTwo>

        <SetasTwo
          onClick={() => changePage("proxima")}
          style={{
            background: atualPage >= numberOfPages ? "#C4C4C4" : "#f27823",
            cursor: atualPage >= numberOfPages ? "default" : "pointer",
          }}
        >
          <FiChevronRight size={28} />
        </SetasTwo>
      </div>
      <Tabela
        style={{
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
          width: "100%",
          minWidth: 200,
          maxWidth: 1000,
          overflow: "auto",
          margin: "auto",
          marginBottom: 30,
        }}
      >
        <table
          style={{
            width: "100%",
            maxWidth: 1000,
            minWidth: 500,
          }}
        >
          <thead style={{ backgroundColor: "#134480", color: "#fff" }}>
            <tr>
              <th
                style={{
                  borderTopLeftRadius: 10,
                  padding: 16,
                  fontFamily: "Gotham-Medium",
                  fontSize: 22,
                  textAlign: "center",
                }}
              >
                #
              </th>
              <th className="th">Nome</th>
              <th
                className="th"
                style={{
                  width: "30%",
                }}
              >
                MindSet
              </th>
              <th
                className="th"
                style={{
                  width: "100%",
                  maxWidth: 450,
                  minWidth: 100,
                }}
              >
                Grupo
              </th>
              <th
                className="th"
                style={{
                  width: "100%",
                  maxWidth: 250,
                  minWidth: 100,
                }}
              >
                Ap. Testes
              </th>
              <th
                className="th"
                style={{
                  width: "100%",
                  maxWidth: 250,
                  minWidth: 100,
                }}
              >
                Ap. Entrevista
              </th>
              <th
                className="th"
                style={{
                  width: "100%",
                  maxWidth: 250,
                  minWidth: 100,
                  borderTopRightRadius: 10,
                }}
              >
                Contrato
              </th>
            </tr>
          </thead>
          <tbody>
            {classifications.length !== 0 &&
              classifications.map((classificated, index) => {
                return (
                  <tr style={{ backgroundColor: "#081118" }}>
                    <th className="th-dinamico">{index + 1}</th>
                    <td className="th-dinamico">{classificated.user.name}</td>
                    <td className="th-dinamico">{classificated.score}</td>
                    <td className="th-dinamico">{classificated.group}</td>
                    <td className="th-dinamico">
                      {classificated.approved === true ? "Sim" : "Não"}
                    </td>
                    <td className="th-dinamico">
                      {classificated.approved_interview === true
                        ? "Sim"
                        : classificated.approved_interview === false
                        ? "Não"
                        : "---"}
                    </td>
                    <td className="th-dinamico">
                      {!!classificated.contract.uploaded &&
                      classificated.contract.uploaded === true ? (
                        <a
                          href={classificated.contract.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaFilePdf size={24} color="#fff" />
                        </a>
                      ) : (
                        <MdNotInterested size={24} color="#f2f2f2" />
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Tabela>
    </Container>
  );
};
