import React, { useCallback, useEffect, useRef, useState } from "react";
import { useGlobal } from "../../../hooks/global";
import api from "../../../services/api";
import { ContainerOptions, Span } from "./style";
import { lightFormat } from "date-fns/esm";
import PaginationCustom, {
  PaginationRefProps,
} from "./../../../components/PaginationCustom";
import { AiOutlineEye } from "react-icons/ai";
import { CircularProgress } from "@material-ui/core";
import { selectionInterface } from "../../../interfaces/selectionInterface";
import { useHistory } from "react-router";

export const Selecoes: React.FC = () => {
  const [selecoes, setSelecoes] = useState<selectionInterface[]>(
    [] as selectionInterface[]
  );
  const paginationRef = useRef<PaginationRefProps>(null);
  const [loadingSelections, setLoadingSelections] = useState(true);
  const [atualPage, setAtualPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const { push } = useHistory();

  const changePage = useCallback(
    (newPage) => {
      if (atualPage !== newPage) setAtualPage(newPage);
    },
    [atualPage]
  );

  const { setAtualPageGlobal, languagePage } = useGlobal();
  const [hover, setHover] = useState<number>(-1);

  const SearchSelections = useCallback(async () => {
    setLoadingSelections(true);
    setTimeout(async () => {
      const resu = await api.get(
        `/root/selections?limit=11&&page=${atualPage}`
      );
      const { rows, count } = resu.data;
      setSelecoes(rows);
      setNumberOfPages(Math.ceil(count / 11));
      setLoadingSelections(false);
    }, 200);
  }, [atualPage]);

  useEffect(() => {
    setAtualPageGlobal("/root/selecoes");
  }, []);

  useEffect(() => {
    SearchSelections();
  }, [atualPage]);

  const retornaDataCorreta = useCallback((dataErrada) => {
    if (!!dataErrada) {
      let dataCorreta = new Date(dataErrada);
      dataCorreta.setDate(dataCorreta.getDate());
      return lightFormat(dataCorreta, "dd/MM/yyyy");
    } else return "--/--/----";
  }, []);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "space-between",
        margin: 20,
        marginTop: 0,
        flexDirection: "column",
        marginBottom: 20,
      }}
    >
      {!loadingSelections && (
        <table
          style={{
            width: "100%",
            borderRadius: 5,
            borderCollapse: "collapse",
            textAlign: "center",
          }}
        >
          <thead>
            {languagePage === "PT" ? (
              <tr style={{ color: "#FFF" }}>
                <th
                  style={{
                    background: "#302c2c",
                    width: "23.33%",
                    borderTopLeftRadius: 10,
                    padding: 10,
                  }}
                >
                  Nome
                </th>
                <th style={{ background: "#302c2c", width: "23.33%" }}>
                  Responsável/Empresa
                </th>
                <th style={{ background: "#302c2c", width: "10%" }}>
                  Candidatos
                </th>
                <th style={{ background: "#302c2c", width: "10%" }}>
                  Solicitações
                </th>
                <th style={{ background: "#302c2c", width: "23.33%" }}>
                  Criada em
                </th>
                <th
                  style={{
                    background: "#302c2c",
                    width: "10%",
                    borderTopRightRadius: 10,
                  }}
                >
                  Ações
                </th>
              </tr>
            ) : (
              <tr style={{ color: "#FFF" }}>
                <th
                  style={{
                    background: "#302c2c",
                    width: "23.33%",
                    borderTopLeftRadius: 10,
                    padding: 10,
                  }}
                >
                  Name
                </th>
                <th style={{ background: "#302c2c", width: "23.33%" }}>
                  Responsible/Company
                </th>
                <th style={{ background: "#302c2c", width: "10%" }}>
                  Candidates
                </th>
                <th style={{ background: "#302c2c", width: "10%" }}>
                  Requests
                </th>
                <th style={{ background: "#302c2c", width: "23.33%" }}>
                  Created in
                </th>
                <th style={{ background: "#302c2c", width: "23.33%" }}>
                  Update in
                </th>
                <th
                  style={{
                    background: "#302c2c",
                    width: "10%",
                    borderTopRightRadius: 10,
                  }}
                >
                  Actions
                </th>
              </tr>
            )}
          </thead>
          <tbody style={{ background: "#FFF" }}>
            {selecoes.map((selecao, index) => (
              <tr key={selecao.id}>
                <td style={{ borderBottom: "1px solid #C4C4C4", padding: 10 }}>
                  {selecao.name}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {selecao.company.responsible}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {selecao.numOfCandidates}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {selecao.numOfSolicitacoes}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {retornaDataCorreta(selecao.createdAt)} 
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {retornaDataCorreta(selecao.updatedAt)}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  <ContainerOptions>
                    <AiOutlineEye
                      onMouseEnter={() => setHover(index)}
                      onMouseLeave={() => setHover(-1)}
                      onClick={() =>
                        push(`/root/selecao/${selecao.id}/candidatos`)
                      }
                      style={{
                        minWidth: "1.25rem",
                        minHeight: "1.25rem",
                        cursor: "pointer",
                      }}
                      color="#101A36"
                    />
                    {hover === index && <Span>Visualizar</Span>}
                  </ContainerOptions>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {loadingSelections && (
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          padding: 10,
        }}
      >
        <PaginationCustom
          ref={paginationRef}
          pages={numberOfPages}
          disable={loadingSelections}
          onChange={changePage}
        />
      </div>
    </div>
  );
};
