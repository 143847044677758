import React, { useCallback, useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { selectionInterface } from "../../../interfaces/selectionInterface";
import api from "../../../services/api";
import ModalNovoQuestionario from "../../../components/Modals/NovoQuestionario";
import { TiDelete } from "react-icons/ti";
import { MdEdit } from "react-icons/md";
import { tagInterface } from "../../../interfaces/tagInterface";
import ModalEditarQuestionario from "../../../components/Modals/EditarQuestionario";
import ModalNovaPergunta from "../../../components/Modals/NovaPergunta";
import { questionInterface } from "../../../interfaces/questionsInterface";
import ModalEditarPergunta from "../../../components/Modals/EditarPergunta";
import Switch from "@material-ui/core/Switch";
import { IoLogoWhatsapp } from "react-icons/io5";
import { useGlobal } from "../../../hooks/global";
import { CircularProgress } from "@material-ui/core";
import { useAuth } from "../../../hooks/auth";
import { FaUser } from "react-icons/fa";
import { useToast } from "../../../hooks/toast";
import { useHistory } from "react-router";
import ToggleButton from "./components/buttonToggle";
import {
  CardContainer,
  CardDescriptionQuiz,
  CardSelecoes,
  Container,
  DescriptionQuiz,
  Selections,
  SelectionsTitle,
  Setas,
  SetasTwo,
  TextAlignCenter,
} from "./style";

export const Questionarios: React.FC = () => {
  const { user } = useAuth();
  const [selections, setSelections] = useState<selectionInterface[]>(
    [] as selectionInterface[]
  );
  const [atualPage, setAtualPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [selectionMarc, setSelectionMarc] = useState<selectionInterface>(
    {} as selectionInterface
  );
  const [openModalNewPergunta, setOpenModalNewPergunta] = useState(false);
  const [openModalNewQuestionario, setOpenModalNewQuestionario] =
    useState(false);
  const [openModalEditarQuestionario, setOpenModalEditarQuestionario] =
    useState(false);
  const [openModalEditarPergunta, setOpenModalEditarPergunta] = useState(false);
  const [tags, setTags] = useState<tagInterface[]>([] as tagInterface[]);
  const [tagMarcEdit, setTagMarcEdit] = useState<tagInterface>(
    {} as tagInterface
  );
  const [tagMarc, setTagMarc] = useState<tagInterface>({} as tagInterface);
  const [questions, setQuestions] = useState<questionInterface[]>(
    [] as questionInterface[]
  );
  const [questionMarcEdit, setQuestionMarcEdit] = useState<questionInterface>(
    {} as questionInterface
  );
  const { setAtualPageGlobal, languagePage } = useGlobal();
  const [loading, setLoading] = useState<boolean>(true);

  const { addToast } = useToast();
  const { push } = useHistory();

  useEffect(() => {
    setAtualPageGlobal("/company/questionarios");
  }, []);

  const searchSelections = useCallback(async () => {
    try {
      setSelections([]);
      setNumberOfPages(1);
      const resu = await api.get(
        `/company/selections?limit=3&page=${atualPage}`
      );
      setSelections(resu.data.rows);
      setNumberOfPages(Math.ceil(resu.data.count / 3));
    } catch (err: any) {
      if (err.response.data.message === "PLAN_ERROR") {
        const typeError = err.response.data.path;
        if (typeError === "expiresIn") {
          if (user.preference !== "consultancy") {
            push("/company/planos");
            addToast(
              user.planName !== "NoPlan"
                ? languagePage === "PT"
                  ? {
                    title: "Seu plano expirou",
                    description: "Renove seu plano",
                    icon: "alert",
                    type: "error",
                  }
                  : {
                    title: "Your plan expired",
                    description: "Renew your plan",
                    icon: "alert",
                    type: "error",
                  }
                : languagePage === "PT"
                  ? {
                    title: "Você não possui uma plano",
                    description: "Faça um plano",
                    icon: "alert",
                    type: "error",
                  }
                  : {
                    title: "You don't have a plan",
                    description: "Make a plan",
                    icon: "alert",
                    type: "error",
                  }
            );
          }
        }
      }
    }
    setLoading(false);
  }, [atualPage]);

  const searchQuestions = useCallback(async (id) => {
    const resu = await api.get(`/company/questions/${id}`);
    setQuestions(resu.data);
  }, []);

  const searchTags = useCallback(
    async (id) => {
      const resu = await api.get(`/company/tags/${id}`);
      setTags(resu.data);
    },
    [selectionMarc]
  );

  useEffect(() => {
    searchQuestions(tagMarc.id);
  }, [tagMarc]);

  useEffect(() => {
    searchTags(selectionMarc.id);
  }, [selectionMarc]);

  useEffect(() => {
    const timer = setTimeout(searchSelections, 1000);
    return () => clearTimeout(timer);
  }, [atualPage]);

  const changePage = useCallback(
    (type) => {
      if (type === "proxima") {
        if (atualPage < numberOfPages) {
          setLoading(true);
          setAtualPage(atualPage + 1);
          setTagMarc({} as tagInterface);
          setSelectionMarc({} as selectionInterface);
        }
      } else if (atualPage !== 1) {
        setLoading(true);
        setAtualPage(atualPage - 1);
        setTagMarc({} as tagInterface);
        setSelectionMarc({} as selectionInterface);
      }
    },
    [atualPage, numberOfPages]
  );

  const onSaveNewQuestionario = useCallback(
    async ({ name }) => {
      await api.post("/company/tag", { name, selectionId: selectionMarc.id });
      searchTags(selectionMarc.id);
      setOpenModalNewQuestionario(false);
    },
    [selectionMarc]
  );

  const onSaveNewPergunta = useCallback(
    async (data) => {
      await api.post(`/company/question/${tagMarc.id}`, data);
      searchQuestions(tagMarc.id);
      setOpenModalNewPergunta(false);
    },
    [tagMarc]
  );

  const onCLickDeleteTag = useCallback(
    async (tagId) => {
      await api.delete(`/company/tags/${tagId}`);
      setTagMarc({} as tagInterface);
      searchTags(selectionMarc.id);
    },
    [selectionMarc]
  );

  const onCLickDeleteQuestion = useCallback(
    async (questionId) => {
      await api.delete(`/company/questions/${questionId}`);
      searchQuestions(tagMarc.id);
    },
    [tagMarc]
  );

  const onSaveEditQuestionario = useCallback(
    async ({ id, name }) => {
      await api.put(`/company/tags/${id}`, { name });
      searchTags(selectionMarc.id);
      setOpenModalEditarQuestionario(false);
    },
    [selectionMarc]
  );

  const onSaveEditPergunta = useCallback(
    async ({ id, ...data }) => {
      await api.put(`/company/questions/${id}`, data);
      searchQuestions(tagMarc.id);
      setOpenModalEditarPergunta(false);
    },
    [tagMarc]
  );

  const marcEditTag = useCallback((newMarctag) => {
    setTagMarcEdit(newMarctag);
    setOpenModalEditarQuestionario(true);
  }, []);

  const marcEditQuestion = useCallback((newMarcQuestion) => {
    setQuestionMarcEdit(newMarcQuestion);
    setOpenModalEditarPergunta(true);
  }, []);

  const marcSelection = useCallback((newMarcSelection) => {
    setSelectionMarc(newMarcSelection);
    setTagMarc({} as tagInterface);
  }, []);

  return (
    <Container>
      <TextAlignCenter>
        {languagePage === "PT" ? (
          <h1>A FlashplayHR disponibilizada 03 tipos de Quizzes</h1>
        ) : (
          <h1>FlashPlayHR offers 03 types of quizzes</h1>
        )}

        <DescriptionQuiz>
          <CardDescriptionQuiz>
            {languagePage === "PT" ? (
              <p>
                1. Avaliação do circular Softskill.
              </p>
            ) : (
              <p>
                1. Evaluation of circular Soft Skills.
              </p>
            )}
          </CardDescriptionQuiz>
          <CardDescriptionQuiz>
            {languagePage === "PT" ? (
              <p>
                2. Avaliação do circular Soft skills e Hard Skills.
              </p>
            ) : (
              <p>
                2. Assessment of circular integrated soft skills and hard skills.
              </p>
            )}
          </CardDescriptionQuiz>

          <CardDescriptionQuiz>
            {languagePage === "PT" ? (
              <p>
                3. O focal quiz trás a possibilidade de construção de
                conhecimento em diversas áreas.
              </p>
            ) : (
              <p>
                3. The focus questionnaire brings the possibility of building
                knowledge in different areas.
              </p>
            )}
          </CardDescriptionQuiz>
        </DescriptionQuiz>
      </TextAlignCenter>

      <Selections>
        <SelectionsTitle>
          {languagePage === "PT"
            ? "Clique na seleção para visualizar os questionários específicos"
            : "Click on selection to view Focal Quiz"}
        </SelectionsTitle>

        <CardSelecoes>
          <div>
            <Setas atualPage={atualPage} onClick={() => changePage("anterior")}>
              <FiChevronLeft />
            </Setas>
          </div>

          <CardContainer>
            {!loading &&
              selections.map((selection) => (
                <div
                  onClick={() =>
                    selection.id !== selectionMarc.id
                      ? marcSelection(selection)
                      : {}
                  }
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    border: `2px solid ${selection.id === selectionMarc.id ? "#081118" : "#134480"
                      }`,
                    minWidth: 200,

                    cursor: "pointer",
                    boxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                    WebkitBoxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                    flexDirection: "column",
                    margin: 20,
                    marginTop: 0,
                    background: "#134480",
                    padding: 20,
                    paddingBottom: 0,
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      color: "#FFF",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        maxHeight: 50,
                        maxWidth: 50,
                        minHeight: 50,
                        minWidth: 50,
                        marginRight: 10,
                        borderRadius: 5,
                        overflow: "hidden",
                      }}
                    >
                      {user.avatar && (
                        <img
                          style={{ height: 50 }}
                          src={user.avatar}
                          alt="Imagem"
                        />
                      )}
                      {!user.avatar && <FaUser size={50} />}
                    </div>
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        minWidth: 100,
                        maxWidth: 100,
                      }}
                    >
                      {selection.name}
                    </div>
                  </div>
                </div>
              ))}
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            )}
            {!loading && selections.length === 0 && (
              <div
                style={{
                  fontStyle: "italic",
                  color: "#DDDDDD",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {languagePage === "PT"
                  ? "Você não possui nenhuma seleção ainda"
                  : "You don't have any selection yet."}
              </div>
            )}
          </CardContainer>
          <div>
            <Setas
              atualPage={atualPage}
              onClick={() => changePage("proxima")}
              style={{
                padding: 5,
                background: atualPage >= numberOfPages ? "#C4C4C4" : "#f27823",
                borderRadius: "50%",
                color: "#FFF",
                justifyContent: "center",
                alignItems: "center",
                cursor: atualPage >= numberOfPages ? "default" : "pointer",
              }}
            >
              <FiChevronRight />
            </Setas>
          </div>
        </CardSelecoes>
        <div style={{ display: "flex" }}>
          <SetasTwo
            onClick={() => changePage("anterior")}
            style={{
              padding: 5,
              background: atualPage === 1 ? "#C4C4C4" : "#f27823",
              borderRadius: "50%",
              color: "#FFF",
              justifyContent: "center",
              alignItems: "center",
              cursor: atualPage === 1 ? "default" : "pointer",
            }}
          >
            <FiChevronLeft />
          </SetasTwo>

          <SetasTwo
            onClick={() => changePage("proxima")}
            style={{
              padding: 5,
              background: atualPage >= numberOfPages ? "#C4C4C4" : "#f27823",
              borderRadius: "50%",
              color: "#FFF",
              justifyContent: "center",
              alignItems: "center",
              cursor: atualPage >= numberOfPages ? "default" : "pointer",
            }}
          >
            <FiChevronRight />
          </SetasTwo>
        </div>
      </Selections>
      {selectionMarc.id !== undefined && (
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: 40 }}
        >
          {/* <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontSize: 26,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {languagePage === "PT"
              ? "Questionários Específicos da Seleção"
              : "Selection Focal Quiz"}{" "}
            "{selectionMarc.name}"
          </div> */}

          {tags.map((tag) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column", // Alinha os itens verticalmente
                marginTop: 20,
                alignItems: "center", // Centraliza os itens horizontalmente
                justifyContent: "center",
                width: "100%", // Garante que o contêiner ocupe toda a largura disponível
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  maxWidth: 600,
                  marginBottom: 10,
                }}
              >
                <ToggleButton
                  tag={tag}
                  tagMarc={tagMarc}
                  setTagMarc={setTagMarc}
                />
                <div
                  onClick={() => setTagMarc(tag)}
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    margin: "0 10px",
                    padding: 10,
                    border: `2px solid #05264e`,
                    cursor: "pointer",
                    boxShadow: "2px 2px 20px -4px rgba(0, 0, 0, 0.7)",
                    background: "#05264e",
                    borderRadius: 5,
                    color: "#fff",
                  }}
                >
                  {tag.name}
                </div>
                <div
                  onClick={() => marcEditTag(tag)}
                  style={{
                    margin: "0 5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    boxShadow: "2px 2px 20px -4px rgba(0, 0, 0, 0.7)",
                    background: "#009848",
                    padding: 12,
                    borderRadius: 5,
                    color: "#fff",
                  }}
                >
                  <MdEdit />
                </div>
                <div
                  onClick={() => onCLickDeleteTag(tag.id)}
                  style={{
                    margin: "0 5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    boxShadow: "2px 2px 20px -4px rgba(0, 0, 0, 0.7)",
                    background: "#EF4136",
                    padding: 12,
                    borderRadius: 5,
                    color: "#fff",
                  }}
                >
                  <TiDelete />
                </div>
              </div>
            </div>
          ))}

          {tags.length === 0 && (
            <div
              style={{
                display: "flex",
                padding: 14,
                paddingBottom: 0,
                justifyContent: "center",
                alignItems: "center",
                margin: 20,
                marginBottom: 10,
                fontFamily: "Gotham-Medium-Italic",
                opacity: 0.8,
              }}
            >
              {languagePage === "PT"
                ? "Esta Seleção não possui nenhum Questionário Específico"
                : "This Selection does not have any Specific Quiz"}
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              onClick={() => setOpenModalNewQuestionario(true)}
              style={{
                display: "flex",
                boxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                WebkitBoxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                background: "#009848",
                marginTop: 20,
                marginBottom: 20,
                padding: 10,
                borderRadius: 5,
                cursor: "pointer",
                color: "#fff",
              }}
            >
              {languagePage === "PT"
                ? "+ Adicionar Questionário Específico"
                : "+ Add Specific Quiz"}
            </div>
          </div>
        </div>
      )}
      {tagMarc.id !== undefined && (
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: 20, alignContent: "center" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontSize: 26,
            }}
          >
            {languagePage === "PT"
              ? "Perguntas do Questionário Específico"
              : "Questions of Specific Quiz"}{" "}
            "{tagMarc.name}"
          </div>
          {questions.map((question, index) => (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                marginTop: 20,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  whiteSpace: "nowrap",
                  marginRight: 10,
                  minWidth: 110,
                  maxWidth: 200,
                  margin: 1,
                  justifyContent: "center",
                  // background: "red"
                }}
              >
                {index + 1}º {languagePage === "PT" ? "Pergunta" : "Question"}
              </div>
              <div
                style={{
                  display: "flex",
                  marginRight: 5,
                  width: "20%",
                  cursor: "pointer",
                  boxShadow: "2px 2px 20px -4px rgba(0, 0, 0, 0.7)",
                  WebkitBoxShadow: "2px 2px 20px -4px rgba(0, 0, 0, 0.7)",
                  background: "#05264e",
                  padding: 10,
                  borderRadius: 5,
                  margin: 5,
                  color: "#fff",
                  maxWidth: 600,
                }}
              >
                {question.title}
              </div>
              <div
                onClick={() => marcEditQuestion(question)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 5,
                  marginLeft: 5,
                  marginRight: 5,
                  cursor: "pointer",
                  boxShadow: "2px 2px 20px -4px rgba(0, 0, 0, 0.7)",
                  WebkitBoxShadow: "2px 2px 20px -4px rgba(0, 0, 0, 0.7)",
                  background: "#009848",
                  padding: 10,
                  borderRadius: 5,
                  color: "#fff",
                }}
              >
                <MdEdit />
              </div>
              <div
                onClick={() => onCLickDeleteQuestion(question.id)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 5,
                  cursor: "pointer",
                  boxShadow: "2px 2px 20px -4px rgba(0, 0, 0, 0.7)",
                  WebkitBoxShadow: "2px 2px 20px -4px rgba(0, 0, 0, 0.7)",
                  background: "#EF4136",
                  padding: 10,
                  borderRadius: 5,
                  color: "#fff",
                }}
              >
                <TiDelete />
              </div>
            </div>
          ))}
          {questions.length === 0 && (
            <div
              style={{
                display: "flex",
                padding: 10,
                paddingBottom: 0,
                justifyContent: "center",
                alignItems: "center",
                margin: 20,
                marginBottom: 10,
                fontFamily: "Gotham-Medium-Italic",
                opacity: 0.8,
              }}
            >
              {languagePage === "PT"
                ? "Este Questionário não possui nenhuma Pergunta"
                : "This Quiz has got no questions"}
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 20,
            }}
          >
            <div
              onClick={() => setOpenModalNewPergunta(true)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                WebkitBoxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
                background: "#009848",
                marginTop: 20,
                padding: 10,
                borderRadius: 5,
                cursor: "pointer",
                color: "#fff",
              }}
            >
              {languagePage === "PT"
                ? "+ Adicionar Pergunta"
                : " + Add Question"}
            </div>
          </div>
        </div>
      )}

      {!loading && (
        <div
          style={{
            color: "#FFF",
            fontFamily: "Gotham-Medium-Italic",
            hyphens: "auto",
            justifyContent: "center",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            margin: 50,
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          {openModalNewPergunta && (
            <ModalNovaPergunta
              open={openModalNewPergunta}
              onClose={() => setOpenModalNewPergunta(false)}
              onSave={onSaveNewPergunta}
            />
          )}
          {openModalNewQuestionario && (
            <ModalNovoQuestionario
              open={openModalNewQuestionario}
              onClose={() => setOpenModalNewQuestionario(false)}
              onSave={onSaveNewQuestionario}
            />
          )}
          {openModalEditarQuestionario && (
            <ModalEditarQuestionario
              open={openModalEditarQuestionario}
              onClose={() => setOpenModalEditarQuestionario(false)}
              onSave={onSaveEditQuestionario}
              data={tagMarcEdit}
            />
          )}
          {openModalEditarPergunta && (
            <ModalEditarPergunta
              open={openModalEditarPergunta}
              onClose={() => setOpenModalEditarPergunta(false)}
              onSave={onSaveEditPergunta}
              data={questionMarcEdit}
            />
          )}

          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5571986956150"
            style={{
              width: "20%",
              minWidth: 200,
              margin: 10,
              display: "inline-block",
              textDecoration: "none",
              color: "#FFF",
              justifyContent: "center",
              textAlign: "center",
              cursor: "pointer",
              fontFamily: "Gotham-Medium",
              boxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
              WebkitBoxShadow: "2px 2px 20px -2px rgba(0, 0, 0, 0.7)",
              alignItems: "center",
              whiteSpace: "nowrap",
              padding: 10,
              borderRadius: 5,
              background: "#f27823",
              flexWrap: "wrap",
            }}
            rel="noreferrer"
          >
            <IoLogoWhatsapp
              style={{ marginRight: 10 }}
              color="#115E1C"
              size={20}
            />
            {languagePage === "PT" ? "Entrar em contato" : "Contact us"}
          </a>
        </div>
      )}
    </Container>
  );
};
