import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface GlobalContextData {
    openSelect: string;
    atualPageGlobal: string;
    preferencia: string;
    languagePage: string;
    tempoEncerrado: string;
    solicitacaoPrivada: boolean;
    paraCronometro: boolean;
    setPreference: (value: "candidate" | "consultant" | "both" | "selections" | "consultancy") => void;
    setLanguagePage(value: "PT" | "EN"): void;
    setAtualPageGlobal(value: string): void;
    setOpenSelect(value: string): void;
    setTempoEcerrado:(value: "false" | "true") => void;
    ClickOnApp(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
    setSolicitacaoPrivada(value: boolean) : void;
    setParaCronometro(value: boolean) : void;
}

const GlobalContext = createContext<GlobalContextData>({} as GlobalContextData)

export const GlobalProvider: React.FC = ({ children }) => {
    const [openSelect, setOpenSelect] = useState<string>('-1')
    const [solicitacaoPrivada, setSolicitacaoPrivada] = useState(false)
    const [paraCronometro, setParaCronometro] = useState(false)
    const [tempoEncerrado, setTempoEcerrado] = useState<"false" |"true">(() => {
        const tempoEncerrado = localStorage.getItem('@flashPlayHR:tempoEncerrado')
            if(tempoEncerrado === "true") {
                return "true"
            }
            return "false"
            
        }
    )
    const [preferencia, setPreference] = useState<"candidate" | "consultant" | "both" | "selections" | "consultancy">(() => {
        const preferencia = localStorage.getItem('@flashPlayHR:preferencia')
        if (preferencia === "candidate") return "candidate"
        else if (preferencia === "consultant") return "consultant"
        else if (preferencia === "both") return "both"
        else if (preferencia === "selections") return "selections"
        else if (preferencia === "consultancy") return "consultancy"
        return "candidate"
    })
    const setPref = useCallback((Preference: "candidate" | "consultant" | "both" | "selections" | "consultancy") => {
        setPreference(preferencia)
        localStorage.setItem('@flashPlayHR:language', preferencia)
    }, [])
    const [languagePage, setLanguagePageGlobal] = useState<"PT" | "EN">(() => {
        const language = localStorage.getItem('@flashPlayHR:language')
        if (language === "PT") return "PT"
        else if (language === "EN") return "EN"
        return "PT"
    })

    const setLanguagePage = useCallback((language: "PT" | "EN") => {
        setLanguagePageGlobal(language)
        localStorage.setItem('@flashPlayHR:language', language)
    }, [])

    const [atualPageGlobal, setAtualPageGlobal] = useState<string>("")

    const ClickOnApp = useCallback((event) => {
        const className = event?.target.getAttribute ? event.target.getAttribute('class') : ''
        if (className !== openSelect) setOpenSelect(className)
        else setOpenSelect("anull")
    }, [openSelect])

    return (
        <GlobalContext.Provider value={{ ClickOnApp, 
        openSelect, setOpenSelect, atualPageGlobal, 
        setAtualPageGlobal, languagePage, setLanguagePage, preferencia, setPreference,
        tempoEncerrado, setTempoEcerrado, solicitacaoPrivada, setSolicitacaoPrivada, paraCronometro, setParaCronometro }}>
            {children}
        </GlobalContext.Provider>
    )
}

export function useGlobal(): GlobalContextData {
    const context = useContext(GlobalContext)
    if (!context) {
        throw new Error('useGlobal must be used within an GlobalProvider')
    }
    return context;
}