import styled from "styled-components";

export const DisplayFlex = styled.div`
  display: flex;
`;
export const Container = styled(DisplayFlex)`
  flex-direction: column;
  font-family: "Gotham-Bold", sans-serif;
  margin: 20px;

  .button-type-selection {
    margin: 2px auto 16px;

    /* button {
      border: none;
      padding: 8px;
      transition: background 0.3s;
    }

    button:hover {
      background: #134480;
      color: #fff;
    }
    button:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
    button:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    } */
  }
`;

interface ButtonProps {
  publicSelection: boolean;
}

export const Button = styled.button<ButtonProps>`
  border: none;
  padding: 8px;
  transition: all 0.3s;
  ${(props) => (props.publicSelection === true ? "background: #134480" : null)};
  ${(props) => (props.publicSelection === true ? "color: #fff" : null)};

  &:hover {
    background: #134480;
    opacity: 0.9;
    color: #fff;
  }

  &:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
  &:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const SubContainer = styled(DisplayFlex)`
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
`;
export const ContainerLoading = styled(DisplayFlex)`
  outline: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const Setas = styled(DisplayFlex)`
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  justify-content: center;
  align-items: center;

  @media (max-width: 433px) {
    display: none;
  }
`;

export const SubContainerLoading = styled(DisplayFlex)`
  flex-wrap: wrap;
  width: 100%;
  min-height: 310px;
  justify-content: center;
`;

export const CardsConteudos = styled(DisplayFlex)`
  justify-content: space-between;
  //box-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 1);
  box-shadow: 10px 10px 70px -25px rgba(79, 79, 79, 0.75);
  -webkit-box-shadow: 10px 10px 70px -25px rgba(79, 79, 79, 0.75);
  -moz-box-shadow: 10px 10px 70px -25px rgba(79, 79, 79, 0.75);
  flex-direction: column;
  margin: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 2.5px;
  width: 100%;
  min-width: 250px;
  max-width: 400px;
`;

export const CardsConteudosNameAvatar = styled(DisplayFlex)`
  flex-direction: row;
  color: #1a2e3d;
  align-items: center;
`;

export const CardsConteudosAvatar = styled(DisplayFlex)`
  justify-content: center;
  align-items: center;
  max-height: 50px;
  max-width: 50px;
  background: #134480;
  padding: 10px;
  min-height: 50px;
  min-width: 50px;
  margin-right: 10px;
  border-radius: 5px;
  overflow: hidden;
`;
export const CardsConteudosName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const CardDescription = styled(DisplayFlex)`
  flex-direction: column;
  font-family: "Gotham-Medium", sans-serif;
  margin-top: 20px;
  margin-bottom: 20px;

  .title-descr {
    color: #1a2e3d;
    font-family: "Gotham-Bold", sans-serif;
  }

  .texto-descr {
    color: #1a2e3d;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-y: auto;
    padding: 12px;
    border-radius: 5px;
    background: #f2f2f2;
    font-family: "Gotham-Medium", sans-serif;
    font-size: 14px;
  }
`;
export const AgurdandoApro = styled(DisplayFlex)`
  justify-content: center;
  color: #fff;
  padding: 10px;
  flex-direction: row;
  border-radius: 2.5px;
  background: #1a2e3d;
`;
export const IrParaSelecao = styled(DisplayFlex)`
  cursor: pointer;
  justify-content: center;
  color: #fff;
  padding: 10px;
  flex-direction: row;
  border-radius: 2.5px;
  background: #1a2e3d;
`;

export const SolicitaInsc = styled(DisplayFlex)`
  cursor: pointer;
  justify-content: center;
  color: #fff;
  padding: 10px;
  flex-direction: row;
  border-radius: 2.5px;
  background: #f08134;
`;
export const RecuSolicitaInsc = styled(DisplayFlex)`
  cursor: none;
  justify-content: center;
  color: #fff;
  padding: 10px;
  flex-direction: row;
  border-radius: 2.5px;
  background: #ee3a3a;
`;

export const NenhumaSolicitao = styled(DisplayFlex)`
  font-style: italic;
  color: #134480;
  align-items: center;
`;
export const SetasTwo = styled.div`
  display: none;
  width: 50px;
  height: 50px;
  padding: 5px;
  margin: 10px 10px 20px;
  color: #134480;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  @media (max-width: 433px) {
    display: flex;
  }
`;

export const ContainerTexto = styled(DisplayFlex)`
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 960px;
  min-width: 240px;
  flex-wrap: wrap;

  .nosso_diferencial {
    display: flex;
    font-size: 26px;
    background: #134480;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    min-width: 200px;
    color: #fff;
  }
  .p_nosso_diferencial {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 500px;
    min-width: 260px;

    .texto_nosso_diferencial {
      display: flex;
      width: 100%;
      max-width: 500px;
      min-width: 260px;
      color: #64666b;
      font-size: 18px;
    }
    .img_nosso_diferencial {
      display: flex;
      height: 200px;
      max-width: 400px;
    }
  }
`;

export const Pagination = styled(DisplayFlex)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 32px 0 64px;
`;

interface PaginationButtonProps {
  active: boolean;
}
export const PaginationButton = styled.button<PaginationButtonProps>`
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  margin: 0 4px;
  background: ${(props) => (props.active ? "#134480" : "#6086b5")};
  color: #fff;
  font-family: "Gotham-Bold", sans-serif;
`;
