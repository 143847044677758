import React, { useEffect } from "react";
import { HashRouter, withRouter } from "react-router-dom";
import ContextProvider from "./context";
import { Container } from "./globalStyle";
import { useGlobal } from "./hooks/global";
import { PrincipalRoutes } from "./routes/principalRoutes";
import ReactGA from 'react-ga';

ReactGA.ga(process.env.REACT_GOOGLE_API_ANALITIC)
const App: React.FC = () => {
  const { ClickOnApp } = useGlobal();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])
  return (
    <ContextProvider>
      <Container onClick={ClickOnApp} className="react-ga">
        <HashRouter>
          <PrincipalRoutes />
        </HashRouter>
      </Container>
    </ContextProvider>

  );
};

export default App;
