import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useGlobal } from "../../hooks/global";
import { HTMLAttributes, useCallback, useEffect, CSSProperties } from "react";
import { Icon, ContainerOptions, OptionLabel } from "./style";
import { FaUsers, FaBuilding, FaTransgenderAlt } from "react-icons/fa";

interface SelectFlyProps extends HTMLAttributes<HTMLDivElement> {
  options: string[];
  defaultOptions?: string;
  onChangeOption: Function;
  posOption: number;
  styleContainer?: CSSProperties;
  styleSelect?: CSSProperties;
  disable?: boolean;
  label: string;
  labelIndicator?: string;
}

export interface RefSelectFlyProps {
  getPos: () => Number;
}

const customStyleSelect: CSSProperties = {
  position: "relative",
  boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,0.7)",
  cursor: "pointer",
  background: "#FFF",
  display: "flex",
  alignItems: "center",
  paddingLeft: "0.625rem",
  paddingRight: "0.625rem",
  borderRadius: "0.3125rem",
};

const customStyleContainer: CSSProperties = {
  position: "relative",
};

const SelectFly: React.ForwardRefRenderFunction<
  RefSelectFlyProps,
  SelectFlyProps
> = (
  {
    labelIndicator,
    defaultOptions,
    className,
    disable = false,
    label,
    options,
    posOption,
    onChangeOption,
    styleSelect = {},
    styleContainer = {},
  },
  ref
) => {
  const { openSelect, setOpenSelect } = useGlobal();
  const [optionsValue, setOptionsValue] = useState(
    defaultOptions || options[posOption]
  );
  const ChangeClassImage = useCallback(() => {
    if (className) {
      document
        .getElementsByClassName(className)[0]
        .getElementsByTagName("path")[0]
        .setAttribute("class", className);
      document
        .getElementsByClassName(className)[0]
        .getElementsByTagName("svg")[0]
        .setAttribute("class", className);
    }
  }, []);

  const getPos = useCallback(() => {
    return posOption;
  }, [posOption]);

  useImperativeHandle(ref, () => {
    return {
      getPos,
    };
  });

  useEffect(() => ChangeClassImage(), []);

  const ClickSelect = useCallback(() => {
    ChangeClassImage();
    if (openSelect === className) setOpenSelect("-1");
  }, [openSelect]);

  const ClickOption = useCallback(
    (index) => {
      if (index !== posOption) onChangeOption(index);
    },
    [posOption]
  );

  return (
    <div
      className={className}
      style={{ ...customStyleContainer, ...styleContainer }}
    >
      <div
        className={className}
        onClick={() => (disable ? {} : ClickSelect())}
        style={{
          ...customStyleSelect,
          color: disable ? "#A89E9F" : "",
          cursor: disable ? "default" : "pointer",
          ...styleSelect,
        }}
      >
        {label === "rangeFunc" && <FaUsers />}
        {label === "companySize" && <FaBuilding />}
        {label === "gênero" && <FaTransgenderAlt />}
        <div
          className={className}
          style={{
            width: "100%",
            marginLeft:
              label !== "rangeFunc" &&
              label !== "companySize" &&
              label !== "gênero"
                ? 0
                : 10,
          }}
        >
          {optionsValue || options[posOption]}
        </div>
        <Icon
          open={openSelect === className && !disable}
          className={className}
          size={"1.25rem"}
        />
      </div>

      {openSelect === className && !disable && (
        <ContainerOptions>
          {options.map((option, index) => (
            <OptionLabel
              key={index}
              select={index === posOption}
              onClick={() => {
                setOptionsValue(option);
                ClickOption(index);
              }}
            >
              {option}
            </OptionLabel>
          ))}
        </ContainerOptions>
      )}
      {!!labelIndicator && (
        <div
          style={{
            position: "absolute",
            top: -10,
            left: 10,
            background: "#FFF",
            fontSize: "0.75rem",
            color: "#757575",
            padding: 5,
          }}
        >
          {labelIndicator}
        </div>
      )}
    </div>
  );
};

export default forwardRef(SelectFly);
