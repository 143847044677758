import React from "react";
import { useGlobal } from "../../hooks/global";

import {
  GeneratedProductsContainer,
  Image,
  ImageContainer,
  SectionTitleContainer,
  SectionTitle,
  ProductListContainerProduct,
  ProductListContainerPaginaInicial,
  PlanOffer,
  LogOnButton,
  Root,
  Section,
  Products,
  ContainerButtons,
  Title,
  LearnMoreButton,
  Link,
  ContainerProducts,
  Subtitle,
  Paragraph,
  QuestionsButton,
  ContainerQuestionsButton,
  ComboButton,
  BookImage,
  ButtonClick,
  GreenButton
} from "./styles";
import { Typography, Grid } from '@mui/material';
import { IoLogoWhatsapp } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import capa from "../../assets/products/capa.jpeg"
import reciclarLixoEletronico from "../../assets/products/reciclar-lixo-eletronico.jpg"


const GeneratedProducts: React.FC = () => {
  const { setLanguagePage, languagePage } = useGlobal();
  const { push } = useHistory();

  return (
    <>
      <Root>

        <GeneratedProductsContainer id="generatedProduct" className="generatedProduct">
          {window.matchMedia("(min-width:769px)").matches && (
            <ImageContainer id="generatedProductsImage">
              <SectionTitleContainer>
                <SectionTitle>
                  {languagePage === "PT"
                    ? "Aprenda a Mapear o perfil do seu time  e stakeholders com o método de Mindset de  linear ao circular"
                    : "Learn how to map the profile of your team and stakeholders with the Mindset method from linear to circular"}
                </SectionTitle>
              </SectionTitleContainer>
              <Image
                src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1702313847/Assets-FlashPlayHr/tdg1qvumkra5pki3uvlg.jpg"
                alt="Para que serve o método?"

              />
            </ImageContainer>
          )}

          <PlanOffer>
            <SectionTitle>
              {languagePage === "PT"
                ? "Artigo publicado em uma Revista Internacional sobre Circular Mindset e Circular Skills"
                : "Article published in an International Magazine about Circular Mindset and Circular Skills"}
            </SectionTitle>
            <Typography
              style={Subtitle}>{languagePage === "PT"
                ? "O resultado de dois pilotos realizados em uma Indústria de Recuperação de Produtos Eletrônicos e Consumidores"
                : "The result of two pilots carried out in an Electronic and Consumer Product Recovery Industry"}</Typography>
            <Typography
              style={Paragraph}
            >Com apenas 10 perguntas você consegue acessar o Artigo Completo</Typography>

            <ProductListContainerProduct >

              <img src={reciclarLixoEletronico} alt="reciclagem-lixo-eletronico-imagem" width={"50%"} />

             

                <GreenButton ><Link
                  href="https://forms.gle/tJUdrLGfD3Y7mH8g6"
                  target="_blank"
                  style={{width:"100%",height:"100%"}}
                >{languagePage === "PT"
                  ? "Clique aqui"
                  : "Click here"}
                </Link></GreenButton>
             
            </ProductListContainerProduct>

          </PlanOffer>


        </GeneratedProductsContainer>

        <Section id="section">

          <ProductListContainerPaginaInicial id="generatedProductsList">
            <SectionTitle >
              {languagePage === "PT"
                ? "Conheça nossos produtos"
                : "Discover our products"}
            </SectionTitle>

            <Grid item id="container" xs={12} style={Products}>

              <Grid item style={ContainerProducts} >
                <BookImage src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1702313848/Assets-FlashPlayHr/rd9mt7srxz7zbdnjcigd.png" alt="livro-saberes-circulares" />
                <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "2px" }}>
                  <Grid>
                    <Typography
                      style={Title}>{languagePage === "PT"
                        ? "Saberes Circulares"
                        : "Circular Knowledge"}</Typography>

                    <Typography
                      style={Paragraph}
                    >E-book</Typography>
                  </Grid>


                  <LogOnButton onClick={() => push("/saberesCirculares")}>{languagePage === "PT"
                    ? "Acesse o Livro"
                    : "Access the Book"}</LogOnButton>

                </Grid>
              </Grid>

              <Grid item style={ContainerProducts} >
                <BookImage  src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1702313849/Assets-FlashPlayHr/lc4pgdnmzftgs0ynasjn.png" alt="livro-mindset" />
                <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "2px" }}>
                  <Grid>
                    <Typography
                      style={Title}>Mindset </Typography>
                    <Typography
                      style={Subtitle}>{languagePage === "PT"
                        ? "O Pensamento Sistêmico e a Economia Circular"
                        : "Systems Thinking and the Circular Economy"}</Typography>

                    <Typography
                      style={Paragraph}
                    >E-book</Typography>
                  </Grid>


                  <LogOnButton onClick={() => push("/mindset")}>{languagePage === "PT"
                    ? "Acesse o Livro"
                    : "Access the Book"}</LogOnButton>

                </Grid>
              </Grid>

              <Grid item style={ContainerProducts} >
                <BookImage src="https://res.cloudinary.com/hkkzuevm3/image/upload/v1702313848/Assets-FlashPlayHr/jm0zhdkgf60ncfcrrvtj.png" alt="livro-lixo-vivo" />
                <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "2px" }}>
                  <Grid>
                    <Typography
                      style={Title}>{languagePage === "PT"
                        ? "A Revolução do Lixo Vivo"
                        : "The Living Waste Revolution"}</Typography>
                    <Typography
                      style={Subtitle}>{languagePage === "PT"
                        ? "Economia Circular para Crianças"
                        : "Circular Economy for Children"}</Typography>

                    <Typography
                      style={Paragraph}
                    >E-book</Typography>
                  </Grid>


                  <LogOnButton onClick={() => push("/revolucaoDoLixoVivo")}>{languagePage === "PT"
                    ? "Acesse o Livro"
                    : "Access the Book"}</LogOnButton>

                </Grid>
              </Grid>

              <Grid item style={ContainerQuestionsButton}>

                <LogOnButton style={QuestionsButton}>
                  <Link
                    href="https://api.whatsapp.com/send?phone=5571986956150"
                   target="_blank"
                  >
                    {languagePage === "PT"
                      ? "Dúvidas"
                      : "Questions"} {" "}
                    <IoLogoWhatsapp color="white" size={20} />
                  </Link>
                </LogOnButton>
              </Grid>
            </Grid>

          </ProductListContainerPaginaInicial>
          <LogOnButton onClick={() => push("/bookCombo")} style={ComboButton}>
            {languagePage === "PT"
              ? "Mais Promoções"
              : "More Promotions"}
          </LogOnButton>
        </Section>


      </Root>

      <img src={capa} alt="eco-recitec-imagem" width={"100%"} />

    </>
  );
};

export default GeneratedProducts;
