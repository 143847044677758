import React from 'react';
import { Modal, Backdrop, Fade, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  modalHeader: {
    marginBottom: theme.spacing(2),
  },
  modalFooter: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

const DecisionModal = ({ isOpen, toggle, onConfirm, languagePage, message }) => {
  const classes = useStyles();
  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <Typography variant="h6" className={classes.modalHeader}>
            Confirmação
          </Typography>
          <Typography variant="body1">{message}</Typography>
          <div className={classes.modalFooter}>
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirm}
              className={classes.button}
            >
              {languagePage === "PT" ? "Confirmar" : "Confirm"}
            </Button>
            <Button
              variant="contained"
              onClick={toggle}
              className={classes.button}
            >
              {languagePage === "PT" ? "Cancelar" : "Cancel"}
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default DecisionModal;
