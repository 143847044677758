import styled, { css } from "styled-components";

interface ContainerPortProps {
  isSelected: boolean;
}

export const ContainerPortCompany = styled.div<ContainerPortProps>`
  display: flex;
  flex-direction: row;
  box-hadow: 4px 4px 20px -2px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 4px 4px 20px -2px rgba(0, 0, 0, 0.7);
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  background: #0e294b;
  border: 4px solid #134480;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  margin: 10px;
  ${(props) =>
    props.isSelected &&
    css`
      border: 4px solid #0b243b;
    `}

  .flex-h40-w40{
    display: flex;
    height: 40px;
    width: 40px; 
  }
`;

export const TypeCompany = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  font-size: 20px;

`
