import styled from "styled-components";


export const Container = styled.div`
        margin-top: 60px;
        display: flex;
        cursor: default;
        width: 100%;
        height: 100%;
        flex-direction: column;
        font-family: Gotham-Bold;
        justify-content: center;
        align-items: center;
        color: #FFF;
        font-size: 26px;
        padding: 10px;
        text-align:center;
` 
export const Titulo = styled.div`
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        color: black !important;
`

export const ContainerButton = styled.div`
        display: flex; 
        flex-direction: row; 
        margin-top: 20px;
`



export const BotoesForm = styled.div`
        display: flex;
        margin:auto;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-shadow: 4px 4px 18px 0px rgba(0,0,0,0.5);
        -webkit-box-shadow: 4px 4px 18px 0px rgba(0,0,0,0.5);
        background: #047A3F;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;

`

