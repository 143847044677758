import React, { useCallback, useState } from "react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { useForm } from "../../../hooks/form";
import {
  DeleteContentBackward,
  IsNumber,
} from "../../../services/inputServices";
import { MaskPhone } from "../../../services/maskServices";
import Input from "../../Input";
import { useGlobal } from "../../../hooks/global";
import { BotoesForm, ButtonNextBack, Container, Titulo } from "../style";
import PhoneInput from "react-phone-input-2";

interface TelefoneCompanyData {
  onNext: Function;
  onBack: Function;
}

const TelefoneCompany: React.FC<TelefoneCompanyData> = ({ onNext, onBack }) => {
  const { telefone, setTelefone } = useForm();

  const [phoneNumber, setPhoneNumber] = useState<string>(telefone);
  const [phoneString, setPhoneString] = useState<string>(MaskPhone(telefone));

  const OnChangeInputCNPJ = useCallback(
    (event) => {
      const char = event.nativeEvent.data;
      const eventType = event.nativeEvent.inputType;
      if (
        eventType === "insertText" &&
        phoneNumber.length < 11 &&
        IsNumber(char)
      ) {
        setTelefone(phoneNumber + char);
        setPhoneNumber(phoneNumber + char);
        setPhoneString(MaskPhone(phoneNumber + char));
      } else if (eventType === "deleteContentBackward" && phoneNumber) {
        setTelefone(DeleteContentBackward(phoneNumber));
        setPhoneNumber(DeleteContentBackward(phoneNumber));
        setPhoneString(MaskPhone(DeleteContentBackward(phoneNumber)));
      }
    },
    [phoneNumber, setPhoneNumber, setPhoneString]
  );

  const onClickNext = useCallback(() => {
    if (telefone.length >= 11) onNext();
  }, [telefone]);
  const { setAtualPageGlobal, languagePage } = useGlobal();

  return (
    <Container
      style={{
        textAlign: "center",
      }}
    >
      <div>
        <Titulo>
          {languagePage === "PT"
            ? "Seu telefone é super importante para nosso contato mais próximo."
            : "Your Company’s phone number is very important for our closest contact."}
        </Titulo>
        {/* <Input
            styleInput={{
              fontFamily: "Gotham-Bold",
              color: "#FFF",
              background: "#0b243b",
              fontSize: 16,
            }}
            styleContainer={{ background: "#0b243b" }}
            placeholder="Digite seu Telefone"
            label="Digite seu Telefone"
            onChange={OnChangeInputCNPJ}
            value={phoneString}
          /> */}
        <PhoneInput
          value={phoneString}
          onChange={(phone) => {
            setTelefone(phone);
            setPhoneString(phone);
          }}
          country="br"
          onlyCountries={["br", "us", "ca", "pt"]}
          inputStyle={{
            width: "100%",
            //maxWidth: 500,
            height: 50,
            backgroundColor: "#0b243b",
            color: "#FFF",
            border: "none",
            fontFamily: "Gotham-Bold",
            fontSize: 16,
          }}
          buttonStyle={{
            backgroundColor: "#0b243b",
            border: "none",
            color: "#fff",
          }}
          dropdownStyle={{
            backgroundColor: "#0b243b",
            color: "#FFF",
            fontFamily: "Gotham-Bold",
          }}
        />
        <ButtonNextBack>
          <BotoesForm onClick={() => onBack()}>
            <GoChevronLeft />
            {languagePage === "PT" ? "Anterior" : "Back"}
          </BotoesForm>
          <BotoesForm
            onClick={onClickNext}
            style={{
              cursor: telefone.length >= 11 ? "pointer" : "not-allowed",
            }}
          >
            {languagePage === "PT" ? " Próximo" : "Next"}
            <GoChevronRight style={{ marginLeft: 5 }} />
          </BotoesForm>
        </ButtonNextBack>
      </div>
    </Container>
  );
};

export default TelefoneCompany;
