import React, { useEffect, useState } from "react";
import { ErrorProps } from "../../../services/schemaServices";
import {
  Container,
  ButtonEnd,
  Formulario,
  ContainerItemForm,
  ContainerBottom,
} from "./style";

import { useGlobal } from "../../../hooks/global";
import api from "../../../services/api";
import InputLabel from "../../../components/InputLabel";
import { useHistory } from "react-router-dom";

export const EditarSelecao: React.FC = () => {
  const [errors, setErrors] = useState<ErrorProps[]>([] as ErrorProps[]);
  // const inputCurrentPassword = useRef<InputRefProps>(null)
  const { push, location } = useHistory<any>();
  const { selection } = location.state || [];
  const { languagePage } = useGlobal();

  const Id = () => {
    try {
      return selection.id;
    } catch (e) {}
  };
  const Nome = () => {
    try {
      return selection.name;
    } catch (e) {}
  };
  const Descricao = () => {
    try {
      return selection.description;
    } catch (e) {}
  };

  const [nome, setNome] = useState<string>(Nome());
  const [descricao, setDescricao] = useState<string>(Descricao());

  useEffect(() => {
    if (location.state === undefined) {
      push("/company/selecoes");
    }
  }, []);

  const SalvarEdicao = async () => {
    try {
      await api.put(`/company/selection/${Id()}`, {
        name: nome,
        description: descricao,
      });
      push("/company/selecoes");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Formulario>
        <ContainerItemForm>
          <InputLabel
            errors={errors}
            type="nometypo"
            label={languagePage === "PT" ? "Nome da seleção" : "Selection name"}
            attr="newnometypo"
            id="inputnometypo"
            // ref={nome}
            onChange={(e) => setNome(e.target.value)}
            value={nome}
            containerStyle={{ width: "100%" }}
            inputProps={{ style: { fontFamily: "math", fontWeight: "bold" } }}
          />
        </ContainerItemForm>
        <ContainerItemForm>
          <InputLabel
            errors={errors}
            type="nometypo"
            label={languagePage === "PT" ? "Digite seu nome" : "Type your name"}
            attr="newnometypo"
            id="inputnometypo"
            // ref={""}
            value={descricao}
            onChange={(e) => {
              setDescricao(e.target.value);
            }}
            containerStyle={{ width: "100%" }}
            inputProps={{ style: { fontFamily: "math", fontWeight: "bold" } }}
          />
        </ContainerItemForm>

        <ContainerItemForm style={{ fontStyle: "italic", color: "#333333" }}>
          {languagePage === "PT"
            ? "Observação: Preencha os campos!"
            : "Note: Fill in the fields!"}
        </ContainerItemForm>
      </Formulario>

      <ContainerBottom>
        <ButtonEnd
          onClick={() => {
            push("/company/selecoes");
          }}
          style={{ color: "#252323" }}
        >
          {" "}
          {languagePage === "PT" ? "Cancelar" : "Cancel"}{" "}
        </ButtonEnd>
        <ButtonEnd
          onClick={() => {
            SalvarEdicao();
          }}
          style={{ color: "#101A36", cursor: "pointer" }}
        >
          {" "}
          {languagePage === "PT" ? "Salvar edição" : "Save edit"}
        </ButtonEnd>
      </ContainerBottom>
    </Container>
  );
};
