import styled from "styled-components";

export const Conteiner = styled.div`
  margin: auto;
  color: #fff;
  font-family: "Gotham-Medium";
  max-width: 800px;
  min-width: 200px;
  width: 100%;

  .cards {
    border-radius: 20px;
    margin: 20px;
    background: #134480;
  }

  .buttons {
    color: #fff;
    background: #1c993f;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }

  .scrollEstilo {
    scrollbar-width: thin;
    scrollbar-color: #00213d #061522;
    padding: 10px;
    height: 60vh;
    margin-left: 10px;

    ::-webkit-scrollbar {
      width: 12px;
      border-radius: 20px;
    }

    ::-webkit-scrollbar-track {
      background: #061522;
      border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #00213d;
      border-radius: 20px;
      border: 3px solid #061522;
    }
  }
`;

export const Titulo = styled.div`
  font-family: "Gotham-Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 32px;

  color: #134480;
`;

interface ButtonProps {
  background: string;
  backgroundHover: string;
}
export const Button = styled.button<ButtonProps>`
  cursor: pointer;

  padding: 10px 16px;
  border-radius: 5px;
  border: none;
  margin-right: 8px;

  font-family: "Gotham-Medium", sans-serif;
  font-size: 14px;
  background: ${(props) => props.background};

  color: #fff;
  transition: 0.5s;

  span {
    visibility: hidden;
  }

  :hover {
    background: ${(props) => props.backgroundHover};
    span {
      visibility: visible;
    }
  }
`;

export const Conteudo = styled.div`
  margin: 10px auto;
  max-width: 1000px;
  min-width: 200px;
  width: 100%;
`;

export const BoxButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  textarea {
    margin-right: 16px;
  }
  textarea::placeholder {
    font-size: 15px;
    font-family: "Gotham-Light";
  }
`;

interface StatusProps {
  state: boolean | null;
}
export const Status = styled.div<StatusProps>`
  padding: 4px 8px;
  border-radius: 2.5px;

  background: ${(props) => (props.state === true ? "#DB2016" : "none")};
  p {
    font-family: "Gotham-Bold", sans-serif;
    color: #fff;
    font-size: 12px;
    margin: auto;
  }
`;

export const FooterText = styled.h6`
  font-family: "Gotham-Bold", sans-serif;
  font-size: 16px;
  color: #4e4e4e;
`;
