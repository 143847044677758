import styled, { css, keyframes } from "styled-components";

const RotateAnimation = keyframes`  
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;
interface BolinhaData {
  isActive: boolean;
}

export const DisplayFlex = styled.div`
  display: flex;
`
export const DisplayFlexDirectionColumn = styled(DisplayFlex)`
  flex-direction: column;
`
export const DisplayJustfyCenter = styled(DisplayFlex)`

    justify-content: center;

`
export const DisplayFlexAlignItemsCenter = styled(DisplayFlex)`
    align-items: center;
`
export const Container = styled.div`
    display: block;
    margin: 20px;
    margin-top: 0px;
    font-family: "Gotham-Medium";
`
export const BoxBackTitle = styled(DisplayJustfyCenter)`
  flex-wrap: wrap;
  height: calc(10vh - 30px);
  position: relative;
  align-items: center;
  width: 100%;
  color: #6b6b6b;
  font-size: 3.5vh;
  margin: 10px;
  

  @media (max-width: 640px) {
    margin-top: 10px;
    font-size: 1.6vh;

    div {
      top: -15px;
    }
  }
`;

export const BotaoVoltar = styled(DisplayFlexAlignItemsCenter)`
  font-size: 2.15vh;
  color: #6b6b6b;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  min-width: 70px;
  border-radius: 50px;
  border: 1px solid #6b6b6b;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 8px;
  position: absolute;
  left: 0;
  transform: translateY(-50%);

  @media (max-width: 768px) {
    font-size: 1.6vh;
    max-width: 120px;
    padding: 6px;
  }

  @media (max-width: 480px) {
    font-size: 1.3vh;
    max-width: 100px;
    padding: 4px;
  }
`;

export const HardSkillTitulo = styled.div`
  width: 100%;
  max-width: 700px;
  min-width: 200px;
  white-space: nowrap;
  overflow: hidden;              /* "overflow" value must be different from "visible" */

  text-overflow:    ellipsis;
`

export const ContainerGenerico = styled(DisplayFlexDirectionColumn)`
  background: #Ccc;
  margin: 80px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-top: 0px;
  box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.3);

  .box-logo {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 20px;
  }

  .box-image {
    display: flex;
    height: 40vh;
    justify-content: center;
  }
  .box-text {
    color: #133770;
    font-family: "Gotham-Bold", sans-serif;
    width: 100%;
    font-size: 2.5vh;
    max-width: 400px;
    min-width: 100px;
    padding: 10px;
    text-align: center;
  }

  .box-fire {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px;
  }

  .left-button {
    display: flex;
    background: blue;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius:50px;
  }

  .left-button-back {
    
    display: none;
  }

  @media (max-width: 640px) {
    margin: 10px;
    .box-logo {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px auto;
    }

    .box-fire {
      margin: 20px 0 10px;
    }

    .box-text {
      font-size: 1.5vh;
      max-width: 270px;
    }

    .box-image {
      height: 35vh;
    }

    .left-button {
      display: none;
      background: blue;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius:50px;
      
    }

    .left-button-back {
      display: flex;
      background: blue;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius:50px;
     
    }
  }
`;
export const LogoAndLegenda = styled(DisplayFlexDirectionColumn)`
  color: #134480;
  align-items: center;
  font-size: 2.5vh;
`
export const Logo = styled(DisplayFlex)`

  height: 9vh;
  width: 9vh;

`

export const TextoExameHard = styled(DisplayFlexDirectionColumn)`
 
  font-size: 2.5vh;
  color: #136EAC;
`

export const Instrucoes = styled(DisplayFlexAlignItemsCenter)`
  
  position: relative;
  width: 100%;
  height: 100%;
`
export const Conteudo = styled(DisplayJustfyCenter)`
  
  position: relative;
  width: 100%;
  flex-direction: row;
  align-items: center;
`
export const ImagemAdnTexto = styled(DisplayFlexDirectionColumn)`
  justify-content: center;
  align-items: center;
`
export const BotaoNext = styled(DisplayFlex)`
  position: absolute;
  right: 0px;
  
`
export const Questão = styled(DisplayFlexAlignItemsCenter)`
    display:block;
  flex-direction: row;
  justify-content: space-between;
  color: #FFF;

`
export const Enuciado = styled(DisplayJustfyCenter)`
  color: #134480;
  font-family: "Gotham-Bold";
  font-size: 2.5vh;
  margin-bottom: 1vh;
`

export const BoxTextButton = styled(DisplayFlexAlignItemsCenter)`
  
  flex-direction: row;
  margin: 1vh;
  margin-left: 2vh;
  margin-right: 2vh;
  color: #133770;
  font-family: "Gotham-Medium", sans-serif;

  @media (max-width: 640px) {
    margin-left: 0vh;
    margin-right: 0vh;
    font-size: 1.6vh;
  }
`;

export const Alternativas = styled(DisplayJustfyCenter)`
  cursor: pointer;
  box-shadow: 0.125rem 0.125rem 0.5rem rgba(0,0,0,0.7);
  align-items: center;
  border-radius: 5px;
  color: #FFF;
  min-width: 30px;
  min-height: 30px;
  margin-right: 10px;


`
export const Bola = styled(DisplayJustfyCenter)`

  margin: 20;
  flex-direction: row;
  width: 100%;

`
export const Bolinha = styled.div<BolinhaData>`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #5f5f5f;
  transition: all 0.3s;
  ${(props) =>
    props.isActive &&
    css`
      background: #f15115;
    `}
`;

export const Setas = styled.div`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 1);
    color: #fff;
    border-radius: 50%;
    font-size: 2.1vh;
    cursor: pointer;
`
