import React, { useCallback, useEffect, useRef, useState } from "react";
import { useGlobal } from "../../../hooks/global";
import { UserProps } from "../../../interfaces/userInterface";
import api from "../../../services/api";
import { ContainerOptions, Span } from "./style";
import { lightFormat } from "date-fns/esm";
import PaginationCustom, {
  PaginationRefProps,
} from "./../../../components/PaginationCustom";
import { CircularProgress } from "@material-ui/core";
import { MaskPhone } from "../../../services/maskServices";
import { MdEdit, MdRemove } from "react-icons/md";
import NovaSenhaRoot from "../../../components/Modals/NovaSenhaRoot";
import CancelContaPlano from "../../../components/Modals/CancelContaPlano";

export const Usuarios: React.FC = () => {
  const [companies, setCompanies] = useState<UserProps[]>([] as UserProps[]);
  const [openMenuFly, setOpenMenuFly] = useState<number>(-1);
  const AnchorsRef = useRef<HTMLDivElement[]>([] as HTMLDivElement[]);
  const paginationRef = useRef<PaginationRefProps>(null);
  const [pages, setPages] = useState<number>(5);
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [atualPage, setAtualPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [openModalUser, setOpenModalUser] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [empresaMarc, setEmpresaMarc] = useState<UserProps>({} as UserProps);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const changePage = useCallback(
    (newPage) => {
      if (atualPage !== newPage) setAtualPage(newPage);
    },
    [atualPage]
  );

  const { setAtualPageGlobal } = useGlobal();
  const [hover, setHover] = useState<number>(-1);

  const SearchCompanies = useCallback(async () => {
    setLoadingCompanies(true);
    setTimeout(async () => {
      const resu = await api.get(`/root/users?limit=11&&page=${atualPage}`);
      setCompanies(resu.data.rows);
      setNumberOfPages(Math.ceil(resu.data.count / 11));
      setLoadingCompanies(false);
    }, 200);
  }, [atualPage]);

  useEffect(() => {
    setAtualPageGlobal("/root/usuarios");
  }, []);

  useEffect(() => {
    SearchCompanies();
  }, [atualPage]);

  const Click = useCallback((data) => {
    setEmpresaMarc(data);
    setOpenModal(true);
  }, []);

  const ClickRemove = useCallback((userId) => {
    setUserId(userId);
    setOpenModalUser(true);
  }, []);

  const editPassword = useCallback(async (data) => {
    setOpenModal(false);
    await api.post("/root/editPassword", data);
  }, []);

  const removeUser = useCallback(async (userId) => {
    await api.delete(`/root/remove/${userId}`);
    setCompanies(companies.filter((company) => company.id !== userId));
    setOpenModalUser(false);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "space-between",
        margin: 20,
        marginTop: 0,
        flexDirection: "column",
        marginBottom: 20,
      }}
    >
      {!loadingCompanies && (
        <table
          style={{
            width: "100%",
            borderRadius: 5,
            borderCollapse: "collapse",
            textAlign: "center",
          }}
        >
          <thead>
            <tr style={{ color: "#FFF" }}>
              <th
                style={{
                  background: "#302c2c",
                  width: "18%",
                  borderTopLeftRadius: 10,
                  padding: 10,
                }}
              >
                Nome
              </th>
              <th style={{ background: "#302c2c", width: "10%" }}>Tipo</th>
              <th style={{ background: "#302c2c", width: "18%" }}>Email</th>
              <th style={{ background: "#302c2c", width: "18%" }}>Telefone</th>
              <th style={{ background: "#302c2c", width: "18%" }}>Criado em</th>
              <th
                style={{
                  background: "#302c2c",
                  width: "10%",
                  borderTopRightRadius: 10,
                }}
              >
                Ações
              </th>
            </tr>
          </thead>
          <tbody style={{ background: "#FFF" }}>
            {companies.map((company, index) => (
              <tr key={company.id}>
                <td style={{ borderBottom: "1px solid #C4C4C4", padding: 10 }}>
                  {company.name}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {company.type === "company" ? "Empresa" : "Candidato"}
                </td>
               
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {company.email}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {MaskPhone(company.phone)}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {lightFormat(new Date(company.createdAt), "dd/MM/yyyy")}
                </td>
                <td
                  style={{
                    borderBottom: "1px solid #C4C4C4",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 50,
                      margin: "auto",
                    }}
                  >
                    <ContainerOptions
                      ref={(ref) =>
                        ref ? (AnchorsRef.current[index] = ref) : null
                      }
                    >
                      <MdEdit
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(-1)}
                        onClick={() => Click(company)}
                        style={{
                          minWidth: "1.25rem",
                          minHeight: "1.25rem",
                          cursor: "pointer",
                        }}
                        color="#101A36"
                      />
                      {hover === index && <Span>Alterar senha</Span>}
                    </ContainerOptions>
                    <ContainerOptions
                      ref={(ref) =>
                        ref ? (AnchorsRef.current[index] = ref) : null
                      }
                    >
                      <MdRemove
                        onMouseEnter={() => setHover(index + 1)}
                        onMouseLeave={() => setHover(-1)}
                        onClick={() => ClickRemove(company.id)}
                        style={{
                          minWidth: "1.25rem",
                          minHeight: "1.25rem",
                          cursor: "pointer",
                        }}
                        color="#101A36"
                      />
                      {hover === index + 1 && <Span>Excluir usuário</Span>}
                    </ContainerOptions>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {loadingCompanies && (
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          padding: 10,
        }}
      >
        <PaginationCustom
          ref={paginationRef}
          pages={numberOfPages}
          disable={loadingCompanies}
          onChange={changePage}
        />
      </div>
      {openModal && (
        <NovaSenhaRoot
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSave={editPassword}
          data={empresaMarc}
        />
      )}
      {openModalUser && (
        <CancelContaPlano
          open={openModalUser}
          onClose={() => setOpenModalUser(false)}
          onExclude={() => removeUser(userId)}
          model="account"
        />
      )}
    </div>
  );
};
