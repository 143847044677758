import React, { useCallback, useEffect, useRef, useState } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import MenuFly from "../../../components/MenuFly";
import { useGlobal } from "../../../hooks/global";
import { UserProps } from "../../../interfaces/userInterface";
import api from "../../../services/api";
import { ContainerOptions, Span } from "./style";
import { lightFormat } from "date-fns/esm";
import PaginationCustom, {
  PaginationRefProps,
} from "./../../../components/PaginationCustom";
import ModalEmpresa from "../../../components/Modals/VerEmpresa";
import { AiOutlineEye } from "react-icons/ai";
import { CircularProgress } from "@material-ui/core";

export const Empresas: React.FC = () => {
  const [companies, setCompanies] = useState<UserProps[]>([] as UserProps[]);
  const [openMenuFly, setOpenMenuFly] = useState<number>(-1);
  const AnchorsRef = useRef<HTMLDivElement[]>([] as HTMLDivElement[]);
  const paginationRef = useRef<PaginationRefProps>(null);
  const [pages, setPages] = useState<number>(5);
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [atualPage, setAtualPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [empresaMarc, setEmpresaMarc] = useState<UserProps>({} as UserProps);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const changePage = useCallback(
    (newPage) => {
      if (atualPage !== newPage) setAtualPage(newPage);
    },
    [atualPage]
  );

  const { setAtualPageGlobal } = useGlobal();
  const [hover, setHover] = useState<number>(-1);

  const SearchCompanies = useCallback(async () => {
    setLoadingCompanies(true);
    setTimeout(async () => {
      const resu = await api.get(`/root/companies?limit=11&&page=${atualPage}`);
      setCompanies(resu.data.rows);
      setNumberOfPages(Math.ceil(resu.data.count / 11));
      setLoadingCompanies(false);
    }, 200);
  }, [atualPage]);

  useEffect(() => {
    setAtualPageGlobal("/root/empresas");
  }, []);

  useEffect(() => {
    SearchCompanies();
  }, [atualPage]);

  const Click = useCallback((data) => {
    setEmpresaMarc(data);
    setOpenModal(true);
  }, []);

  const editCompany = useCallback(
    async (data) => {
      setLoadingCompanies(true);
      setOpenModal(false);
      const resu = await api.put(`/root/editCompany/${data.id}`, data);
      SearchCompanies();
    },
    [atualPage]
  );

  const retornaDataCorreta = useCallback((dataErrada) => {
    if (!!dataErrada) {
      let dataCorreta = new Date(dataErrada);
      dataCorreta.setDate(dataCorreta.getDate());
      return lightFormat(dataCorreta, "dd/MM/yyyy");
    } else return "--/--/----";
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "space-between",
        margin: 20,
        marginTop: 0,
        flexDirection: "column",
        marginBottom: 20,
      }}
    >
      {!loadingCompanies && (
        <table
          style={{
            width: "100%",
            borderRadius: 5,
            borderCollapse: "collapse",
            textAlign: "center",
          }}
        >
          <thead>
            <tr style={{ color: "#FFF" }}>
              <th
                style={{
                  background: "#302c2c",
                  width: "18%",
                  borderTopLeftRadius: 10,
                  padding: 10,
                }}
              >
                Empresa
              </th>
              <th style={{ background: "#302c2c", width: "15%" }}>Vagas</th>
              <th style={{ background: "#302c2c", width: "15%" }}>
                Candidatos
              </th>
              <th style={{ background: "#302c2c", width: "15%" }}>Plano</th>
              <th style={{ background: "#302c2c", width: "15%" }}>Criado em</th>
              <th style={{ background: "#302c2c", width: "15%" }}>Atualizado em</th>
              <th style={{ background: "#302c2c", width: "15%" }}>Expira em</th>
              <th style={{ background: "#302c2c", width: "15%" }}>Status</th>
              <th
                style={{
                  background: "#302c2c",
                  width: "10%",
                  borderTopRightRadius: 10,
                }}
              >
                Ações
              </th>
            </tr>
          </thead>
          <tbody style={{ background: "#FFF" }}>
            {companies.map((company, index) => (
              <tr key={company.id}>
                <td style={{ borderBottom: "1px solid #C4C4C4", padding: 10 }}>
                  {company.name}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {company.numOfSelection}/
                  {company.vacancyLimit ? company.vacancyLimit : 0}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {company.numOfCandidates}/
                  {company.candidateLimit ? company.candidateLimit : 0}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {!!company.planName ? company.planName : "Nenhum"}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {retornaDataCorreta(company.createdAt)}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {retornaDataCorreta(company.updatedAt)}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {retornaDataCorreta(company.expiresIn)}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  {company.statusPlan}
                </td>
                <td style={{ borderBottom: "1px solid #C4C4C4" }}>
                  <ContainerOptions
                    ref={(ref) =>
                      ref ? (AnchorsRef.current[index] = ref) : null
                    }
                  >
                    <AiOutlineEye
                      onMouseEnter={() => setHover(index)}
                      onMouseLeave={() => setHover(-1)}
                      onClick={() => Click(company)}
                      style={{
                        minWidth: "1.25rem",
                        minHeight: "1.25rem",
                        cursor: "pointer",
                      }}
                      color="#101A36"
                    />
                    {hover === index && <Span>Visualizar</Span>}
                  </ContainerOptions>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {loadingCompanies && (
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          padding: 10,
          color: "white",
        }}
      >
        <PaginationCustom
          ref={paginationRef}
          pages={numberOfPages}
          disable={loadingCompanies}
          onChange={changePage}
        />
      </div>
      {openModal && (
        <ModalEmpresa
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSave={editCompany}
          data={empresaMarc}
        />
      )}
    </div>
  );
};
