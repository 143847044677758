import { useTransition } from "@react-spring/core";
import { animated } from "@react-spring/web";
import React, { useCallback } from "react";
import { Switch, useHistory, useLocation, Redirect } from "react-router";
import { Header } from "../components/Header";
import { useGlobal } from "../hooks/global";
import { Aba, ContainerAbas } from "./style";
import { Route } from "./route";
import { Empresas } from "../pages/DashboardRoot/Empresas";
import { Consultores } from "../pages/DashboardRoot/Consultores";
import { Selecoes } from "../pages/DashboardRoot/Selecoes";
import { SelecaoEspecifica } from "../pages/DashboardRoot/SelecaoEspecifica";
import { CriarSelecao } from "../pages/DashboardRoot/CriarSelecao";
import { RootSolicitacoes } from "../pages/DashboardRoot/Solicitacoes";
import { RootPerfilCandidatos } from "../pages/DashboardRoot/RootPerfilCandidatos";
import { CandidatosConsultores } from "../pages/DashboardRoot/CandidatosConsultores";
import { RootPerfilCandidato } from "../pages/DashboardRoot/RootPerfilCandidato";
import { FinanceiroPropostas } from "../pages/DashboardRoot/FinanceiroPropostas";
import { Usuarios } from "../pages/DashboardRoot/Usuarios";
import { PropostasRoot } from "../pages/DashboardRoot/PropostasRoot";
import { ListaConsultoresRoot } from "../pages/DashboardRoot/ListaConsultoresRoot";

const DashboardRootRoutes: React.FC = () => {
  const location = useLocation();

  const transitions = useTransition(location, {
    from: { opacity: 0, config: { duration: 0 } },
    enter: { opacity: 1, config: { duration: 400 } },
    leave: { opacity: 0, config: { duration: 0 } },
  });

  const { atualPageGlobal } = useGlobal();

  const { push } = useHistory();

  const clickOnAba = useCallback((newAba) => {
    push(newAba);
  }, []);

  return (
    <div
      id="navBarDash"
      style={{
        display: "flex",
        minWidth: 1220,
        height: "100%",
        background: "#010648",
        overflowY: "scroll",
        flexDirection: "column",
      }}
    >
      <div style={{ marginBottom: 100 }}>
        <Header />
      </div>
      <ContainerAbas>
        <Aba
          isAtual={atualPageGlobal === "/root/empresas"}
          onClick={() => clickOnAba("/root/empresas")}
        >
          Empresas
        </Aba>
        <Aba
          isAtual={atualPageGlobal === "/root/selecoes"}
          onClick={() => clickOnAba("/root/selecoes")}
        >
          Seleções
        </Aba>
        <Aba
          isAtual={atualPageGlobal === "/root/consultores"}
          onClick={() => clickOnAba("/root/consultores")}
        >
          Seleção Consultores
        </Aba>
        <Aba
          isAtual={atualPageGlobal === "/root/criar-selecao"}
          onClick={() => clickOnAba("/root/criar-selecao")}
        >
          Criar Seleção para Consultores
        </Aba>
        <Aba
          isAtual={atualPageGlobal === "/root/financeiro"}
          onClick={() => clickOnAba("/root/financeiro")}
        >
          Financeiro
        </Aba>
        <Aba
          isAtual={atualPageGlobal === "/root/propostas"}
          onClick={() => clickOnAba("/root/propostas")}
        >
          Propostas
        </Aba>
        <Aba
          isAtual={atualPageGlobal === "/root/usuarios"}
          onClick={() => clickOnAba("/root/usuarios")}
        >
          Usuários
        </Aba>
      </ContainerAbas>
      <div
        style={{
          display: "flex",
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {transitions((props, item) => (
          <animated.div
            style={{
              ...props,
              position: "absolute",
              width: "100%",
              height: "100%",
              flexDirection: "row",
            }}
          >
            <Switch location={item}>
              <Route
                typeAccess="root"
                exact
                path="/root/empresas"
                component={Empresas}
              />
              <Route
                typeAccess="root"
                exact
                path="/root/selecoes"
                component={Selecoes}
              />
              <Route
                typeAccess="root"
                exact
                path="/root/usuarios"
                component={Usuarios}
              />
              <Route
                typeAccess="root"
                exact
                path="/root/consultores"
                component={Consultores}
              />
              <Route
                typeAccess="root"
                exact
                path="/root/criar-selecao"
                component={CriarSelecao}
              />
              <Route
                typeAccess="root"
                exact
                path="/root/selecao/:selectionId/candidatos"
                component={SelecaoEspecifica}
              />
              <Route
                typeAccess="root"
                exact
                path="/root/solicitacoes/:selectionId"
                component={RootSolicitacoes}
              />
              <Route
                typeAccess="root"
                exact
                path="/root/solicitacoes/perfilConsultor/:selectionId/:requestId"
                component={RootPerfilCandidatos}
              />
              <Route
                typeAccess="root"
                exact
                path="/root/consultores/:selectionId"
                component={CandidatosConsultores}
              />
              <Route
                typeAccess="root"
                exact
                path="/root/consultores/perfilConsultor/:selectionId/:userId"
                component={RootPerfilCandidato}
              />
              <Route
                typeAccess="root"
                exact
                path="/root/financeiro"
                component={FinanceiroPropostas}
              />
              <Route
                typeAccess="root"
                exact
                path="/root/consultoria/:proposalId"
                component={ListaConsultoresRoot}
              />
              <Route
                typeAccess="root"
                exact
                path="/root/propostas"
                component={PropostasRoot}
              />
              <Redirect
                to={{ pathname: "/login", state: { from: location } }}
              />
              ;
            </Switch>
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default DashboardRootRoutes;
