import { url } from "inspector";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Header } from "../../components/Header/index";
import { useForm } from "../../hooks/form";
import { useGlobal } from "../../hooks/global";
import "./css/bootstrap.min.css";
import { Container } from "./style";

import Celene from "../../assets/fonts/provaSocial/ImagemCelene.jpeg";

export const Educacao: React.FC = () => {
  const { push } = useHistory();
  const { setType } = useForm();

  const { setAtualPageGlobal, languagePage } = useGlobal();
  const onClick = useCallback(() => {
    push("logon");
    setType("");
  }, []);

  useEffect(() => {
    setAtualPageGlobal("home");
  }, []);

  return (
    <div>
      <div style={{ marginTop: -20 }}>
        <Header />
      </div><br /><br /><br /><br />
      <Container>


        <main style={{}}>
          <section className="py-5 text-center container">
            <div className="row py-lg-5">
              <div className="col-lg-6 col-md-8 mx-auto text-white">
                <h1
                  style={{
                    fontFamily: "Gotham-Bold",
                    color: "#f15115",
                    marginTop: "-10%",
                  }}
                  className="fw-light efeito-text"
                >
                  {languagePage === "PT" ? "EAD Modelos Circulares" : "Circular Models DL"}
                </h1>
              </div>
            </div>
          </section>

          <div className="album py-5 ">
            <div className="container">
              
              <div
                className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3"
                style={{ marginTop: -8 }}
              >
                <div className="col" style={{ marginBottom: 10 }}>
                  <a className="card shadow-sm bg_card" style={{ height: 650 }}>
                    <iframe
                      src="https://www.youtube.com/embed/ixWXYDP3VN4"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{
                        width: "90%",
                        minWidth: "250px",
                        borderRadius: 20,
                        height: "300px",
                        margin: "auto",
                        marginTop: 10,
                      }}
                      className="card-img-top"
                    ></iframe>

                   
                    <div
                      className="card-body "
                      style={{ textAlign: "center", margin: "auto" }}
                    >
                      <h2 className="efeito-text jLink">
                      {languagePage === "PT" ? "Evento Internacional A Round Table" : "A Round Table International Event"}
                      </h2>
                      <p className="card-text jDescricao" style={{
                        height: 300,
                        overflowY: "scroll"
                      }}>
                        {languagePage === "PT" ? "O evento internacional foi realizado no mês junho, organizado pela CEC e a Startup EcoRecitec. Contou com a presença de e Celene Brito CEC City Salvador e da Chistiane Coordenadora CEC mundial. O movimento foi puxado na Europa, mas se tornou mais um estímulo para as pessoas repensarem as suas vidas e seus negócios. Em Salvador foi muito exitoso, pois foi realizada uma parceria com CEC City Almada de Portugal. Vale a pena conferir o vídeo." : "The international event was held in June, organized by CEC and the EcoRecitec startup. It was attended by Celene Brito CEC City Salvador and Chistiane Coordinator CEC worldwide. The movement was pulled in Europe, but it became more of a stimulus for people to rethink their lives and their businesses. In Salvador it was very successful, as a partnership was made with CEC City Almada de Portugal. The video is worth checking out."}
                      </p>
                    </div>
                  </a>
                </div>
                <div className="col">
                  <div className="col" style={{ marginBottom: 10 }}>
                    <a style={{ height: 650 }} className="card shadow-sm bg_card">
                      {/* <img src={"https://res.cloudinary.com/hkkzuevm3/image/upload/v1639517325/noticias/jogo-removebg-preview_uebufm.png"}
                                            style={{ width: "300px", textAlign: "center", margin: "auto" }} className="card-img-top" /> */}

                      <iframe
                        src="https://www.youtube.com/embed/82gumdJgmiE"
                        title="YouTube video player"
                        frameBorder="0"
                        style={{
                          width: "90%",
                          minWidth: "250px",
                          borderRadius: 20,
                          height: "300px",
                          margin: "auto",
                          marginTop: 10,
                        }}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                      <div
                        className="card-body "
                        style={{ textAlign: "center", margin: "auto" }}
                      >
                        <h2 className="efeito-text jLink">
                        {languagePage === "PT" ? "Jornada de Psicologia UCES 2020 Parte 3" : "Psychology journey UCES 2020 Part 3"}
                        </h2>
                        <p className="card-text jDescricao">
                        {languagePage === "PT" ? "Participação de Celene Brito na Jornada de Psicologia UCES 2020 Parte 3. Buenos Aires, Argentina" : "Participation of Celene Brito in the UCES Psychology Journey 2020 Part 3. Buenos Aires, Argentina"}
                        </p>
                        <div className="d-flex justify-content-between "></div>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="col">
                  <div className="col">
                    <div className="col" style={{ marginBottom: 10 }}>
                      <a
                        style={{ height: 650 }}
                        className="card shadow-sm bg_card"
                      >
                        <iframe
                          src="https://www.youtube.com/embed/_vdd-_fSNHg"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          style={{
                            width: "90%",
                            minWidth: "250px",
                            borderRadius: 20,
                            height: "300px",
                            margin: "auto",
                            marginTop: 10,
                          }}
                        ></iframe>

                        <div
                          className="card-body "
                          style={{ textAlign: "center", margin: "auto" }}
                        >
                          <h2 className="efeito-text jLink">
                          {languagePage === "PT" ? "Mindset e oportunidades de negocios circulares" : "Mindset and circular business opportunities"}
                          </h2>
                          <p className="card-text jDescricao">
                          {languagePage === "PT" ? "Palestra de Celene Brito sobre mindset e oportunidades de negocios circulares. Evento realizado pelo clube mundial de economia circular no ano de 2020" : "Lecture by Celene Brito on mindset and circular business opportunities. Event held by the world club of circular economy in the year of 2020"}
                          </p>
                          <div className="d-flex justify-content-between "></div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 "
                style={{ textAlign: "center", marginTop: -8 }}
              >
                <div className="col-md-6">
                  <div
                    className="col"
                    style={{ margin: "auto", marginBottom: 10 }}
                  >
                    <a
                      style={{ height: 500, textAlign: "center" }}
                      className="card shadow-sm bg_card center"
                    >
                      <iframe
                        src="https://www.youtube.com/embed/nB05Us6__24"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                          width: "90%",
                          minWidth: "250px",
                          borderRadius: 20,
                          height: "300px",
                          margin: "auto",
                          marginTop: 10,
                        }}
                      ></iframe>

                      <div
                        className="card-body "
                        style={{ textAlign: "center", margin: "auto" }}
                      >
                        <h2 className="efeito-text jLink">
                          {languagePage === "PT" ? "Apresentação Mintegra" : "Mintegra presentation"}
                        </h2>
                        <p className="card-text jDescricao">
                          {languagePage === "PT" ? "Celene Brito CEO da EcoRecitec e o parceiro Oswaldo Serrano apresentaram o Mintegra , metodologia Sistêmica para a Economia Circular em Evento promovidos por Universidades Européias." : "Celene Brito CEO of EcoRecitec and partner Oswaldo Serrano presented Mintegra, a Systemic methodology for the Circular Economy at an event promoted by European Universities."}
                        </p>
                        <div className="d-flex justify-content-between "></div>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="col-md-6">
                  <div
                    className="col"
                    style={{ margin: "auto", marginBottom: 10 }}
                  >
                    <a href="https://pt.linkedin.com/pulse/mindset-e-economia-circular-celene-brito-?trk=pulse-article" style={{ height: 500 }} className="card shadow-sm bg_card">
                    <img style={{
                          width: "70%",
                          minWidth: "250px",
                          borderRadius: 20,
                          height: "250px",
                          margin: "auto",
                          marginTop: 10,
                        }} src={Celene} alt=""/>

                      <div
                        className="card-body "
                        style={{ textAlign: "center", margin: "auto" }}
                      >
                        <h2 className="efeito-text jLink">{languagePage === "PT" ? "O Pensamento e os Saberes Circulares" : "Circular thinking and knowledge"}</h2>
                        <p className="card-text jDescricao">
                        {languagePage === "PT" ? "Autora: Brito, Celene- Doutoranda em Psicologia no Contexto da economia Circular- UCES. CEO/EcoRecitec- 2020, parte de Trabalho enviado à UCES. Introdução A inovação de produtos e serviços, com novas propostas de modelos de negócio, veja o artigo" : "Author: Brito, Celene- PhD Student in Psychology in the Context of the Circular Economy - UCES. CEO/EcoRecitec- 2020, part of the Work sent to UCES. Introduction Product and service innovation, with new business model proposals, see the article"}
                        </p>
                        <div className="d-flex justify-content-between "></div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div className="bodyQ">
          <div className="quadrado"></div>
        </div>
        <div
          style={{
            textAlign: "center",
            padding: 20,
            fontFamily: "Gotham-Bold",
            color: "#fff",
            borderTop: "solid",
          }}
        >
          2021 | Eco-Recitec | Todos os direitos reservados.
        </div>
      </Container>
    </div>

  );
};
export default Educacao;
