import React from 'react';
import { InputBaseProps } from '@material-ui/core';
import {
    forwardRef,
    useCallback,
    useRef,
    useState,
    useImperativeHandle
} from 'react';
import {
    Container,
    ContainerError,
    InputBase,
    TooltipError,
    AlertError,
    ContainerInput,
    Label
} from './style';
import { CSSProperties } from 'styled-components';
import { FiAlertCircle } from 'react-icons/fi';

export interface InputRefProps {
    getInputValue: () => string;
    setInputValue: (value: string) => void;
}

interface InputProps extends InputBaseProps {
    styleInput: CSSProperties;
    styleContainer: CSSProperties;
    diferTooltip?: boolean;
    errors?: string | null;
    label: string;
}

const InputMultiline: React.ForwardRefRenderFunction<InputRefProps, InputProps> = ({ errors, diferTooltip = false, styleInput, styleContainer, label, ...props }, ref) => {
    const [hoverError, setHoverError] = useState<boolean>(false)
    const inputRef = useRef<HTMLDivElement>(null)

    const getInputValue = useCallback(() => {
        return inputRef.current?.getElementsByTagName('textarea')[0].value ? inputRef.current?.getElementsByTagName('textarea')[0].value : ""
    }, [inputRef])

    const setInputValue = useCallback((newValue) => {
        document.getElementsByClassName(label)[0].getElementsByTagName("textarea")[0].value = newValue
    }, [])

    useImperativeHandle(ref, () => {
        return {
            getInputValue,
            setInputValue
        };
    });

    const focusInput = useCallback(() => {
        inputRef.current?.getElementsByTagName('textarea')[0].focus()
    }, [])

    return (
        <Container ref={(ref: any) => ref = ref} >
            <ContainerInput style={{ ...styleContainer }} error={!!errors}>
                <InputBase className={label} autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" {...props} style={{ ...styleInput }} ref={inputRef} />
                {!!errors &&
                    <ContainerError onClick={focusInput}>
                        <AlertError onMouseEnter={() => setHoverError(true)} onMouseLeave={() => setHoverError(false)} style={{ opacity: errors !== null ? 1 : 0, cursor: errors !== null ? "default" : "text" }}>
                            <FiAlertCircle color={"#AF0708"} size={"1.375rem"} style={{ padding: "0.3125rem", paddingRight: 0 }} />
                        </AlertError>
                        {hoverError &&
                            <TooltipError diferTooltip={diferTooltip}>{errors}</TooltipError>
                        }
                    </ContainerError>
                }
            </ContainerInput>
        </Container>
    )
}

export default forwardRef(InputMultiline);