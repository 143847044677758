import { Pagination } from '@material-ui/lab'
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'



interface PaginationProps {
    pages: number;
    disable: boolean;
    onChange: Function;
}

export interface PaginationRefProps {
    changeAtualPage: (value: number) => void;
}

const PaginationCustom: React.ForwardRefRenderFunction<PaginationRefProps, PaginationProps> = ({ pages, disable, onChange }, ref) => {
    const [atualPage, setAtualPage] = useState(1)

    const changeAtualPage = useCallback((newPage) => {
        setAtualPage(newPage)
    }, [])

    useImperativeHandle(ref, () => {
        return {
            changeAtualPage
        };
    });

    const clickInPagination = useCallback((event) => {

        let attribute = "-1"
        let newPage = -1

        const tagName = event.target.tagName

        if (tagName === "LI") return 0;
        else if (tagName === "path") attribute = event.target.getAttribute("d").split(" ")[0]
        else if (tagName === "svg") attribute = event.target.getElementsByTagName("path")[0].getAttribute("d").split(" ")[0]
        else if (tagName === "BUTTON") {
            if (event.target.getElementsByTagName("path").length > 0) attribute = event.target.getElementsByTagName("path")[0].getAttribute("d").split(" ")[0]
            else newPage = event.target.innerText * 1
        }

        if (attribute !== "-1") newPage = atualPage + (attribute === "M10" ? 1 : -1)

        if (newPage !== -1) {
            setAtualPage(newPage)
            onChange(newPage)
        }

    }, [atualPage])


    return (
        
        <Pagination ref={(ref:any) => ref = ref}  color="secondary" variant="outlined" shape="rounded" size="large" disabled={disable} onClick={clickInPagination} page={atualPage} count={pages} />
        
    )
}

export default forwardRef(PaginationCustom);