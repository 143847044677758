import styled from "styled-components";
import GothamBold from './assets/fonts/Gotham/GothamBold.ttf';
import GothamMedium from './assets/fonts/Gotham/GothamMedium.ttf';
import GothamLight from './assets/fonts/Gotham/GothamLight.ttf';
import GothamMediumItalic from './assets/fonts/Gotham/GothamMediumItalic.ttf';
import GothamLightItalic from './assets/fonts/Gotham/GothamLightItalic.ttf';

export const Container = styled.div`
/* Works on Chrome, Edge, and Safari */
    /* *::-webkit-scrollbar {
        border-radius: 20px;
        border: 3px solid #C4C4C4;
        height: 5px;
    }
    *::-webkit-scrollbar-thumb {
        background: #C4C4C4;
        height: 5px;
        border-radius: 20px;
    }
    *::-webkit-scrollbar-track-piece {
        height: 5px;
    } */
    @font-face {
        font-family: "Gotham-Bold";
        src: local("Gotham-Bold"),
        url(${GothamBold}) format("truetype");
    }
    @font-face {
        font-family: "Gotham-Medium-Italic";
        src: local("Gotham-Medium-Italic"),
        url(${GothamMediumItalic}) format("truetype");
    }
    @font-face {
        font-family: "Gotham-Light-Italic";
        src: local("Gotham-Light-Italic"),
        url(${GothamLightItalic}) format("truetype");
    }
    @font-face {
        font-family: "Gotham-Medium";
        src: local("Gotham-Medium"),
        url(${GothamMedium}) format("truetype");
    }
    @font-face {
        font-family: "Gotham-Light";
        src: local("Gotham-Light"),
        url(${GothamLight}) format("truetype");
    }
    font-family:'Gotham-Light';
`
