import styled from "styled-components";

const TryContainer = styled.section`
	grid-area: try;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	width: 100%;
	background-color: #000124;
	gap: 2em;
	padding: 2em;
	color: white;
	place-items: center;
	@media screen and (min-width: 769px) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: 1fr;
	}
	@media screen and (min-width: 321px) and (max-width: 375px) {
		width: 101%;
	}
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-family: Inter-Medium;
	line-height: 1.6;
	font-size: large;
	@media screen and (min-width: 769px) {
		font-size: x-large;
		padding: 2em;
	}
`;
const DescriptionOne = styled.p`
	margin-bottom: 1em;
`;
const DescriptionTwo = styled.p`
	margin-top: 1em;
`;
const ImageContainer = styled.div`
	width: 80%;
`;
const Image = styled.img`
	border-radius: 28px;
	width: 100%;
`;

const SectionTitleContainer = styled.div`
	padding: 5px 10px;
	margin-bottom: 2.2em;
	font-family: Inter-Bold;
	color: inherit;
	font-size: inherit;
	border: none;
	background-color: #e58417;
	border-radius: 28px;
	max-width: fit-content;
	line-height: normal;
`;

const SectionTitle = styled.h1``;

export {
	TryContainer,
	TextContainer,
	DescriptionOne,
	DescriptionTwo,
	Image,
	ImageContainer,
	SectionTitle,
	SectionTitleContainer,
};
