import React, { useEffect } from "react";

import { useGlobal } from "../../hooks/global";

export const Pdf: React.FC = () => {
  const { setAtualPageGlobal, languagePage } = useGlobal();

  useEffect(() => {
    setAtualPageGlobal("/pdf");
  }, []);
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <iframe
        src="https://firebasestorage.googleapis.com/v0/b/flashplayhrc-e8566.appspot.com/o/termoDeUso%2FTermo%20de%20uso%20da%20FlashPlay%20HR.pdf?alt=media&token=af39f517-1864-4ae4-9297-41e977e1f443"
        width="100%"
        height="100%"
        
      />
    </div>
  );
};