import styled from "styled-components";

const NavBarContainer = styled.nav`
	color: white;
	font-family: Inter-Medium;
	font-size: large;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1000;
	@media only screen and (min-device-width: 769px) {
		flex-direction: row;
		min-width: fit-content;
		position: relative;
	}
	@media only screen and (max-device-width: 768px) {
		left: 60px;
		/* min-width: 300px; */
		max-width: 600px;
		position: absolute;
		/* From https://css.glass */
		background: rgba(255, 255, 255, 0.18);
		border-top-left-radius: 0;
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 16px;
		border-top-right-radius: 16px;
		/* border-radius: 16px; */
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(5.7px);
		-webkit-backdrop-filter: blur(5.7px);
		border: 1px solid rgba(255, 255, 255, 0.24);
		/* border: none; */
	}
`;

const Link = styled.a`
	text-decoration: none;
	color: white;
	a,
	:hover,
	:focus,
	:active {
		text-decoration: none;
		color: inherit;
	}
	font-family: inherit;
	cursor: pointer;
`;
const NavLinkList = styled.ul`
	list-style: none;
	margin: 0px;
	padding: 0px;
`;
const LinkListItem = styled.li`
	margin: 5px 5px 5px 10px;
	padding: 8px;
	@media only screen and (min-device-width: 769px) {
		margin: 0px 0px 0px 50px;
		padding: 0px;
		display: inline-block;
	}
`;
const ContryFlag = styled.img`
	padding: 0 5px;
	cursor: pointer;
	:hover {
		filter: grayscale(70%);
	}
`;
const CountryFlagContainer = styled.div`
	display: flex;
	flex-direction: row;
	background: rgba(255, 255, 255, 0.25);
	/* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	@media screen and (max-device-width: 768px) {
		max-width: 110px;
	}
	@media only screen and (min-device-width: 769px) {
		margin-left: 40px;
	}
`;

const LogInOnContainer = styled.div`
	display: flex;
	flex-direction: row;
	@media only screen and (min-device-width: 769px) {
		margin-left: 40px;
		margin-right: 60px;
	}
`;
const LogInButton = styled.button`
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	border: none;
	background-color: transparent;
	cursor: pointer;
`;
const LogOnButton = styled.button`
	padding: 10px 20px;
	margin-left: 10px;
	/* min-width: 185px; */
	font-family: inherit;
	color: inherit;
	font-size: inherit;
	border: none;
	background-color: #e58417;
	border-radius: 28px;
	cursor: pointer;
`;

const ToggleNavBar = styled.button`
	align-self: flex-start;
	width: 35px;
	position: absolute;
	padding: 5px;
	/* From https://css.glass */
	background: rgba(255, 255, 255, 0.18);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	/* backdrop-filter: blur(5.7px);
	-webkit-backdrop-filter: blur(5.7px); */
	border: 1px solid rgba(255, 255, 255, 0.24);
	@media only screen and (min-device-width: 769px) {
		display: none;
	}
	color: #e58417;
`;

export {
	NavBarContainer,
	Link,
	LinkListItem,
	NavLinkList,
	ContryFlag,
	CountryFlagContainer,
	LogInOnContainer,
	LogInButton,
	LogOnButton,
	ToggleNavBar,
};
