import Dropzone from "react-dropzone";
import { useGlobal } from "../../hooks/global";

const DropZoneUploadContracts = ({ onUploadContracts }: any) => {
  const { languagePage } = useGlobal();
  const renderBack = (isDragActive: any, isDragReject: any) => {
    if (!isDragActive) {
      return "#fff";
    }
    if (isDragReject) {
      return "#e45454";
    }
    return "#78e5d5";
  };
  const renderDragMessage = (isDragActive: any, isDragReject: any) => {
    if (!isDragActive) {
      return languagePage === "PT" ? (
        <p style={{ color: "#fff" }}>Arraste o contrato aqui no formato PDF</p>
      ) : (
        <p style={{ color: "#fff" }}>Drag the contract here in PDF format</p>
      );
    }
    if (isDragReject) {
      return languagePage === "PT" ? (
        <p style={{ color: "#e45454" }}>Arquivo não suportado</p>
      ) : (
        <p style={{ color: "#e45454" }}>Unsupported file</p>
      );
    }
    return languagePage === "PT" ? (
      <p style={{ color: "#78e5d5" }}>Solte o contrato aqui</p>
    ) : (
      <p style={{ color: "#78e5d5" }}>Drop the contract here</p>
    );
  };

  return (
    <Dropzone accept="application/pdf" onDropAccepted={onUploadContracts}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <div
          {...getRootProps()}
          style={{
            borderWidth: 2,
            borderStyle: "dashed",
            borderColor: renderBack(isDragActive, isDragReject),
            borderRadius: 20,
            padding: 32,
            textAlign: "center",
          }}
        >
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive, isDragReject)}
        </div>
      )}
    </Dropzone>
  );
};

export default DropZoneUploadContracts;
