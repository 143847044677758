import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useGlobal } from "../../../hooks/global";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";

import { ButtonField, Container, InputField, TitleField } from "./styles";

export const CriarProposta: React.FC = () => {
  const { setAtualPageGlobal } = useGlobal();
  const { push } = useHistory();
  const { addToast } = useToast();
  const [category, setCategory] = useState<string>("");
  const [amountEstimate, setAmountEstimate] = useState<number>(0);
  const [hoursEmployed, setHoursEmployed] = useState<string | number>(0);
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    setAtualPageGlobal("/company/consultoria/criar-proposta");
  }, []);

  const handleCrateProposal = useCallback(
    async (event) => {
      event.preventDefault();
      if (
        category.length === 0 &&
        description.length === 0 &&
        amountEstimate <= 0 &&
        hoursEmployed <= 0
      ) {
        addToast({
          title: "Campos Vazios",
          description: "existe alguns campos nao preenchidos",
          icon: "alert",
          type: "info",
        });
        return;
      }
      try {
        const res = await api.post("/company/create-demand-proposal", {
          amountEstimate,
          description,
          hoursEmployed,
          category,
        });
        const demand = res.data;

        if (demand) {
          addToast({
            title: "Sucesso!",
            description: "sua proposta foi criada.",
            icon: "info",
            type: "success",
          });
          push("/company/consultoria/minhas-propostas");
        }
      } catch (err: any) {
        addToast({
          title: "Error!",
          description: err.response.data.message,
          icon: "alert",
          type: "error",
        });
        if (amountEstimate > 10000) {
          push("/company/planos");
        }
      }
    },
    [addToast, amountEstimate, category, description, hoursEmployed, push]
  );
  return (
    <Container>
      <TitleField>
        <h4>Criação de Proposta</h4>
      </TitleField>
      <InputField>
        <label>Qual área voltada:</label>
        <input
          type="text"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
      </InputField>
      <InputField>
        <label>Diga o máximo que quer gastar:</label>
        <input
          type="number"
          step={100}
          value={amountEstimate.toFixed(2)}
          onChange={(e) => {
            if (Number(e.target.value) < 0) setAmountEstimate(0);
            else setAmountEstimate(Number(e.target.value));
          }}
        />
      </InputField>
      <InputField>
        <label>Quantidade de horas quer que seja empregada:</label>
        <input
          type="number"
          value={hoursEmployed}
          onChange={(e) => setHoursEmployed(e.target.value)}
        />
      </InputField>
      <InputField>
        <label>Descrição:</label>
        <textarea
          value={description}
          placeholder="Descreva seu projeto"
          onChange={(e) => setDescription(e.target.value)}
        />
      </InputField>
      <ButtonField>
        <button onClick={handleCrateProposal}>Criar Proposta</button>
      </ButtonField>
    </Container>
  );
};
