import styled from "styled-components";

export const ContainerLoading = styled.div`
    display: flex;
    outline: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`

export const ContainerRadio = styled.div`

    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;

`