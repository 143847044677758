import React, { useState, useEffect, useCallback } from "react";

import { Container, BoxMessage } from "./styles";
import { FiSend } from "react-icons/fi";

import { useAuth } from "../../hooks/auth";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { useGlobal } from "../../hooks/global";

interface ChatProps {
  proposalId: string;
  socket: any;
}

export const Chat: React.FC<ChatProps> = ({ proposalId, socket }) => {
  dayjs.locale("pt-br");
  const [message, setMessage] = useState<string>("");
  const { user } = useAuth();
  const [messages, setMessages] = useState({});
  const { languagePage } = useGlobal();

  const handleMessage = useCallback(
    (e: any) => {
      e.preventDefault();
      socket.connect();
      socket.emit("message", {
        room: proposalId,
        user_id: user.id,
        username: user.name,
        message: message,
      });

      if (message.trim()) setMessage("");

      return () => {
        socket.disconnect();
      };
    },
    [message, proposalId, user.id, user.name, socket]
  );
  useEffect(() => {
    socket.connect();
    socket.emit(
      "select_proposal_room",
      {
        room: proposalId,
        user_id: user.id,
        username: user.name,
      },
      (messages: any) => {
        setMessages(messages);
      }
    );

    return () => {
      socket.disconnect();
    };
  }, [message, proposalId, user.id, user.name, socket]);

  useEffect(() => {
    socket.connect();
    const messageListener = (message: any) => {
      setMessages((prevMessages) => {
        const newMessages: any = { ...prevMessages };
        newMessages[message.id] = message;
        return newMessages;
      });
    };

    socket.on("message", messageListener);

    return () => {
      socket.disconnect();
    };
  }, [message, socket]);

  return (
    <Container>
      <div className="chat-header">
        <h6>Chat com consultor</h6>
      </div>
      <div className="chat-body">
        {[...Object.values(messages)]
          .sort((a: any, b: any) => a.createdAt - b.createdAt)
          .map((message: any) => (
            <BoxMessage left={message.username === user.name}>
              <span className="message-name">{message.username}</span>
              <span className="message-text">{message.text}</span>
              <span className="message-date">
                {dayjs(message.createdAt).format("ddd HH:MM")}
              </span>
            </BoxMessage>
          ))}
      </div>
      <form onSubmit={handleMessage} className="chat-footer">
        <input
          value={message}
          type="text"
          placeholder={
            languagePage === "PT"
              ? "Digite sua mensagem..."
              : "Type your message..."
          }
          onChange={(e) => setMessage(e.target.value)}
        />
        <button type="submit">
          <FiSend color="#fff" />
        </button>
      </form>
    </Container>
  );
};
