import { CircularProgress, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Container, Input, ButtonPayment, Div } from "./style";

import { FaFileInvoice, FaCreditCard } from "react-icons/fa";
import { loadStripe } from "@stripe/stripe-js";

import {
  useElements,
  CardElement,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import axios from "axios";
import jsonp from "jsonp";
import { useGlobal } from "../../../hooks/global";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`);

interface ModalPaymentProps {
  value: number;
  open: boolean;
  proposalId: any;
  onClose: Function;
  onFunction: Function;
}

const CardPaymentElement = ({ amount, proposalId, selectedPlan }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [state, setState] = useState("");
  const [paymentIntent, setPaymentIntent] = useState<any>();
  const [availablePlans, setAvailablePlans] = useState<any>();
  const [paymentId, setPaymentId] = useState<any>();
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);

  const verify = (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (state === "plans") {
      confirmPayment();
    } else {
      handleSubmit();
    }
  };

  const confirmPayment = async () => {
    const res = await api.post("/company/confirm-proposal-invoice", {
      paymentId,
      selectedPlan,
    });

    const { success, status } = res.data;

    if (success) {
      addToast({
        type: "success",
        title: "Pagamento finalizado!",
        description: status,
        icon: "info",
      });
      setLoading(false);
    }
  };
  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement) as any,
    });

    if (error) {
      addToast({
        type: "error",
        description: error.message ? error.message : "",
        title: "",
        icon: "info",
      });
      setLoading(false);
      return;
    }
    const response = await api.post("/company/check-details", {
      proposalValue: amount,
      proposalId: proposalId,
      paymentMethodId: paymentMethod?.id,
    });

    const { paymentIntent, availablePlans, paymentId } = response.data;

    if (paymentIntent && availablePlans) {
      setPaymentIntent(paymentIntent);
      setAvailablePlans(availablePlans);
      setPaymentId(paymentId);
      setState("plans");
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <h6>Inserir dados:</h6>
      <Div>
        <CardElement className="card" />
      </Div>
      {state === "plans" &&
        availablePlans.length !== 0 &&
        availablePlans.map((plan: any) => <div>{plan.count}</div>)}
      {state === "plans" && availablePlans.length === 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "24px 0px 0px",
          }}
        >
          <p>Nenhuma opção de parcelamento</p>
        </div>
      )}
      <ButtonPayment onClick={verify}>
        {loading === false ? (
          state === "plans" ? (
            "Finalizar compra"
          ) : (
            "Avançar"
          )
        ) : (
          <CircularProgress size={18} color="inherit" />
        )}
      </ButtonPayment>
    </div>
  );
};

const BoletoPaymentElement = ({ amount, proposalId, onClose }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const { languagePage } = useGlobal();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [dados, setDados] = useState<string>("");
  const [erro, setError] = useState<boolean>(false);
  const [cep, setCep] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [uf, setUf] = useState<string>("");


  const handleCEP = (e: any) => {
    setCep(mask(e.target.value));
  };

  const handleCPFOrCNPJ = (e: any) => {
    setDados(mask(e.target.value));
  };

  const mask = (v: string) => {
    v = v.replace(/\D/g, "");

    if (v.length <= 8) {
      v = v.replace(/(\d{5})(\d{3})$/, "$1-$2");
    } else if (v.length > 8 && v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v;
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (erro) {
      setLoading(false);
      return;
    }
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const formatDados = dados.replace(/\D/g, "");
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "boleto",
      boleto: { tax_id: formatDados },
      billing_details: {
        name: name,
        email: email,
        address: {
          line1: address,
          city: city,
          state: uf,
          postal_code: cep,
          country: "BR",
        },
      },
    });

    if (error) {
      addToast({
        type: "error",
        description: error.message ? error.message : "",
        title: "",
        icon: "info",
      });
      setLoading(false);
      return;
    }
    const response = await api.post("/company/check-details", {
      proposalValue: amount,
      proposalId: proposalId,
      paymentMethodId: paymentMethod?.id,
    });

    const { paymentIntent } = response.data;

    const { error: errorT } = await stripe.confirmBoletoPayment(paymentIntent, {
      payment_method: {
        boleto: { tax_id: formatDados },
        billing_details: {
          name: name,
          email: email,
          address: {
            line1: address,
            city: city,
            state: uf,
            postal_code: cep,
            country: "BR",
          },
        },
      },
    });

    if (errorT) {
      addToast({
        type: "error",
        description: errorT.message ? errorT.message : "",
        title: "",
        icon: "info",
      });
      return;
    }
    setLoading(false);
    addToast({
      type: "success",
      title: "Etapa concluída",
      icon: "info",
      description: "aguardando o pagamento",
    });
    onClose();
  };

  const validateCNPJ = async (e: any) => {
    if (dados.length > 14) {
      const formated = dados.replace(/\D/g, "");
      jsonp(
        `https://www.receitaws.com.br/v1/cnpj/${formated}`,
        {},
        (err, res) => {
          if (err) {
            addToast({
              title: "Erro",
              description:
                languagePage === "PT"
                  ? "Ocorreu um erro, por favor tente novamente"
                  : "An error occurred. Please try again",
              icon: "alert",
              type: "error",
            });
            setError(true);
          } else {
            const { situacao } = res;

            if (situacao !== "ATIVA") {
              addToast({
                title: "Erro",
                description:
                  languagePage === "PT"
                    ? "CNPJ inválido ou não existe"
                    : "Invalid CNPJ or does not exist",
                icon: "alert",
                type: "error",
              });
              setError(true);
            } else {
              setError(false);
            }
          }
        }
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ padding: 16, borderRadius: 8, flexDirection: "column" }}>
        <div style={{ margin: "10px 0" }}>
          <label>Nome</label>
          <Input
            id="name"
            value={name}
            name="name"
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div style={{ margin: "10px 0" }}>
          <label>CPF/CNPJ</label>
          <Input
            id="tax_id"
            name="tax_id"
            required
            maxLength={18}
            value={dados}
            onChange={handleCPFOrCNPJ}
            onBlur={validateCNPJ}
          />
        </div>

        <div style={{ margin: "10px 0" }}>
          <label>E-mail</label>
          <Input
            type="email"
            value={email}
            id="email"
            name="email"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ margin: "10px 0", width: 160, marginRight: 16 }}>
            <label>CEP</label>
            <Input
              id="postal_code"
              name="postal_code"
              type="tel"
              required
              maxLength={9}
              value={cep}
              onChange={handleCEP}
              onBlur={async () => {
                const formatCep = cep.replace(/\D/g, "");
                if (formatCep !== "" && formatCep.length >= 8) {
                  const res = await axios.get(
                    `https://viacep.com.br/ws/${formatCep}/json/`
                  );

                  const { uf, logradouro, localidade } = res.data;

                  setUf(uf);
                  setCity(localidade);
                  setAddress(logradouro);
                }
              }}
            />
          </div>
          <div style={{ margin: "10px 0", flex: 1 }}>
            <label>Endereço</label>
            <Input
              value={address}
              id="address"
              name="address"
              required
              onChange={(e) => setAddress(e.target.value)}
              disabled={address.length > 0 || city.length > 0 ? false : true}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ margin: "10px 0", flex: 1 }}>
            <label>Cidade</label>
            <Input
              value={city}
              id="city"
              name="city"
              required
              disabled={city.length > 0 ? false : true}
            />
          </div>

          <div style={{ margin: "10px 0", width: 100, marginLeft: 16 }}>
            <label>Estado</label>
            <Input
              value={uf}
              id="state"
              name="state"
              required
              disabled={uf.length > 0 ? false : true}
            />
          </div>
        </div>

        <div id="error-message" role="alert"></div>
      </div>
      <ButtonPayment onClick={handleSubmit}>
        {loading === false ? (
          "Finalizar pagamento"
        ) : (
          <CircularProgress size={18} color="inherit" />
        )}
      </ButtonPayment>
    </div>
  );
};

export const ModalPayment: React.FC<ModalPaymentProps> = ({
  value,
  open,
  proposalId,
  onClose,
  onFunction,
}) => {
  const [state, setState] = useState<string>("");

  useEffect(() => {
    return setState("");
  }, [open]);
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <p>
          Para concluir sua proposta é preciso efetuar o último pagamento no
          valor de:
        </p>
        <h4>
          {Intl.NumberFormat("pt-BR", {
            currency: "brl",
            style: "currency",
          }).format(value)}
        </h4>
        {state === "" && (
          <>
            <h6>Escolha uma forma de pagamento:</h6>
            <div>
              <Button onClick={() => setState("card")}>
                <FaCreditCard size={48} style={{ paddingBottom: 8 }} />
                Cartão de crédito/débito
              </Button>
              <Button onClick={() => setState("boleto")} disabled={true}>
                <FaFileInvoice size={48} style={{ paddingBottom: 8 }} />
                Boleto
              </Button>
            </div>
          </>
        )}
        {state === "card" && (
          <>
            <Elements stripe={stripePromise}>
              <CardPaymentElement amount={value} proposalId={proposalId} />
            </Elements>
          </>
        )}
        {state === "boleto" && (
          <>
            <Elements stripe={stripePromise}>
              <BoletoPaymentElement
                amount={value}
                proposalId={proposalId}
                onClose={() => onClose()}
              />
            </Elements>
          </>
        )}
      </Container>
    </Modal>
  );
};
