import React from "react";
import {
    float, 
    myFloat
} from "./styles"
import { IoLogoWhatsapp } from "react-icons/io5";

import { Button } from '@mui/material';

const WhatsAppButton: React.FC  = () => {

    return (
        <>
        <Button style={float}><a
                        href="https://api.whatsapp.com/send?phone=5571986956150"
                        target="_blanck"
                        style={myFloat}
                      ><IoLogoWhatsapp color="white" size={35} />
                      </a></Button>
        </>
    );

};

export default WhatsAppButton;